import styled from "styled-components";

// Had to do this because Typescript yelled at me
// import { Container as OriginalContainer } from "../../../containers/QuickLook/styled";

// export const Container = styled(OriginalContainer)`
//   flex-direction: column;
//   align-self: flex-start;
// `;

export const Container = styled.div``;

export const ResultsHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;

  h2 {
    font-weight: normal;
    font-size: 3rem;
    margin: 0;
  }
`;

export const HashTagList = styled.ul`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: 50px;
  align-items: center;

  li {
    list-style-type: none;
    border: 2px solid ${(props) => props.theme.pallet.black};
    padding: 8px;
    border-radius: ${(props) => props.theme.utils.borderRadius};
    font-size: 12px;
    margin: 5px;

    span {
      margin-left: 10px;
    }
  }
`;

export const PostList = styled.ul`
  padding: 0;
  width: 100%;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: minmax(200px, 1fr);
  /* grid-template-columns: repeat(auto-fill, 200px) ;
  grid-auto-rows: 200px; */
  grid-gap: 0.5vw;

  li {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.15s ease-in-out;
    cursor: pointer;

    img {
      max-width: 100%;
    }

    &:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      transition: transform 0.4s;
      transform-style: preserve-3d;
    }

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      overflow: hidden;
    }

    .flip-card-front {
      background: linear-gradient(
        135deg,
        #4b5acb 0%,
        #c134a2 50%,
        #fcbe45 100%
      );
      padding: 4px;

      > div {
        background-size: cover;
        background-position: center;
      }
    }

    .flip-card-back {
      background: #4b5acb;
      background: linear-gradient(
        135deg,
        #4b5acb 0%,
        #c134a2 50%,
        #fcbe45 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

      color: white;
      transform: rotateY(180deg);
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 10px;
        border-radius: 50%;
        width: 44px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: ${(props) => props.theme.animations.default};

        &:first-of-type {
          margin-right: 20px;
        }

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
`;
