import styled from "styled-components";

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
`;

export const Actions = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;

  li {
    list-style-type: none;
    cursor: pointer;

    &:hover,
    &:hover svg {
      color: ${(props) => props.theme.pallet.primary};
    }
  }
`;

export const TextInputContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;

  textarea {
    position: absolute;
    left: 0;
    opacity: 0;

    &:focus {
      outline: none;
    }
  }

  .textValue {
    display: flex;
    align-self: center;
    flex-direction: column;

    p {
      align-self: center;
      font-weight: 600;
      line-height: 1;
      margin-top: 5px;
    }
  }
`;

export const ColorList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-evenly;
`;

export const ColorListItem = styled.li<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  list-style-type: none;
  width: 30px;
  height: 30px;
  border: 2px solid white;
  border-radius: 50%;

  &:first-of-type() {
    margin-left: 0;
  }
`;
