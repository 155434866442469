import styled from "styled-components";
import { Stack } from "components/Facelift/shared";
import { DotsHorizontalRounded } from "@styled-icons/boxicons-regular/DotsHorizontalRounded";

export const MenuIconContainer = styled.div`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  padding: 2px;
  border-radius: 8px;
  /* background: var(--color-100); */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  appearance: none !important;
  /* :hover {
    box-shadow: var(--shadow);
  } */
`;

export const MenuIcon = styled(DotsHorizontalRounded)`
  width: 100%;
  height: 100%;
  display: block;
  color: var(--color);
`;

export const BucketMenuDropdown = styled(Stack)`
  border-radius: 5px;
  background: white;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  overflow: hidden;
  padding: 0.25rem;
`;

export const BucketMenuControl = styled.div`
  all: unset;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background: inherit;
  display: flex;
  align-items: center;
  color: #7f7cc3;
  background: white;
  border-radius: 2px;

  &:hover {
    color: white;
    background: #7f7cc3;
  }

  &:focus {
    color: white;
    background: #7f7cc3;
  }
`;

export const BucketMenuAction = styled.span`
  color: inherit;
  min-width: 18ch;
  letter-spacing: 0.25px;
  font-size: 13px;
  line-height: 1;
  white-space: nowrap;
  width: 100%;
`;

export const BucketKeypress = styled.kbd`
  margin: 0px 0.1em;
  padding: 0.1em 0.6em;
  border-radius: 3px;
  color: white;
  /* line-height: 1.4; */
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  display: inline-block;
  text-transform: uppercase;
  background: #635ec0;
  opacity: 0.8;
`;
