import * as React from "react";
import styled from "styled-components";
import Dropzone from "react-dropzone";
import Modal, { Styles } from "react-modal";
import ReactCrop, { Crop } from "react-image-crop";
import Button from "../ui/Button";

import "react-image-crop/dist/ReactCrop.css";

const modalStyles: Styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, .8)"
  },
  content: {
    backgroundColor: "#211549",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  }
};

const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
`;

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

interface IPostCropperModalProps {
  setCroppedImageUrl: (croppedImageUrl: any) => void;
  isOpen: boolean;
  toggleModal: (isOpen: boolean) => void;
}

interface IPostCropperModalState {
  src: string | ArrayBuffer | null;
  crop: ReactCrop.Crop;
}

class PostCropperModal extends React.Component<
  IPostCropperModalProps,
  IPostCropperModalState
> {
  imageRef: HTMLImageElement = new Image();
  fileUrl: any;

  constructor(props: IPostCropperModalProps) {
    super(props);

    this.state = {
      src: "",
      crop: {
        aspect: 9 / 16,
        width: 350
      }
    };
  }

  onSelectFile = (acceptedFiles: any) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        this.setState({ src: reader.result });
        this.props.toggleModal(true);
      });

      reader.readAsDataURL(acceptedFiles[0]);
    }
  };

  async makeClientCrop(crop: Crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );

      this.props.setCroppedImageUrl(croppedImageUrl);
    }
  }

  onImageLoaded = (image: HTMLImageElement) => {
    this.imageRef = image;
  };

  onCropComplete = (crop: Crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: Crop) => {
    this.setState({ crop });
  };

  getCroppedImg(image: HTMLImageElement, crop: Crop, fileName: string) {
    if (
      crop.width &&
      crop.height &&
      crop.x !== undefined &&
      crop!.x >= 0 &&
      crop.y !== undefined &&
      crop!.y >= 0
    ) {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width * scaleX;
      canvas.height = crop.height * scaleY;
      const ctx = canvas.getContext("2d");

      ctx!.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );

      return new Promise((resolve, reject) => {
        canvas.toBlob((blob: any) => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve(this.fileUrl);
        }, "image/jpeg");
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Dropzone
          onDrop={(acceptedFiles) => this.onSelectFile(acceptedFiles)}
          accept="image/*"
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <UploadContainer {...getRootProps()}>
              <input {...getInputProps()} />
              {/* <p className="upload-text">Drag 'n' drop some files here, or click to select files</p> */}
              <p className="upload-text">
                Drag & drop to upload an image or click to browse
              </p>
            </UploadContainer>
          )}
        </Dropzone>

        <Modal
          isOpen={this.props.isOpen}
          onRequestClose={() => this.props.toggleModal(false)}
          contentLabel="Cropping Modal"
          style={modalStyles}
        >
          {this.state.src && (
            <ReactCrop
              src={this.state.src as string}
              crop={this.state.crop}
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
              style={{ marginBottom: "30px" }}
            />
          )}
          <Button
            type="button"
            text="Accept"
            onClick={() => this.props.toggleModal(false)}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

export default PostCropperModal;
