import styled from "styled-components";

export const SidebarContainer = styled.div<{ hideSidebar: boolean }>`
  background: ${(props) => props.theme.pallet.sidebarBackground};
  display: ${(props) => (props.hideSidebar ? "none" : "flex")};
  justify-content: space-between;

  @media screen and (min-width: ${(props) => props.theme.breakPoints.medium}) {
    width: 80px;
    padding-top: 10px;
    padding-bottom: 10px;
    flex-direction: column;
  }
`;

export const SidebarInner = styled.div`
  background: ${(props) => props.theme.pallet.sidebarBackground};
  width: 100vw;
  height: 70px;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${(props) => props.theme.breakPoints.medium}) {
    position: fixed;
    width: 80px;
    height: 100vh;
    flex-direction: column;
  }
`;

export const SidebarLinks = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    width: 45px;
    height: 45px;
    margin: 0 10px;
    display: flex;

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      transition: all 0.15s ease-in-out;

      &.active {
        background-color: ${(props) => props.theme.pallet.primary};
      }

      &:hover {
        transform: scale(1.2);
      }
    }

    img {
      width: 55%;
    }

    button {
      padding: 0;
      border: none;
      background: transparent;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakPoints.medium}) {
    flex-direction: column;

    li {
      margin: 10px 0;
      width: 50px;
      height: 50px;
    }
  }
`;
