import React from "react";
import * as Checkbox from "@radix-ui/react-checkbox";
import styled from "styled-components";

const Indeterminate = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path d="M12 0H2a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V2a2 2 0 00-2-2zm-1 8H3V6h8v2z"></path>
  </svg>
);

const Checked = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <path d="M2 0a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V2a2 2 0 00-2-2H2zm4 10.414L3.293 7.707l1.414-1.414L6 7.586l3.793-3.793 1.414 1.414L6 10.414z"></path>
  </svg>
);

function ShareCheckbox({ checked }: { checked: "indeterminate" | boolean }) {
  return (
    <CheckboxRoot checked={checked}>
      <CheckboxIndicator asChild>
        <>
          {checked === "indeterminate" && <Indeterminate />}
          {checked === true && <Checked />}
        </>
      </CheckboxIndicator>
    </CheckboxRoot>
  );
}

export default ShareCheckbox;

const CheckboxRoot = styled(Checkbox.Root)`
  all: unset;
  box-sizing: border-box;
  background: white;
  width: 18px;
  height: 18px;
  border-radius: 6px;
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #635ec0;

  svg {
    width: 100%;
    height: 100%;
    color: #635ec0;
    fill: #635ec0;
  }
`;

const CheckboxIndicator = styled(Checkbox.Indicator)``;
