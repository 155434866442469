import styled from "styled-components";
import { motion } from "framer-motion";

export const defaultModalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, .8)",
    zIndex: 11,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    padding: 0,
    top: "initial",
    right: "initial",
    bottom: "initial",
    left: "initial",
    border: "none",
    borderRadius: "10px"
  }
};

export const Container = styled(motion.div)`
  padding: 20px;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.mm.white};
  max-width: 550px;

  h2 {
    border-bottom: 1px solid ${(props) => props.theme.mm.white};
    padding-bottom: 5px;
    margin-top: 5px;
  }

  .confirm-text {
    margin: 20px 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

export const ConfirmButton = styled.button`
  background-color: ${(props) => props.theme.mm.altTwo};
  padding: 6px 20px;
  outline: none;
  display: flex;
  border: 2px solid white;
  transition: ${(props) => props.theme.animations.default};
  cursor: pointer;
  border: none;
  color: ${(props) => props.theme.mm.white};
  border-radius: 5px;

  &:hover {
    transform: scale(1.1);
  }

  &:first-child {
    margin-right: 10px;
  }

  &:disabled {
    background-color: rgba(230, 160, 0, 0.9);
    cursor: not-allowed;

    /* Keep the hover state while an animation plays */
    &:hover {
      transform: initial;
    }

    svg {
      animation: App-logo-spin infinite 2s linear;
    }
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const CancelButton = styled(ConfirmButton)`
  background-color: transparent;
`;
