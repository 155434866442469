import React from "react";
import styled from "styled-components";
import * as Tooltip from "@radix-ui/react-tooltip";

interface TooltipEntryProps {
  children: React.ReactNode;
  message: string | React.ReactNode;
  side?: "bottom" | "left" | "right" | "top";
  sideOffset?: number;
  offset?: number;
}

function TooltipEntry({
  children,
  message,
  sideOffset = 4,
  side = "top",
  offset = 0
}: TooltipEntryProps) {
  return (
    <Root delayDuration={100}>
      <Trigger asChild>{children}</Trigger>
      <Content side={side} sideOffset={sideOffset}>
        <Arrow offset={offset} />
        {message}
      </Content>
    </Root>
  );
}

export default TooltipEntry;

const Root = styled(Tooltip.Root)``;

const Trigger = styled(Tooltip.Trigger)`
  cursor: default !important;
`;

const Content = styled(Tooltip.Content)`
  border-radius: 4px;
  padding: 0.5rem 0.75rem;
  font-size: 12.5px;
  letter-spacing: 0.1px;
  line-height: 1;
  color: white;
  background: rgba(46, 53, 57, 1);
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
`;

const Arrow = styled(Tooltip.Arrow)`
  fill: rgba(46, 53, 57, 1);
`;
