export enum STEPS {
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  FIVE = "FIVE",
  SIX = "SIX",
  SEVEN = "SEVEN",
  EIGHT = "EIGHT",
  NINE = "NINE",
  TEN = "TEN",
  ELEVEN = "ELEVEN",
  TWELVE = "TWELVE",
  THIRTEEN = "THIRTEEN",
  FOURTEEN = "FOURTEEN",
  FIFTEEN = "FIFTEEN",
  SIXTEEN = "SIXTEEN",
  SEVENTEEN = "SEVENTEEN",
  EIGHTEEN = "EIGHTEEN"
}

export interface archetype {
  title: string;
  description: string;
}

export interface archetypeAnswers {
  archetype: string;
  favoritePart: string;
  why: string;
}

export interface beniftStatementAnswers {
  beniftType: string;
  favoritePart: string;
  why: string;
}
