import styled from "styled-components";

export const AbstractImage = styled.img`
  position: absolute;
  top: -20px;
  right: -20px;
  width: 50%;
  z-index: -2;
`;

export const QuestionsContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0vh auto;
  align-items: center;
  width: 80% h2 {
    font-size: 2.3rem;
    line-height: 1.5;
    margin-top: 0;
  }

  h3 {
    font-size: 1.6rem;
  }
`;

interface IQuestionProps {
  active: boolean;
}

export const Question = styled.div<IQuestionProps>`
  margin-top: 75px;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 30px;
  transition: all 1s;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  border-radius: 25px;
  position: relative;
  width: 100%;
  max-width: 1000px;
  box-shadow: -5px 5px 4px 0px rgba(0, 0, 0, 0.3);
`;

interface IButtonProps {
  largeMargin?: boolean;
}

export const QuestionOffsetBackground = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: -15px;
  left: -15px;
  background-color: rgba(250, 166, 26, 0.9);
  z-index: -1;
  border-radius: 25px;
`;

export const Button = styled.button<IButtonProps>`
  background-color: rgba(250, 166, 26, 1);
  border: none;
  min-width: 150px;
  height: 50px;
  border-radius: 25px;
  color: white;
  outline: none;
  margin: ${(props) => (props.largeMargin ? "75px 0" : 0)};
  transition: all 0.15s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
