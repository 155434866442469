import axios from "axios";
import AuthStorage from "./AuthStorage";
import { getOrganizationFromURI } from "./facelift";

export default () => {
  // Set Base URL
  axios.defaults.baseURL = `${process.env.REACT_APP_API_HOST}api/v1`;

  // Check localstorage for auth token and orgId
  axios.interceptors.request.use(
    (config) => {
      const { token, orgId, studyId } = AuthStorage.getToken();
      if (token) {
        config.headers.common["Authorization"] = `Bearer ${token}`;
        if (orgId) {
          config.headers.common["Organization-Id"] = orgId;
        }
        if (studyId) {
          // console.log(`setting study id: `, studyId);
          config.headers.common["Study-Id"] = studyId;
        }
      } else {
        config.headers.common["Authorization"] = null;
        config.headers.common["Organization-Id"] = null;
      }
      if (token && getOrganizationFromURI() === "localhost") {
        config.headers.common["Authorization"] = `Bearer ${token}`;
        config.headers.common[
          "Organization-Id"
        ] = `${process.env.REACT_APP_SYMPLER_ORG_ID}`;
        config.headers.common[
          "Study-Id"
        ] = `${process.env.REACT_APP_SYMPLER_STUDY_ID}`;
        console.log(
          "Running on localhost using environment variables REACT_APP_SYMPLER_ORG_ID: " +
            config.headers.common["Organization-Id"] +
            " and REACT_APP_SYMPLER_STUDY_ID: " +
            config.headers.common["Study-Id"]
        );
      }
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Handle Errors
  axios.interceptors.response.use(
    function(response) {
      // Do something with response data
      return response;
    },
    function(error) {
      // Do something with response error
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        return Promise.reject({
          status: error.response.data.status,
          errorMessage: error.response.data.message
        });
      }
      return Promise.reject({
        status: "failed",
        errorMessage: "An unknown error occurred. Please contact support"
      });
    }
  );
};
