import * as React from "react";
import { Container, LoadingContainer } from "./styled";

interface PostLoadingProps {
  isLoading: boolean;
}

const PostLoading: React.SFC<PostLoadingProps> = ({ isLoading }) => (
  <Container isLoading={isLoading} data-html2canvas-ignore>
    <LoadingContainer>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LoadingContainer>
    <p>Uploading Post</p>
  </Container>
);

export default PostLoading;
