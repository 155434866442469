import { useMemo } from "react";
import { useOptions, useResponses, useQuestions, useGrid } from "hooks";
import { getMediaInfo, stringToRegex } from "utils/facelift";
import { Response } from "types/facelift";

function useGridResponses() {
  const { options, selectedFilters } = useOptions();
  const gridResponses = useResponses((s) => s.gridResponses);
  const currentQuestion = useQuestions((s) => s.currentQuestion);
  const searchTerm = useGrid((s) => s.searchTerm);
  const selectedUser = useGrid((s) => s.selectedUser);

  return useMemo(() => {
    const entries = Object.entries(selectedFilters)
      .filter((item) => item[1].length > 0)
      .map((item) => [
        options.find((option) => option.displayName === item[0])?.columnName,
        item[1]
      ]);

    const optionsToCheck = Object.fromEntries(entries);

    function isCurrentQuestion(response: Response) {
      if (currentQuestion === null) return true;

      return Number(response.question_no) === currentQuestion;
    }

    function containsSearchWord(response: Response) {
      if (searchTerm.length === 0) return true;

      const isRegex = searchTerm.startsWith("/");

      if (isRegex) {
        const regex = stringToRegex(searchTerm);
        return regex.test(response.text);
      }

      const { isJpeg, isPng, isAudio, isVideo, isGif } = getMediaInfo(
        response.media_url
      );

      if (searchTerm === "video") {
        if (isVideo) return true;
      }

      if (searchTerm === "gif" || searchTerm === "animated") {
        if (isGif) return true;
      }

      if (
        searchTerm === "image" ||
        searchTerm === "photo" ||
        searchTerm === "still"
      ) {
        if (isJpeg || isPng) return true;
      }

      if (searchTerm === "audio") {
        if (isAudio) return true;
      }

      if (response.username.toLowerCase().includes(searchTerm?.toLowerCase())) {
        return true;
      }

      if (
        response.media_url.toLowerCase().includes(searchTerm?.toLowerCase())
      ) {
        return true;
      }

      if (response.text.toLowerCase().includes(searchTerm?.toLowerCase())) {
        return true;
      }

      if (response.topic.toLowerCase().includes(searchTerm?.toLowerCase())) {
        return true;
      }

      if (response.subtopic.toLowerCase().includes(searchTerm?.toLowerCase())) {
        return true;
      }

      return false;
    }

    function passesFilters(response: Response) {
      for (let option in optionsToCheck) {
        const responseOption = response[option as keyof Response];
        const stateOption = optionsToCheck[option];

        if (!stateOption.includes(responseOption)) {
          return false;
        }
      }
      return true;
    }

    function isSelectedUser(response: Response) {
      if (selectedUser === null) return true;

      return response.uid === selectedUser.uid;
    }

    const responses = gridResponses
      .filter(isCurrentQuestion)
      .filter(containsSearchWord)
      .filter(passesFilters)
      .filter(isSelectedUser);

    // Overwrite default sort order if viewing individual respondent data
    if (selectedUser !== null) {
      responses.sort((a, b) => {
        return Number(a.question_no) > Number(b.question_no) ? 1 : -1;
      });
    }

    return responses;
  }, [
    options,
    selectedFilters,
    gridResponses,
    currentQuestion,
    searchTerm,
    selectedUser
  ]);
}

export default useGridResponses;
