import { useEffect } from "react";
import { isWindows, isMacOs } from "react-device-detect";
import { useOverlays } from "hooks";

function useKeyboardShortcuts() {
  const toggleBucketContainer = useOverlays((s) => s.toggleBucketContainer);

  useEffect(() => {
    function ctrlEHandler(event: KeyboardEvent) {
      if (isMacOs) {
        if (event.metaKey && event.key === "e") {
          event.preventDefault();
          toggleBucketContainer();
        }
      } else if (isWindows) {
        if (event.ctrlKey && event.key === "e") {
          event.preventDefault();
          toggleBucketContainer();
        }
      }
    }

    window.addEventListener("keydown", ctrlEHandler);

    return () => window.removeEventListener("keydown", ctrlEHandler);
  }, [toggleBucketContainer]);
}

export default useKeyboardShortcuts;
