import * as React from "react";
import Modal from "react-modal";
import {
  defaultModalStyles,
  Container,
  ButtonsContainer,
  CancelButton,
  ConfirmButton
} from "../../../assets/styles/modals";

interface IConfirmModalProps {
  isOpen: boolean;
  contentLabel: string; // a string describing what the modal is for
  title: string;
  confirmMessage: string;
  cancelText: string;
  cancelAction: () => void;
  confirmText: string;
  confirmAction: () => void;
}

export default ({
  isOpen,
  contentLabel,
  title,
  confirmMessage,
  cancelText,
  cancelAction,
  confirmText,
  confirmAction
}: IConfirmModalProps) => (
  <Modal isOpen={isOpen} style={defaultModalStyles} contentLabel={contentLabel}>
    <Container>
      <h2>{title}</h2>
      <p className="confirm-text">{confirmMessage}</p>

      <ButtonsContainer>
        <CancelButton onClick={cancelAction}>{cancelText}</CancelButton>
        <ConfirmButton onClick={confirmAction}>{confirmText}</ConfirmButton>
      </ButtonsContainer>
    </Container>
  </Modal>
);
