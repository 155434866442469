import React from "react";
import styled from "styled-components";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { Stack } from "components/Facelift/shared";
import { useBucketsMutation } from "hooks/useRQResponses";
import {
  AlertStack,
  Overlay,
  Title,
  Description,
  AlertCancel,
  AlertAction
} from "./styled";
import { Bucket } from "types/facelift";

interface BucketRemoveShareModalProps {
  bucket: Bucket;
  isShareRemovalDialogOpen: boolean;
  setShareRemovalDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}

type ButtonEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

function BucketRemoveShareModal({
  bucket,
  isShareRemovalDialogOpen,
  setShareRemovalDialogOpen,
  children
}: BucketRemoveShareModalProps) {
  const { removeSelfFromSharedBucket } = useBucketsMutation();

  function handleRemove(event: ButtonEvent) {
    event.stopPropagation();

    removeSelfFromSharedBucket.mutate(bucket.id);
  }

  function handleCancel(event: ButtonEvent) {
    event.stopPropagation();
  }

  return (
    <AlertRoot
      open={isShareRemovalDialogOpen}
      onOpenChange={(open) => setShareRemovalDialogOpen(open)}
    >
      <AlertTrigger asChild>{children}</AlertTrigger>

      <AlertOverlay asChild>
        <Overlay />
      </AlertOverlay>

      <AlertContent asChild>
        <AlertStack type="column" gap={1}>
          <Stack
            type="column"
            gap={0.25}
            style={{ gridTemplateColumns: "minmax(0, 1fr)" }}
          >
            <AlertTitle asChild>
              <Title>Are you sure?</Title>
            </AlertTitle>
            <AlertDescription asChild>
              <Description>
                This will remove your access to this bucket. This cannot be
                undone unless the bucket owner shares with you again.
              </Description>
            </AlertDescription>
          </Stack>
          <Stack type="row" gap={1} style={{ justifyContent: "flex-end" }}>
            <AlertDialog.Cancel asChild>
              <AlertCancel type="button" onClick={handleCancel}>
                Cancel
              </AlertCancel>
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <AlertAction type="button" onClick={handleRemove}>
                Remove Bucket
              </AlertAction>
            </AlertDialog.Action>
          </Stack>
        </AlertStack>
      </AlertContent>
    </AlertRoot>
  );
}

export default BucketRemoveShareModal;

const AlertRoot = styled(AlertDialog.Root)``;

const AlertTrigger = styled(AlertDialog.Trigger)``;

const AlertOverlay = styled(AlertDialog.Overlay)``;

const AlertTitle = styled(AlertDialog.Title)``;

const AlertDescription = styled(AlertDialog.Description)``;

const AlertContent = styled(AlertDialog.Content)``;
