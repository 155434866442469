import * as React from "react";
import styled from "styled-components";
import { FieldProps } from "formik";

import { archetype, STEPS } from "./types";
import { IFormValues } from "./index";
import { Question, Button, QuestionOffsetBackground } from "./styled";
import QuestionPlaceHolder from "./QuestionPlaceHolder";

const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: transparent;
  padding: 10px;
  outline: #211549;
`;

interface IWhyTextBoxProps extends FieldProps<IFormValues> {
  id: string;
  unlocked: boolean;
  steps: STEPS[];
  question: string;
  archetype: archetype;
  scrollTo: (
    event: React.MouseEvent<HTMLElement>,
    toStep: STEPS,
    speed: number
  ) => void;
}

class WhyTextBox extends React.Component<IWhyTextBoxProps, {}> {
  constructor(props: IWhyTextBoxProps) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    this.props.scrollTo(e, this.props.steps[1], 2000);
  }

  handleOnChange(e: React.FormEvent<HTMLTextAreaElement>) {
    this.props.form.setFieldTouched(this.props.field.name, true);
    this.props.form.handleChange(e);
  }

  render() {
    const { question } = this.props;

    if (!this.props.unlocked) {
      return <QuestionPlaceHolder />;
    }

    const fieldValue: keyof IFormValues = this.props.form.values[
      this.props.field.name as keyof IFormValues
    ] as keyof IFormValues;

    return (
      <Question
        id={this.props.id}
        active
        onClick={(e: React.MouseEvent<HTMLElement>) =>
          this.props.scrollTo(e, this.props.steps[0], 2000)
        }
      >
        <QuestionOffsetBackground />

        <h2>{question}</h2>
        <Textarea
          name={this.props.field.name}
          value={fieldValue}
          onClick={(e: React.MouseEvent<HTMLTextAreaElement>) =>
            e.stopPropagation()
          }
          onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
            this.handleOnChange(e)
          }
          onBlur={this.props.form.handleBlur}
        ></Textarea>

        <Button
          type="button"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            this.handleOnClick(e)
          }
          disabled={fieldValue.length < 1}
        >
          Next
        </Button>
      </Question>
    );
  }
}

export default WhyTextBox;
