import React from "react";
import styled from "styled-components";
import { useGrid } from "hooks";
import { Stack } from "components/Facelift/shared";
import DefaultProfilePic from "assets/images/default-profile-white.png";

function handleImageError(
  event: React.SyntheticEvent<HTMLImageElement, Event>
) {
  (event.target as HTMLImageElement).src = DefaultProfilePic;
}

function CurrentUserInformation() {
  const selectedUser = useGrid((s) => s.selectedUser);
  const name = selectedUser?.username.split(" ")[0];

  return (
    <UserInformationWrapper>
      <UserInformationText>Showing all responses from</UserInformationText>
      <Stack type="row" gap={0.5} style={{ display: "inline-grid" }}>
        <SmallAvatarContainer
          src={selectedUser?.social_profile_picture}
          onError={handleImageError}
        />
        <SmallUsername>{name}</SmallUsername>
      </Stack>
    </UserInformationWrapper>
  );
}

export default CurrentUserInformation;

const UserInformationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const UserInformationText = styled.div`
  font-size: 0.9rem;
  letter-spacing: 0.25px;
  color: white;
  letter-spacing: 0.5px;
`;

const SmallAvatarContainer = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const SmallUsername = styled.div`
  display: flex;
  align-items: center;
  user-select: text;
  font-size: 2.1rem;
  /* font-weight: 200; */
  color: white;
  letter-spacing: -0.5px;
  /* font-family: "Inter", sans-serif; */
`;
