export const CARD_WIDTH = 225;
export const CARD_RADIUS = Math.floor(CARD_WIDTH / 3.25);
export const GUTTER = 16;
export const COLUMN_COUNT = 4;
export const CARD_MARGIN = GUTTER / 2;
export const COLUMN_WIDTH = CARD_WIDTH;
export const EXPANDED_CARD_WIDTH = CARD_WIDTH + CARD_WIDTH + GUTTER;
export const EXPANDED_CARD_HEIGHT = CARD_WIDTH + CARD_WIDTH + GUTTER;
export const ONE_CARD_DOWN = CARD_WIDTH + GUTTER;
export const TWO_CARDS_DOWN = ONE_CARD_DOWN * 2;
export const GRID_WIDTH =
  COLUMN_WIDTH * COLUMN_COUNT + GUTTER * (COLUMN_COUNT - 1);
