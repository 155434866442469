import React, { useState, useEffect, useContext } from "react";
import { IOrganization } from "../types/organization";
import axios from "axios";
import { getOrganizationFromURI } from "utils/facelift";

interface SiteProps {
  organization?: IOrganization;
  setOrganization: (o: IOrganization) => void;
}

export const OrganizationContext = React.createContext<SiteProps>({
  setOrganization: (o) => {}
});

export const OrganizationProvider: React.FC = ({ children, ...props }) => {
  const [org, setOrg] = useState<IOrganization>();

  const loadOrg = async () => {
    try {
      const subdomain = getOrganizationFromURI();

      const response = await axios.get<{
        status: string;
        response?: IOrganization;
      }>(`publicorg?subdomain=${subdomain}`);

      if (response.data.response) {
        const org = response.data.response;
        if (org.studies) {
          org.studies = org.studies.filter((s) => s.approved);
        }
        console.log(`set org: `, org);
        setOrg(org);
      }
    } catch (e) {
      console.log(`failed to get org with error: `, e);
    }
  };

  useEffect(() => {
    loadOrg();
  }, []);

  return (
    <OrganizationContext.Provider
      value={{
        organization: org,
        setOrganization: setOrg
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export const useOrg = () => {
  const ctx = useContext(OrganizationContext);
  if (!ctx) {
    throw new Error("useOrg must be used within an AppProvider");
  }

  return ctx.organization;
};
export default useOrg;
