import * as React from "react";
import axios, { AxiosResponse } from "axios";
import GroupActions from "./GroupActions";
import Modal from "react-modal";
import { X } from "react-feather";
import Image from "react-async-image";
import AppContext, { IAppContext } from "../../utils/AppContext";
import {
  Table,
  Column,
  Index,
  TableHeaderProps,
  AutoSizer,
  SortDirection,
  SortDirectionType,
  defaultTableRowRenderer,
  TableRowProps
} from "react-virtualized";
import { RouteProps } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  WrappedComponent
} from "react-sortable-hoc";
import Tooltip from "react-tooltip";
import { Helmet } from "react-helmet";
import defaultProfilePicture from "../../assets/images/mm-default-profile-pic.jpg";
import { Edit, CheckSquare, CornerUpLeft, Trash2 } from "react-feather";
import { debounce } from "lodash-es";
import arrayMove from "array-move";
import {
  TableWrapper,
  StyledColumn,
  TopicInputContainer,
  SaveButton,
  CheckBox,
  TooltipWrapper
} from "./styled";
import ToolBar from "../../components/ui/ToolBar";
// import getSite from "../../utils/Site";
import { ThemeProvider } from "styled-components";

export interface ICSVRow {
  id: number;
  date: string;
  username: string;
  platform: string;
  uid: string;
  text: string;
  question: string;
  topic: string;
  subtopic: string;
  media_url: string;
  profile_picture: string;
  checked: boolean;
  deleted: string;
  editMode: boolean;
  social_profile_picture: string | undefined;
}

interface ICSVEditorState {
  performingNetworkAction: boolean;
  error?: string;
  rows: ICSVRow[];
  filteredRows: ICSVRow[];
  groupEditMode: boolean;
  modalIsOpen: boolean;
  topicText: string;
  searchText: string;
  rowProfilePicture: string;
  rowUsername: string;
  rowText: string;
  rowQuestion: string;
  rowTopic: string;
  rowSubtopic: string;
  rowMedia: string;
  sortBy: string;
  sortDirection: SortDirectionType;
}

const SortableTable = SortableContainer(Table, { withRef: true });
const SortableTableRowRenderer = SortableElement(
  defaultTableRowRenderer as WrappedComponent<TableRowProps>
);

class CSVEditor extends React.Component<RouteProps, ICSVEditorState> {
  // #region Construction
  // site = getSite();

  context: IAppContext | undefined = undefined;

  constructor(props: any) {
    super(props);

    // console.log(`site`);
    // console.log(this.site);

    this.state = {
      performingNetworkAction: false,
      error: undefined,
      groupEditMode: false,
      modalIsOpen: false,
      rows: [],
      filteredRows: [],
      searchText: "",
      topicText: "",
      rowProfilePicture: "",
      rowUsername: "",
      rowText: "",
      rowQuestion: "",
      rowTopic: "",
      rowSubtopic: "",
      rowMedia: "",
      sortBy: "date",
      sortDirection: SortDirection.DESC
    };
  }

  componentDidMount() {
    this.fetchCSVData();
    this._rebuildTooltip();
  }

  // #endregion Construction

  // #region Data

  sort = (info: { sortBy: string; sortDirection: SortDirectionType }) => {
    const sortedRows = this.state.filteredRows.sort((a, b) => {
      switch (info.sortBy) {
        case "id":
          if (info.sortDirection === SortDirection.ASC) {
            return a.id > b.id ? 1 : -1;
          } else {
            return a.id < b.id ? 1 : -1;
          }
        case "username":
          if (info.sortDirection === SortDirection.ASC) {
            return a.username > b.username ? 1 : -1;
          } else {
            return a.username < b.username ? 1 : -1;
          }
        case "text":
          if (info.sortDirection === SortDirection.ASC) {
            return a.text > b.text ? 1 : -1;
          } else {
            return a.text < b.text ? 1 : -1;
          }
        case "question":
          if (info.sortDirection === SortDirection.ASC) {
            return a.question > b.question ? 1 : -1;
          } else {
            return a.question < b.question ? 1 : -1;
          }
        case "subtopic":
          if (info.sortDirection === SortDirection.ASC) {
            return a.subtopic > b.subtopic ? 1 : -1;
          } else {
            return a.subtopic < b.subtopic ? 1 : -1;
          }
        case "date":
          if (info.sortDirection === SortDirection.ASC) {
            return new Date(a.date) > new Date(b.date) ? 1 : -1;
          } else {
            return new Date(a.date) < new Date(b.date) ? 1 : -1;
          }
        default:
          return 1;
      }
    });

    this.setState({
      sortBy: info.sortBy,
      sortDirection: info.sortDirection,
      filteredRows: sortedRows
    });
  };

  hasEditPermission = () => {
    if (this.context && this.context.permissions) {
      for (const permission of this.context.permissions) {
        console.log(permission);
        if (permission.permission === "CSV_EDIT" && permission.allowed === 1) {
          return true;
        }
      }
    }

    return false;
  };

  fetchCSVData = async () => {
    this.setState({
      performingNetworkAction: true
    });

    const response: AxiosResponse<ICSVRow> = await axios.get<any>(
      `${process.env.REACT_APP_API_HOST}api/v1/social`
    );

    if (response && response.data && response.data) {
      if (response.status === 200) {
        const data: any = response.data;
        if (data.response) {
          this.setState({
            performingNetworkAction: false,
            error: undefined,
            rows: data.response,
            filteredRows: []
          });

          this.sort({
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
          });
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            performingNetworkAction: false,
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            filteredRows: []
          });
        }
      } else {
        console.log("Failed to get CSV data");
        console.log(response);

        this.setState({
          performingNetworkAction: false,
          error: "Oops, something went wrong. Please try again.",
          rows: [],
          filteredRows: []
        });
      }
    }

    this.updateFilteredRows();
  };

  selectedRows = (): ICSVRow[] => {
    let selectedRows: ICSVRow[] = [];

    for (const r of this.state.filteredRows) {
      if (r.checked) {
        selectedRows.push(r);
      }
    }

    return selectedRows;
  };

  formDataForRow = (row: ICSVRow) => {
    const data = new FormData();
    data.append("id", row.id.toString());
    data.append("date", row.date);
    data.append("username", row.username);
    data.append("platform", row.platform);
    data.append("text", row.text);
    data.append("question", row.question);
    data.append("topic", row.topic);
    data.append("subtopic", row.subtopic);
    data.append("media_url", row.media_url);
    data.append("uid", row.uid);
    data.append("profile_picture", row.profile_picture);
    data.append("deleted", row.deleted);

    return data;
  };

  saveRow = async (row: ICSVRow) => {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const response: AxiosResponse<ICSVRow> = await axios.post<any>(
      `${process.env.REACT_APP_API_HOST}api/v1/social`,
      {
        rows: [row]
      },
      axiosConfig
    );

    // const response: AxiosResponse<ICSVRow> = await axios.post<any>(`${process.env.REACT_APP_API_HOST}/api/v1/social`, data);

    this.setState({
      performingNetworkAction: true
    });

    if (response && response.data && response.data) {
      if (response.status === 200) {
        const data: any = response.data;
        if (data.response) {
          this.setState({
            error: undefined,
            rows: data.response,
            performingNetworkAction: false
          });

          this.sort({
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
          });
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            performingNetworkAction: false
          });
        }
      } else {
        console.log("Failed to get CSV data");
        console.log(response);

        this.setState({
          error: "Oops, something went wrong. Please try again.",
          rows: [],
          performingNetworkAction: false
        });
      }

      this.updateFilteredRows();
    }
  };

  clickedSaveTopic = async () => {
    const selectedRows = this.selectedRows();

    if (selectedRows.length > 0) {
      for (const row of selectedRows) {
        row.topic = this.state.topicText;
        row.checked = false;
      }

      this.updateLocalRows(selectedRows);

      this.setState({
        groupEditMode: false,
        topicText: "",
        modalIsOpen: false,
        performingNetworkAction: true
      });

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      const response: AxiosResponse<ICSVRow> = await axios.post<any>(
        `${process.env.REACT_APP_API_HOST}api/v1/social`,
        {
          rows: selectedRows
        },
        axiosConfig
      );

      if (response && response.data && response.data) {
        if (response.status === 200) {
          const data: any = response.data;
          if (data.response) {
            this.setState({
              error: undefined,
              rows: data.response,
              performingNetworkAction: false
            });

            this.sort({
              sortBy: this.state.sortBy,
              sortDirection: this.state.sortDirection
            });
          } else {
            console.log("Failed to get CSV data");
            console.log(response);

            this.setState({
              error: "Oops, something went wrong. Please try again.",
              rows: [],
              performingNetworkAction: false
            });
          }
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            performingNetworkAction: false
          });
        }

        this.updateFilteredRows();
      }
    }
  };

  clickedRecoverOnRows = async () => {
    const selectedRows = this.selectedRows();

    if (selectedRows.length > 0) {
      for (const row of selectedRows) {
        row.deleted = "0";
        row.checked = false;
      }

      this.updateLocalRows(selectedRows);

      this.setState({
        groupEditMode: false,
        performingNetworkAction: true
      });

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      const response: AxiosResponse<ICSVRow> = await axios.post<any>(
        `${process.env.REACT_APP_API_HOST}api/v1/social`,
        {
          rows: selectedRows
        },
        axiosConfig
      );

      if (response && response.data && response.data) {
        if (response.status === 200) {
          const data: any = response.data;
          if (data.response) {
            this.setState({
              error: undefined,
              rows: data.response,
              performingNetworkAction: false
            });

            this.sort({
              sortBy: this.state.sortBy,
              sortDirection: this.state.sortDirection
            });
          } else {
            console.log("Failed to get CSV data");
            console.log(response);

            this.setState({
              error: "Oops, something went wrong. Please try again.",
              rows: [],
              performingNetworkAction: false
            });
          }
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            performingNetworkAction: false
          });
        }

        this.updateFilteredRows();
      }
    }
  };

  clickedDeleteOnRows = async () => {
    let selectedRows = this.selectedRows();

    if (selectedRows.length > 0) {
      for (const row of selectedRows) {
        row.deleted = "1";
        row.checked = false;
      }

      this.updateLocalRows(selectedRows);

      this.setState({
        groupEditMode: false,
        performingNetworkAction: true
      });

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      const response: AxiosResponse<ICSVRow> = await axios.post<any>(
        `${process.env.REACT_APP_API_HOST}api/v1/social`,
        {
          rows: selectedRows
        },
        axiosConfig
      );

      if (response && response.data && response.data) {
        if (response.status === 200) {
          const data: any = response.data;
          if (data.response) {
            this.setState({
              error: undefined,
              rows: data.response,
              performingNetworkAction: false
            });

            this.sort({
              sortBy: this.state.sortBy,
              sortDirection: this.state.sortDirection
            });
          } else {
            console.log("Failed to get CSV data");
            console.log(response);

            this.setState({
              error: "Oops, something went wrong. Please try again.",
              rows: [],
              performingNetworkAction: false
            });
          }
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            performingNetworkAction: false
          });
        }

        this.updateFilteredRows();
      }
    }
  };

  clickedDelete = async (row: ICSVRow) => {
    row.deleted = "1";
    this.updateLocalRows([row]);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    this.setState({
      performingNetworkAction: true
    });

    const response: AxiosResponse<ICSVRow> = await axios.post<any>(
      `${process.env.REACT_APP_API_HOST}api/v1/social`,
      {
        rows: [row]
      },
      axiosConfig
    );

    if (response && response.data && response.data) {
      if (response.status === 200) {
        const data: any = response.data;
        if (data.response) {
          this.setState({
            error: undefined,
            rows: data.response,
            performingNetworkAction: false
          });

          this.sort({
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
          });
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            performingNetworkAction: false
          });
        }
      } else {
        console.log("Failed to get CSV data");
        console.log(response);

        this.setState({
          error: "Oops, something went wrong. Please try again.",
          rows: [],
          performingNetworkAction: false
        });
      }

      this.updateFilteredRows();
    }
  };

  clickedRecover = async (row: ICSVRow) => {
    row.deleted = "0";
    this.updateLocalRows([row]);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    this.setState({
      performingNetworkAction: true
    });

    const response: AxiosResponse<ICSVRow> = await axios.post<any>(
      `${process.env.REACT_APP_API_HOST}api/v1/social`,
      {
        rows: [row]
      },
      axiosConfig
    );

    // const data = this.formDataForRow(row);

    // const response: AxiosResponse<ICSVRow> = await axios.post<any>(`${process.env.REACT_APP_API_HOST}/api/v1/social`, data);

    if (response && response.data && response.data) {
      if (response.status === 200) {
        const data: any = response.data;
        if (data.response) {
          this.setState({
            error: undefined,
            rows: data.response,
            performingNetworkAction: false
          });
          this.sort({
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
          });
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            performingNetworkAction: false
          });
        }
      } else {
        console.log("Failed to get CSV data");
        console.log(response);

        this.setState({
          error: "Oops, something went wrong. Please try again.",
          rows: [],
          performingNetworkAction: false
        });
      }

      this.updateFilteredRows();
    }
  };

  // #endregion Data

  // #region Actions

  clickedSaveRow = (row: ICSVRow) => {
    const newRow: ICSVRow = {
      id: row.id,
      date: row.date,
      username: this.state.rowUsername,
      platform: row.platform,
      uid: row.uid,
      text: this.state.rowText,
      question: this.state.rowQuestion,
      topic: this.state.rowTopic,
      subtopic: this.state.rowSubtopic,
      media_url: this.state.rowMedia,
      profile_picture: this.state.rowProfilePicture,
      checked: false,
      deleted: row.deleted,
      editMode: false,
      social_profile_picture: row.social_profile_picture
    };

    if (row.username !== this.state.rowUsername) {
      newRow.social_profile_picture = " ";
    }

    this.updateLocalRows([newRow]);
    this.saveRow(newRow);
  };

  clickedEdit = (row: ICSVRow) => {
    let rowsToUpdate: ICSVRow[] = [];

    for (const r of this.state.filteredRows) {
      if (r.editMode) {
        r.editMode = false;
        rowsToUpdate.push(r);
      }
    }

    this.updateLocalRows(rowsToUpdate);

    row.editMode = true;

    this.setState({
      rowProfilePicture: row.profile_picture,
      rowUsername: row.username,
      rowText: row.text,
      rowQuestion: row.question,
      rowTopic: row.topic,
      rowSubtopic: row.subtopic,
      rowMedia: row.media_url
    });

    this.updateLocalRows([row]);
  };

  clickedEditOnRows = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };

  closeGroupdActions = () => {
    let rows = this.state.filteredRows;

    for (let r of rows) {
      r.checked = false;
    }

    this.setState({
      groupEditMode: false,
      rows: rows
    });
  };

  updateLocalRows = (rows: ICSVRow[]) => {
    let newRows = this.state.filteredRows;

    for (const row of rows) {
      let index = -1;
      let i = 0;

      for (const r of this.state.filteredRows) {
        if (r.id === row.id) {
          index = i;
          break;
        }

        i += 1;
      }

      if (index >= 0) {
        newRows[index] = row;
      }
    }

    this.setState({
      rows: newRows
    });
  };

  checkRowValueChanged = (row: ICSVRow, checked: boolean) => {
    for (const r of this.state.filteredRows) {
      if (r.id === row.id) {
        r.checked = checked;

        break;
      }
    }

    let c = false;
    for (const r of this.state.filteredRows) {
      if (r.checked) {
        c = true;
        break;
      }
    }

    let newRows: ICSVRow[] = this.state.filteredRows;
    for (const r of newRows) {
      r.editMode = false;
    }

    this.setState({ rows: newRows, groupEditMode: c });
  };

  updateFilteredRows = () => {
    if (this.state.searchText.length > 0) {
      console.log("getting rows with text: " + this.state.searchText);

      let rows: ICSVRow[] = [];
      const search = this.state.searchText.toLocaleLowerCase();

      for (const row of this.state.rows) {
        if (
          row.question.toLocaleLowerCase().indexOf(search) >= 0 ||
          row.username.toLocaleLowerCase().indexOf(search) >= 0 ||
          row.text.toLocaleLowerCase().indexOf(search) >= 0 ||
          row.topic.toLocaleLowerCase().indexOf(search) >= 0 ||
          row.media_url.toLocaleLowerCase().indexOf(search) >= 0 ||
          row.subtopic.toLocaleLowerCase().indexOf(search) >= 0
        ) {
          rows.push(row);
        }
      }

      this.setState({ filteredRows: rows }, () => {
        this.sort({
          sortBy: this.state.sortBy,
          sortDirection: this.state.sortDirection
        });
      });
    } else {
      this.setState({ filteredRows: this.state.rows }, () => {
        this.sort({
          sortBy: this.state.sortBy,
          sortDirection: this.state.sortDirection
        });
      });
    }
  };

  // #endregion Actions

  // #region Render Functions

  renderGroupActions = () => {
    if (this.state.groupEditMode) {
      return (
        <GroupActions
          deletePressed={this.clickedDeleteOnRows}
          editPressed={this.clickedEditOnRows}
          recoverPressed={this.clickedRecoverOnRows}
          closePressed={this.closeGroupdActions}
        />
      );
    }

    return null;
  };

  renderEditButtons = (row: ICSVRow) => {
    if (!this.state.groupEditMode) {
      if (row.editMode) {
        return (
          <>
            <CheckSquare
              className="edit-icon"
              onClick={() => {
                this.clickedSaveRow(row);
              }}
            />
          </>
        );
      } else {
        return (
          <>
            <Edit
              className="edit-icon"
              onClick={() => {
                this.clickedEdit(row);
              }}
            />
            {this.renderDeleteButtons(row)}
          </>
        );
      }
    }
  };

  renderDeleteButtons = (row: ICSVRow) => {
    if (row.deleted === "1") {
      return (
        <CornerUpLeft
          className="undo-icon"
          onClick={() => {
            this.clickedRecover(row);
          }}
        />
      );
    } else {
      return (
        <Trash2
          className="delete-icon"
          onClick={() => {
            this.clickedDelete(row);
          }}
        />
      );
    }
  };

  renderEditTopicModal = () => {
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            height: "175px"
          }
        }}
        contentLabel="Favroite Media"
      >
        <X onClick={() => this.setState({ modalIsOpen: false })} />

        <TopicInputContainer>
          <label>New Topic</label>
          <input
            type="text"
            value={this.state.topicText}
            onChange={(event) => {
              this.setState({ topicText: event.target.value });
            }}
          />
        </TopicInputContainer>

        <SaveButton onClick={this.clickedSaveTopic}>Save</SaveButton>
      </Modal>
    );
  };

  renderProfilePicture = (row: ICSVRow) => {
    let profilePicture = row.profile_picture;
    if (profilePicture && profilePicture.length > 0) {
      profilePicture = profilePicture.trim();
    }

    if (
      row.social_profile_picture &&
      row.social_profile_picture !== "<blank>"
    ) {
      return <Image src={row.social_profile_picture} />;
    } else if (profilePicture && profilePicture.length > 0) {
      return <Image src={profilePicture} />;
    } else {
      return (
        <img
          className="default-profile-picture"
          src={defaultProfilePicture}
          alt="default profile"
        />
      );
    }
  };

  renderMedia = (mediaUrl: string) => {
    if (mediaUrl.length > 0) {
      const url = mediaUrl.toLowerCase();

      if (
        url.indexOf(".jpg") !== -1 ||
        url.indexOf(".jpeg") !== -1 ||
        url.indexOf(".png") !== -1 ||
        url.indexOf(".gif") !== -1 || 
        url.indexOf(".webp") !== -1
      ) {
        return <Image src={mediaUrl} />;
      } else if (url.indexOf(".aac") !== -1 || url.indexOf(".mp3") !== -1) {
        return (
          <audio controls>
            <source src={mediaUrl} type="audio/ogg" />
          </audio>
        );
      } else if (url.indexOf(".mp4") !== -1 || url.indexOf(".webm") !== -1) {
        return (
          <video controls={true}>
            <source src={mediaUrl} />
          </video>
        );
      }
    }

    return null;
  };

  render() {
    return (
      <AppContext.Consumer>
        {(context: IAppContext) => {
          this.context = context;

          return (
            <ThemeProvider theme={{}}>
              <React.Fragment>
                <Helmet>
                  <title>Magic Mirror - CSV Editor</title>
                </Helmet>
                <div style={{ height: "100%" }}>
                  <div style={{ height: "100%" }}>
                    <AutoSizer onResize={this._rebuildTooltip}>
                      {({ height, width }) => (
                        <TableWrapper style={{ width: "100vw" }}>
                          <ToolBar
                            loading={this.state.performingNetworkAction}
                            searchText={this.state.searchText}
                            searchValueUpdated={(searchValue) => {
                              this.setState({ searchText: searchValue }, () => {
                                this.updateFilteredRows();
                              });
                            }}
                            selectedAllPressed={() => {
                              const posts = this.state.filteredRows;
                              for (const post of posts) {
                                post.checked = true;
                              }
                              this.setState({
                                filteredRows: posts,
                                groupEditMode: true
                              });
                            }}
                          />
                          <SortableTable
                            axis="y"
                            lockAxis="y"
                            distance={2}
                            disableHeader={false}
                            height={height}
                            width={width < 1000 ? 1000 : width}
                            headerHeight={50}
                            helperClass="sortableHelper"
                            rowHeight={160}
                            rowRenderer={this._rowRenderer}
                            rowGetter={this._rowGetter}
                            rowCount={this.state.filteredRows.length}
                            sort={this.sort}
                            sortBy={this.state.sortBy}
                            sortDirection={this.state.sortDirection}
                            onScroll={this._rebuildTooltip}
                            onSortEnd={this._handleSort}
                            rowClassName={this._getRowClassName}
                          >
                            {this.hasEditPermission() && (
                              <Column
                                dataKey="checkbox"
                                width={45}
                                disableSort={true}
                                headerRenderer={this._headerRenderer.checkbox}
                                cellRenderer={this._cellRenderer.checkbox}
                              />
                            )}

                            {this.hasEditPermission() && (
                              <Column
                                dataKey="edit_row"
                                width={35}
                                disableSort={true}
                                headerRenderer={(props: TableHeaderProps) => {
                                  return <StyledColumn />;
                                }}
                                cellRenderer={(data) => {
                                  const row: ICSVRow = data.rowData;

                                  return (
                                    <div
                                      className={
                                        this.state.groupEditMode
                                          ? "cell"
                                          : "cell edit-buttons"
                                      }
                                    >
                                      {this.renderEditButtons(row)}
                                    </div>
                                  );
                                }}
                              />
                            )}

                            <Column
                              dataKey="profile_picture"
                              width={75}
                              disableSort={true}
                              headerRenderer={
                                this._headerRenderer.profile_picture
                              }
                              cellRenderer={this._cellRenderer.profile_picture}
                            />

                            <Column
                              dataKey="id"
                              width={45}
                              headerRenderer={this._headerRenderer.id}
                              cellRenderer={this._cellRenderer.id}
                            />

                            <Column
                              dataKey="username"
                              width={70}
                              flexGrow={1}
                              headerRenderer={this._headerRenderer.username}
                              cellRenderer={this._cellRenderer.username}
                            />

                            <Column
                              dataKey="question"
                              width={75}
                              flexGrow={1}
                              headerRenderer={this._headerRenderer.question}
                              cellRenderer={this._cellRenderer.question}
                            />

                            <Column
                              dataKey="text"
                              width={75}
                              flexGrow={1}
                              headerRenderer={this._headerRenderer.text}
                              cellRenderer={this._cellRenderer.text}
                            />

                            <Column
                              dataKey="topic"
                              width={50}
                              flexGrow={1}
                              headerRenderer={this._headerRenderer.topic}
                              cellRenderer={this._cellRenderer.topic}
                            />

                            <Column
                              dataKey="subtopic"
                              width={50}
                              flexGrow={1}
                              headerRenderer={this._headerRenderer.subtopic}
                              cellRenderer={this._cellRenderer.subtopic}
                            />

                            <Column
                              dataKey="media"
                              width={75}
                              flexGrow={1}
                              disableSort={true}
                              headerRenderer={this._headerRenderer.media}
                              cellRenderer={this._cellRenderer.media}
                            />
                          </SortableTable>
                        </TableWrapper>
                      )}
                    </AutoSizer>

                    {this.renderGroupActions()}
                  </div>

                  <TooltipWrapper>
                    <Tooltip
                      className="tooltip-text"
                      type="dark"
                      place="right"
                    />
                  </TooltipWrapper>

                  {this.renderEditTopicModal()}
                </div>
              </React.Fragment>
            </ThemeProvider>
          );
        }}
      </AppContext.Consumer>
    );
  }

  // #endregion Render Functions

  _handleSort = ({ oldIndex, newIndex }: any) => {
    this.setState(({ filteredRows }) => ({
      filteredRows: arrayMove(filteredRows, oldIndex, newIndex)
    }));
  };

  _rowRenderer = (props: any): React.ReactElement => {
    return <SortableTableRowRenderer disabled={false} {...props} />;
  };

  _rowGetter = ({ index }: Index) => {
    return this.state.filteredRows[index];
  };

  _getRowClassName = ({ index }: Index) => {
    if (index >= 0) {
      const row = this.state.filteredRows[index];
      if (row.deleted === "1") {
        return "deleted";
      }
    }

    return "";
  };

  _getHeaderClassName = ({
    dataKey,
    sortBy,
    sortDirection
  }: TableHeaderProps): string => {
    return dataKey === sortBy
      ? sortDirection === SortDirection.ASC
        ? "headerSortDown"
        : "headerSortUp"
      : "";
  };

  _rebuildTooltip = debounce(() => Tooltip.rebuild(), 200, {
    leading: false,
    trailing: true
  });

  _headerRenderer = {
    checkbox: (props: TableHeaderProps) => <StyledColumn />,
    edit_row: (props: TableHeaderProps) => <StyledColumn />,
    profile_picture: (props: TableHeaderProps) => <StyledColumn />,
    id: (props: TableHeaderProps) => (
      <StyledColumn className={this._getHeaderClassName(props)}>
        ID
      </StyledColumn>
    ),
    username: (props: TableHeaderProps) => (
      <StyledColumn className={this._getHeaderClassName(props)}>
        Username
      </StyledColumn>
    ),
    text: (props: TableHeaderProps) => (
      <StyledColumn className={this._getHeaderClassName(props)}>
        {"Text"}
      </StyledColumn>
    ),
    question: (props: TableHeaderProps) => (
      <StyledColumn className={this._getHeaderClassName(props)}>
        Question
      </StyledColumn>
    ),
    topic: (props: TableHeaderProps) => (
      <StyledColumn className={this._getHeaderClassName(props)}>
        Topic
      </StyledColumn>
    ),
    subtopic: (props: TableHeaderProps) => (
      <StyledColumn className={this._getHeaderClassName(props)}>
        {"Location"}
      </StyledColumn>
    ),
    media: () => <StyledColumn>Media</StyledColumn>
  };

  _cellRenderer = {
    checkbox: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div className="cell">
          <CheckBox
            type="checkbox"
            checked={row.checked}
            onChange={(event) => {
              this.checkRowValueChanged(row, event.target.checked);
            }}
          />
        </div>
      );
    },
    edit_row: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div
          className={this.state.groupEditMode ? "cell" : "cell edit-buttons"}
        >
          {this.renderEditButtons(row)}
        </div>
      );
    },
    profile_picture: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div className="cell profile-picture">
          {row.editMode && (
            <textarea
              maxLength={700}
              defaultValue={this.state.rowProfilePicture}
              onChange={(event) => {
                this.setState({
                  rowProfilePicture: event.currentTarget.value
                });
              }}
            />
          )}

          {!row.editMode && <>{this.renderProfilePicture(row)}</>}
        </div>
      );
    },
    id: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div className="cell">
          <strong>{row.id}</strong>
        </div>
      );
    },
    username: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div className="cell">
          {row.editMode && (
            <textarea
              maxLength={700}
              defaultValue={this.state.rowUsername}
              onChange={(event) => {
                this.setState({
                  rowUsername: event.currentTarget.value
                });
              }}
            />
          )}

          {!row.editMode && <span>{row.username}</span>}
        </div>
      );
    },
    text: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <>
          {row.editMode && (
            <div className="cell">
              <textarea
                maxLength={700}
                defaultValue={this.state.rowText}
                onChange={(event) => {
                  this.setState({
                    rowText: event.currentTarget.value
                  });
                }}
              />
            </div>
          )}

          {!row.editMode && (
            <>
              <div className="cell" data-tip={row.text}>
                {row.text}
              </div>
            </>
          )}
        </>
      );
    },
    question: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <>
          {row.editMode && (
            <div className="cell">
              <textarea
                maxLength={700}
                defaultValue={this.state.rowQuestion}
                onChange={(event) => {
                  this.setState({
                    rowQuestion: event.currentTarget.value
                  });
                }}
              />
            </div>
          )}

          {!row.editMode && (
            <>
              <div className="cell" data-tip={row.question}>
                {row.question}
              </div>
            </>
          )}
        </>
      );
    },
    topic: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div className="cell">
          {row.editMode && (
            <textarea
              maxLength={700}
              defaultValue={this.state.rowTopic}
              onChange={(event) => {
                this.setState({
                  rowTopic: event.currentTarget.value
                });
              }}
            />
          )}

          {!row.editMode && <span>{row.topic}</span>}
        </div>
      );
    },
    subtopic: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div className="cell">
          {row.editMode && (
            <textarea
              maxLength={700}
              defaultValue={this.state.rowSubtopic}
              onChange={(event) => {
                this.setState({
                  rowSubtopic: event.currentTarget.value
                });
              }}
            />
          )}

          {!row.editMode && <span>{row.subtopic}</span>}
        </div>
      );
    },
    media: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div className="cell media-column">
          {row.editMode && (
            <textarea
              maxLength={700}
              defaultValue={this.state.rowMedia}
              onChange={(event) => {
                this.setState({
                  rowMedia: event.currentTarget.value
                });
              }}
            />
          )}

          {!row.editMode && (
            <a target="_blank" rel="noopener noreferrer" href={row.media_url}>
              {this.renderMedia(row.media_url)}
            </a>
          )}
        </div>
      );
    }
  };
}

export default CSVEditor;
