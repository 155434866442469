import * as React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import {
  AbstractImage,
  QuestionsContainer,
  Question,
  QuestionOffsetBackground
} from "./styled";
import abstractImageSource from "../../assets/images/abstract.png";

const Link = styled.a`
  color: #211549;
  font-weight: 600;
  text-decoration: underline;

  &:hover {
    color: rgba(250, 166, 26, 0.9);
  }
`;

interface ISurveyCompletedProps {
  ctx: string;
  uid: string;
}

const SurveyCompleted: React.StatelessComponent<ISurveyCompletedProps> = (
  props
) => (
  <QuestionsContainer>
    <Helmet>
      <title>Survey</title>
    </Helmet>
    <AbstractImage src={abstractImageSource} />

    <Question active style={{ textAlign: "center" }}>
      <QuestionOffsetBackground />
      <h2>Thanks for completing our questionnaire!</h2>
      <p>
        Click{" "}
        <Link
          href={`https://thawing-savannah-67371.herokuapp.com/?uid=${props.uid}&campaign=${props.ctx}`}
        >
          HERE
        </Link>{" "}
        to redeem your reward!
      </p>
    </Question>
  </QuestionsContainer>
);

export default SurveyCompleted;
