import { useMemo } from "react";
import { useResponses } from "hooks";
import { useSnapshotHistoryAPI } from "./useSnapshotHistory";
import { FilterState, FilterConfigWithResults } from "utils/Site";
import { getOptionsFromCategory, range } from "utils/facelift";
import { Response } from "types/facelift";
import { ExtractFieldsOfType } from "types/magicMirror";
import create from "zustand";
import { useGridAPI } from "./useGrid";
import { useApp } from "context/AppContext";
import defaultFilterCategories from "utils/defaultFilterCategories";

interface UseFilterOptionsProps {
  initialFilters: FilterState;
  updateInitialFilters: (newFilters: FilterState) => void;
  selectedFilters: FilterState;
  updateSelectedFilter: (
    item: FilterConfigWithResults,
    selected: string,
    shouldCapture: boolean
  ) => void;
  updateSelectedFilters: (newFilters: FilterState) => void;
  displayField: string;
  updateDisplayField: (newDisplayField: string) => void;
  reset: () => void;
}

const [useFilterOptions, useFilterOptionsAPI] = create<UseFilterOptionsProps>(
  (set, get) => ({
    initialFilters: {},
    updateInitialFilters: (newFilters: FilterState) => {
      set({ initialFilters: newFilters });
    },
    selectedFilters: {},
    updateSelectedFilter: (
      item: FilterConfigWithResults,
      selected: string,
      shouldCapture: boolean = false
    ) => {
      if (shouldCapture) useSnapshotHistoryAPI.getState().captureSnapshot();

      const { selectedFilters } = get();
      if (selectedFilters[item.displayName].includes(selected)) {
        set({
          selectedFilters: {
            ...selectedFilters,
            [item.displayName]: selectedFilters[item.displayName].filter(
              (item) => item !== selected
            )
          }
        });
      } else {
        set({
          selectedFilters: {
            ...selectedFilters,
            [item.displayName]: [...selectedFilters[item.displayName], selected]
          }
        });
      }

      setTimeout(() => {
        useGridAPI.setState({ isSearching: false });
      }, range(1, 50));
    },
    updateSelectedFilters: (newFilters: FilterState) => {
      set({ selectedFilters: newFilters });
    },
    displayField: "",
    updateDisplayField: (newDisplayField: string) => {
      const currentDisplayField = get().displayField;

      if (currentDisplayField === newDisplayField) {
        set({ displayField: "" });
      } else {
        set({ displayField: newDisplayField });
      }
    },

    reset: () => {
      const { initialFilters } = get();
      set({ selectedFilters: initialFilters });

      setTimeout(() => {
        useGridAPI.setState({ isSearching: false });
      }, range(1, 50));
    }
  })
);

function useOptions() {
  const responses = useResponses((s) => s.responses);
  // const responses = useGridResponses();
  const displayField = useFilterOptions((s) => s.displayField);
  const updateDisplayField = useFilterOptions((s) => s.updateDisplayField);
  const selectedFilters = useFilterOptions((s) => s.selectedFilters);
  const updateSelectedFilter = useFilterOptions((s) => s.updateSelectedFilter);
  const reset = useFilterOptions((s) => s.reset);

  const { appCtx } = useApp();
  const { currentStudy } = appCtx;

  const options: FilterConfigWithResults[] = useMemo(() => {
    if (currentStudy !== undefined) {
      let categories;

      if (currentStudy.filters.length > 0) {
        categories = currentStudy.filters;
      } else {
        categories = defaultFilterCategories;
      }

      return categories.map((category) => {
        const results = getOptionsFromCategory(
          category.columnName as ExtractFieldsOfType<Response, string>,
          responses
        );

        return {
          columnName: category.columnName,
          displayName: category.displayName,
          results
        };
      });
    }

    return [];
  }, [currentStudy, responses]);

  const areFiltersApplied = Object.values(selectedFilters).some(
    (filter) => filter.length > 0
  );

  const numberOfFiltersApplied = Object.values(selectedFilters).reduce(
    (acc, filters) => acc + filters.length,
    0
  );

  return {
    options,
    displayField,
    updateDisplayField,
    selectedFilters,
    areFiltersApplied,
    numberOfFiltersApplied,
    updateSelectedFilter,
    reset
  };
}

export { useFilterOptions, useFilterOptionsAPI };
export default useOptions;
