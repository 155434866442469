import styled from "styled-components";
import { motion } from "framer-motion";
import Highlighter from "react-highlight-words";
import { Download } from "@styled-icons/heroicons-solid/Download";
import { Copy } from "@styled-icons/boxicons-solid/Copy";
import { Play } from "@styled-icons/boxicons-regular/Play";
import { Pause } from "@styled-icons/boxicons-regular/Pause";
import { Check } from "@styled-icons/heroicons-solid/Check";
import { DotsHorizontalRounded } from "@styled-icons/boxicons-regular/DotsHorizontalRounded";

export const Component = styled(motion.div)<{ gender?: string }>`
  --blue: #4677d7;
  --pink: #ff7995;
  --transition: background 0.3s ease-in-out;
  --br: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: var(--br);
  box-shadow: ${(props) => {
    if (props.gender === "male")
      return "0px 20px 40px rgba(70, 119, 215, 0.20)";
    if (props.gender === "female")
      return "0px 20px 40px rgba(255, 121, 149, 0.20)";
    else return "0px 20px 40px rgba(0, 0, 0, 0.20)";
  }};
  position: relative;
  min-width: 100%;
  background: transparent;
`;

export const CloseButton = styled.button`
  background-color: ${(props) => props.theme.mm.altTwo};
  position: absolute;
  padding: 6px;
  top: -20px;
  right: -20px;
  border-radius: 50%;
  border: none;
  display: flex;
  border: 2px solid white;
  transition: ${(props) => props.theme.animations.default};
  cursor: pointer;
  box-shadow: -5px 5px 4px 0px rgba(0, 0, 0, 0.3);
  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    outline: none;
  }
  > svg {
    color: white;
  }
`;

export const ImageContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 175px;
  max-height: 300px;
  position: relative;

  video,
  img {
    object-fit: cover;
    width: 100%;
    height: 275px;
  }
`;

export const VideoContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Video = styled(motion.video)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const TimeContainer = styled(motion.div)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TotalTime = styled.p`
  font-size: 12px;
  letter-spacing: 0.5px;
  color: white;
`;

export const ProgressBar = styled(motion.div)`
  width: 100%;
  height: 0.25rem;
  background: white;
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Progress = styled(motion.div)<{ progress: number }>`
  width: ${(props) => props.progress + "%"};
  height: 100%;
  background: #d1a5f6;
`;

export const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0 0 0 / 0.4);
  width: 100%;
  height: 100%;
  z-index: 3;
`;

export const PlayButtonContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--sympler-orange);
  box-shadow: var(--shadow);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  padding: 8px;
  cursor: pointer;
`;

export const PauseButton = styled(Pause)`
  width: 100%;
  height: 100%;
  color: white;
  display: block;
`;

export const PlayButton = styled(Play)`
  width: 100%;
  height: 100%;
  color: white;
  display: block;
`;

export const DownloadIconContainer = styled(motion.a)`
  position: absolute;
  right: 12px;
  bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background: var(--sympler-orange);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 6px;
  box-shadow: var(--shadow);
  z-index: 4;

  &:hover {
    cursor: pointer;
  }
`;

export const DownloadIcon = styled(Download)`
  width: 100%;
  height: 100%;
  display: block;
  color: white;
`;

export const TextContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
  background: white;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  position: relative;
  z-index: 3;
  border-bottom-left-radius: var(--br);
  border-bottom-right-radius: var(--br);
`;

export const CopyIconContainer = styled(motion.div)`
  position: absolute;
  right: 12px;
  bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background: var(--sympler-orange);
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 6px;
  box-shadow: var(--shadow);

  &:hover {
    cursor: pointer;
  }
`;

export const CopyIcon = styled(Copy)`
  width: 100%;
  height: 100%;
  display: block;
  color: white;
`;

export const CopiedIcon = styled(Check)`
  width: 100%;
  height: 100%;
  display: block;
  color: white;
`;

export const Text = styled(Highlighter)`
  font-size: 14px;
  letter-spacing: 0.25px;
  width: 100%;
  display: block;
  color: #333333;
`;

export const TextButton = styled.span`
  margin: 0.5rem 0 0.5rem 0;
  align-self: flex-start;
  font-size: 12px;
  letter-spacing: 0.25px;
  color: cornflowerblue;
  cursor: pointer;
`;

export const ControlsContainer = styled(motion.div)<{ gender?: string }>`
  width: 100%;
  background: ${(props) => {
    if (props.gender === "male") return "var(--blue)";
    if (props.gender === "female") return "var(--pink)";
    else return "#D1A5F6";
  }};
  position: relative;
  z-index: 3;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  border-top-left-radius: var(--br);
  border-top-right-radius: var(--br);
`;

export const ProfileContainer = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: auto;
`;

export const AvatarContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  padding: 5px;
`;

export const Avatar = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: none;
`;

export const ProfileName = styled.p`
  text-align: left;
  padding-left: 0.5rem;
  font-size: 12px;
  letter-spacing: 1.1px;
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  span {
    cursor: pointer;
  }
`;

export const QuestionOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  background: var(--dark-gray-gradient);
  width: 100%;
  height: 100%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  border-radius: 70px;
  z-index: 5;
`;

export const QuestionText = styled(motion.div)`
  letter-spacing: 1px;
  line-height: 1.5;
  font-size: 14px;
  color: white;
  text-align: left;
`;

export const AddIconContainer = styled(motion.div)`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 14px;
  bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const AddIcon = styled(DotsHorizontalRounded)`
  width: 100%;
  height: 100%;
  display: block;
  color: white;
`;

export const BucketListItems = styled(motion.ul)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 125px;
  height: 125px;
  background: red;
`;
