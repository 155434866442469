import styled from "styled-components";
import iPhoneMax from "../../assets/images/xs-max-edited.png";

export const Container = styled.div`
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const LoggedInAs = styled.p`
  text-align: center;
  color: ${(props) => props.theme.pallet.white};
`;

export const PostContainer = styled.section`
  background-image: url(${iPhoneMax});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding-top: 65px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 60px;
  width: 420px;
  height: 830px;
  color: white;
  overflow: hidden;
`;

export const PostScreen = styled.div`
  background-color: ${(props) => props.theme.pallet.sidebarBackground};
  height: 100%;
  overflow: hidden;

  .upload-text {
    color: white;
    justify-self: center;
    align-self: center;
    font-weight: 800;
    font-size: 2rem;
    padding: 0 40px;
    cursor: pointer;

    &:hover {
      color: ${(props) => props.theme.pallet.primary};
    }
  }
`;

export const PostContent = styled.div`
  background-color: green;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 90%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0;
  }
`;

export const PostActionListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 0 10px;

  .cancel-icon {
    font-size: 24px;
    cursor: pointer;
    text-shadow: 2px 2px 2px #000000;

    &:hover {
      color: ${(props) => props.theme.pallet.primary};
    }
  }
`;

export const PostActionList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;

  li {
    list-style-type: none;
    margin-left: 15px;
    cursor: pointer;

    &:hover {
      svg {
        color: ${(props) => props.theme.pallet.primary};
      }
    }

    &.stickers {
      width: 15%;
    }

    &.text {
      width: 11%;
    }

    &.stickers > img,
    &.text > img {
      max-width: 100%;
    }
  }
`;

export const SendTo = styled.ul`
  background-color: #0b0b0b;
  padding: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 10%;
  margin: 0;

  li {
    list-style-type: none;

    &.send-to {
      background-color: transparent;
      color: ${(props) => props.theme.pallet.white};
      font-size: 14px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      padding: 7px 15px;
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
        background-color: ${(props) => props.theme.pallet.primary};
      }
    }
  }
`;

export const SendToFiller = styled.div`
  background-color: #0b0b0b;
  width: 100%;
  height: 10%;
`;

export const TextObject = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`;

export const NoAccountContainer = styled.div`
  background-color: ${(props) => props.theme.pallet.white};
  padding: 15px 30px;
  border-radius: 15px;
`;
