import styled from "styled-components";
import { motion } from "framer-motion";
import FavoriteButton from "../FavoriteButton";
import LogoBGFadeFour from "../../../assets/images/logo-bg-fade-four.png";

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 2.5%;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 4;
  background-color: ${(props) => props.theme.colors.secondary};
  ${(props) =>
    props.theme.enableHeaderSVG && `background-image: url(${LogoBGFadeFour})`};
  background-repeat: no-repeat;
  /* margin-bottom: 20px; */
  @media screen and (min-width: ${(props) => props.theme.breakPoints.large}) {
    width: 100%;
  }
`;

export const Logo = styled(motion.img)`
  height: 50px;
  width: ${(props) => (props.theme.enableHeaderSVG ? "125" : "175")}px;
  display: block;
  cursor: pointer;
  margin: 0 1rem;
`;

export const PageTitle = styled.h4`
  color: ${(props) => props.theme.mm.primary};
  font-size: 1.9rem;
  font-weight: 600;
  margin: 0px;
`;

export const OptionsBar = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const HeaderIcon = styled(FavoriteButton)`
  position: relative;
  z-index: 100;
`;

export const IconContainer = styled.div`
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  z-index: 100;
  :hover {
    cursor: pointer;
  }
`;

export const IconLabel = styled.p`
  position: absolute;
  font-size: 11px;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  margin-top: 5px;
  width: 100%;
  z-index: 100;
`;
