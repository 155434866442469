export default [
  {
    columnName: "format",
    displayName: "type"
  },
  {
    columnName: "platform",
    displayName: "platform"
  },
  {
    columnName: "subtopic",
    displayName: "location"
  },
  {
    columnName: "gender",
    displayName: "gender"
  }
];
