import * as React from "react";

import { Container, HashTagList } from "./styled";

interface IHashtagsSlideoutProps {
  toggleHashtagsSlideout: () => void;
  isOpen: boolean;
  hashtags: any[];
}

class HashtagsSlideout extends React.Component<IHashtagsSlideoutProps, {}> {
  render() {
    return (
      <React.Fragment>
        <Container isOpen={this.props.isOpen}>
          <HashTagList>
            <li>
              <span className="name">#Taylormade</span>
              <span className="count">(234)</span>
            </li>
            <li>
              <span className="name">#garywoodland</span>
              <span className="count">(234)</span>
            </li>
            <li>
              <span className="name">#garywoodland</span>
              <span className="count">(234)</span>
            </li>
            <li>
              <span className="name">#garywoodland</span>
              <span className="count">(234)</span>
            </li>
            <li>
              <span className="name">#garywoodland</span>
              <span className="count">(234)</span>
            </li>
            <li>
              <span className="name">#garywoodland</span>
              <span className="count">(234)</span>
            </li>
            <li>
              <span className="name">#garywoodland</span>
              <span className="count">(234)</span>
            </li>
          </HashTagList>
        </Container>
      </React.Fragment>
    );
  }
}

export default HashtagsSlideout;
