import * as React from "react";
import { Helmet } from "react-helmet";

import Toolbar from "../../components/Toolbar";
import TopLevelStats from "../../components/TopLevelStats";

const PromotedStories: React.SFC<{}> = () => (
  <React.Fragment>
    <Helmet>
      <title>Promoted Stories</title>
    </Helmet>
    <div style={{ width: "100%" }}>
      <Toolbar title="Promoted Stories" />
      <TopLevelStats />
    </div>
  </React.Fragment>
);

export default PromotedStories;
