import React, { useState } from "react";
import styled from "styled-components";
import TextInput from "../TextInput";
// import getSite from "../../../utils/Site";
import {
  IconLabel,
  IconContainer,
  HeaderIcon
} from "../../MagicMirror/Header/styled";
import FavoriteButton from "../../MagicMirror/FavoriteButton/index";
import { Grid, LogOut } from "react-feather";
import { useHistory } from "react-router-dom";
import { Logo } from "../../../components/Logo";
import LogoBGFadeFour from "../../../assets/images/logo-bg-fade-four.png";
import theme from "../../../assets/styles/theme";
import ConfirmModal from "../../ui/ConfirmModal";
import useApp from "context/AppContext";

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 2.5%;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 4;
  background-color: ${(props) => props.theme.colors.secondary};
  ${(props) =>
    props.theme.enableHeaderSVG && `background-image: url(${LogoBGFadeFour})`};
  background-repeat: no-repeat;
  @media screen and (min-width: ${(props) => props.theme.breakPoints.large}) {
    width: 100%;
  }
`;

const SelectAllButton = styled.button`
  margin-right: 25px;
  border-radius: 10px;
  border: none;
  background-color: ${theme.mm.altTwo};
  color: white;
`;

const LeftContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RightContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
`;

const LoadingIndicator = styled.div`
  margin-top: -20px;
  position: relative;
  width: 24px;
  height: 24px;
  transform: scale(0.5);

  div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation: lds-grid 1.2s linear infinite;
  }

  div:nth-child(1) {
    top: 6px;
    left: 6px;
    animation-delay: 0s;
  }
  div:nth-child(2) {
    top: 6px;
    left: 26px;
    animation-delay: -0.4s;
  }
  div:nth-child(3) {
    top: 6px;
    left: 45px;
    animation-delay: -0.8s;
  }
  div:nth-child(4) {
    top: 26px;
    left: 6px;
    animation-delay: -0.4s;
  }
  div:nth-child(5) {
    top: 26px;
    left: 26px;
    animation-delay: -0.8s;
  }
  div:nth-child(6) {
    top: 26px;
    left: 45px;
    animation-delay: -1.2s;
  }
  div:nth-child(7) {
    top: 45px;
    left: 6px;
    animation-delay: -0.8s;
  }
  div:nth-child(8) {
    top: 45px;
    left: 26px;
    animation-delay: -1.2s;
  }
  div:nth-child(9) {
    top: 45px;
    left: 45px;
    animation-delay: -1.6s;
  }

  @keyframes lds-grid {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`;

interface ToolBarProps {
  searchValueUpdated: (search: string) => void;
  selectedAllPressed?: () => void;
  searchText?: string;
  loading: boolean;
}

export const ToolBar: React.FC<ToolBarProps> = ({
  searchValueUpdated,
  selectedAllPressed,
  loading,
  searchText
}) => {
  const { appCtx } = useApp();
  // const site = getSite();
  const [hoverDash, setHoverDash] = useState(false);
  const [logoutHover, setLogoutHover] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const history = useHistory();

  return (
    <Container>
      <LeftContainer>
        {true && (
          <IconContainer
            onMouseEnter={() => {
              setHoverDash(true);
            }}
            onMouseLeave={() => {
              setHoverDash(false);
            }}
          >
            <FavoriteButton
              toggle={() => {
                history.push("/magic-mirror");
              }}
              Icon={Grid}
              fill="white"
              color="white"
              bgColor={theme.mm.altTwo}
            />
            {hoverDash && <IconLabel>Grid</IconLabel>}
          </IconContainer>
        )}

        <Logo />

        {loading && (
          <LoadingIndicator>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </LoadingIndicator>
        )}
      </LeftContainer>

      <RightContainer>
        {searchText && searchText.length > 0 && (
          <SelectAllButton onClick={selectedAllPressed}>
            Select All
          </SelectAllButton>
        )}

        <TextInput
          disabled={loading}
          className="search-input"
          placeholder={loading ? "Loading..." : "Search..."}
          onChange={(e) => {
            searchValueUpdated(e.target.value);
          }}
        />

        <IconContainer
          onMouseEnter={() => {
            setLogoutHover(true);
          }}
          onMouseLeave={() => {
            setLogoutHover(false);
          }}
        >
          <HeaderIcon
            toggle={() => setLogoutModalOpen(!logoutModalOpen)}
            Icon={LogOut}
            fill="none"
            color="white"
            bgColor={theme.mm.altTwo}
          />
          {logoutHover && <IconLabel>Logout</IconLabel>}
        </IconContainer>
      </RightContainer>

      <ConfirmModal
        cancelAction={() => setLogoutModalOpen(false)}
        cancelText="Cancel"
        confirmAction={appCtx.logout}
        confirmMessage="Are you sure you want to sign out?"
        confirmText="Yes"
        contentLabel="Logout Modal"
        isOpen={logoutModalOpen}
        title="Logout?"
      />
    </Container>
  );
};

export default ToolBar;
