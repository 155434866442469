import * as React from "react";
import axios, { AxiosResponse } from "axios";
import GroupActions from "./GroupActions";
import Modal from "react-modal";
import { X } from "react-feather";
import Image from "react-async-image";
import {
  Table,
  Column,
  Index,
  TableHeaderProps,
  AutoSizer,
  SortDirection,
  SortDirectionType,
  defaultTableRowRenderer,
  TableRowProps
} from "react-virtualized";
import { RouteProps } from "react-router-dom";
import {
  SortableContainer,
  SortableElement,
  WrappedComponent
} from "react-sortable-hoc";
import Tooltip from "react-tooltip";
import { Helmet } from "react-helmet";
import defaultProfilePicture from "../../assets/images/mm-default-profile-pic.jpg";
import { Edit, CheckSquare, CornerUpLeft, Trash2 } from "react-feather";
import { debounce } from "lodash-es";
import arrayMove from "array-move";
import {
  TableWrapper,
  StyledColumn,
  TopicInputContainer,
  SaveButton,
  CheckBox,
  TooltipWrapper
} from "./styled";
import ToolBar from "../../components/ui/ToolBar";

export interface ICSVRow {
  id: number;
  timestamp: string;
  timezone: string;
  first_name: string;
  last_name: string;
  gender: string;
  locale: string;
  source: string;
  messenger: string;
  last_user_form_input: string;
  profile_picture: string;
  question_count: string;
  user_input: string;
  campaign: string;
  sympler_mirror: string;
  user_sent_final_video: string;
  deleted: string;
  question?: IQuestion;

  checked: boolean;
  editMode: boolean;
}

interface IQuestion {
  id: number;
  question: string;
  media_prompt: string;
}

interface ICSVEditorState {
  performingNetworkAction: boolean;
  error?: string;
  rows: ICSVRow[];
  filteredRows: ICSVRow[];
  groupEditMode: boolean;
  modalIsOpen: boolean;
  sortBy: string;
  sortDirection: SortDirectionType;

  topicText: string;
  searchText: string;

  rowProfilePicture: string;
  rowFirstName: string;
  rowLastName: string;
  rowUserInput: string;
  rowSymplerMirror: string;
}

const SortableTable = SortableContainer(Table, { withRef: true });
const SortableTableRowRenderer = SortableElement(
  defaultTableRowRenderer as WrappedComponent<TableRowProps>
);

class LiveCSVEditor extends React.Component<RouteProps, ICSVEditorState> {
  // #region Construction

  constructor(props: any) {
    super(props);

    this.state = {
      performingNetworkAction: false,
      error: undefined,
      groupEditMode: false,
      modalIsOpen: false,
      rows: [],
      filteredRows: [],
      searchText: "",
      topicText: "",
      sortBy: "date",
      sortDirection: SortDirection.DESC,

      rowProfilePicture: "",
      rowFirstName: "",
      rowLastName: "",
      rowUserInput: "",
      rowSymplerMirror: ""
    };
  }

  componentDidMount() {
    this.fetchCSVData();
    this._rebuildTooltip();
  }

  // #endregion Construction

  // #region Data

  sort = (info: { sortBy: string; sortDirection: SortDirectionType }) => {
    const sortedRows = this.state.filteredRows.sort((a, b) => {
      switch (info.sortBy) {
        case "id":
          if (info.sortDirection === SortDirection.ASC) {
            return a.id > b.id ? 1 : -1;
          } else {
            return a.id < b.id ? 1 : -1;
          }
        // break;
        // case "username":
        //   if (info.sortDirection === SortDirection.ASC) {
        //     return a.username > b.username ? 1 : -1;
        //   } else {
        //     return a.username < b.username ? 1 : -1;
        //   }
        //   break;
        // case "text":
        //   if (info.sortDirection === SortDirection.ASC) {
        //     return a.text > b.text ? 1 : -1;
        //   } else {
        //     return a.text < b.text ? 1 : -1;
        //   }
        //   break;
        // case "question":
        //   if (info.sortDirection === SortDirection.ASC) {
        //     return a.question > b.question ? 1 : -1;
        //   } else {
        //     return a.question < b.question ? 1 : -1;
        //   }
        //   break;
        // case "subtopic":
        //   if (info.sortDirection === SortDirection.ASC) {
        //     return a.subtopic > b.subtopic ? 1 : -1;
        //   } else {
        //     return a.subtopic < b.subtopic ? 1 : -1;
        //   }
        //   break;
        // case "date":
        //   if (info.sortDirection === SortDirection.ASC) {
        //     return new Date(a.date) > new Date(b.date) ? 1 : -1;
        //   } else {
        //     return new Date(a.date) < new Date(b.date) ? 1 : -1;
        //   }
        //   break;
        default:
          return 1;
      }
    });

    this.setState({
      sortBy: info.sortBy,
      sortDirection: info.sortDirection,
      filteredRows: sortedRows
    });
  };

  fetchCSVData = async () => {
    this.setState({
      performingNetworkAction: true
    });

    const response: AxiosResponse<ICSVRow> = await axios.get<any>(
      `${process.env.REACT_APP_API_HOST}api/v1/liveSocial`
    );

    if (response && response.data && response.data) {
      if (response.status === 200) {
        const data: any = response.data;
        if (data.response) {
          this.setState({
            performingNetworkAction: false,
            error: undefined,
            rows: data.response.results,
            filteredRows: []
          });

          this.sort({
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
          });
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            performingNetworkAction: false,
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            filteredRows: []
          });
        }
      } else {
        console.log("Failed to get CSV data");
        console.log(response);

        this.setState({
          performingNetworkAction: false,
          error: "Oops, something went wrong. Please try again.",
          rows: [],
          filteredRows: []
        });
      }
    }

    this.updateFilteredRows();
  };

  selectedRows = (): ICSVRow[] => {
    let selectedRows: ICSVRow[] = [];

    for (const r of this.state.filteredRows) {
      if (r.checked) {
        selectedRows.push(r);
      }
    }

    return selectedRows;
  };

  formDataForRow = (row: ICSVRow) => {
    const data = new FormData();
    data.append("id", row.id.toString());
    data.append("timestamp", row.timestamp);
    data.append("timezone", row.timezone);
    data.append("first_name", row.first_name);
    data.append("last_name", row.last_name);
    data.append("gender", row.gender);
    data.append("source", row.source);
    data.append("messenger", row.messenger);
    data.append("last_user_form_input", row.last_user_form_input);
    data.append("profile_picture", row.profile_picture);
    data.append("question_count", row.question_count);
    data.append("user_input", row.user_input);
    data.append("campaign", row.campaign);
    data.append("sympler_mirror", row.sympler_mirror);
    data.append("user_sent_final_video", row.user_sent_final_video);

    return data;
  };

  saveRow = async (row: ICSVRow) => {
    let axiosConfig = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const response: AxiosResponse<ICSVRow> = await axios.post<any>(
      `${process.env.REACT_APP_API_HOST}api/v1/livesocial`,
      {
        rows: [row]
      },
      axiosConfig
    );

    // const response: AxiosResponse<ICSVRow> = await axios.post<any>(`${process.env.REACT_APP_API_HOST}/api/v1/social`, data);

    this.setState({
      performingNetworkAction: true
    });

    if (response && response.data && response.data) {
      if (response.status === 200) {
        const data: any = response.data;
        if (data.response) {
          this.setState({
            error: undefined,
            rows: data.response,
            performingNetworkAction: false
          });

          this.sort({
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
          });
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            performingNetworkAction: false
          });
        }
      } else {
        console.log("Failed to get CSV data");
        console.log(response);

        this.setState({
          error: "Oops, something went wrong. Please try again.",
          rows: [],
          performingNetworkAction: false
        });
      }

      this.updateFilteredRows();
    }
  };

  clickedSaveTopic = async () => {
    // const selectedRows = this.selectedRows();
    // if (selectedRows.length > 0) {
    //   for (const row of selectedRows) {
    //     row.topic = this.state.topicText;
    //     row.checked = false;
    //   }
    //   this.updateLocalRows(selectedRows);
    //   this.setState({
    //     groupEditMode: false,
    //     topicText: "",
    //     modalIsOpen: false,
    //     performingNetworkAction: true
    //   });
    //   let axiosConfig = {
    //     headers: {
    //       "Content-Type": "application/json"
    //     }
    //   };
    //   const response: AxiosResponse<ICSVRow> = await axios.post<any>(
    //     `${process.env.REACT_APP_API_HOST}api/v1/livesocial`,
    //     {
    //       rows: selectedRows
    //     },
    //     axiosConfig
    //   );
    //   if (response && response.data && response.data) {
    //     if (response.status === 200) {
    //       const data: any = response.data;
    //       if (data.response) {
    //         this.setState({
    //           error: undefined,
    //           rows: data.response,
    //           performingNetworkAction: false
    //         });
    //         this.sort({
    //           sortBy: this.state.sortBy,
    //           sortDirection: this.state.sortDirection
    //         });
    //       } else {
    //         console.log("Failed to get CSV data");
    //         console.log(response);
    //         this.setState({
    //           error: "Oops, something went wrong. Please try again.",
    //           rows: [],
    //           performingNetworkAction: false
    //         });
    //       }
    //     } else {
    //       console.log("Failed to get CSV data");
    //       console.log(response);
    //       this.setState({
    //         error: "Oops, something went wrong. Please try again.",
    //         rows: [],
    //         performingNetworkAction: false
    //       });
    //     }
    //     this.updateFilteredRows();
    //   }
    // }
  };

  clickedRecoverOnRows = async () => {
    const selectedRows = this.selectedRows();

    if (selectedRows.length > 0) {
      for (const row of selectedRows) {
        row.deleted = "0";
        row.checked = false;
      }

      this.updateLocalRows(selectedRows);

      this.setState({
        groupEditMode: false,
        performingNetworkAction: true
      });

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      const response: AxiosResponse<ICSVRow> = await axios.post<any>(
        `${process.env.REACT_APP_API_HOST}api/v1/livesocial`,
        {
          rows: selectedRows
        },
        axiosConfig
      );

      if (response && response.data && response.data) {
        if (response.status === 200) {
          const data: any = response.data;
          if (data.response) {
            this.setState({
              error: undefined,
              rows: data.response,
              performingNetworkAction: false
            });

            this.sort({
              sortBy: this.state.sortBy,
              sortDirection: this.state.sortDirection
            });
          } else {
            console.log("Failed to get CSV data");
            console.log(response);

            this.setState({
              error: "Oops, something went wrong. Please try again.",
              rows: [],
              performingNetworkAction: false
            });
          }
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            performingNetworkAction: false
          });
        }

        this.updateFilteredRows();
      }
    }
  };

  clickedDeleteOnRows = async () => {
    let selectedRows = this.selectedRows();

    if (selectedRows.length > 0) {
      for (const row of selectedRows) {
        row.deleted = "1";
        row.checked = false;
      }

      this.updateLocalRows(selectedRows);

      this.setState({
        groupEditMode: false,
        performingNetworkAction: true
      });

      let axiosConfig = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      const response: AxiosResponse<ICSVRow> = await axios.post<any>(
        `${process.env.REACT_APP_API_HOST}api/v1/livesocial`,
        {
          rows: selectedRows
        },
        axiosConfig
      );

      if (response && response.data && response.data) {
        if (response.status === 200) {
          const data: any = response.data;
          if (data.response) {
            this.setState({
              error: undefined,
              rows: data.response,
              performingNetworkAction: false
            });

            this.sort({
              sortBy: this.state.sortBy,
              sortDirection: this.state.sortDirection
            });
          } else {
            console.log("Failed to get CSV data");
            console.log(response);

            this.setState({
              error: "Oops, something went wrong. Please try again.",
              rows: [],
              performingNetworkAction: false
            });
          }
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            performingNetworkAction: false
          });
        }

        this.updateFilteredRows();
      }
    }
  };

  clickedDelete = async (row: ICSVRow) => {
    row.deleted = "1";
    this.updateLocalRows([row]);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    this.setState({
      performingNetworkAction: true
    });

    const response: AxiosResponse<ICSVRow> = await axios.post<any>(
      `${process.env.REACT_APP_API_HOST}api/v1/livesocial`,
      {
        rows: [row]
      },
      axiosConfig
    );

    if (response && response.data && response.data) {
      if (response.status === 200) {
        const data: any = response.data;
        if (data.response) {
          this.setState({
            error: undefined,
            rows: data.response,
            performingNetworkAction: false
          });

          this.sort({
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
          });
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            performingNetworkAction: false
          });
        }
      } else {
        console.log("Failed to get CSV data");
        console.log(response);

        this.setState({
          error: "Oops, something went wrong. Please try again.",
          rows: [],
          performingNetworkAction: false
        });
      }

      this.updateFilteredRows();
    }
  };

  clickedRecover = async (row: ICSVRow) => {
    row.deleted = "0";
    this.updateLocalRows([row]);

    let axiosConfig = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    this.setState({
      performingNetworkAction: true
    });

    const response: AxiosResponse<ICSVRow> = await axios.post<any>(
      `${process.env.REACT_APP_API_HOST}api/v1/livesocial`,
      {
        rows: [row]
      },
      axiosConfig
    );

    // const data = this.formDataForRow(row);

    // const response: AxiosResponse<ICSVRow> = await axios.post<any>(`${process.env.REACT_APP_API_HOST}/api/v1/social`, data);

    if (response && response.data && response.data) {
      if (response.status === 200) {
        const data: any = response.data;
        if (data.response) {
          this.setState({
            error: undefined,
            rows: data.response,
            performingNetworkAction: false
          });
          this.sort({
            sortBy: this.state.sortBy,
            sortDirection: this.state.sortDirection
          });
        } else {
          console.log("Failed to get CSV data");
          console.log(response);

          this.setState({
            error: "Oops, something went wrong. Please try again.",
            rows: [],
            performingNetworkAction: false
          });
        }
      } else {
        console.log("Failed to get CSV data");
        console.log(response);

        this.setState({
          error: "Oops, something went wrong. Please try again.",
          rows: [],
          performingNetworkAction: false
        });
      }

      this.updateFilteredRows();
    }
  };

  // #endregion Data

  // #region Actions

  clickedSaveRow = (row: ICSVRow) => {
    const newRow: ICSVRow = {
      id: row.id,
      timestamp: row.timestamp,
      timezone: row.timezone,
      first_name: this.state.rowFirstName,
      last_name: this.state.rowLastName,
      gender: row.gender,
      locale: row.locale,
      source: row.source,
      messenger: row.messenger,
      last_user_form_input: row.last_user_form_input,
      profile_picture: row.profile_picture,
      question_count: row.question_count,
      user_input: this.state.rowUserInput,
      campaign: row.campaign,
      sympler_mirror: this.state.rowSymplerMirror,
      user_sent_final_video: row.user_sent_final_video,
      deleted: row.deleted,

      checked: false,
      editMode: false
    };

    this.updateLocalRows([newRow]);
    this.saveRow(newRow);
  };

  clickedEdit = (row: ICSVRow) => {
    let rowsToUpdate: ICSVRow[] = [];

    for (const r of this.state.filteredRows) {
      if (r.editMode) {
        r.editMode = false;
        rowsToUpdate.push(r);
      }
    }

    this.updateLocalRows(rowsToUpdate);

    row.editMode = true;

    this.setState({
      rowProfilePicture: row.profile_picture,
      rowFirstName: row.first_name,
      rowLastName: row.last_name,
      rowUserInput: row.user_input,
      rowSymplerMirror: row.sympler_mirror
    });

    this.updateLocalRows([row]);
  };

  clickedSelectAll = () => {
    let newRows = this.state.filteredRows;

    for (const row of newRows) {
      row.checked = true;
    }

    this.setState({ filteredRows: newRows, groupEditMode: true });
  };

  clickedEditOnRows = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen });
  };

  closeGroupdActions = () => {
    let rows = this.state.filteredRows;

    for (let r of rows) {
      r.checked = false;
    }

    this.setState({
      groupEditMode: false,
      rows: rows
    });
  };

  updateLocalRows = (rows: ICSVRow[]) => {
    let newRows = this.state.filteredRows;

    for (const row of rows) {
      let index = -1;
      let i = 0;

      for (const r of this.state.filteredRows) {
        if (r.id === row.id) {
          index = i;
          break;
        }

        i += 1;
      }

      if (index >= 0) {
        newRows[index] = row;
      }
    }

    this.setState({
      rows: newRows
    });
  };

  checkRowValueChanged = (row: ICSVRow, checked: boolean) => {
    for (const r of this.state.filteredRows) {
      if (r.id === row.id) {
        r.checked = checked;

        break;
      }
    }

    let c = false;
    for (const r of this.state.filteredRows) {
      if (r.checked) {
        c = true;
        break;
      }
    }

    let newRows: ICSVRow[] = this.state.filteredRows;
    for (const r of newRows) {
      r.editMode = false;
    }

    this.setState({ rows: newRows, groupEditMode: c });
  };

  updateFilteredRows = () => {
    if (this.state.searchText.length > 0) {
      console.log("getting rows with text: " + this.state.searchText);

      let rows: ICSVRow[] = [];
      const search = this.state.searchText.toLocaleLowerCase();

      for (const row of this.state.rows) {
        if (
          (row.first_name &&
            row.first_name.toLocaleLowerCase().indexOf(search) >= 0) ||
          (row.last_name &&
            row.last_name.toLocaleLowerCase().indexOf(search) >= 0) ||
          (row.user_input &&
            row.user_input.toLocaleLowerCase().indexOf(search) >= 0) ||
          (row.sympler_mirror &&
            row.sympler_mirror.toLocaleLowerCase().indexOf(search) >= 0)
        ) {
          rows.push(row);
        }
      }

      this.setState({ filteredRows: rows }, () => {
        this.sort({
          sortBy: this.state.sortBy,
          sortDirection: this.state.sortDirection
        });
      });
    } else {
      this.setState({ filteredRows: this.state.rows }, () => {
        this.sort({
          sortBy: this.state.sortBy,
          sortDirection: this.state.sortDirection
        });
      });
    }
  };

  // #endregion Actions

  // #region Render Functions

  renderGroupActions = () => {
    if (this.state.groupEditMode) {
      return (
        <GroupActions
          deletePressed={this.clickedDeleteOnRows}
          editPressed={this.clickedEditOnRows}
          recoverPressed={this.clickedRecoverOnRows}
          closePressed={this.closeGroupdActions}
        />
      );
    }

    return null;
  };

  renderEditButtons = (row: ICSVRow) => {
    if (!this.state.groupEditMode) {
      if (row.editMode) {
        return (
          <>
            <CheckSquare
              className="edit-icon"
              onClick={() => {
                this.clickedSaveRow(row);
              }}
            />
          </>
        );
      } else {
        return (
          <>
            <Edit
              className="edit-icon"
              onClick={() => {
                this.clickedEdit(row);
              }}
            />
            {this.renderDeleteButtons(row)}
          </>
        );
      }
    }
  };

  renderDeleteButtons = (row: ICSVRow) => {
    if (row.deleted === "1") {
      return (
        <CornerUpLeft
          className="undo-icon"
          onClick={() => {
            this.clickedRecover(row);
          }}
        />
      );
    } else {
      return (
        <Trash2
          className="delete-icon"
          onClick={() => {
            this.clickedDelete(row);
          }}
        />
      );
    }
  };

  renderEditTopicModal = () => {
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: "300px",
            height: "175px"
          }
        }}
        contentLabel="Favroite Media"
      >
        <X onClick={() => this.setState({ modalIsOpen: false })} />

        <TopicInputContainer>
          <label>New Topic</label>
          <input
            type="text"
            value={this.state.topicText}
            onChange={(event) => {
              this.setState({ topicText: event.target.value });
            }}
          />
        </TopicInputContainer>

        <SaveButton onClick={this.clickedSaveTopic}>Save</SaveButton>
      </Modal>
    );
  };

  renderProfilePicture = (row: ICSVRow) => {
    console.log(`profile pciture: ${row.profile_picture}`);

    if (row.profile_picture && row.profile_picture.length > 0) {
      return <Image src={row.profile_picture} />;
    } else {
      return (
        <img
          className="default-profile-picture"
          src={defaultProfilePicture}
          alt="default profile"
        />
      );
    }
  };

  renderMedia = (mediaUrl: string) => {
    if (mediaUrl && mediaUrl.length > 0) {
      const url = mediaUrl.toLowerCase();

      if (
        url.indexOf(".jpg") !== -1 ||
        url.indexOf(".jpeg") !== -1 ||
        url.indexOf(".png") !== -1 ||
        url.indexOf(".gif") !== -1 ||
        url.indexOf(".webp") !== -1
      ) {
        return <Image src={url} alt="media img" />;
      } else if (url.indexOf(".mp4") !== -1 || url.indexOf(".webm") !== -1) {
        return (
          <video controls={true}>
            <source src={url} />
          </video>
        );
      }
    }

    return null;
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Magic Mirror - Live CSV Editor</title>
        </Helmet>
        <div style={{ height: "100%" }}>
          <div style={{ height: "100%" }}>
            <AutoSizer onResize={this._rebuildTooltip}>
              {({ height, width }) => (
                <TableWrapper>
                  <ToolBar
                    loading={this.state.performingNetworkAction}
                    searchText={this.state.searchText}
                    selectedAllPressed={this.clickedSelectAll}
                    searchValueUpdated={(searchValue) => {
                      this.setState({ searchText: searchValue }, () => {
                        this.updateFilteredRows();
                      });
                    }}
                  />
                  <SortableTable
                    axis="y"
                    lockAxis="y"
                    distance={2}
                    disableHeader={false}
                    height={height}
                    width={width < 1000 ? 1000 : width}
                    headerHeight={50}
                    helperClass="sortableHelper"
                    rowHeight={160}
                    rowRenderer={this._rowRenderer}
                    rowGetter={this._rowGetter}
                    rowCount={this.state.filteredRows.length}
                    sort={this.sort}
                    sortBy={this.state.sortBy}
                    sortDirection={this.state.sortDirection}
                    onScroll={this._rebuildTooltip}
                    onSortEnd={this._handleSort}
                    rowClassName={this._getRowClassName}
                  >
                    <Column
                      dataKey="checkbox"
                      width={45}
                      disableSort={true}
                      headerRenderer={this._headerRenderer.checkbox}
                      cellRenderer={this._cellRenderer.checkbox}
                    />

                    {this.props.location &&
                      this.props.location.pathname === "/live-csv-editor" && (
                        <Column
                          dataKey="edit_row"
                          width={35}
                          disableSort={true}
                          headerRenderer={(props: TableHeaderProps) => {
                            return <StyledColumn />;
                          }}
                          cellRenderer={(data) => {
                            const row: ICSVRow = data.rowData;

                            return (
                              <div
                                className={
                                  this.state.groupEditMode
                                    ? "cell"
                                    : "cell edit-buttons"
                                }
                              >
                                {this.renderEditButtons(row)}
                              </div>
                            );
                          }}
                        />
                      )}

                    <Column
                      dataKey="profile_picture"
                      width={75}
                      disableSort={true}
                      headerRenderer={this._headerRenderer.profile_picture}
                      cellRenderer={this._cellRenderer.profile_picture}
                    />

                    <Column
                      dataKey="id"
                      width={45}
                      headerRenderer={this._headerRenderer.id}
                      cellRenderer={this._cellRenderer.id}
                    />

                    <Column
                      dataKey="first_name"
                      width={70}
                      flexGrow={1}
                      headerRenderer={this._headerRenderer.first_name}
                      cellRenderer={this._cellRenderer.first_name}
                    />

                    <Column
                      dataKey="last_name"
                      width={75}
                      flexGrow={1}
                      headerRenderer={this._headerRenderer.last_name}
                      cellRenderer={this._cellRenderer.last_name}
                    />

                    <Column
                      dataKey="user_input"
                      width={75}
                      flexGrow={1}
                      headerRenderer={this._headerRenderer.user_input}
                      cellRenderer={this._cellRenderer.user_input}
                    />

                    <Column
                      dataKey="question"
                      width={75}
                      flexGrow={1}
                      headerRenderer={this._headerRenderer.question}
                      cellRenderer={this._cellRenderer.question}
                    />

                    <Column
                      dataKey="sympler_mirror"
                      width={75}
                      flexGrow={1}
                      disableSort={true}
                      headerRenderer={this._headerRenderer.sympler_mirror}
                      cellRenderer={this._cellRenderer.sympler_mirror}
                    />
                  </SortableTable>
                </TableWrapper>
              )}
            </AutoSizer>

            {this.renderGroupActions()}
          </div>

          <TooltipWrapper>
            <Tooltip className="tooltip-text" type="dark" place="right" />
          </TooltipWrapper>

          {this.renderEditTopicModal()}
        </div>
      </React.Fragment>
    );
  }

  // #endregion Render Functions

  _handleSort = ({ oldIndex, newIndex }: any) => {
    this.setState(({ filteredRows }) => ({
      filteredRows: arrayMove(filteredRows, oldIndex, newIndex)
    }));
  };

  _rowRenderer = (props: any): React.ReactElement => {
    return <SortableTableRowRenderer {...props} />;
  };

  _rowGetter = ({ index }: Index) => {
    return this.state.filteredRows[index];
  };

  _getRowClassName = ({ index }: Index) => {
    if (index >= 0) {
      const row = this.state.filteredRows[index];
      if (row.deleted === "1") {
        return "deleted";
      }
    }

    return "";
  };

  _getHeaderClassName = ({
    dataKey,
    sortBy,
    sortDirection
  }: TableHeaderProps): string => {
    return dataKey === sortBy
      ? sortDirection === SortDirection.ASC
        ? "headerSortDown"
        : "headerSortUp"
      : "";
  };

  _rebuildTooltip = debounce(() => Tooltip.rebuild(), 200, {
    leading: false,
    trailing: true
  });

  _headerRenderer = {
    checkbox: (props: TableHeaderProps) => <StyledColumn />,
    edit_row: (props: TableHeaderProps) => <StyledColumn />,
    profile_picture: (props: TableHeaderProps) => <StyledColumn />,
    id: (props: TableHeaderProps) => (
      <StyledColumn className={this._getHeaderClassName(props)}>
        ID
      </StyledColumn>
    ),
    first_name: (props: TableHeaderProps) => (
      <StyledColumn className={this._getHeaderClassName(props)}>
        First Name
      </StyledColumn>
    ),
    last_name: (props: TableHeaderProps) => (
      <StyledColumn className={this._getHeaderClassName(props)}>
        Last Name
      </StyledColumn>
    ),
    user_input: (props: TableHeaderProps) => (
      <StyledColumn className={this._getHeaderClassName(props)}>
        User Input
      </StyledColumn>
    ),
    question: (props: TableHeaderProps) => (
      <StyledColumn className={this._getHeaderClassName(props)}>
        Question
      </StyledColumn>
    ),
    sympler_mirror: () => <StyledColumn>Media</StyledColumn>
  };

  _cellRenderer = {
    checkbox: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div className="cell">
          <CheckBox
            type="checkbox"
            checked={row.checked}
            onChange={(event) => {
              this.checkRowValueChanged(row, event.target.checked);
            }}
          />
        </div>
      );
    },
    edit_row: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div
          className={this.state.groupEditMode ? "cell" : "cell edit-buttons"}
        >
          {this.renderEditButtons(row)}
        </div>
      );
    },
    profile_picture: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div className="cell profile-picture">
          {row.editMode && (
            <textarea
              maxLength={700}
              defaultValue={this.state.rowProfilePicture}
              onChange={(event) => {
                this.setState({
                  rowProfilePicture: event.currentTarget.value
                });
              }}
            />
          )}

          {!row.editMode && <>{this.renderProfilePicture(row)}</>}
        </div>
      );
    },
    id: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div className="cell">
          <strong>{row.id}</strong>
        </div>
      );
    },
    first_name: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div className="cell">
          {row.editMode && (
            <textarea
              maxLength={700}
              defaultValue={this.state.rowFirstName}
              onChange={(event) => {
                this.setState({
                  rowFirstName: event.currentTarget.value
                });
              }}
            />
          )}

          {!row.editMode && <span>{row.first_name}</span>}
        </div>
      );
    },
    last_name: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <>
          {row.editMode && (
            <div className="cell">
              <textarea
                maxLength={700}
                defaultValue={this.state.rowLastName}
                onChange={(event) => {
                  this.setState({
                    rowLastName: event.currentTarget.value
                  });
                }}
              />
            </div>
          )}

          {!row.editMode && (
            <>
              <div className="cell" data-tip={row.last_name}>
                {row.last_name}
              </div>
            </>
          )}
        </>
      );
    },
    user_input: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <>
          {row.editMode && (
            <div className="cell">
              <textarea
                maxLength={700}
                defaultValue={this.state.rowUserInput}
                onChange={(event) => {
                  this.setState({
                    rowUserInput: event.currentTarget.value
                  });
                }}
              />
            </div>
          )}

          {!row.editMode && (
            <>
              <div className="cell" data-tip={row.user_input}>
                {row.user_input}
              </div>
            </>
          )}
        </>
      );
    },
    question: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div
          className="cell"
          data-tip={row.question ? row.question.question : ""}
        >
          {row.question ? row.question.question : ""}
        </div>
      );
    },
    sympler_mirror: (data: any) => {
      const row: ICSVRow = data.rowData;

      return (
        <div className="cell media-column">
          {row.editMode && (
            <textarea
              maxLength={700}
              defaultValue={this.state.rowSymplerMirror}
              onChange={(event) => {
                this.setState({
                  rowSymplerMirror: event.currentTarget.value
                });
              }}
            />
          )}

          {!row.editMode && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={row.sympler_mirror}
            >
              {this.renderMedia(row.sympler_mirror)}
            </a>
          )}
        </div>
      );
    }
  };
}

export default LiveCSVEditor;
