import React from "react";
import {
  SectionGroup,
  ModalContainer,
  ModalInnerContainer,
  ModalBackground,
  TextFieldContainer
} from "./styled";
import { Formik, FormikActions, Field, FormikProps } from "formik";
import FormInput from "../../components/ui/FormInput";
import {
  INotificationGroup,
  INotificationTemplatePath,
  INotificationTemplate
} from "../../types/magicMirror";
import axios, { AxiosResponse } from "axios";
import * as Yup from "yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface CreateNotificationProps {
  modelOpen: boolean;
  toggle: (refresh: boolean) => void;
  selectedGroup: (notificationGroup: INotificationGroup) => void;
}

interface CreateNotificationState {
  submitErrors: string;
  notificationGroups: INotificationGroup[];
  templatePaths: INotificationTemplatePath[];
  notificationTemplates: string[];
}

interface IFormValues {
  name: string;
  triggerDate?: Date;
  recurring: boolean;
  enddate?: Date;
  endafterOccurrences?: number;
  fromEmail?: string;
  fromName?: string;
  toEmail?: string;
  subject: string;
  selectedGroup?: string;
  selectedTemplate?: string;
  messageType: string;
  plainMessage?: string;
  htmlMessage?: string;
  recurringtype?: string;
  bodyEditorState: EditorState;
  bodyTwoEditorState: EditorState;
  bodyThreeEditorState: EditorState;
  bodyFourEditorState: EditorState;
}

export default class CreateNotification extends React.Component<
  CreateNotificationProps,
  CreateNotificationState
> {
  initialFormValues = {
    name: "",
    triggerDate: undefined,
    recurring: false,
    enddate: undefined,
    endafterOccurrences: undefined,
    fromEmail: undefined,
    fromName: undefined,
    toEmail: "",
    subject: "",
    selectedGroup: undefined,
    selectedTemplate: undefined,
    messageType: "",
    plainMessage: undefined,
    htmlMessage: undefined,
    recurringtype: undefined,
    bodyEditorState: EditorState.createEmpty(),
    bodyTwoEditorState: EditorState.createEmpty(),
    bodyThreeEditorState: EditorState.createEmpty(),
    bodyFourEditorState: EditorState.createEmpty()
  };

  formValidation = Yup.object().shape({});

  constructor(props: CreateNotificationProps) {
    super(props);

    this.state = {
      submitErrors: "",
      notificationGroups: [],
      templatePaths: [],
      notificationTemplates: []
    };
  }

  async componentDidMount() {
    const response: AxiosResponse<INotificationGroup> = await axios.get<any>(
      `${process.env.REACT_APP_API_HOST}api/v1/notifications/groups`
    );
    let groups: INotificationGroup[] = [];

    if (response && response.data && response.data) {
      if (response.status === 200) {
        const data: any = response.data;

        if (data.response) {
          groups = data.response;
        }
      }
    }

    const notificationTemplatesReponse: AxiosResponse<INotificationGroup> = await axios.get<
      any
    >(`${process.env.REACT_APP_API_HOST}api/v1/notifications/templatePaths`);
    let templatePaths: INotificationTemplatePath[] = [];

    if (
      notificationTemplatesReponse &&
      notificationTemplatesReponse.data &&
      notificationTemplatesReponse.data
    ) {
      if (notificationTemplatesReponse.status === 200) {
        const data: any = notificationTemplatesReponse.data;

        if (data.response) {
          templatePaths = data.response;
        }
      }
    }

    this.setState({
      notificationGroups: groups,
      templatePaths: templatePaths
    });
  }

  async scheduleNotification(
    values: IFormValues,
    actions: FormikActions<IFormValues>,
    template?: INotificationTemplate
  ) {
    let data = {
      name: values.name,
      triggerDate: values.triggerDate
        ? moment.utc(values.triggerDate).format("YYYY-MM-DD HH:mm:ss")
        : undefined,
      subject: values.subject,
      sendSMS: false,
      sendEmail: true,
      showInUI: false,
      plainMessage: values.plainMessage,
      htmlMessage: values.htmlMessage,
      recurring: values.recurring,
      startDate: values.triggerDate
        ? moment.utc(values.triggerDate).format("YYYY-MM-DD HH:mm:ss")
        : undefined,
      endDate: values.enddate
        ? moment.utc(values.enddate).format("YYYY-MM-DD HH:mm:ss")
        : undefined,
      repeat: values.recurringtype,
      templateId: template ? template.id : undefined,
      endAfterNumberOfOccurrences: values.endafterOccurrences,
      toEmail: values.toEmail,
      toGroupId: values.selectedGroup,
      fromEmail: values.fromEmail,
      fromName: values.fromName
    };

    try {
      const response: AxiosResponse<any> = await axios.post<any>(
        `notifications`,
        data
      );

      if (response.data) {
        this.props.toggle(true);
        actions.resetForm();
      } else {
        alert("Failed to create notification");
      }
    } catch (error) {
      alert(`Failed to create notification\n${error}`);
    }

    actions.setSubmitting(false);
  }

  async handleSubmit(values: IFormValues, actions: FormikActions<IFormValues>) {
    if (values.messageType === "template") {
      let templatePath = "";
      for (const template of this.state.templatePaths) {
        if (Number(template.id) === Number(values.selectedTemplate)) {
          templatePath = template.file_path;
          break;
        }
      }

      const body = draftToHtml(
        convertToRaw(values.bodyEditorState.getCurrentContent())
      );
      const bodyTwo = draftToHtml(
        convertToRaw(values.bodyTwoEditorState.getCurrentContent())
      );
      const bodyThree = draftToHtml(
        convertToRaw(values.bodyThreeEditorState.getCurrentContent())
      );
      const bodyFour = draftToHtml(
        convertToRaw(values.bodyFourEditorState.getCurrentContent())
      );

      let data = {
        template_path: templatePath,
        name: values.name,
        body: body,
        bodyTwo: bodyTwo,
        bodyThree: bodyThree,
        bodyFour: bodyFour
      };

      try {
        const response: AxiosResponse<any> = await axios.post<
          INotificationTemplate
        >(`notifications/template`, data);

        if (response.data) {
          this.scheduleNotification(values, actions, response.data);
        } else {
          console.log(`Failed to create notification`);
        }
      } catch (error) {
        console.log(`Failed to create notification`);
        console.log(error);
      }
    } else {
      this.scheduleNotification(values, actions);
    }
  }

  renderTemplateArea(values: IFormValues, props: FormikProps<IFormValues>) {
    return (
      <>
        <hr />
        <h6>Templates</h6>
        <table>
          <thead>
            <tr>
              <th />
              <th>Template Name</th>
            </tr>
          </thead>
          <tbody>
            {this.state.templatePaths.map((template) => {
              return (
                <tr key={template.id}>
                  <td>
                    <Field
                      name="selectedTemplate"
                      component="input"
                      type="radio"
                      value={template.id}
                      required
                    />
                  </td>
                  <td>{template.name}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <TextFieldContainer>
          <Editor
            placeholder="Body"
            editorState={values.bodyEditorState}
            wrapperClassName="demo-wrapper"
            editorClassName="editor"
            onEditorStateChange={(newState) => {
              props.setFieldValue("bodyEditorState", newState);
            }}
          />

          <Editor
            placeholder="Body Two"
            editorState={values.bodyTwoEditorState}
            wrapperClassName="demo-wrapper"
            editorClassName="editor"
            onEditorStateChange={(newState) => {
              props.setFieldValue("bodyTwoEditorState", newState);
            }}
          />

          <Editor
            placeholder="Body Three"
            editorState={values.bodyThreeEditorState}
            wrapperClassName="demo-wrapper"
            editorClassName="editor"
            onEditorStateChange={(newState) => {
              props.setFieldValue("bodyThreeEditorState", newState);
            }}
          />

          <Editor
            placeholder="Body Four"
            editorState={values.bodyFourEditorState}
            wrapperClassName="demo-wrapper"
            editorClassName="editor"
            onEditorStateChange={(newState) => {
              props.setFieldValue("bodyFourEditorState", newState);
            }}
          />

          {this.renderTemplatePreview(values)}
        </TextFieldContainer>
      </>
    );
  }

  renderTemplatePreview(values: IFormValues) {
    if (values.selectedTemplate) {
      let templateHTML = "";
      for (const template of this.state.templatePaths) {
        if (Number(template.id) === Number(values.selectedTemplate)) {
          templateHTML = template.html;
          break;
        }
      }

      const body = draftToHtml(
        convertToRaw(values.bodyEditorState.getCurrentContent())
      );
      const bodyTwo = draftToHtml(
        convertToRaw(values.bodyTwoEditorState.getCurrentContent())
      );
      const bodyThree = draftToHtml(
        convertToRaw(values.bodyThreeEditorState.getCurrentContent())
      );
      const bodyFour = draftToHtml(
        convertToRaw(values.bodyFourEditorState.getCurrentContent())
      );

      if (body && body.length > 0) {
        templateHTML = templateHTML.replace("{{body}}", body);
      }
      if (bodyTwo && bodyTwo.length > 0) {
        templateHTML = templateHTML.replace("{{bodyTwo}}", bodyTwo);
      }
      if (bodyThree && bodyThree.length > 0) {
        templateHTML = templateHTML.replace("{{bodyThree}}", bodyThree);
      }
      if (bodyFour && bodyFour.length > 0) {
        templateHTML = templateHTML.replace("{{bodyFour}}", bodyFour);
      }

      return <div dangerouslySetInnerHTML={{ __html: templateHTML }} />;
    }

    return null;
  }

  renderPlainMessageTextArea() {
    return (
      <>
        <hr />

        <Field
          name="plainMessage"
          placeholder="Plain Text Message"
          component="textarea"
          required
        />

        <Field
          name="htmlMessage"
          placeholder="HTML Message"
          component="textarea"
          required
        />
      </>
    );
  }

  render() {
    return (
      <ModalContainer
        style={{ display: this.props.modelOpen ? "block" : "none" }}
      >
        <ModalBackground
          onClick={() => {
            this.props.toggle(false);
          }}
        />
        <ModalInnerContainer>
          <div>
            <h1>Notification Scheduler</h1>

            <Formik
              initialValues={this.initialFormValues}
              onSubmit={(values, actions) => this.handleSubmit(values, actions)}
              validationSchema={this.formValidation}
            >
              {(props) => {
                const { isSubmitting, handleSubmit } = props;

                console.log(props.values);

                return (
                  <form onSubmit={handleSubmit}>
                    <SectionGroup>
                      <h4>Basic</h4>

                      <Field
                        name="name"
                        placeholder="Notification Name"
                        component={FormInput}
                        required
                      />

                      <DatePicker
                        placeholderText="Trigger Date"
                        showTimeSelect
                        dateFormat="Pp"
                        selected={props.values.triggerDate}
                        onChange={(val) => {
                          props.setFieldValue("triggerDate", val);
                        }}
                      />
                    </SectionGroup>

                    <SectionGroup>
                      <h4>Recurring</h4>

                      <div className="row">
                        <div
                          className="col-12"
                          style={{ display: "inline-block" }}
                        >
                          <label>
                            <Field
                              name="recurring"
                              component="input"
                              type="checkbox"
                            />{" "}
                            Is Recurring
                          </label>
                        </div>

                        <hr />
                        <br />
                      </div>

                      {props.values.recurring && (
                        <div className="row">
                          <div className="col-xs-12 col-md-6">
                            <div className="row">
                              <div className="col-12">
                                <h6>Type</h6>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12">
                                <Field
                                  name="recurringtype"
                                  component="input"
                                  type="radio"
                                  value="daily"
                                  required={props.values.recurring}
                                />
                                <label> Daily</label>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12">
                                <Field
                                  name="recurringtype"
                                  component="input"
                                  type="radio"
                                  value="weekly"
                                  required={props.values.recurring}
                                />
                                <label> Weekly</label>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12">
                                <Field
                                  name="recurringtype"
                                  component="input"
                                  type="radio"
                                  value="monthly"
                                  required={props.values.recurring}
                                />
                                <label> Monthly</label>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-12">
                                <Field
                                  name="recurringtype"
                                  component="input"
                                  type="radio"
                                  value="yearly"
                                  required={props.values.recurring}
                                />
                                <label> Yearly</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-xs-12 col-md-6">
                            <DatePicker
                              placeholderText="End Date"
                              showTimeSelect
                              dateFormat="Pp"
                              selected={props.values.enddate}
                              onChange={(val) => {
                                props.setFieldValue("enddate", val);
                              }}
                            />
                            <Field
                              name="endafterOccurrences"
                              placeholder="End After Occurrences (optional)"
                              component={FormInput}
                            />
                          </div>
                        </div>
                      )}
                    </SectionGroup>

                    <SectionGroup>
                      <h4>Recipients</h4>

                      <div className="row">
                        <div className="col-12">
                          <Field
                            name="toEmail"
                            placeholder="To Email (optional)"
                            component={FormInput}
                            value={props.values.toEmail}
                          />
                        </div>
                        <div className="col-12">
                          <h6>Groups</h6>
                          <table>
                            <thead>
                              <tr>
                                <th />
                                <th>Group Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.notificationGroups.map((group) => {
                                return (
                                  <tr key={group.id}>
                                    <td>
                                      <Field
                                        name="selectedGroup"
                                        component="input"
                                        type="radio"
                                        value={group.id}
                                      />
                                    </td>
                                    <td
                                      onClick={() => {
                                        this.props.selectedGroup(group);
                                      }}
                                    >
                                      {group.name}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </SectionGroup>

                    <SectionGroup>
                      <h4>Message</h4>

                      <div className="row">
                        <div className="col-xs-12 col-md-6">
                          <Field
                            name="fromEmail"
                            placeholder="From Email (optional)"
                            component={FormInput}
                          />
                        </div>
                        <div className="col-xs-12 col-md-6">
                          <Field
                            name="fromName"
                            placeholder="From Name (optional)"
                            component={FormInput}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <Field
                            name="subject"
                            placeholder="Subject"
                            component={FormInput}
                            required
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <h6>Message Type</h6>
                          <label>
                            <Field
                              name="messageType"
                              component="input"
                              type="radio"
                              value="template"
                            />{" "}
                            Template
                          </label>
                          <br />
                          <label>
                            <Field
                              name="messageType"
                              component="input"
                              type="radio"
                              value="plain"
                            />{" "}
                            Plain
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          {props.values.messageType === "template" && (
                            <>{this.renderTemplateArea(props.values, props)}</>
                          )}
                          {props.values.messageType === "plain" && (
                            <>{this.renderPlainMessageTextArea()}</>
                          )}
                        </div>
                      </div>
                    </SectionGroup>

                    <button
                      type="submit"
                      className="theme-button"
                      style={{
                        background: "#FAA61A",
                        borderRadius: 10,
                        width: "100%",
                        color: "white",
                        height: 55
                      }}
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>

                    {this.state.submitErrors.length > 0 && (
                      <p>{this.state.submitErrors}</p>
                    )}
                  </form>
                );
              }}
            </Formik>
          </div>
        </ModalInnerContainer>
      </ModalContainer>
    );
  }
}
