import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FallbackProps } from "react-error-boundary";

function FallbackGrid({ resetErrorBoundary }: FallbackProps) {
  return (
    <ErrorWrapper>
      <ErrorButton onClick={resetErrorBoundary}>Back</ErrorButton>
    </ErrorWrapper>
  );
}

export default FallbackGrid;

const ErrorWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
`;

const ErrorButton = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: cornflowerblue;
  color: whitesmoke;
  border-radius: 10px;
  box-shadow: var(--shadow);
  padding: 0.5rem 1rem;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  margin-top: 2rem;
`;
