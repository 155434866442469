import styled from "styled-components";

export const ScreenWrapper = styled.div`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;

  .close {
    font-size: 1rem;
    cursor: pointer;
    align-self: flex-end;
    margin: 10px;

    &:hover {
      color: ${(props) => props.theme.pallet.primary};
    }
  }
`;

export const Container = styled.div`
  width: 80%;
  align-self: center;
  margin-top: 30%;
`;

export const SliderContainer = styled.div`
  background-color: ${(props) => props.theme.pallet.white};
  padding: 20px;
  border-radius: 10px;
`;

export const TextArea = styled.textarea`
  resize: none;
  overflow: hidden;
  height: 30px;
  width: 100%;
  background-color: transparent;
  font-weight: 600;
  color: ${(props) => props.theme.pallet.black};
  border: none;
  text-align: center;
  font-size: 20px;

  &:focus {
    outline: none;
  }
`;

export const Slider = styled.div`
  position: relative;
  /* background-color: blue; */
  display: flex;
  align-items: center;

  .icon {
    font-size: 38px;
    position: relative;
    z-index: 2;
  }

  .slide-bg {
    background-color: ${(props) => props.theme.pallet.lightGrey};
    position: absolute;
    height: 8px;
    width: 98%;
    left: 2%;
    z-index: 1;
    border-radius: 10px;
  }
`;
