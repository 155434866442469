import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  useResponses,
  useGrid,
  useBuckets,
  useOptions,
  useQuestions
} from "hooks";
import { Bucket } from "types/facelift";
import { Bucket as BucketIconSVG } from "@styled-icons/entypo/Bucket";

function EmptyGrid() {
  const resetResponses = useResponses((s) => s.resetResponses);
  const updateSearching = useGrid((s) => s.updateSearching);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);
  const updateSearchTerm = useGrid((s) => s.updateSearchTerm);
  const updateExpandedIndex = useGrid((s) => s.updateExpandedIndex);
  const updateCurrentQuestion = useQuestions((s) => s.updateCurrentQuestion);
  const updateSelectedUser = useGrid((s) => s.updateSelectedUser);
  const { reset } = useOptions();

  function handleReset() {
    updateSearching(true);
    resetResponses();
    updateSearchTerm("");
    updateExpandedIndex(null);
    updateSelectedBucket({} as Bucket);
    updateCurrentQuestion(null);
    updateSelectedUser(null);
    reset();
  }

  return (
    <EmptyGridWrapper>
      <BoldText>
        It looks like there aren't any responses at the moment.
      </BoldText>
      <RegularText>
        Head <span onClick={handleReset}>back to all responses</span> and try
        again.
      </RegularText>
    </EmptyGridWrapper>
  );
}

export default EmptyGrid;

const EmptyGridWrapper = styled(motion.div)`
  margin-top: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const BoldText = styled.div`
  font-size: 1.5rem;
  letter-spacing: 0.75px;
  color: #333;
  font-weight: 600;
  position: relative;
  z-index: 1;
  text-align: center;
  margin: 0 auto;
  max-width: 450px;
  line-height: 1.3;
  margin-bottom: 1.5rem;
`;

const RegularText = styled.div`
  font-size: 1rem;
  letter-spacing: 0.5px;
  color: #333;
  font-weight: 400;
  position: relative;
  z-index: 1;

  span {
    text-decoration: underline;
    color: #635ec0;
    cursor: pointer;
  }
`;

const BackgroundContainer = styled(motion.div)`
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 175px;
  height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.1;
`;

const BackgroundBucketIcon = styled(BucketIconSVG)`
  width: 100%;
  height: 100%;
  display: block;
  color: var(--gray-600);
`;
