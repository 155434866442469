import styled from "styled-components";
import Button from "../../components/ui/Button";

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  margin: 0 20px;
  font-family: "Open Sans", sans-serif;

  @media screen and (min-width: ${(props) => props.theme.breakPoints.small}) {
    margin: 0;
  }
`;

export const SymplerLogo = styled.img`
  width: 475px;
  margin-top: 15%;
  align-self: center;
  max-width: 100%;
`;

export const Form = styled.form`
  background: linear-gradient(90deg, #7ecefd 0%, #9ebdff 100%);
  /* background: #635ec0; */
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  border-radius: 20px;
  max-width: 550px;

  @media screen and (min-width: ${(props) => props.theme.breakPoints.small}) {
    padding: 40px;
  }
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.mm.white};
  font-size: 2.5rem;
  letter-spacing: 1px;
  line-height: 2.5rem;
  font-weight: 400;
  padding-bottom: 20px;
`;

export const SubmitButton = styled(Button)`
  border-radius: 10px;
  background: linear-gradient(180.31deg, #f78dd3 4.18%, #ff7da9 79.3%);
  letter-spacing: 0.5px;
`;

export const AuthContainerShadow = styled.img`
  margin-top: 10px;
  max-width: 100%;
`;

export const MagicMirrorGuy = styled.img`
  position: absolute;
  bottom: 40px;
  right: 40px;
  opacity: 0.25;
  width: 200px;
  z-index: -1;
  display: none;

  @media screen and (min-width: ${(props) => props.theme.breakPoints.small}) {
    display: block;
  }

  @media screen and (min-width: ${(props) => props.theme.breakPoints.large}) {
    width: 300px;
  }
`;
