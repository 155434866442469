import * as React from "react";
import useOrg from "context/OrganizationContext";
import { Redirect } from "react-router";

export const NoMatch: React.SFC<{}> = () => {
  const org = useOrg();

  if (org && org.settings.length) {
    const setting = org.settings[0];
    return <Redirect to={setting.default_route} />;
  } else {
    return <Redirect to="/facelift" />;
  }
};

export default NoMatch;
