import * as React from "react";
import { Hash, Image } from "react-feather";
import styled from "styled-components";

interface ISlideoutTogglesProps {
  toggleHastagsSlideout: () => void;
  toggleSavedImagesSlideout: () => void;
}

export default ({
  toggleHastagsSlideout,
  toggleSavedImagesSlideout
}: ISlideoutTogglesProps) => (
  <SlideToggle>
    <Hash color="white" size={32} onClick={toggleHastagsSlideout} />
    <Image color="white" size={32} onClick={toggleSavedImagesSlideout} />
  </SlideToggle>
);

const SlideToggle = styled.div`
  background-color: #00db93;
  position: absolute;
  right: 0;
  top: 140px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;

  svg:first-of-type {
    margin-bottom: 20px;
  }

  svg {
    transition: ${(props) => props.theme.animations.default};

    &:hover {
      transform: scale(1.2);
    }
  }
`;
