import * as React from "react";
import { NavLink } from "react-router-dom";
import { IRoute } from "../../types/routes";
import promotedSnapsIcon from "../../assets/images/PromotedSnapsIcon.svg";
import logout from "../../assets/images/Logout.svg";

import { SidebarContainer, SidebarInner, SidebarLinks } from "./styled";

interface ISidebarProps {
  routes: IRoute[];
  hideSidebar: boolean;
}

class Sidebar extends React.Component<ISidebarProps, {}> {
  renderTopRoutes() {
    const { routes } = this.props;

    if (routes.length > 0) {
      const topRoutes = routes.filter((route: IRoute) => route.order >= 0);

      return topRoutes.map((route: IRoute, index: number) => (
        <li key={index}>
          <NavLink exact to={route.path}>
            <img
              src={`data:image/png;base64,${route.image_url}`}
              alt={route.title}
            />
          </NavLink>
        </li>
      ));
    }
  }

  renderBottomRoutes() {
    const { routes } = this.props;

    if (routes.length > 0) {
      const bottomRoutes = routes.filter((route: IRoute) => route.order < 0);

      return bottomRoutes.map((route: IRoute, index: number) => (
        <li key={index}>
          <NavLink exact to={route.path}>
            <img
              src={`data:image/png;base64,${route.image_url}`}
              alt={route.title}
            />
          </NavLink>
        </li>
      ));
    }
  }

  render() {
    return (
      <SidebarContainer hideSidebar={this.props.hideSidebar}>
        <SidebarInner>
          <SidebarLinks>
            {this.renderTopRoutes()}
            <li>
              <NavLink exact to="/mars">
                <img src={promotedSnapsIcon} alt="Mars" />
              </NavLink>
            </li>
          </SidebarLinks>

          <SidebarLinks>
            {this.renderBottomRoutes()}
            <li>
              <button onClick={() => {}} className="last-button">
                <img className="button" src={logout} alt="Logout" />
              </button>
            </li>
          </SidebarLinks>
        </SidebarInner>
      </SidebarContainer>
    );
  }
}

export default Sidebar;
