import * as React from "react";
import { Maximize, Star } from "react-feather";

import { Container, ResultsHeader, HashTagList, PostList } from "./styled";
import ViewMoreModal from "../ViewMoreModal";

interface IPostsProps {
  data: any;
  dataType: string;
}

interface IPostsState {
  viewMoreModalOpen: boolean;
}

class Posts extends React.Component<IPostsProps, IPostsState> {
  constructor(props: IPostsProps) {
    super(props);

    this.state = {
      viewMoreModalOpen: false
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(viewMoreModalOpen: boolean) {
    this.setState({ viewMoreModalOpen });
  }

  renderResults() {
    console.log("data: ", this.props.data);
    console.log("dataType: ", this.props.dataType);

    let posts = [];
    if (this.props.dataType === "hashtagsearch") {
      posts = this.props.data.data.response.results.map((post: any) => {
        return {
          src: post.profile_pic_url
        };
      });
    }

    // return fakeData.map((data: any, index: number) => (
    return posts.map((data: any, index: number) => (
      <li
        key={index}
        className="flip-card"
        onClick={() => this.toggleModal(true)}
      >
        <div className="flip-card-inner">
          <div className="flip-card-front">
            <div
              style={{
                width: "100%",
                height: "100%",
                // backgroundImage: `url(${placeHolderImage})`,
                backgroundImage: `url(${data.src})`
              }}
            ></div>
          </div>
          <div className="flip-card-back">
            <span>
              <Maximize />
            </span>
            <span>
              <Star />
            </span>
          </div>
        </div>
      </li>
    ));
  }

  render() {
    return (
      <Container className="container">
        <ResultsHeader>
          <h2>#PGATour</h2>
          <HashTagList>
            <li>
              #Taylormade
              <span>(234)</span>
            </li>
            <li>
              #garywoodland
              <span>(234)</span>
            </li>
            <li>
              #garywoodland
              <span>(234)</span>
            </li>
            <li>
              #garywoodland
              <span>(234)</span>
            </li>
            <li>
              #garywoodland
              <span>(234)</span>
            </li>
          </HashTagList>
        </ResultsHeader>
        <PostList>{this.renderResults()}</PostList>
        <ViewMoreModal
          isOpen={this.state.viewMoreModalOpen}
          toggleModal={this.toggleModal}
        />
      </Container>
    );
  }
}

export default Posts;
