import React from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import * as sizes from "utils/facelift/gridMeasurements";

function SkeletonCard() {
  return (
    <Wrapper>
      <BoxTwo></BoxTwo>
    </Wrapper>
  );
}

export default SkeletonCard;

const shine = keyframes`
  0% { 
    transform:translateX(-100%)
  },
	100% {
    transform:translateX(100%)
  }
`;

const Wrapper = styled(motion.div)`
  width: ${sizes.CARD_WIDTH}px;
  height: ${sizes.CARD_WIDTH}px;
  /* margin-left: ${sizes.CARD_MARGIN}px;
  margin-right: ${sizes.CARD_MARGIN}px;
  margin-bottom: ${sizes.GUTTER}px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 70px;
  overflow: hidden;
  border-radius: 1px solid #7a7a7a;
  opacity: 0.1;
  position: relative;
  z-index: 0;

  ::after {
    animation: ${shine} 1s ease-in-out infinite;

    position: absolute;
    content: "";
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    z-index: 1;

    background: linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(50%, rgba(255, 255, 255, 0.8)),
      color-stop(99%, rgba(128, 186, 232, 0)),
      color-stop(100%, rgba(125, 185, 232, 0))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* IE10+ */
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* W3C */
  }
`;

const BoxOne = styled(motion.div)`
  width: 100%;
  height: 36px;
  display: flex;
  background: #333;
`;

const BoxTwo = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  background: #7a7a7a;
  flex: 1;
`;

const BoxThree = styled(motion.div)`
  width: 100%;
  height: 36px;
  display: flex;
  background: #333;
  position: relative;
`;

const Circle = styled(motion.div)`
  width: 65px;
  height: 65px;
  border-radius: 50%;
  background: #333;
  position: absolute;
  bottom: 5px;
  left: 1rem;
`;
