import create from "zustand";
import axios from "axios";
import { range, addAdditionalFields } from "utils/facelift";
import { useGridAPI } from "./useGrid";
import { RawResponse, Response } from "types/facelift";

interface ResponsesProps {
  // Containing the initial batch of responses from the server -- fetched once and stored here, never mutated.
  responses: Response[];
  // Containing whatever responses should be displayed in the grid.
  gridResponses: Response[];
  // Containing whatever responses have been shift-clicked by the user in order to batch save to a bucket.
  selectedResponses: Response[];
  gridName: string;
  addSelectedResponse: (response: Response) => void;
  addSelectedResponses: (responses: Response[]) => void;
  removeSelectedResponse: (response: Response) => void;
  clearSelectedResponses: () => void;
  updateGridResponses: (gridResponses: Response[]) => void;
  fetchResponses: () => void;
  resetResponses: () => void;
}

const [useResponses, useResponsesAPI] = create<ResponsesProps>((set, get) => ({
  responses: [],
  gridResponses: [],
  selectedResponses: [],
  gridName: "",
  addSelectedResponse: (response: Response) => {
    set((state) => ({
      selectedResponses: [...state.selectedResponses, response]
    }));
  },
  addSelectedResponses: (responses: Response[]) => {
    set((state) => ({
      selectedResponses: responses
    }));
  },
  removeSelectedResponse: (response: Response) => {
    set((state) => ({
      selectedResponses: state.selectedResponses.filter(
        (resp: Response) => resp.id !== response.id
      )
    }));
  },
  clearSelectedResponses: () => set({ selectedResponses: [] }),
  fetchResponses: async () => {
    console.log(`fetching responses: `);
    const { data } = await axios.get("/social");

    const rawResponses: RawResponse[] = data.response;

    const responses = addAdditionalFields(rawResponses);

    // So responses with the longest text show up first in the grid
    const sortedResponses = [...responses].sort((a, z) =>
      a.text.length > z.text.length ? -1 : 1
    );

    set({
      responses: sortedResponses,
      gridResponses: sortedResponses,
      gridName: data?.currentStudy || ""
    });
  },
  updateGridResponses: (newResponses: Response[]) => {
    // So responses with the longest text show up first in the grid
    const sortedResponses = [...newResponses].sort((a, z) =>
      a.text.length > z.text.length ? -1 : 1
    );

    set({
      gridResponses: sortedResponses
    });

    setTimeout(() => {
      useGridAPI.setState({ isSearching: false });
    }, range(1, 50));
  },
  resetResponses: () => {
    const allResponses = get().responses;

    set({ gridResponses: allResponses });

    setTimeout(() => {
      useGridAPI.setState({ isSearching: false });
    }, range(1, 50));
  }
}));

export default useResponses;

export { useResponsesAPI };
