import * as React from "react";
import { ChevronRight } from "react-feather";
import update from "react-addons-update";
import html2canvas from "html2canvas";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import {
  Container,
  LoggedInAs,
  PostContainer,
  PostScreen,
  PostContent,
  PostActionListContainer,
  PostActionList,
  SendTo,
  SendToFiller,
  NoAccountContainer
} from "./styled";
import ToolBar from "../../components/Toolbar";
import PostObject from "../../components/PostBuilder/PostObject";
import PostCropperModal from "../../components/PostCropperModal";
import TextBuilder, {
  ITextLines
} from "../../components/PostBuilder/TextBuilder";
import PollBuilder from "../../components/PostBuilder/PollBuilder";
import AUTH from "../../types/auth";
import AppContext, { IAppContext } from "../../utils/AppContext";
import PostLoading from "../../components/PostBuilder/PostLoading";
import SliderBuilder from "../../components/PostBuilder/SliderBuilder";
import QuestionBuilder from "../../components/PostBuilder/QuestionBuilder";
import pollImage from "../../assets/images/poll.png";
import questionsImage from "../../assets/images/questions.png";
import sliderImage from "../../assets/images/slider.png";
import IGTextImage from "../../assets/images/IGText.png";

export interface IPollObject {
  questionURL: string;
  yesNoURL: string;
  width: number;
  height: number;
  x: number;
  y: number;
  question: string;
  answerOne: string;
  answerTwo: string;
}

export interface ISliderObject {
  canvasURL: string;
  width: number;
  height: number;
  x: number;
  y: number;
  question: string;
}

export interface IQuestionObject {
  canvasURL: string;
  width: number;
  height: number;
  x: number;
  y: number;
  question: string;
}

export interface ITextObject {
  index: null | number;
  canvasURL: string;
  width: number;
  height: number;
  x: number;
  y: number;
  color: string;
  backgroundEnabled: boolean;
  textLines: ITextLines[];
}

export enum POST_OBJECT_TYPES {
  TEXT = "TEXT",
  POLL = "POLL",
  SLIDER = "SLIDER",
  QUESTION = "QUESTION"
}

interface IPostBuilderState {
  cropModalIsOpen: boolean;
  croppedImageUrl: any;
  textBuilderOpen: boolean;
  textObjects: ITextObject[];
  pollBuilderOpen: boolean;
  pollObject: any;
  sliderBuilderOpen: boolean;
  sliderObject: any;
  questionBuilderOpen: boolean;
  questionObject: any;
  isPosting: boolean;
  postObjectContentToEdit: any;
}

class PostBuilder extends React.Component<{}, IPostBuilderState> {
  postWidth: number = 356;
  postHeight: number = 635;

  constructor(props: {}) {
    super(props);

    this.state = {
      cropModalIsOpen: false,
      croppedImageUrl: undefined,
      // croppedImageUrl: "blob:http://localhost:3000/b878513c-482a-485d-aa2e-f6398b6b5ce9",
      textBuilderOpen: false,
      textObjects: [],
      pollBuilderOpen: false,
      pollObject: null,
      sliderBuilderOpen: false,
      sliderObject: null,
      questionBuilderOpen: false,
      questionObject: null,
      isPosting: false,
      postObjectContentToEdit: null
    };

    this.toggleCropModal = this.toggleCropModal.bind(this);
    this.deleteTextObject = this.deleteTextObject.bind(this);
    this.renderPostContent = this.renderPostContent.bind(this);
    this.updatePostContentScreen = this.updatePostContentScreen.bind(this);
    this.setPostObjectPosition = this.setPostObjectPosition.bind(this);
    this.setPostObjectDimensions = this.setPostObjectDimensions.bind(this);
    this.deletePollObject = this.deletePollObject.bind(this);
    this.deleteSliderObject = this.deleteSliderObject.bind(this);
    this.deleteQuestionObject = this.deleteQuestionObject.bind(this);
    this.sendTo = this.sendTo.bind(this);
    this.setStateToDefault = this.setStateToDefault.bind(this);
    this.editTextObject = this.editTextObject.bind(this);
    this.editPollObject = this.editPollObject.bind(this);
    this.editQuestionObject = this.editQuestionObject.bind(this);
    this.editSliderObject = this.editSliderObject.bind(this);
  }

  toggleCropModal(isOpen: boolean) {
    this.setState({ cropModalIsOpen: isOpen });
  }

  deleteTextObject(index: number) {
    const array = [...this.state.textObjects];
    array.splice(index, 1);

    this.setState({ textObjects: array });
  }

  deletePollObject() {
    this.setState({ pollObject: null });
  }

  deleteSliderObject() {
    this.setState({ sliderObject: null });
  }

  deleteQuestionObject() {
    this.setState({ questionObject: null });
  }

  editTextObject(index: number) {
    const content = this.state.textObjects[index];
    content.index = index;

    this.setState({
      postObjectContentToEdit: content,
      textBuilderOpen: true
    });
  }

  editPollObject() {
    this.setState({
      postObjectContentToEdit: this.state.pollObject,
      pollBuilderOpen: true
    });
  }

  editQuestionObject() {
    this.setState({
      postObjectContentToEdit: this.state.questionObject,
      questionBuilderOpen: true
    });
  }

  editSliderObject() {
    this.setState({
      postObjectContentToEdit: this.state.sliderObject,
      sliderBuilderOpen: true
    });
  }

  updatePostContentScreen(action: any, objectToAdd?: any) {
    const updatedTextObjects = this.state.textObjects;
    let updatedPollObject = this.state.pollObject;
    let updatedSliderObject = this.state.sliderObject;
    let updatedQuestionObject = this.state.questionObject;

    if (objectToAdd !== undefined) {
      switch (objectToAdd.type) {
        case POST_OBJECT_TYPES.TEXT:
          if (objectToAdd.index === null) {
            updatedTextObjects.push(objectToAdd);
          } else {
            updatedTextObjects[objectToAdd.index] = objectToAdd;
          }
          break;
        case POST_OBJECT_TYPES.POLL:
          updatedPollObject = objectToAdd;
          break;
        case POST_OBJECT_TYPES.SLIDER:
          updatedSliderObject = objectToAdd;
          break;
        case POST_OBJECT_TYPES.QUESTION:
          updatedQuestionObject = objectToAdd;
          break;
        default:
          console.log("switch default ran for some reason");
      }
    }

    const updates = {
      textBuilderOpen: false,
      textObjects: updatedTextObjects,
      pollBuilderOpen: false,
      pollObject: updatedPollObject,
      sliderObject: updatedSliderObject,
      questionObject: updatedQuestionObject,
      postObjectContentToEdit: null,
      ...action
    };

    this.setState(updates);
  }

  setPostObjectPosition(type: any, index: number, x: number, y: number) {
    switch (type) {
      case POST_OBJECT_TYPES.TEXT:
        this.setState({
          textObjects: update(this.state.textObjects, {
            [index]: {
              x: { $set: x },
              y: { $set: y }
            }
          })
        });
        break;
      case POST_OBJECT_TYPES.POLL:
        this.setState({
          pollObject: update(this.state.pollObject, {
            x: { $set: x },
            y: { $set: y }
          })
        });
        break;
      case POST_OBJECT_TYPES.SLIDER:
        this.setState({
          sliderObject: update(this.state.sliderObject, {
            x: { $set: x },
            y: { $set: y }
          })
        });
        break;
      case POST_OBJECT_TYPES.QUESTION:
        this.setState({
          questionObject: update(this.state.questionObject, {
            x: { $set: x },
            y: { $set: y }
          })
        });
        break;
      default:
        console.log("switch default ran for some reason");
    }
  }

  setPostObjectDimensions(
    type: any,
    index: number,
    width: number,
    height: number
  ) {
    switch (type) {
      case POST_OBJECT_TYPES.TEXT:
        this.setState({
          textObjects: update(this.state.textObjects, {
            [index]: {
              width: { $set: width },
              height: { $set: height }
            }
          })
        });
        break;
      case POST_OBJECT_TYPES.POLL:
        this.setState({
          pollObject: update(this.state.pollObject, {
            width: { $set: width },
            height: { $set: height }
          })
        });
        break;
      case POST_OBJECT_TYPES.SLIDER:
        this.setState({
          sliderObject: update(this.state.sliderObject, {
            width: { $set: width },
            height: { $set: height }
          })
        });
        break;
      case POST_OBJECT_TYPES.QUESTION:
        this.setState({
          questionObject: update(this.state.questionObject, {
            width: { $set: width },
            height: { $set: height }
          })
        });
        break;
      default:
        console.log("switch default ran for some reason");
    }
  }

  renderPostContent() {
    if (this.state.textBuilderOpen) {
      return (
        <React.Fragment>
          <PostContent
            className="post-content"
            style={{ backgroundImage: `url(${this.state.croppedImageUrl})` }}
          >
            <TextBuilder
              content={this.state.postObjectContentToEdit}
              close={this.updatePostContentScreen}
            />
          </PostContent>
          <SendToFiller />
        </React.Fragment>
      );
    }

    if (this.state.pollBuilderOpen) {
      return (
        <React.Fragment>
          <PostContent
            className="post-content"
            style={{ backgroundImage: `url(${this.state.croppedImageUrl})` }}
          >
            <PollBuilder
              content={this.state.postObjectContentToEdit}
              close={this.updatePostContentScreen}
            />
          </PostContent>
          <SendToFiller />
        </React.Fragment>
      );
    }

    if (this.state.sliderBuilderOpen) {
      return (
        <React.Fragment>
          <PostContent
            className="post-content"
            style={{ backgroundImage: `url(${this.state.croppedImageUrl})` }}
          >
            <SliderBuilder
              content={this.state.postObjectContentToEdit}
              close={this.updatePostContentScreen}
            />
          </PostContent>
          <SendToFiller />
        </React.Fragment>
      );
    }

    if (this.state.questionBuilderOpen) {
      return (
        <React.Fragment>
          <PostContent
            className="post-content"
            style={{ backgroundImage: `url(${this.state.croppedImageUrl})` }}
          >
            <QuestionBuilder
              content={this.state.postObjectContentToEdit}
              close={this.updatePostContentScreen}
            />
          </PostContent>
          <SendToFiller />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <PostContent
          className="post-content"
          style={{ backgroundImage: `url(${this.state.croppedImageUrl})` }}
        >
          <PostLoading isLoading={this.state.isPosting} />

          <PostActionListContainer>
            <p className="cancel-icon" onClick={this.setStateToDefault}>
              X
            </p>
            <PostActionList data-html2canvas-ignore>
              <li
                className="stickers"
                onClick={() =>
                  this.updatePostContentScreen({ pollBuilderOpen: true })
                }
              >
                <img src={pollImage} alt="poll" />
              </li>
              <li
                className="stickers"
                onClick={() =>
                  this.updatePostContentScreen({ questionBuilderOpen: true })
                }
              >
                <img src={questionsImage} alt="questions" />
              </li>
              <li
                className="stickers"
                onClick={() =>
                  this.updatePostContentScreen({ sliderBuilderOpen: true })
                }
              >
                <img src={sliderImage} alt="slider" />
              </li>
              <li
                className="text"
                onClick={() =>
                  this.updatePostContentScreen({ textBuilderOpen: true })
                }
              >
                <img src={IGTextImage} alt="ig text" />
              </li>
            </PostActionList>
          </PostActionListContainer>
          {this.state.textObjects &&
            this.state.textObjects.map((textObject, index) => (
              <PostObject
                key={textObject.canvasURL}
                index={index}
                type={POST_OBJECT_TYPES.TEXT}
                imgs={[<img src={textObject.canvasURL} key={1} alt="post" />]}
                width={textObject.width}
                height={textObject.height}
                x={textObject.x}
                y={textObject.y}
                setPostObjectPosition={this.setPostObjectPosition}
                setPostObjectDimensions={this.setPostObjectDimensions}
                editObject={this.editTextObject}
                deleteNode={() => this.deleteTextObject(index)}
              />
            ))}
          {this.state.pollObject && (
            <PostObject
              className="poll-object"
              index={1}
              type={POST_OBJECT_TYPES.POLL}
              imgs={[
                <img
                  src={this.state.pollObject.questionURL}
                  className="question-object"
                  key={1}
                  alt="poll object"
                />,
                <img
                  src={this.state.pollObject.yesNoURL}
                  className="yes-no-object"
                  key={2}
                  alt="yes-no"
                />
              ]}
              width={this.state.pollObject.width}
              height={this.state.pollObject.height}
              x={this.state.pollObject.x}
              y={this.state.pollObject.y}
              setPostObjectPosition={this.setPostObjectPosition}
              setPostObjectDimensions={this.setPostObjectDimensions}
              editObject={this.editPollObject}
              deleteNode={() => this.deletePollObject()}
            />
          )}
          {this.state.sliderObject && (
            <PostObject
              className="slider-object"
              index={1}
              type={POST_OBJECT_TYPES.SLIDER}
              imgs={[
                <img
                  src={this.state.sliderObject.canvasURL}
                  className="slider-object"
                  key={1}
                  alt="slider"
                />
              ]}
              width={this.state.sliderObject.width}
              height={this.state.sliderObject.height}
              x={this.state.sliderObject.x}
              y={this.state.sliderObject.y}
              setPostObjectPosition={this.setPostObjectPosition}
              setPostObjectDimensions={this.setPostObjectDimensions}
              editObject={this.editSliderObject}
              deleteNode={this.deleteSliderObject}
            />
          )}
          {this.state.questionObject && (
            <PostObject
              index={1}
              type={POST_OBJECT_TYPES.QUESTION}
              imgs={[
                <img
                  src={this.state.questionObject.canvasURL}
                  key={1}
                  alt="question"
                />
              ]}
              width={this.state.questionObject.width}
              height={this.state.questionObject.height}
              x={this.state.questionObject.x}
              y={this.state.questionObject.y}
              setPostObjectPosition={this.setPostObjectPosition}
              setPostObjectDimensions={this.setPostObjectDimensions}
              editObject={this.editQuestionObject}
              deleteNode={this.deleteQuestionObject}
            />
          )}
        </PostContent>

        <SendTo data-html2canvas-ignore>
          <li onClick={this.sendTo} className="send-to">
            Post to story <ChevronRight />
          </li>
        </SendTo>
      </React.Fragment>
    );
  }

  async sendTo() {
    const { pollObject, sliderObject } = this.state;

    this.setState({ isPosting: true });

    if (pollObject) {
      this.postPoll();
    } else if (sliderObject) {
      this.postSlider();
    } else {
      this.postStory();
    }
  }

  postStory() {
    const pollElem: HTMLElement = document.querySelector(
      ".post-content"
    ) as HTMLElement;

    html2canvas(pollElem!, {
      backgroundColor: null,
      onclone: function(doc) {
        const resizeableHandles = doc.querySelectorAll(
          ".react-resizable-handle"
        );
        resizeableHandles.forEach((elem) => {
          (elem as HTMLElement).style.display = "none";
        });
      }
    }).then(async (canvas) => {
      canvas.toBlob(async (blob) => {
        if (!blob) {
          // TODO: Handle no image
        } else {
          const localStorageData = localStorage.getItem(AUTH.LOCALSTORAGE);
          if (localStorageData) {
            const authData = JSON.parse(localStorageData);

            let caption = "";
            this.state.textObjects.forEach((textObject) => {
              textObject.textLines.forEach((textLine) => {
                caption += ` ${textLine.fontSize}`;
              });
            });

            try {
              const data = new FormData();
              data.append("ig_account", authData.iGAccounts[0].id);
              data.append("caption", caption);
              data.append("photo", blob!);

              await axios.post<any>(
                `${process.env.REACT_APP_API_HOST}/api/v1/ig/story`,
                data
              );

              this.setStateToDefault();
              toast("Story successfully posted!");
            } catch (error) {
              toast.error((error as any).errorMessage);
              this.setState({ isPosting: false });
            }
          }
        }
      });
    });
  }

  postPoll() {
    const questionElem = document.querySelector(
      ".question-object"
    ) as HTMLImageElement;
    const yesNoElem = document.querySelector(
      ".yes-no-object"
    ) as HTMLImageElement;

    // The drag element is moved around using the transform property. This isn't accounted for when
    // getting the element's offset to pass the element's position to the api. So we get the transform
    // amount, add that to the offset and use that to calculate the percentage based position to pass
    // to the api.
    // Also, the image displayed is only for the user, the real width and height of the yes/no container
    // is passed back from the pollObjectBuilder.
    const containerTransform = yesNoElem!.parentElement!.parentElement!
      .parentElement!.style.transform;
    const containerMatrix = new WebKitCSSMatrix(containerTransform as string);
    const containerTranslateLeft = containerMatrix.m41;
    const containerTranslateTop = containerMatrix.m42;

    const containerOffsetLeft = yesNoElem!.parentElement!.parentElement!
      .parentElement!.offsetLeft;
    const containerOffsetTop = yesNoElem!.parentElement!.parentElement!
      .parentElement!.offsetTop;
    const realOffsetLeft = containerOffsetLeft + containerTranslateLeft;
    const realOffsetTop =
      containerOffsetTop + containerTranslateTop + 20 + questionElem.height;

    const containerWidth = yesNoElem.width;
    const containerHeight = yesNoElem.height;

    const x = (
      (containerWidth / 2 + realOffsetLeft) /
      this.postWidth
    ).toString();
    const y = (
      (containerHeight / 2 + realOffsetTop) /
      this.postHeight
    ).toString();

    const yesNoWidth = (containerWidth / this.postWidth).toString();
    const yesNoHeight = (containerHeight / this.postHeight).toString();

    const postElem: HTMLElement = document.querySelector(
      ".post-content"
    ) as HTMLElement;

    html2canvas(postElem!, {
      backgroundColor: null,
      onclone: (doc) => {
        const resizeableHandles = doc.querySelectorAll(
          ".react-resizable-handle"
        );
        resizeableHandles.forEach((elem) => {
          (elem as HTMLElement).style.display = "none";
        });

        const tempElem = doc.querySelector(
          ".yes-no-object"
        ) as HTMLImageElement;
        tempElem.remove();
      }
    }).then(async (canvas) => {
      canvas.toBlob(async (blob) => {
        if (!blob) {
          // TODO: Handle no image
        } else {
          const localStorageData = localStorage.getItem(AUTH.LOCALSTORAGE);

          if (localStorageData) {
            const authData = JSON.parse(localStorageData);
            const answers = [
              {
                text: this.state.pollObject.answerOne,
                font_size: "27.5"
              },
              {
                text: this.state.pollObject.answerTwo,
                font_size: "27.5"
              }
            ];

            const data = new FormData();
            data.append("ig_account", authData.iGAccounts[0].id);
            data.append("question", this.state.pollObject.question);
            data.append("answers", JSON.stringify(answers));
            data.append("width", yesNoWidth);
            data.append("height", yesNoHeight);
            data.append("x", x);
            data.append("y", y);
            data.append("photo", blob!);

            try {
              await axios.post<any>(
                `${process.env.REACT_APP_API_HOST}/api/v1/ig/poll`,
                data
              );

              this.setStateToDefault();
              toast("Story successfully posted!");
            } catch (error) {
              toast.error((error as any).errorMessage);
              this.setState({ isPosting: false });
            }
          }
        }
      });
    });
  }

  postSlider() {
    const sliderElem = document.querySelector(
      ".slider-object"
    ) as HTMLImageElement;

    // The drag element is moved around using the transform property. This isn't accounted for when
    // getting the element's offset to pass the element's position to the api. So we get the transform
    // amount, add that to the offset and use that to calculate the percentage based position to pass
    // to the api.
    // Also, the image displayed is only for the user, the real width and height of the yes/no container
    // is passed back from the pollObjectBuilder.
    const containerTransform = sliderElem!.parentElement!.parentElement!
      .parentElement!.style.transform;
    const containerMatrix = new WebKitCSSMatrix(containerTransform as string);
    const containerTranslateLeft = containerMatrix.m41;
    const containerTranslateTop = containerMatrix.m42;

    const containerOffsetLeft = sliderElem!.parentElement!.parentElement!
      .parentElement!.offsetLeft;
    const containerOffsetTop = sliderElem!.parentElement!.parentElement!
      .parentElement!.offsetTop;

    const realOffsetLeft = containerOffsetLeft + containerTranslateLeft;
    const realOffsetTop = containerOffsetTop + containerTranslateTop;

    const x = (
      (this.state.sliderObject.width / 2 + realOffsetLeft) /
      this.postWidth
    ).toFixed(2);
    const y = (
      (this.state.sliderObject.height / 2 + realOffsetTop) /
      this.postHeight
    ).toFixed(2);

    const width = (this.state.sliderObject.width / this.postWidth).toString();
    const height = (
      this.state.sliderObject.height / this.postHeight
    ).toString();

    const postElem: HTMLElement = document.querySelector(
      ".post-content"
    ) as HTMLElement;

    html2canvas(postElem!, {
      backgroundColor: null,
      onclone: (doc) => {
        const resizeableHandles = doc.querySelectorAll(
          ".react-resizable-handle"
        );
        resizeableHandles.forEach((elem) => {
          (elem as HTMLElement).style.display = "none";
        });

        const slideElem = doc.querySelector(
          ".slider-object"
        ) as HTMLImageElement;
        slideElem.remove();
      }
    }).then(async (canvas) => {
      canvas.toBlob(async (blob) => {
        if (!blob) {
          // Handle no image
        } else {
          const localStorageData = localStorage.getItem(AUTH.LOCALSTORAGE);

          if (localStorageData) {
            const authData = JSON.parse(localStorageData);

            const data = new FormData();
            data.append("ig_account", authData.iGAccounts[0].id);
            data.append("question", this.state.sliderObject.question);
            data.append("background_color", "#ffffff");
            data.append("text_color", "#000000");
            data.append("emoji", "😍");
            data.append("x", x);
            data.append("y", y);
            data.append("width", width);
            data.append("height", height);
            data.append("rotation", "0.0");
            data.append("photo", blob!);

            try {
              await axios.post<any>(
                `${process.env.REACT_APP_API_HOST}/api/v1/ig/storyslider`,
                data
              );
              this.setStateToDefault();
              toast("Story successfully posted!");
            } catch (error) {
              toast.error((error as any).errorMessage);
              this.setState({ isPosting: false });
            }
          }
        }
      });
    });
  }

  setStateToDefault() {
    this.setState({
      cropModalIsOpen: false,
      croppedImageUrl: undefined,
      textBuilderOpen: false,
      textObjects: [],
      pollBuilderOpen: false,
      pollObject: null,
      sliderBuilderOpen: false,
      sliderObject: null,
      questionBuilderOpen: false,
      questionObject: null,
      isPosting: false
    });
  }

  render() {
    return (
      <AppContext.Consumer>
        {(context: IAppContext) => (
          <Container>
            <Helmet>
              <title>Post Builder</title>
            </Helmet>
            <ToolBar title="Post Builder" />

            {context.currentIGAccount > -1 ? (
              <div>
                <LoggedInAs>
                  Posting as:{" "}
                  {context.iGAccounts[context.currentIGAccount].username}
                </LoggedInAs>
                <PostContainer>
                  <PostScreen>
                    {this.state.croppedImageUrl === undefined ||
                    this.state.cropModalIsOpen ? (
                      <PostCropperModal
                        setCroppedImageUrl={(croppedImageUrl: string) =>
                          this.setState({ croppedImageUrl })
                        }
                        isOpen={this.state.cropModalIsOpen}
                        toggleModal={this.toggleCropModal}
                      />
                    ) : (
                      this.renderPostContent()
                    )}
                  </PostScreen>
                </PostContainer>
              </div>
            ) : (
              <NoAccountContainer>
                <p>No Account selected</p>
              </NoAccountContainer>
            )}
          </Container>
        )}
      </AppContext.Consumer>
    );
  }
}

export default PostBuilder;
