import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;

  .logo {
    width: 80px;
  }

  h2 {
    margin: 0;
    font-size: 1.1rem;

    span {
      color: ${(props) => props.theme.pallet.funGreen};
    }
  }
`;
