import * as React from "react";
import { PageContainer, TableContainer } from "./styled";
import { PlusCircle } from "react-feather";
import axios, { AxiosResponse } from "axios";
import { INotification, INotificationGroup } from "../../types/magicMirror";
import CreateNotification from "./CreateNotification";
import NotificationGroupTray from "./NotificationGroupTray";
import moment from "moment";

interface NotificationSchedulerProps {}

interface NotificationSchedulerState {
  notifications: INotification[];
  modelOpen: boolean;
  selectedNotificationGroup?: INotificationGroup;
}

export default class NotificationScheduler extends React.Component<
  NotificationSchedulerProps,
  NotificationSchedulerState
> {
  constructor(props: NotificationSchedulerProps) {
    super(props);

    this.state = {
      notifications: [],
      modelOpen: false
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    this.downloadData();
  }

  async downloadData() {
    const notificationsResponse: AxiosResponse<INotification> = await axios.get<
      any
    >(`${process.env.REACT_APP_API_HOST}api/v1/allNotifications`);
    let notifications: INotification[] = [];

    if (
      notificationsResponse &&
      notificationsResponse.data &&
      notificationsResponse.data
    ) {
      if (notificationsResponse.status === 200) {
        const data: any = notificationsResponse.data;

        if (data.response) {
          notifications = data.response;
        }
      }
    }

    this.setState({
      notifications: notifications
    });
  }

  toggleModal(refresh: boolean) {
    if (refresh) {
      this.downloadData();
    }

    this.setState({
      modelOpen: !this.state.modelOpen
    });
  }

  render() {
    return (
      <PageContainer>
        <div>
          <h1>Scheduled Notifications</h1>

          <TableContainer>
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Trigger Date</th>
                  <th>
                    <PlusCircle
                      onClick={() => {
                        this.toggleModal(false);
                      }}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.notifications.map((notification) => {
                  return (
                    <tr key={notification.id}>
                      <td>{notification.name}</td>
                      <td>
                        {notification.schedule
                          ? moment
                              .utc(notification.schedule.triggerDate)
                              .local()
                              .format("MM/DD/YY - hh:mm:ss a")
                          : ""}
                      </td>
                      <td />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </TableContainer>
        </div>

        <CreateNotification
          modelOpen={this.state.modelOpen}
          toggle={this.toggleModal}
          selectedGroup={(group) => {
            this.setState({ selectedNotificationGroup: group });
          }}
        />

        {this.state.selectedNotificationGroup && (
          <NotificationGroupTray
            group={this.state.selectedNotificationGroup}
            deselectGroup={() => {
              this.setState({
                selectedNotificationGroup: undefined
              });
            }}
          />
        )}
      </PageContainer>
    );
  }
}
