import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useResponses, useBuckets, useGrid, useSnapshotHistory } from "hooks";
import MainGridHeader from "./MainGridHeader";
import BucketGridHeader from "./BucketGridHeader";
import { Bucket } from "types/facelift";

function GridHeader() {
  const resetResponses = useResponses((s) => s.resetResponses);
  const selectedBucket = useBuckets((s) => s.selectedBucket);
  const updateSearching = useGrid((s) => s.updateSearching);
  const previousSnapshot = useSnapshotHistory((s) => s.previousSnapshot);

  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);
  const updateSearchTerm = useGrid((s) => s.updateSearchTerm);
  const updateExpandedIndex = useGrid((s) => s.updateExpandedIndex);
  const updateHoveredQuestion = useGrid((s) => s.updateHoveredQuestion);
  const updateSelectedUser = useGrid((s) => s.updateSelectedUser);

  function handleBack() {
    // updateSearching(true);
    // resetResponses();
    // previousSnapshot();


    updateSearching(true);
    resetResponses();
    updateSearchTerm("");
    updateExpandedIndex(null);
    updateSelectedBucket({} as Bucket);
    updateHoveredQuestion(null);
    updateSelectedUser(null);
  }

  const isBucketView = selectedBucket.id !== undefined;

  return (
    <Wrapper>
      {isBucketView ? (
        <BucketGridHeader handleBack={handleBack} />
      ) : (
        <MainGridHeader handleBack={handleBack} />
      )}
    </Wrapper>
  );
}

export default GridHeader;

const Wrapper = styled(motion.div)`
  grid-area: header;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  margin: 1rem auto;
  position: relative;
  z-index: 4;
`;
