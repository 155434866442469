import * as React from "react";

import { Container } from "./styled";

interface ICompanyInfoProps {
  logo: any;
  name: string;
  brief: string;
}

export default ({ logo, name, brief }: ICompanyInfoProps) => (
  <Container>
    <img src={logo} alt="Fake Logo" className="logo" />
    <h2>
      <span>Company Name -</span> Brief Name
    </h2>
  </Container>
);
