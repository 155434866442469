import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import tinycolor from "tinycolor2";
import Tooltip from "components/Facelift/Tooltip";
import useApp from "context/AppContext";
import {
  capitalizeFirstLetter,
  getSentimentColorByValue,
  getEmotionsFromResponse,
  getDominantSentiment,
  getEmotionalPillValue,
  getEmotionalValue
} from "utils/facelift";
import { Response, SentimentType, EmotionsType } from "types/facelift";

export const sentimentIcons: { [key in SentimentType]: JSX.Element } = {
  positive: (
    <svg
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"></path>
    </svg>
  ),
  neutral: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 16 16"
    >
      <path d="M16 8A8 8 0 110 8a8 8 0 0116 0zM4.5 7.5a.5.5 0 000 1h7a.5.5 0 000-1h-7z"></path>
    </svg>
  ),
  negative: (
    <svg
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"></path>
    </svg>
  )
};

export const emojiIcons: { [key in EmotionsType]: JSX.Element } = {
  joy: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
      <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm4 72.6c-20.8 25-51.5 39.4-84 39.4s-63.2-14.3-84-39.4c-8.5-10.2-23.7-11.5-33.8-3.1-10.2 8.5-11.5 23.6-3.1 33.8 30 36 74.1 56.6 120.9 56.6s90.9-20.6 120.9-56.6c8.5-10.2 7.1-25.3-3.1-33.8-10.1-8.4-25.3-7.1-33.8 3.1z"></path>
    </svg>
  ),
  sadness: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
      <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm144 386.4V280c0-13.2-10.8-24-24-24s-24 10.8-24 24v151.4C315.5 447 282.8 456 248 456s-67.5-9-96-24.6V280c0-13.2-10.8-24-24-24s-24 10.8-24 24v114.4c-34.6-36-56-84.7-56-138.4 0-110.3 89.7-200 200-200s200 89.7 200 200c0 53.7-21.4 102.5-56 138.4zM205.8 234.5c4.4-2.4 6.9-7.4 6.1-12.4-4-25.2-34.2-42.1-59.8-42.1s-55.9 16.9-59.8 42.1c-.8 5 1.7 10 6.1 12.4 4.4 2.4 9.9 1.8 13.7-1.6l9.5-8.5c14.8-13.2 46.2-13.2 61 0l9.5 8.5c2.5 2.3 7.9 4.8 13.7 1.6zM344 180c-25.7 0-55.9 16.9-59.8 42.1-.8 5 1.7 10 6.1 12.4 4.5 2.4 9.9 1.8 13.7-1.6l9.5-8.5c14.8-13.2 46.2-13.2 61 0l9.5 8.5c2.5 2.2 8 4.7 13.7 1.6 4.4-2.4 6.9-7.4 6.1-12.4-3.9-25.2-34.1-42.1-59.8-42.1zm-96 92c-30.9 0-56 28.7-56 64s25.1 64 56 64 56-28.7 56-64-25.1-64-56-64z"></path>
    </svg>
  ),
  anger: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
      <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm0-144c-33.6 0-65.2 14.8-86.8 40.6-8.5 10.2-7.1 25.3 3.1 33.8s25.3 7.2 33.8-3c24.8-29.7 75-29.7 99.8 0 8.1 9.7 23.2 11.9 33.8 3 10.2-8.5 11.5-23.6 3.1-33.8-21.6-25.8-53.2-40.6-86.8-40.6zm-48-72c10.3 0 19.9-6.7 23-17.1 3.8-12.7-3.4-26.1-16.1-29.9l-80-24c-12.8-3.9-26.1 3.4-29.9 16.1-3.8 12.7 3.4 26.1 16.1 29.9l28.2 8.5c-3.1 4.9-5.3 10.4-5.3 16.6 0 17.7 14.3 32 32 32s32-14.4 32-32.1zm199-54.9c-3.8-12.7-17.1-19.9-29.9-16.1l-80 24c-12.7 3.8-19.9 17.2-16.1 29.9 3.1 10.4 12.7 17.1 23 17.1 0 17.7 14.3 32 32 32s32-14.3 32-32c0-6.2-2.2-11.7-5.3-16.6l28.2-8.5c12.7-3.7 19.9-17.1 16.1-29.8z"></path>
    </svg>
  ),
  fear: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
      <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm96-312c-44.2 0-80 35.8-80 80s35.8 80 80 80 80-35.8 80-80-35.8-80-80-80zm0 128c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-72c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm-112 24c0-44.2-35.8-80-80-80s-80 35.8-80 80 35.8 80 80 80 80-35.8 80-80zm-80 48c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-72c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm160 144H184c-13.2 0-24 10.8-24 24s10.8 24 24 24h128c13.2 0 24-10.8 24-24s-10.8-24-24-24z"></path>
    </svg>
  ),
  surprise: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
      <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm0-176c-35.3 0-64 28.7-64 64s28.7 64 64 64 64-28.7 64-64-28.7-64-64-64zm-48-72c0-17.7-14.3-32-32-32s-32 14.3-32 32 14.3 32 32 32 32-14.3 32-32zm128-32c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32z"></path>
    </svg>
  ),
  disgust: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
      <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-110.3 0-200-89.7-200-200S137.7 56 248 56s200 89.7 200 200-89.7 200-200 200zm-80-216c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm16 16H152c-26.5 0-48 21.5-48 48v32c0 26.5 21.5 48 48 48h192c26.5 0 48-21.5 48-48v-32c0-26.5-21.5-48-48-48zm-168 96h-24c-8.8 0-16-7.2-16-16v-8h40v24zm0-40h-40v-8c0-8.8 7.2-16 16-16h24v24zm64 40h-48v-24h48v24zm0-40h-48v-24h48v24zm64 40h-48v-24h48v24zm0-40h-48v-24h48v24zm56 24c0 8.8-7.2 16-16 16h-24v-24h40v8zm0-24h-40v-24h24c8.8 0 16 7.2 16 16v8z"></path>
    </svg>
  )
};

interface DominantSentimentIconProps {
  response: Response;
}

export function DominantSentimentIcon({
  response
}: DominantSentimentIconProps) {
  const { sentimentFeatureEnabled } = useApp();

  if (sentimentFeatureEnabled === false) return null;

  const dominantSentiment = getDominantSentiment([
    { percentage: Math.round(+response.neg), name: "negative" },
    { percentage: Math.round(+response.neu), name: "neutral" },
    { percentage: Math.round(+response.pos), name: "positive" }
  ]);

  return (
    <Tooltip message={`${capitalizeFirstLetter(dominantSentiment)} sentiment`}>
      <SentimentIconContainer
        style={{
          fill: getSentimentColorByValue(dominantSentiment),
          color: getSentimentColorByValue(dominantSentiment)
        }}
      >
        {sentimentIcons[dominantSentiment as SentimentType]}
      </SentimentIconContainer>
    </Tooltip>
  );
}

interface EmotionalPillsProps {
  response: Response;
}

export function EmotionalPills({ response }: EmotionalPillsProps) {
  const { emotionFeatureEnabled, emotions } = useApp();

  if (emotionFeatureEnabled === false) return null;

  const responseEmotions = getEmotionsFromResponse(response, emotions);

  return (
    <EmotionalPillContainer type="row" gap={0.25}>
      {responseEmotions.map((emotion) => (
        <Tooltip key={emotion} message={`${capitalizeFirstLetter(emotion)}`}>
          <EmotionalPill
            style={{
              background: getEmotionalPillValue(emotion),
              fill: tinycolor(getEmotionalPillValue(emotion))
                .darken(60)
                .toString()
            }}
            onClick={() => {
              console.log({
                anger: getEmotionalValue(response.anger),
                fear: getEmotionalValue(response.fear),
                disgust: getEmotionalValue(response.disgust),
                joy: getEmotionalValue(response.joy),
                sadness: getEmotionalValue(response.sadness),
                surprise: getEmotionalValue(response.surprise)
              });
            }}
          >
            {/* {isExpanded ? emotion : emotion[0]} */}
            {emojiIcons[emotion]}
          </EmotionalPill>
        </Tooltip>
      ))}
    </EmotionalPillContainer>
  );
}

export const Stack = styled(motion.div)<{
  type: "row" | "column";
  gap: number;
}>`
  display: grid;
  grid-auto-flow: ${(props) => (props.type === "column" ? "row" : "column")};
  grid-gap: ${(props) => `${props.gap}rem`};
  justify-content: flex-start;
  align-items: center;
`;

const EmotionalPillContainer = styled(Stack)``;

const EmotionalPill = styled.span`
  /* background: #635ec0; */
  color: #132142;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 13px;
  /* font-weight: bold; */
  /* padding: 0.25rem 0.75rem; */
  padding: 2px 4px;
  border-radius: 9999px;
  appearance: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;

  svg {
    fill: inherit;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

export const ToastText = styled.div`
  font-size: 14px;
  color: white;
  letter-spacing: 0.75px;
`;

export const QuestionNumber = ({
  size,
  children
}: {
  size: number;
  children: React.ReactNode;
}) => (
  <QuestionNumberContainer>
    <Number size={size}>{children}</Number>
  </QuestionNumberContainer>
);

const QuestionNumberContainer = styled.div`
  display: inline-grid;
  place-items: center;
`;

const Number = styled.div<{ size: number }>`
  width: ${(p) => p.size * 2}px;
  height: ${(p) => p.size * 2}px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${(p) => p.size}px;
  font-family: "Century Gothic", "Open Sans", sans-serif;
  z-index: 2;
  border-radius: 50%;
  position: relative;
  background: white;
  color: #635ec0;
  font-weight: 600;
`;

const SentimentIconContainer = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: inherit;
  color: inherit;
  background: white;
  border-radius: 9999px;
  appearance: none !important;

  svg {
    width: 100%;
    height: 100%;
    display: block;
    fill: inherit;
    color: inherit;
    stroke-width: 4px;
  }
`;
