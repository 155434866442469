import * as React from "react";
import html2canvas from "html2canvas";
import { toast } from "react-toastify";

import {
  ScreenWrapper,
  QuestionWrapper,
  Container,
  Avatar,
  TextArea,
  AnswersHere
} from "./styled";
import {
  IQuestionObject,
  POST_OBJECT_TYPES
} from "../../../containers/PostBuilder";
import AvatarImage from "../../../assets/images/download.svg";

interface IQuestionBuilderProps {
  content: any;
  close: (action: any, question?: IQuestionObject) => void;
}

interface IQuestionBuilderState {
  textAreaValue: string;
}

class QuestionBuilderProps extends React.Component<
  IQuestionBuilderProps,
  IQuestionBuilderState
> {
  private textArea: React.RefObject<HTMLTextAreaElement>;

  constructor(props: IQuestionBuilderProps) {
    super(props);

    if (props.content === null) {
      this.state = {
        textAreaValue: ""
      };
    } else {
      this.state = {
        textAreaValue: props.content.question
      };
    }

    this.textArea = React.createRef();

    this.exportAndExit = this.exportAndExit.bind(this);
  }

  componentDidMount() {
    this.textArea.current!.focus();

    if (this.props.content !== null) {
      this.updateTextAreaHeight(this.props.content.question);
    }
  }

  updateTextAreaHeight(textAreaValue: string) {
    if (textAreaValue.length <= 72) {
      this.setState({ textAreaValue }, () => {
        this.textArea.current!.style.height = 30 + "px";
        this.textArea.current!.style.height =
          this.textArea.current!.scrollHeight + "px";
      });
    } else {
      toast.warn("Question can only be 72 charcters long");
    }
  }

  exportAndExit() {
    const questionElem: HTMLElement = document.querySelector(
      ".question-container"
    ) as HTMLElement;

    html2canvas(questionElem!, { backgroundColor: null }).then((canvas) => {
      canvas.toBlob((blob) => {
        if (!blob || this.state.textAreaValue === "") {
          this.props.close({ questionBuilderOpen: false });
        } else {
          const url = URL.createObjectURL(blob);

          const question = {
            type: POST_OBJECT_TYPES.QUESTION,
            canvasURL: url,
            width: this.props.content
              ? this.props.content.width
              : canvas.width / 2,
            height: this.props.content
              ? this.props.content.height
              : canvas.height / 2,
            x: this.props.content
              ? this.props.content.x
              : 356 / 2 - canvas.width / 2 / 2,
            y: this.props.content ? this.props.content.y : 0,
            question: this.state.textAreaValue
          };

          this.props.close({ questionBuilderOpen: false }, question);
        }
      });
    });
  }

  render() {
    return (
      <ScreenWrapper>
        <p className="close" onClick={this.exportAndExit}>
          Done
        </p>
        <QuestionWrapper className="question-container">
          <Container>
            <Avatar>
              <img src={AvatarImage} alt="User Avatar" />
            </Avatar>
            <TextArea
              ref={this.textArea}
              onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                this.updateTextAreaHeight(e.currentTarget.value)
              }
              value={this.state.textAreaValue}
            />
            <AnswersHere>
              <p>Viewers respond here</p>
            </AnswersHere>
          </Container>
        </QuestionWrapper>
      </ScreenWrapper>
    );
  }
}

export default QuestionBuilderProps;
