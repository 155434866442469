import React from "react";
import styled from "styled-components";
import { useQuestions, useGrid, useOptions } from "hooks";
import { Stack } from "components/Facelift/shared";
import HeaderControls from "./HeaderControls";
import BackControls from "./BackControls";
import StudyControls from "./StudyControls";
import CurrentQuestionInformation from "./CurrentQuestionInformation";
import CurrentUserInformation from "./CurrentUserInformation";

function MainGridHeader({ handleBack }: { handleBack: () => void }) {
  const currentQuestion = useQuestions((s) => s.currentQuestion);
  const selectedUser = useGrid((s) => s.selectedUser);
  const searchTerm = useGrid((s) => s.searchTerm);
  const { areFiltersApplied } = useOptions();

  const shouldShowStudyControls =
    areFiltersApplied === false &&
    selectedUser === null &&
    currentQuestion === null &&
    searchTerm.length === 0;

  return (
    <TextBlock>
      <Stack
        type="column"
        gap={1}
        style={{ width: "100%", justifyContent: "stretch" }}
      >
        <Stack
          type="row"
          gap={1}
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "stretch"
          }}
        >
          {shouldShowStudyControls ? (
            <StudyControls />
          ) : (
            <BackControls handleBack={handleBack} />
          )}

          <HeaderControls />
        </Stack>

        {selectedUser !== null && <CurrentUserInformation />}

        {currentQuestion !== null && <CurrentQuestionInformation />}

        {/* <AdditionalGridInformation /> */}
      </Stack>
    </TextBlock>
  );
}

export default MainGridHeader;

const TextBlock = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  padding: 2rem;
  background: #8987cd;
  border-radius: 15px;
  color: white;
  box-shadow: var(--shadow);
`;
