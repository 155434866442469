import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  useBuckets,
  useGrid,
  useResponses,
  useOptions,
  useQuestions,
  useSnapshotHistory
} from "hooks";
import { Bucket } from "types/facelift";

function Logo() {
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);
  const resetResponses = useResponses((s) => s.resetResponses);
  const updateCurrentQuestion = useQuestions((s) => s.updateCurrentQuestion);
  const updateSearching = useGrid((s) => s.updateSearching);
  const updateSearchTerm = useGrid((s) => s.updateSearchTerm);
  const updateExpandedIndex = useGrid((s) => s.updateExpandedIndex);
  const updateHoveredQuestion = useGrid((s) => s.updateHoveredQuestion);
  const updateSelectedUser = useGrid((s) => s.updateSelectedUser);
  const displayStyle = useGrid((s) => s.displayStyle);
  const updateDisplayStyle = useGrid((s) => s.updateDisplayStyle);
  const resetSnapshots = useSnapshotHistory((s) => s.resetSnapshots);
  const { reset } = useOptions();

  function handleClick() {
    updateSearching(true);
    resetResponses();
    updateSearchTerm("");
    updateExpandedIndex(null);
    updateCurrentQuestion(null);
    updateSelectedBucket({} as Bucket);
    updateHoveredQuestion(null);
    updateSelectedUser(null);
    if (displayStyle === "graph") updateDisplayStyle("compact");
    resetSnapshots();
    reset();
  }

  return (
    <Container onClick={handleClick}>
      <svg viewBox="0 0 600 600" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M598.325 257.325C577.433 110.608 449.624 6.45017e-06 300.928 3.03196e-06C262.686 2.15285e-06 225.666 6.99432 191.1 20.5626C187.259 22.0704 188.921 28.3366 193.048 28.3366C203.151 28.3366 213.218 29.4117 222.975 31.5256C276.819 43.1973 304.812 93.4195 306.61 96.7452L452.935 349.757C468.493 376.66 498.597 394.989 527.221 382.885C539.667 377.622 552.406 369.751 563.108 357.988C601.834 315.429 598.535 260.259 598.325 257.325Z"
          fill="url(#paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M220.472 43.9059C211.593 41.9879 202.421 41.0176 193.212 41.0176C150.163 41.0176 117.549 62.2059 115.205 63.7731C41.9825 121.083 -1.7806e-06 207.03 -2.77354e-06 299.606C-3.66314e-06 382.548 33.6673 459.191 90.7362 514.434C97.4155 520.899 109.791 510.043 106.944 501.194C90.0417 448.672 119.917 399.247 121.767 396.259L268.297 143.765C284.054 116.613 284.774 80.9998 259.616 62.2217C248.855 54.1891 235.77 47.2108 220.472 43.9059Z"
          fill="url(#paint1_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M572.128 367.326C537.724 405.053 485.244 406.963 475.072 406.968C474.237 406.968 473.735 406.949 473.607 406.949L181.072 406.949C149.878 406.949 118.868 423.967 115.068 454.928C113.434 468.239 113.882 483.042 118.661 497.914C136.557 553.593 188.005 578.347 188.525 578.592L189.096 578.861C224.464 592.888 261.846 600 300.204 600C440.262 600 558.245 504.104 591.438 374.772C593.732 365.833 578.348 360.508 572.128 367.326Z"
          fill="url(#paint2_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="169.037"
            y1="335.914"
            x2="598.479"
            y2="335.914"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7ECEFD" />
            <stop offset="1" stopColor="#9EBDFF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="90.6414"
            y1="91.6984"
            x2="269.647"
            y2="506.744"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC7D49" />
            <stop offset="1" stopColor="#FF788D" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="524.842"
            y1="360.627"
            x2="163.057"
            y2="581.301"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D1A5F6" />
            <stop offset="1" stopColor="#FC89CB" />
          </linearGradient>
        </defs>
      </svg>
    </Container>
  );
}

export default Logo;

const Container = styled(motion.div)`
  cursor: pointer;
  align-self: center;
  padding: 0.5rem 1rem;
  width: 75px;
  height: 75px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
