import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;

  .close {
    font-size: 1rem;
    cursor: pointer;
    align-self: flex-end;
    margin: 10px;

    &:hover {
      color: ${(props) => props.theme.pallet.primary};
    }
  }
`;

export const PollContainer = styled.div`
  width: 80%;
  align-self: center;
  margin-top: 30%;
`;

export const TextArea = styled.textarea`
  resize: none;
  overflow: hidden;
  height: 30px;
  width: 100%;
  background-color: transparent;
  font-weight: 600;
  color: white;
  border: none;
  text-align: center;
  font-size: 22px;

  &:focus {
    outline: none;
  }
`;

export const YesNoContainer = styled.div`
  background-color: white;
  display: flex;
  color: black;
  border-radius: 6px;

  div {
    width: 50%;
    text-align: center;
    padding: 20px 0;

    &:last-of-type {
      border-left: 1px solid lightgray;
    }

    p {
      font-size: 1.7rem;
    }

    textarea {
      width: 100%;
      height: 27px;
      text-align: center;
      resize: none;
      font-size: 20px;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
`;
