import * as React from "react";
import { Route, RouteProps, RouteComponentProps } from "react-router-dom";

import { Main } from "./styled";

interface AuthRoutesProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

type RenderComponent = (props: RouteComponentProps<any>) => React.ReactNode;

class AuthRoutes extends Route<AuthRoutesProps> {
  render() {
    const { component: Component, ...rest }: AuthRoutesProps = this.props;

    const renderComponent: RenderComponent = (props) => {
      return (
        <Main>
          <Component {...props} />
        </Main>
      );
    };

    return <Route {...rest} render={renderComponent} />;
  }
}

export default AuthRoutes;
