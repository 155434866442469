import * as React from "react";
import { Shuffle } from "react-feather";
import html2canvas from "html2canvas";

import {
  ITextObject,
  POST_OBJECT_TYPES
} from "../../../containers/PostBuilder";
import {
  Container,
  Actions,
  TextInputContainer,
  ColorList,
  ColorListItem
} from "./styled";

interface ITextBuilderProps {
  content: any;
  close: (action: any, textObject?: ITextObject) => void;
}

export interface ITextLines {
  fontSize: number;
  text: string;
}

interface ITextBuilderState {
  value: string;
  textLines: ITextLines[];
  color: string;
  backgroundEnabled: boolean;
}

class TextBuilder extends React.Component<
  ITextBuilderProps,
  ITextBuilderState
> {
  private textInput: React.RefObject<HTMLTextAreaElement>;
  private textDisplay: React.RefObject<HTMLDivElement>;
  private textInputContainer: React.RefObject<HTMLDivElement>;
  private maxFontSize: number = 200;
  private minFontSize: number = 40;
  private textColorList: string[] = [
    "#fff",
    "#000",
    "#5395e9",
    "#83be5f",
    "#f5cc6e",
    "#ee9248",
    "#db565b",
    "#bf2a68",
    "#9520b3"
  ];

  constructor(props: ITextBuilderProps) {
    super(props);

    if (props.content === null) {
      this.state = {
        value: "",
        textLines: [],
        color: "#fff",
        backgroundEnabled: false
      };
    } else {
      this.state = {
        value: props.content.value,
        textLines: props.content.textLines,
        color: props.content.color,
        backgroundEnabled: props.content.backgroundEnabled
      };
    }

    this.textInput = React.createRef();
    this.textDisplay = React.createRef();
    this.textInputContainer = React.createRef();

    this.handleOnChange = this.handleOnChange.bind(this);
    this.updateFocus = this.updateFocus.bind(this);
    this.exportText = this.exportText.bind(this);
  }

  componentDidMount() {
    this.textInput.current!.focus();
  }

  increaseFontSize() {
    let update = false;
    const textLines = this.state.textLines;

    textLines.forEach((textLine, index) => {
      const container = this.textInputContainer.current!.offsetWidth;
      const text = this.textDisplay.current!.children[index] as HTMLElement;

      if (
        container - text.offsetWidth < 10 &&
        textLine.fontSize >= this.minFontSize
      ) {
        update = true;
        textLine.fontSize = textLine.fontSize - 10;
      }
    });

    if (update) {
      this.setState({ textLines }, this.increaseFontSize);
    }
  }

  decreaseFontSize() {
    let update = false;
    const textLines = this.state.textLines;

    textLines.forEach((textLine, index) => {
      const container = this.textInputContainer.current!.offsetWidth;
      const text = this.textDisplay.current!.children[index] as HTMLElement;

      if (
        container - text.offsetWidth > 10 &&
        textLine.fontSize <= this.maxFontSize
      ) {
        update = true;
        textLine.fontSize = textLine.fontSize + 5;
      }
    });

    if (update) {
      this.setState({ textLines }, this.decreaseFontSize);
    }
  }

  handleOnChange(value: string) {
    const lines = value.split("\n");

    const newTextLines = lines.map((line, index) => {
      if (this.state.textLines[index]) {
        return {
          fontSize: this.state.textLines[index].fontSize,
          text: line
        };
      } else {
        return { fontSize: 200, text: line };
      }
    });

    if (this.state.value.length < value.length) {
      this.setState(
        {
          value,
          textLines: newTextLines
        },
        this.increaseFontSize
      );
    } else {
      this.setState(
        {
          value,
          textLines: newTextLines
        },
        this.decreaseFontSize
      );
    }
  }

  updateFocus() {
    this.textInput.current!.focus();
  }

  exportText() {
    const text: HTMLElement = document.querySelector(
      ".textValue"
    ) as HTMLElement;

    html2canvas(text!, { backgroundColor: null }).then((canvas) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          this.props.close({ textBuilderOpen: false });
        } else {
          let newImg = document.createElement("img");
          let url = URL.createObjectURL(blob);

          newImg.onload = function() {
            // no longer need to read the blob so it's revoked
            URL.revokeObjectURL(url);
          };

          const textObject = {
            type: POST_OBJECT_TYPES.TEXT,
            canvasURL: url,
            width: this.props.content
              ? this.props.content.width
              : canvas.width / 2,
            height: this.props.content
              ? this.props.content.height
              : canvas.height / 2,
            x: this.props.content
              ? this.props.content.x
              : 356 / 2 - canvas.width / 2 / 2,
            y: this.props.content ? this.props.content.y : 0,
            color: this.state.color,
            backgroundEnabled: this.state.backgroundEnabled,
            textLines: this.state.textLines,
            value: this.state.value,
            index: this.props.content ? this.props.content.index : null
          };

          this.props.close({ textBuilderOpen: false }, textObject);
        }
      });
    });
  }

  renderTextColor() {
    console.log("backgroundEnabled: ", this.state.backgroundEnabled);
    console.log("color: ", this.state.color);
    if (this.state.backgroundEnabled) {
      return this.state.color === "#fff" ? "#000" : "#fff";
    } else {
      return this.state.color;
    }
  }

  render() {
    return (
      <Container>
        <Actions>
          <li
            onClick={() =>
              this.setState({
                backgroundEnabled: !this.state.backgroundEnabled
              })
            }
          >
            <Shuffle />
          </li>
          <li></li>
          <li className="close" onClick={this.exportText}>
            Done
          </li>
        </Actions>

        <TextInputContainer
          ref={this.textInputContainer}
          onClick={this.updateFocus}
        >
          <textarea
            ref={this.textInput}
            value={this.state.value}
            onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
              this.handleOnChange(e.currentTarget.value)
            }
          />
          <div ref={this.textDisplay} className="textValue">
            {this.state.textLines.map((line, index) => (
              <p
                key={index}
                style={{
                  fontSize: line.fontSize,
                  backgroundColor: this.state.backgroundEnabled
                    ? this.state.color
                    : "transparent",
                  color: this.renderTextColor()
                }}
              >
                {line.text}
              </p>
            ))}
          </div>
        </TextInputContainer>

        <div>
          <ColorList>
            {this.textColorList.map((color) => (
              <ColorListItem
                key={color}
                bgColor={color}
                onClick={() => this.setState({ color })}
              />
            ))}
          </ColorList>
        </div>
      </Container>
    );
  }
}

export default TextBuilder;
