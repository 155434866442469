import * as React from "react";
import html2canvas from "html2canvas";

import {
  ISliderObject,
  POST_OBJECT_TYPES
} from "../../../containers/PostBuilder";
import {
  ScreenWrapper,
  Container,
  SliderContainer,
  Slider,
  TextArea
} from "./styled";

interface ISliderBuilderProps {
  content: any;
  close: (action: any, slider?: ISliderObject) => void;
}

interface ISliderBuilderState {
  textAreaValue: string;
}

class SliderBuilderProps extends React.Component<
  ISliderBuilderProps,
  ISliderBuilderState
> {
  private textArea: React.RefObject<HTMLTextAreaElement>;

  constructor(props: ISliderBuilderProps) {
    super(props);

    if (props.content === null) {
      this.state = {
        textAreaValue: ""
      };
    } else {
      this.state = {
        textAreaValue: props.content.question
      };
    }

    this.textArea = React.createRef();

    this.exportAndExit = this.exportAndExit.bind(this);
  }

  componentDidMount() {
    this.textArea.current!.focus();
  }

  updateTextAreaHeight(textAreaValue: string) {
    this.setState({ textAreaValue }, () => {
      this.textArea.current!.style.height = 30 + "px";
      this.textArea.current!.style.height =
        this.textArea.current!.scrollHeight + "px";
    });
  }

  exportAndExit() {
    const sliderElem: HTMLElement = document.querySelector(
      ".slider-container"
    ) as HTMLElement;

    html2canvas(sliderElem!, { backgroundColor: null }).then((canvas) => {
      canvas.toBlob((blob) => {
        if (!blob || this.state.textAreaValue === "") {
          this.props.close({ sliderBuilderOpen: false });
        } else {
          const url = URL.createObjectURL(blob);

          const slider = {
            type: POST_OBJECT_TYPES.SLIDER,
            canvasURL: url,
            width: this.props.content
              ? this.props.content.width
              : canvas.width / 2,
            height: this.props.content
              ? this.props.content.height
              : canvas.height / 2,
            x: this.props.content
              ? this.props.content.x
              : 356 / 2 - canvas.width / 2 / 2,
            y: this.props.content ? this.props.content.y : 0,
            question: this.state.textAreaValue
          };

          this.props.close({ sliderBuilderOpen: false }, slider);
        }
      });
    });
  }

  render() {
    return (
      <ScreenWrapper>
        <p className="close" onClick={this.exportAndExit}>
          Done
        </p>
        <Container className="slider-container">
          <SliderContainer>
            <TextArea
              ref={this.textArea}
              onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                this.updateTextAreaHeight(e.currentTarget.value)
              }
              value={this.state.textAreaValue}
            />
            <Slider>
              <div className="slide-bg" />
              <p className="icon">
                <span role="img" aria-label="heart-eyes emoji">
                  😍
                </span>
              </p>
            </Slider>
          </SliderContainer>
        </Container>
      </ScreenWrapper>
    );
  }
}

export default SliderBuilderProps;
