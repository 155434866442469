import React, { useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import useApp from "context/AppContext";

interface MatchParams {
  hash: string;
}

function Hotstart({ match }: RouteComponentProps<MatchParams>) {
  const { appCtx } = useApp();
  const { hash } = match.params;

  // async function useHashToSetAuth(hash: string) {
  // try {
  //   const {
  //     data: {
  //       response: { token, organizations, user }
  //     }
  //   } = await axios.post("/hotstart", { hash });

  // let permissions: IPermission[] = [];

  // if (user.permissions) {
  //   permissions = user.permissions;
  // }

  // const organization = organizations.find(
  //   (org: IUserOrganization) => org.default === 1
  // );

  // const orgId = organization.organization_id;

  // const {
  //   routes,
  //   socialMediaAccounts,
  //   usersOrganizations
  // } = await loadUser(token, orgId);

  // setAuth(
  //   { token, orgId },
  //   routes,
  //   permissions,
  //   usersOrganizations,
  //   socialMediaAccounts
  // );
  // } catch (error) {
  //   toast.error(`Invalid Hotstart attempt: ${hash}`);
  // }
  // }

  // useHashToSetAuth(hash);

  useEffect(() => {
    const load = async () => {
      await appCtx.hotstart(hash);
    };
    load();
  }, []);

  return <Redirect to="/magic-mirror" />;
}

export default Hotstart;
