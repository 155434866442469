import * as React from "react";
import styled from "styled-components";

const PageWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 200px;
  border-radius: 10px;

  h1 {
    font-size: 2rem;
  }

  p {
    margin-bottom: 0;
  }
`;

interface ILoadingErrorProps {
  text: string;
}

const LoadingError: React.SFC<ILoadingErrorProps> = ({ text }) => (
  <PageWrap>
    <Container>
      <h1>Whoops!</h1>
      <p>{text}</p>
    </Container>
  </PageWrap>
);

export default LoadingError;
