import * as React from "react";
import styled from "styled-components";
import { Loader } from "react-feather";

const ButtonStyled = styled.button`
  background: ${(props) => props.theme.mm.altOne};
  min-width: 150px;
  height: 50px;
  border-radius: 25px;
  color: ${(props) => props.theme.pallet.white};
  border: 0px;
  outline: none;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  display: block;
  margin-bottom: 20px;

  &:hover {
    transform: scale(1.1);
  }

  &:disabled {
    cursor: not-allowed;

    svg {
      animation: spin infinite 2s linear;
      margin-top: 5px;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

interface IButtonProps {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  text: string;
  loadingText?: string;
  type?: "button" | "submit" | "reset";
  onClick?: () => void;
}

const Button: React.StatelessComponent<IButtonProps> = ({
  className,
  disabled = false,
  loading = false,
  text,
  loadingText,
  type,
  onClick = undefined
}) => {
  return (
    <ButtonStyled
      type={type}
      onClick={onClick}
      className={className}
      disabled={disabled || loading}
    >
      {loading ? <Loader size={22} /> : text}
    </ButtonStyled>
  );
};

export default Button;
