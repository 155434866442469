import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import { useOverlays, useQuestions, useGrid } from "hooks";
import CountUp from "react-countup";
import ReactTooltip from "react-tooltip";
import hexToRgba from "hex-to-rgba";
import { Stack } from "components/Facelift/shared";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { FormatType } from "types/facelift";
import { InfoCircle } from "@styled-icons/boxicons-solid/InfoCircle";

interface QuestionBoxProps {
  questionNumber: string;
  question: string;
  amount: number;
  background: (inputValue: number) => string;
  scale: (inputValue: number) => number;
  isCurrent: boolean;
  handleQuestionChange: (questionNo: string) => void;
  primaryFormat: FormatType;
}

function QuestionBox({
  questionNumber,
  question,
  amount,
  background,
  scale,
  handleQuestionChange,
  isCurrent,
  primaryFormat
}: QuestionBoxProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [showInfo, setShowInfo] = useState(false);
  const showingSidebarContent = useOverlays((s) => s.showingSidebarContent);
  const sidebarContentType = useOverlays((s) => s.sidebarContentType);
  const hoveredQuestion = useGrid((s) => s.hoveredQuestion);

  const dynamicColor = background(amount);

  const showingFilters =
    showingSidebarContent === true && sidebarContentType === "filters";

  const isHovered = hoveredQuestion === questionNumber;

  const isHoveredColor = hexToRgba("#635ec0", "0.1");

  return (
    <Wrapper
      onClick={() => {
        if (amount === 0) return;

        handleQuestionChange(questionNumber);
      }}
      onHoverStart={() => setShowInfo(true)}
      onHoverEnd={() => setShowInfo(false)}
      onMouseLeave={() => setShowInfo(false)}
      style={{
        cursor: amount === 0 ? "not-allowed" : "pointer"
      }}
      ref={ref}
    >
      <QuestionNumber
        style={{
          color: isCurrent ? "white" : dynamicColor,
          border: `2px solid ${dynamicColor}`,
          background: isCurrent
            ? dynamicColor
            : isHovered
            ? isHoveredColor
            : "white"
        }}
        animate={{
          scale: isHovered ? scale(amount + 35) : scale(amount),
          opacity: amount === 0 ? 0.2 : 1
        }}
      >
        {showingFilters ? (
          <CountUp delay={0} end={amount} preserveValue redraw duration={0.7}>
            {({ countUpRef }) => (
              <motion.span
                style={{ color: isCurrent ? "white" : dynamicColor }}
                ref={countUpRef}
              />
            )}
          </CountUp>
        ) : (
          `${questionNumber}`
        )}

        {showInfo && amount > 0 && (
          <QuestionInfo
            dynamicColor={dynamicColor}
            questionNumber={questionNumber}
          />
        )}
      </QuestionNumber>

      <Tooltip
        id={`${questionNumber}`}
        backgroundColor="#7e7acb"
        arrowColor="#7e7acb"
        place="right"
      >
        <QuestionBoxContent
          question={question}
          amount={amount}
          primaryFormat={primaryFormat}
        />
      </Tooltip>
    </Wrapper>
  );
}

export default QuestionBox;

function QuestionInfo({
  dynamicColor,
  questionNumber
}: {
  dynamicColor: string;
  questionNumber: string;
}) {
  useEffect(() => {
    Tooltip.rebuild();
  });

  return (
    <QuestionNumberInfo
      style={{ color: dynamicColor }}
      initial={{ x: "0.5rem", y: "0.25rem" }}
      onClick={(event) => event.stopPropagation()}
      data-tip
      data-for={`${questionNumber}`}
    >
      <QuestionInfoIcon />
    </QuestionNumberInfo>
  );
}

const Wrapper = styled(motion.div)`
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
  background: transparent;
`;

const QuestionNumber = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: "Century Gothic", "Open Sans", sans-serif;
  z-index: 2;
  border-radius: 50%;
  position: relative;
`;

const QuestionNumberInfo = styled(motion.div)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const QuestionInfoIcon = styled(InfoCircle)`
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 50%;
  display: block;
`;

const QuestionHeader = styled.div`
  font-size: 22px;
  letter-spacing: 1px;
  font-family: "Century Gothic", "Open Sans", sans-serif;
  color: white;
  margin-left: 0.5rem;
`;

const QuestionBoxTagsContainer = styled(motion.div)`
  width: auto;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  justify-content: flex-start;
  align-items: center;
`;

const QuestionTag = styled(motion.div)`
  width: auto;
  background: hotpink;
  color: white;
  border-radius: 9999px;
  padding: 0.25rem 1rem;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  color: white;
  font-size: 12px;
  letter-spacing: 0.75px;
`;

const Division = styled.div`
  width: 4px;
  height: 100%;
  background: hotpink;
  border-radius: 999px;
`;

const TopTextExtra = styled(motion.div)`
  border-radius: 9999px;
  padding: 0.25rem 1rem;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 500;
    color: white;
    font-size: 12px;
    letter-spacing: 0.75px;
  }

  div {
    display: none;
    transition: display 0.3s ease-in-out;
  }

  :hover div {
    display: block;
  }
`;

const Tooltip = styled(ReactTooltip)`
  padding: 0 !important;
  border: none !important;
  background: none !important;
  opacity: 1 !important;

  ::after {
    /* border-bottom-color: none !important; */
    /* border-left-color: none !important; */
    /* border-right-color: #7e7acb !important; */
    /* border-top-color: #7e7acb !important; */
    /* border-color: none !important; */
  }
`;

function QuestionBoxContent({
  question,
  amount,
  primaryFormat
}: {
  question: string;
  amount: number;
  primaryFormat: FormatType;
}) {
  return (
    <QuestionBoxContentWrapper>
      <Stack type="row" gap={1}>
        <Stack type="column" gap={0.5}>
          <QuestionBoxText>{question}</QuestionBoxText>
          <Stack type="row" gap={0.5}>
            <TopTextExtra style={{ background: "hotpink" }}>
              <span style={{ fontWeight: "bold", fontSize: "13.5px" }}>
                {amount}
              </span>
              <span style={{ marginLeft: "1ch", fontSize: "11px" }}>
                {amount === 1 ? "Response" : "Responses"}
              </span>
            </TopTextExtra>
            <TopTextExtra style={{ background: "hotpink" }}>
              <span style={{ fontWeight: "bold", fontSize: "11px" }}>
                {primaryFormat}
              </span>
              <span style={{ marginLeft: "1ch", fontSize: "11px" }}>
                {amount === 1 ? "Response" : "Responses"}
              </span>
            </TopTextExtra>
          </Stack>
        </Stack>
      </Stack>
    </QuestionBoxContentWrapper>
  );
}

const QuestionBoxContentWrapper = styled(motion.div)`
  width: max-content;
  max-width: 425px;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  border-radius: 5px;
  padding: 1rem;
  background: #7e7acb;
`;

const QuestionBoxText = styled(motion.div)`
  padding: 0.5rem;
  color: white;
  font-size: 14px;
  letter-spacing: 0.5px;
`;
