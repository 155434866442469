import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PrivateRoute from "../../components/PrivateRoute";
import AuthRoutes from "../../components/AuthRoutes";

// Components
import Home from "../Home";
import PromotedStories from "../PromotedStories";
import Settings from "../Settings";
import Survey from "../Survey";
import PostBuilder from "../PostBuilder";
import Login from "../Login";
import Register from "../Register";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import NoMatch from "../../components/NoMatch";
import QuickLook from "../QuickLook";
import CSVEditor from "../CSVEditor";
import LiveCSVEditor from "../LiveCSVEditor";
import NotificationScheduler from "../NotificationScheduler";
import Hotstart from "../Hotstart";
import Facelift from "../Facelift";

export const Routes: React.FC = () => {
  return (
    <Switch>
      {/* Auth Routes */}
      <AuthRoutes path="/login" component={Login} exact />
      <AuthRoutes path="/register" component={Register} exact />
      <AuthRoutes path="/forgot-password" component={ForgotPassword} exact />
      <AuthRoutes path="/resetpassword" component={ResetPassword} />
      <Route
        path="/hotstart/:hash"
        render={(props) => <Hotstart {...props} />}
      />

      {/* Authenticated Routes */}
      {/* <PrivateRoute path="/" component={Home} exact /> */}
      <PrivateRoute path="/promoted-stories" component={PromotedStories} />
      <PrivateRoute path="/post-builder" component={PostBuilder} />
      <PrivateRoute path="/settings" component={Settings} />
      <PrivateRoute path="/facelift" component={Facelift} />
      <PrivateRoute path="/listview" component={CSVEditor} />
      <Redirect from="/csv-editor" to="/listview" />
      <PrivateRoute path="/live-csv-editor" component={LiveCSVEditor} />
      <PrivateRoute
        path="/notification-scheduler"
        component={NotificationScheduler}
      />

      {/* Public Routes */}
      <Route path="/survey" component={Survey} exact />
      <Route path="/quick-look" component={QuickLook} exact />

      <Route component={NoMatch} />
    </Switch>
  );
};
export default Routes;
