import { useEffect } from "react";
import { useBucketsQuery } from "hooks/useRQResponses";
import { usePrevious, useBuckets } from "hooks";
import toast from "react-hot-toast";
import { useApp } from "context";
import { Bucket } from "types/facelift";

function getSharedIdsFromBuckets(buckets: Array<Bucket>, userId: number) {
  return (buckets ?? [])
    .filter((bucket) => bucket.userId !== userId)
    .map((bucket) => bucket.id);
}

function getDifferencesBetweenBuckets(
  previousIds: Array<number>,
  currentIds: Array<number>
) {
  return {
    addedIds: currentIds.filter((id) => previousIds.includes(id) === false),
    removedIds: previousIds.filter((id) => currentIds.includes(id) === false)
  };
}

function useBucketSharingNotifications() {
  const { appCtx } = useApp();
  const { data: currentBuckets } = useBucketsQuery();
  const previousBuckets = usePrevious<Array<Bucket> | undefined>(
    currentBuckets
  );

  const notificationBucketIds = useBuckets((s) => s.notificationBucketIds);
  const updateNotificationBucketIds = useBuckets(
    (s) => s.updateNotificationBucketIds
  );

  useEffect(() => {
    if (appCtx?.user?.id === undefined) return;
    if (previousBuckets === undefined) return;
    if (currentBuckets === undefined) return;

    const previousSharedIds = getSharedIdsFromBuckets(
      previousBuckets,
      appCtx.user.id
    );

    const currentSharedIds = getSharedIdsFromBuckets(
      currentBuckets,
      appCtx.user.id
    );

    const { addedIds, removedIds } = getDifferencesBetweenBuckets(
      previousSharedIds,
      currentSharedIds
    );

    if (addedIds.length > 0) {
      updateNotificationBucketIds([...notificationBucketIds, ...addedIds]);
      toast.success("A new bucket has been shared with you", {
        duration: 8000
      });
    }
  }, [
    appCtx?.user?.id,
    previousBuckets,
    currentBuckets,
    notificationBucketIds,
    updateNotificationBucketIds
  ]);
}

export default useBucketSharingNotifications;
