import { useState, useEffect } from "react";
import { buildSearchCount } from "utils/facelift";

const cache = new Map();

function useSearchCache(searchTerm: string) {
  const [data, setData] = useState<[string, number][]>([]);

  useEffect(() => {
    if (!searchTerm) return;

    if (cache.has(searchTerm)) {
      const results = cache.get(searchTerm);
      setData(results);
    } else {
      const results = buildSearchCount(searchTerm);
      cache.set(searchTerm, results);
      setData(results);
    }
  }, [searchTerm]);

  return data;
}

export default useSearchCache;
