import * as React from "react";
import SmoothScroll from "smooth-scroll";
import { Formik, Field, FormikActions } from "formik";
import * as Yup from "yup";
import qs from "query-string";
import axios, { AxiosResponse } from "axios";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

import { QuestionsContainer, Button, AbstractImage } from "./styled";
import { STEPS, archetype } from "./types";
import ArchetypeSelector from "./ArchetypeSelector";
import SentenceSelector from "./SentenceSelector";
import WhyTextBox from "./WhyTextBox";
import abstractImageSource from "../../assets/images/abstract.png";
import ErrorBox from "./ErrorBox";
import SurveyCompleted from "./SurveyCompleted";

interface ISurveyState {
  loading: boolean;
  error: string;
  surveyCompleted: boolean;
  ctx: string;
  uid: string;
  activeQuestion: STEPS;
  archetypes: archetype[];
  benefitTypes: archetype[];
}

export interface IFormValues {
  archetypeOne: string;
  archetypeOneIndex: number;
  archetypeOneWhy: string;
  archetypeOneFavorite: string;
  archetypeOneFavoriteIndex: null | number;
  archetypeOneFavoriteWhy: string;

  archetypeTwo: string;
  archetypeTwoIndex: number;
  archetypeTwoWhy: string;
  archetypeTwoFavorite: string;
  archetypeTwoFavoriteIndex: null | number;
  archetypeTwoFavoriteWhy: string;

  benefitTypeOne: string;
  benefitTypeOneIndex: number;
  benefitTypeOneWhy: string;
  benefitTypeOneFavorite: string;
  benefitTypeOneFavoriteIndex: null | number;
  benefitTypeOneFavoriteWhy: string;

  benefitTypeTwo: string;
  benefitTypeTwoIndex: number;
  benefitTypeTwoWhy: string;
  benefitTypeTwoFavorite: string;
  benefitTypeTwoFavoriteIndex: null | number;
  benefitTypeTwoFavoriteWhy: string;

  benefitTypeThree: string;
  benefitTypeThreeIndex: number;
  benefitTypeThreeWhy: string;
  benefitTypeThreeFavorite: string;
  benefitTypeThreeFavoriteIndex: null | number;
  benefitTypeThreeFavoriteWhy: string;
}

interface IFormResponse {
  success: boolean;
  message: string;
}

const validationSchema = Yup.object().shape({
  archetypeOne: Yup.string().required("archetype required"),
  archetypeOneWhy: Yup.string().required("archetype required"),
  archetypeOneFavorite: Yup.string().required("archetype required"),
  archetypeOneFavoriteWhy: Yup.string().required("archetype required"),

  archetypeTwo: Yup.string().required("archetype required"),
  archetypeTwoWhy: Yup.string().required("archetype required"),
  archetypeTwoFavorite: Yup.string().required("archetype required"),
  archetypeTwoFavoriteWhy: Yup.string().required("archetype required"),

  benefitTypeOne: Yup.string().required("benefit type required"),
  benefitTypeOneWhy: Yup.string().required("benefit type required"),
  benefitTypeOneFavorite: Yup.string().required("benefit type required"),
  benefitTypeOneFavoriteWhy: Yup.string().required("benefit type required"),

  benefitTypeTwo: Yup.string().required("benefit type required"),
  benefitTypeTwoWhy: Yup.string().required("benefit type required"),
  benefitTypeTwoFavorite: Yup.string().required("benefit type required"),
  benefitTypeTwoFavoriteWhy: Yup.string().required("benefit type required"),

  benefitTypeThree: Yup.string().required("benefit type required")
});

class Survey extends React.Component<{}, ISurveyState> {
  smoothScroll: SmoothScroll = new SmoothScroll('a[href*="#"]', {
    offset: () => window.innerHeight / 2,
    speed: 1500 // Integer. Amount of time in milliseconds it should take to scroll 1000px
  });

  initialFormValues = {
    archetypeOne: "",
    archetypeOneWhy: "",
    archetypeOneIndex: 100,
    archetypeOneFavorite: "",
    archetypeOneFavoriteIndex: null,
    archetypeOneFavoriteWhy: "",

    archetypeTwo: "",
    archetypeTwoIndex: 100,
    archetypeTwoWhy: "",
    archetypeTwoFavorite: "",
    archetypeTwoFavoriteIndex: null,
    archetypeTwoFavoriteWhy: "",

    benefitTypeOne: "",
    benefitTypeOneIndex: 100,
    benefitTypeOneWhy: "",
    benefitTypeOneFavorite: "",
    benefitTypeOneFavoriteIndex: null,
    benefitTypeOneFavoriteWhy: "",

    benefitTypeTwo: "",
    benefitTypeTwoIndex: 100,
    benefitTypeTwoWhy: "",
    benefitTypeTwoFavorite: "",
    benefitTypeTwoFavoriteIndex: null,
    benefitTypeTwoFavoriteWhy: "",

    benefitTypeThree: "",
    benefitTypeThreeIndex: 100,
    benefitTypeThreeWhy: "",
    benefitTypeThreeFavorite: "",
    benefitTypeThreeFavoriteIndex: null,
    benefitTypeThreeFavoriteWhy: ""
  };

  constructor(props: {}) {
    super(props);

    this.state = {
      loading: false,
      error: "",
      surveyCompleted: false,
      ctx: "",
      uid: "",
      activeQuestion: STEPS.ONE,
      archetypes: [
        {
          title: "Explorer",
          description:
            "An explorer that pushes the boundaries to free my finances from the complexity of the traditional banking system. They replace restrictions and frustrations with flexibility and independence. With the explorer, I have the confidence and freedom to discover my own path in life."
        },
        {
          title: "Guide",
          description:
            "A guide that is like a friend that helps me get to my desired destination. I trust them to help me make smarter decisions because they are able to keep me informed and show me new possibilities. Whether it's sharing new tools or giving advice, I have the peace of mind that they've got my back and can empower me to achieve my goals."
        },
        {
          title: "Alchemist",
          description:
            "An alchemist that improves my quality of life with their ability to transform the complex into the simple. They make banking a magically easy experience so I have a clearer view of my financial life. With them by my side, I have the power to turn my dreams into realities."
        },
        {
          title: "Creator",
          description:
            "A creator whose imagination and invention transforms the traditional. They create beautifully designed solutions to help with my financial challenges. By making banking a more enjoyable experience, they inspire me to make the most of my money and pursue my passions."
        }
      ],
      benefitTypes: [
        {
          title: "Fast and responsive",
          description:
            "An efficient banking experience that allows you to sign up in minutes, get immediate access to money, and receive real-time updates on transactions right on the app."
        },
        {
          title: "Smart design",
          description:
            "A sophisticated banking experience with an intuitive interface that makes banking seamless, and a distinctive card design that helps you stand out."
        },
        {
          title: "Simplicity and ease",
          description:
            "A beautifully simple banking experience, that makes it easy to sign up, send payments, and manage transactions with the swipe of a finger."
        },
        {
          title: "Personalized to my lifestyle",
          description:
            "A tailored banking service that’s built around your life, with customizable accounts, pain free travel, and lifestyle perks."
        },
        {
          title: "Innovative Tech",
          description:
            "An innovative banking experience that offers better service, and features to help you optimise your finances."
        },
        {
          title: "Competitive rates",
          description:
            "A fairer banking experience that saves you money, by offering lower fees on overdrafts, and ATM withdrawals, abroad unlike traditional banking."
        },
        {
          title: "Control and flexibility",
          description:
            "A digital-only banking experience that gives you full account control on your phone, through real-time notifications, spending insights, and customisable sub accounts for savings."
        },
        {
          title: "Security and support",
          description:
            "a secure and reliable banking service that gives the peace of mind of having the protection of a fully licensed bank, with access to friendly customer service, from anywhere."
        }
      ]
    };

    this.scrollTo = this.scrollTo.bind(this);
  }

  componentDidMount() {
    // Get the "ctx" & "uid" query string param from the URL
    // and parse it to get what we need.
    const parsed = qs.parse(window.location.search);

    if (Object.keys(parsed).length === 0) {
      // No query string found?
      return this.setState({
        loading: false,
        error: "It looks like the page you requested doesn't exist."
      });
    } else if (!parsed.ctx || !parsed.uid) {
      // Do the "ctx" & "uid" params exist?
      return this.setState({
        loading: false,
        error: "It looks like the page you requested doesn't exist."
      });
    }

    // It's possible for the param to be a string or array.
    // Take the param and ensure it's in string form.
    const ctx: string = Array.isArray(parsed.ctx)
      ? parsed!.ctx[0]
      : parsed!.ctx;
    const uid: string = Array.isArray(parsed.uid)
      ? parsed!.uid[0]
      : parsed!.uid;

    this.setState({
      loading: false,
      error: "",
      ctx,
      uid
    });
  }

  scrollTo(event: React.MouseEvent<HTMLElement>, toStep: STEPS, speed: number) {
    event.stopPropagation();
    const anchor = document.querySelector(`#${toStep.toLowerCase()}`);

    // I don't really understand the second anchor input but he's not
    // hurting anyone and I want to control the speed of the scroll.
    // ¯\_(ツ)_/¯
    this.smoothScroll.animateScroll(anchor, anchor, { speed });
    this.setState({ activeQuestion: toStep });
  }

  async handleSubmit(values: IFormValues, actions: FormikActions<IFormValues>) {
    const data = {
      questionnaire: this.state.ctx,
      uid: this.state.uid,
      json: JSON.stringify(values)
    };

    try {
      const response: AxiosResponse<IFormResponse> = await axios.post<any>(
        `${process.env.REACT_APP_API_HOST}/api/v1/questionnaire`,
        data
      );

      actions.setSubmitting(false);

      if (response.data.success) {
        this.setState({ surveyCompleted: true });
      } else {
        this.setState({
          error:
            "An error occurred while submitting the form. Please contact support."
        });
        console.log("response.message err: ", response);
      }
    } catch (error) {
      toast.error((error as any).errorMessage);
    }
  }

  render() {
    const { loading, error, archetypes, benefitTypes } = this.state;

    if (loading) {
      return <p>Screen Loading</p>;
    }

    if (error.length) {
      return <ErrorBox errorMessage={this.state.error} />;
    }

    if (this.state.surveyCompleted) {
      return <SurveyCompleted uid={this.state.uid} ctx={this.state.ctx} />;
    }

    return (
      <div>
        <Helmet>
          <title>Survey</title>
        </Helmet>
        <AbstractImage src={abstractImageSource} />
        <Formik
          initialValues={this.initialFormValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => this.handleSubmit(values, actions)}
        >
          {(props) => {
            const {
              values,

              isValid,

              handleSubmit,
              isSubmitting
            } = props;

            console.log("formik props: ", props);

            return (
              <form onSubmit={handleSubmit}>
                <QuestionsContainer>
                  {/* Question One */}
                  <Field
                    id="one"
                    name="archetypeOne"
                    component={ArchetypeSelector}
                    unlocked={true}
                    steps={[STEPS.ONE, STEPS.TWO]}
                    question={
                      "Thanks for stopping by. Imagine you are creating your ideal bank of the future. Please read the following statements and try to picture them.  Once you’ve read them all, please select the statement that most appeals to you."
                    }
                    archetypes={archetypes}
                    activeType={values.archetypeOneIndex}
                    disabledTypes={[values.archetypeTwoIndex]}
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Two */}
                  <Field
                    id="two"
                    name="archetypeOneWhy"
                    component={WhyTextBox}
                    unlocked={values.archetypeOne.length > 0}
                    steps={[STEPS.TWO, STEPS.THREE]}
                    question={"Why is this your favorite statement?"}
                    archetype={
                      values.archetypeOneIndex !== null
                        ? archetypes[values.archetypeOneIndex!]
                        : []
                    }
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Three */}
                  <Field
                    id="three"
                    name="archetypeOneFavorite"
                    component={SentenceSelector}
                    unlocked={values.archetypeOneWhy.length > 0}
                    steps={[STEPS.THREE, STEPS.FOUR]}
                    regexType="period"
                    question={
                      "Please select the part of this statement that most appeals to you."
                    }
                    archetype={
                      values.archetypeOneIndex !== null
                        ? archetypes[values.archetypeOneIndex!]
                        : []
                    }
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Four */}
                  <Field
                    id="four"
                    name="archetypeOneFavoriteWhy"
                    component={WhyTextBox}
                    unlocked={values.archetypeOneFavorite.length > 0}
                    steps={[STEPS.FOUR, STEPS.FIVE]}
                    question={"Why did you choose this part?"}
                    archetype={
                      values.archetypeOneIndex !== null
                        ? archetypes[values.archetypeOneIndex!]
                        : []
                    }
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Five */}
                  <Field
                    id="five"
                    name="archetypeTwo"
                    component={ArchetypeSelector}
                    unlocked={values.archetypeOneFavoriteWhy.length > 0}
                    steps={[STEPS.FIVE, STEPS.SIX]}
                    question={
                      "Please select which of the statements was your 2nd favorite?"
                    }
                    archetypes={archetypes}
                    activeType={values.archetypeTwoIndex}
                    disabledTypes={[values.archetypeOneIndex]}
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Six */}
                  <Field
                    id="six"
                    name="archetypeTwoWhy"
                    component={WhyTextBox}
                    unlocked={values.archetypeTwo.length > 0}
                    steps={[STEPS.SIX, STEPS.SEVEN]}
                    question={"Why was this one your 2nd favorite?"}
                    archetype={
                      values.archetypeOneIndex !== null
                        ? archetypes[values.archetypeOneIndex!]
                        : []
                    }
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Seven */}
                  <Field
                    id="seven"
                    name="archetypeTwoFavorite"
                    component={SentenceSelector}
                    unlocked={values.archetypeTwoWhy.length > 0}
                    steps={[STEPS.SEVEN, STEPS.EIGHT]}
                    regexType="period"
                    question={
                      "As above, select the part of this statement that most appeals to you."
                    }
                    archetype={
                      values.archetypeTwoIndex !== null
                        ? archetypes[values.archetypeTwoIndex!]
                        : []
                    }
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Eight */}
                  <Field
                    id="eight"
                    name="archetypeTwoFavoriteWhy"
                    component={WhyTextBox}
                    unlocked={props.values.archetypeTwoFavorite.length > 0}
                    steps={[STEPS.EIGHT, STEPS.NINE]}
                    question={"Why did you choose this?"}
                    archetype={
                      values.archetypeTwoIndex !== null
                        ? archetypes[values.archetypeTwoIndex!]
                        : []
                    }
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Nine */}
                  <Field
                    id="nine"
                    name="benefitTypeOne"
                    component={ArchetypeSelector}
                    unlocked={values.archetypeTwoFavoriteWhy.length > 0}
                    steps={[STEPS.NINE, STEPS.TEN]}
                    question={
                      "Changing gears a bit, please read some potential benefits about this bank and choose your favorite."
                    }
                    archetypes={benefitTypes}
                    activeType={values.benefitTypeOneIndex}
                    disabledTypes={[values.benefitTypeTwoIndex]}
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Ten */}
                  <Field
                    id="ten"
                    name="benefitTypeOneWhy"
                    component={WhyTextBox}
                    unlocked={values.benefitTypeOne.length > 0}
                    steps={[STEPS.TEN, STEPS.ELEVEN]}
                    question={"Why was this your favorite?"}
                    archetype={
                      values.benefitTypeOneIndex !== null
                        ? archetypes[values.benefitTypeOneIndex!]
                        : []
                    }
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Eleven */}
                  <Field
                    id="eleven"
                    name="benefitTypeOneFavorite"
                    component={SentenceSelector}
                    unlocked={props.values.benefitTypeOneWhy.length > 0}
                    steps={[STEPS.ELEVEN, STEPS.TWELVE]}
                    regexType="comma"
                    question={
                      "Please choose the part of this statement you found most appealing."
                    }
                    archetype={
                      values.benefitTypeOneIndex !== null
                        ? benefitTypes[values.benefitTypeOneIndex!]
                        : []
                    }
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Twelve */}
                  <Field
                    id="twelve"
                    name="benefitTypeOneFavoriteWhy"
                    component={WhyTextBox}
                    unlocked={values.benefitTypeOneFavorite.length > 0}
                    steps={[STEPS.TWELVE, STEPS.THIRTEEN]}
                    question={
                      "Why did you choose this as the most appealing part?"
                    }
                    archetype={
                      values.benefitTypeOneIndex !== null
                        ? benefitTypes[values.benefitTypeOneIndex!]
                        : []
                    }
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Thirteen */}
                  <Field
                    id="thirteen"
                    name="benefitTypeTwo"
                    component={ArchetypeSelector}
                    unlocked={values.benefitTypeOneFavoriteWhy.length > 0}
                    steps={[STEPS.THIRTEEN, STEPS.FOURTEEN]}
                    question={"Next, please select your 2nd favorite benefit."}
                    archetypes={benefitTypes}
                    activeType={values.benefitTypeTwoIndex}
                    disabledTypes={[values.benefitTypeOneIndex]}
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Fourteen */}
                  <Field
                    id="fourteen"
                    name="benefitTypeTwoWhy"
                    component={WhyTextBox}
                    unlocked={values.benefitTypeTwo.length > 0}
                    steps={[STEPS.FOURTEEN, STEPS.FIFTEEN]}
                    question={"Why did you choose this for your 2nd favorite?"}
                    archetype={
                      values.benefitTypeTwoIndex !== null
                        ? benefitTypes[values.benefitTypeTwoIndex!]
                        : []
                    }
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Fifteen */}
                  <Field
                    id="fifteen"
                    name="benefitTypeTwoFavorite"
                    component={SentenceSelector}
                    unlocked={values.benefitTypeTwoWhy.length > 0}
                    steps={[STEPS.FIFTEEN, STEPS.SIXTEEN]}
                    regexType="comma"
                    question={
                      "Please select the part of this statement that you found most appealing."
                    }
                    archetype={
                      values.benefitTypeTwoIndex !== null
                        ? benefitTypes[values.benefitTypeTwoIndex!]
                        : []
                    }
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Sixteen */}
                  <Field
                    id="sixteen"
                    name="benefitTypeTwoFavoriteWhy"
                    component={WhyTextBox}
                    unlocked={values.benefitTypeTwoFavorite.length > 0}
                    steps={[STEPS.SIXTEEN, STEPS.SEVENTEEN]}
                    question={"Why did you choose this part?"}
                    archetype={
                      values.benefitTypeTwoIndex !== null
                        ? benefitTypes[values.benefitTypeTwoIndex!]
                        : []
                    }
                    scrollTo={this.scrollTo}
                  />

                  {/* Question Seventeen */}
                  <Field
                    id="thirteen"
                    name="benefitTypeThree"
                    component={ArchetypeSelector}
                    unlocked={values.benefitTypeTwoFavoriteWhy.length > 0}
                    steps={[STEPS.SEVENTEEN, STEPS.EIGHTEEN]}
                    question={
                      "Finally, please select your 3rd favorite statement."
                    }
                    archetypes={benefitTypes}
                    activeType={values.benefitTypeThreeIndex}
                    disabledTypes={[
                      values.benefitTypeOneIndex,
                      values.benefitTypeTwoIndex
                    ]}
                    scrollTo={this.scrollTo}
                  />

                  <Button
                    id="eighteen"
                    type="submit"
                    largeMargin={true}
                    disabled={isSubmitting || !isValid}
                  >
                    Submit
                  </Button>
                </QuestionsContainer>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default Survey;
