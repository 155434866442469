import * as React from "react";
import { Star, XCircle, ArrowDown } from "react-feather";

import { Container, Header, Title, ImagesList } from "./styled";
import testImage from "../../../assets/images/square-test-image.png";
import placeHolderImage from "../../../assets/images/saved-image-placeholder.png";

interface ISavedImagesSlideoutProps {
  toggle: () => void;
  isOpen: boolean;
}

export default ({ toggle, isOpen }: ISavedImagesSlideoutProps) => (
  <Container isOpen={isOpen}>
    <Header>
      <Title>
        <Star fill="#000" />
        <h3>Saved Images</h3>
      </Title>
      <ArrowDown onClick={toggle} />
    </Header>
    <ImagesList>
      <li>
        <XCircle stroke="white" fill="red" className="delete-image" size="30" />
        <img src={testImage} alt="test" />
      </li>
      <li>
        <XCircle stroke="white" fill="red" className="delete-image" size="30" />
        <img src={testImage} alt="test" />
      </li>
      <li>
        <XCircle stroke="white" fill="red" className="delete-image" size="30" />
        <img src={testImage} alt="test" />
      </li>
      <li>
        <img src={placeHolderImage} alt="placeholder" />
      </li>
      <li>
        <img src={placeHolderImage} alt="placeholder" />
      </li>
      <li>
        <img src={placeHolderImage} alt="placeholder" />
      </li>
      <li>
        <img src={placeHolderImage} alt="placeholder" />
      </li>
      <li>
        <img src={placeHolderImage} alt="placeholder" />
      </li>
      <li>
        <img src={placeHolderImage} alt="placeholder" />
      </li>
      <li>
        <img src={placeHolderImage} alt="placeholder" />
      </li>
      <li>
        <img src={placeHolderImage} alt="placeholder" />
      </li>
    </ImagesList>
  </Container>
);
