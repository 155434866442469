import React from "react";
import { Stack } from "components/Facelift/shared";
import SearchOverlay from "components/Facelift/SearchOverlay";
import GridLayoutControls from "components/Facelift/GridLayoutControls";

function HeaderControls() {
  return (
    <Stack type="row" gap={1}>
      <SearchOverlay />

      <GridLayoutControls />
    </Stack>
  );
}

export default HeaderControls;
