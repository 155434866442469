import styled from "styled-components";

export const Container = styled.div<{ isOpen: boolean }>`
  background-color: ${(props) => props.theme.pallet.white};
  position: absolute;
  width: 350px;
  height: calc(100% - 140px);
  right: 0;
  top: 140px;
  z-index: 0;
  padding: 20px 65px 20px 20px;

  transition: all 250ms;
  transition-timing-function: ease;
  transform: ${(props) =>
    props.isOpen ? "translateX(0)" : "translateX(350px)"};
`;

export const HashTagList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    list-style-type: none;
    border: 2px solid black;
    padding: 10px;
    /* margin-right: 10px; */
    margin: 5px;
    border-radius: 5px;
    font-size: 12px;
    flex: 3;
    width: 45%;
    display: flex;
    justify-content: space-between;

    &:nth-child(3n) {
      flex: 1;
    }

    .name {
      width: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .count {
      margin-left: 10px;
    }
  }
`;
