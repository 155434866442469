import React, { useEffect } from "react";
import styled from "styled-components";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import {
  MenuIconContainer,
  MenuIcon,
  BucketMenuDropdown,
  BucketMenuControl,
  BucketMenuAction,
  BucketKeypress
} from "./styled";

interface DropdownProps {
  isSharedBucket: boolean;
  isDropdownOpen: boolean;
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProfileDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShareDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCopyDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShareRemovalDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function Dropdown({
  isSharedBucket,
  isDropdownOpen,
  setDropdownOpen,
  setProfileDialogOpen,
  setShareDialogOpen,
  setDeleteDialogOpen,
  setCopyDialogOpen,
  setShareRemovalDialogOpen
}: DropdownProps) {
  function stopPropagation(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    event.stopPropagation();
  }

  function handleOpenChange(open: boolean) {
    setDropdownOpen(open);
  }

  useEffect(() => {
    function keypressHandler(event: KeyboardEvent) {
      if (isDropdownOpen === true) {
        if (isSharedBucket) {
          if (event.key === "c") {
            event.preventDefault();
            setCopyDialogOpen(true);
            setDropdownOpen(false);
          }

          if (event.key === "r") {
            event.preventDefault();
            setShareRemovalDialogOpen(true);
            setDropdownOpen(false);
          }
        } else {
          if (event.key === "e") {
            event.preventDefault();
            setProfileDialogOpen(true);
            setDropdownOpen(false);
          }

          if (event.key === "s") {
            event.preventDefault();
            setShareDialogOpen(true);
            setDropdownOpen(false);
          }

          if (event.key === "d") {
            event.preventDefault();
            setDeleteDialogOpen(true);
            setDropdownOpen(false);
          }
        }
      }
    }

    window.addEventListener("keydown", keypressHandler);

    return () => window.removeEventListener("keydown", keypressHandler);
  }, [
    isDropdownOpen,
    setProfileDialogOpen,
    setShareDialogOpen,
    setDeleteDialogOpen,
    setCopyDialogOpen,
    setShareRemovalDialogOpen
  ]);

  const ownedBucketOptions = [
    {
      id: 1,
      handleSelect: () => setProfileDialogOpen(true),
      actionText: "Edit Bucket",
      keypress: "e"
    },
    {
      id: 2,
      handleSelect: () => setShareDialogOpen(true),
      actionText: "Share Bucket",
      keypress: "s"
    },
    {
      id: 3,
      handleSelect: () => setDeleteDialogOpen(true),
      actionText: "Delete Bucket",
      keypress: "d"
    }
  ];

  const sharedBucketOptions = [
    {
      id: 1,
      handleSelect: () => setCopyDialogOpen(true),
      actionText: "Copy Bucket",
      keypress: "c"
    },
    {
      id: 2,
      handleSelect: () => setShareRemovalDialogOpen(true),
      actionText: "Remove Bucket",
      keypress: "r"
    }
  ];

  const bucketOptions = isSharedBucket
    ? sharedBucketOptions
    : ownedBucketOptions;

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={handleOpenChange}>
      <DropdownTrigger asChild>
        <MenuIconContainer onClick={stopPropagation}>
          <MenuIcon />
        </MenuIconContainer>
      </DropdownTrigger>

      <DropdownContent align="end" sideOffset={0}>
        <BucketMenuDropdown type="column" gap={0} onClick={stopPropagation}>
          {bucketOptions.map((option) => {
            return (
              <DropdownItem
                asChild
                key={option.id}
                onSelect={option.handleSelect}
              >
                <BucketMenuControl>
                  <BucketMenuAction>{option.actionText}</BucketMenuAction>
                  <BucketKeypress className="keypress">
                    {option.keypress}
                  </BucketKeypress>
                </BucketMenuControl>
              </DropdownItem>
            );
          })}
        </BucketMenuDropdown>

        {/* <DropdownArrow  offset={10} /> */}
      </DropdownContent>
    </DropdownRoot>
  );
}

export default Dropdown;

const DropdownRoot = styled(DropdownMenu.Root)``;

const DropdownTrigger = styled(DropdownMenu.Trigger)``;

const DropdownContent = styled(DropdownMenu.Content)``;

const DropdownItem = styled(DropdownMenu.Item)``;

const DropdownArrow = styled(DropdownMenu.Arrow)`
  fill: white;
`;
