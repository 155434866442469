import React, { useEffect } from "react";
import {
  useResponses,
  useGrid,
  useKeyboardShortcuts,
  useBucketSharingNotifications
} from "hooks";
import styled from "styled-components";
import { Toaster } from "react-hot-toast";
import { Stack } from "components/Facelift/shared";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Sidebar from "components/Facelift/Sidebar";
import MasonryGrid from "components/Facelift/MasonryGrid";
import GridSidebar from "components/Facelift/GridSidebar";
import SidebarContent from "components/Facelift/SidebarContent";
import { GlobalStyles, Wrapper, Content } from "./styled";
import useApp from "context/AppContext";
import { useFilterOptions } from "hooks/useOptions";
import { useOptions } from "hooks";
import { FilterState, FilterConfig } from "utils/Site";
import defaultFilterCategories from "utils/defaultFilterCategories";

function Facelift() {
  const { appCtx } = useApp();

  const fetchResponses = useResponses((s) => s.fetchResponses);
  const updateSearching = useGrid((s) => s.updateSearching);
  const displayStyle = useGrid((s) => s.displayStyle);
  const updateInitialFilters = useFilterOptions((s) => s.updateInitialFilters);
  const { updateDisplayField } = useOptions();
  const updateSelectedFilters = useFilterOptions(
    (s) => s.updateSelectedFilters
  );

  const updateStudyLoaded = useGrid((s) => s.updateStudyLoaded);

  useKeyboardShortcuts();

  useBucketSharingNotifications();

  useEffect(() => {
    async function loadData() {
      await fetchResponses();
      updateStudyLoaded(true);
    }

    if (appCtx.currentStudy?.display_name) {
      updateDisplayField(appCtx.currentStudy.display_name);
    }

    if (appCtx.currentStudy) {
      let categories;

      if (appCtx.currentStudy.filters.length > 0) {
        categories = appCtx.currentStudy.filters;
      } else {
        categories = defaultFilterCategories;
      }

      const filters = categories.reduce(
        (state: FilterState, currCategory: FilterConfig) => {
          state[currCategory.displayName] = [];

          return state;
        },
        {}
      );

      updateInitialFilters(filters);
      updateSelectedFilters(filters);

      loadData();
    }
  }, [appCtx.currentStudy]);

  useEffect(() => {
    // Show skeleton UI for a few seconds before rendering grid
    const id = setTimeout(() => {
      updateSearching(false);
    }, 2000);

    return () => clearTimeout(id);
  }, [updateSearching]);

  return (
    <DndProvider backend={HTML5Backend}>
      <GlobalStyles />

      <Wrapper>
        <Content>
          <Sidebar />

          <ContentStack type="row" gap={1}>
            {displayStyle !== "graph" && <GridSidebar />}

            <MasonryGrid />
          </ContentStack>
        </Content>

        <SidebarContent />
        <Toaster
          position="bottom-center"
          toastOptions={{
            style: {
              background: "#635ec0",
              color: "white",
              fontSize: "0.9rem",
              letterSpacing: "0.2px",
              width: "max-content"
            },

            duration: 6000,

            error: {
              style: {
                background: "#FF5760"
              }
            }
          }}
        />
      </Wrapper>
    </DndProvider>
  );
}

export default Facelift;

const ContentStack = styled(Stack)`
  align-items: center;
  overflow-x: auto;
  width: max-content;
  height: 100%;
  margin: 0 auto;
`;
