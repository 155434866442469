import React, { useRef, useCallback } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useBuckets, useOverlays, useCloseOnEsc, useOptions } from "hooks";
import SidebarContentBuckets from "components/Facelift/SidebarContentBuckets";
import SidebarContentFilters from "components/Facelift/SidebarContentFilters";

function SidebarContent() {
  const showingSidebarContent = useOverlays((s) => s.showingSidebarContent);
  const hideBucketContainer = useOverlays((s) => s.hideBucketContainer);
  const sidebarContentType = useOverlays((s) => s.sidebarContentType);
  const updateSidebarContent = useOverlays((s) => s.updateSidebarContent);

  const { numberOfFiltersApplied } = useOptions();

  const ref = useRef<HTMLDivElement>(null);

  // useCloseOnEsc(useCallback(hideBucketContainer, [hideBucketContainer]));

  return (
    <AnimatePresence>
      {showingSidebarContent && (
        <Wrapper
          ref={ref}
          key="side"
          initial={{ x: "100%" }}
          animate={{ x: "0%" }}
          exit={{ x: "100%" }}
          transition={{ type: "just" }}
        >
          {sidebarContentType === "buckets" && <SidebarContentBuckets />}

          {sidebarContentType === "filters" && <SidebarContentFilters />}
        </Wrapper>
      )}
    </AnimatePresence>
  );
}

export default SidebarContent;

const Wrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  z-index: 5;
  padding: 1rem;
  background: white;
  box-shadow: var(--shadow);
  width: 350px;
`;

const Title = styled(motion.div)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 1rem;
`;

const SidebarOption = styled(motion.div)<{ selected: boolean }>`
  border-bottom: none;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: #635ec0;
  font-size: 16.5px;
  font-weight: normal;
  letter-spacing: 0.75px;
  cursor: pointer;
  ${(p) =>
    p.selected &&
    `
    font-weight: bold;
    border-bottom: 2px solid #635ec0;
  `}
`;

const SidebarOptionNumber = styled(motion.div)`
  width: 24px;
  height: 24px;
  background: #ff69b4;
  font-size: 13.5px;
  color: white;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
`;
