import * as React from "react";
import styled from "styled-components";
import { FieldProps } from "formik";

import { IFormValues } from "./index";
import { archetype, STEPS } from "./types";
import { Question, Button, QuestionOffsetBackground } from "./styled";
import QuestionPlaceHolder from "./QuestionPlaceHolder";

const ArcheTypesList = styled.ul`
  padding: 0;
`;

interface IArcheTypesListItem {
  active: boolean;
  disabled: boolean;
}

const ArcheTypesListItem = styled.li<IArcheTypesListItem>`
  list-style-type: none;
  padding: 15px;
  background-color: ${(props) =>
    props.active ? "rgba(250, 166, 26, .2)" : "none"};
  background-color: ${(props) => (props.disabled ? "lightgrey" : "none")};

  &:hover {
    cursor: pointer;
    background-color: rgba(250, 166, 26, 0.2);
    background-color: ${(props) => (props.disabled ? "lightgrey" : "none")};
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }

  h3 {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }
`;

interface IArchetypeSelectorProps extends FieldProps<IFormValues> {
  id: string;
  unlocked: boolean;
  steps: STEPS[];
  question: string;
  archetypes: archetype[];
  activeType: null | number;
  disabledTypes: number[];
  scrollTo: (
    event: React.MouseEvent<HTMLElement>,
    toStep: STEPS,
    speed: number
  ) => void;
}

class ArchetypeSelector extends React.Component<IArchetypeSelectorProps, {}> {
  constructor(props: IArchetypeSelectorProps) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
    this.setValue = this.setValue.bind(this);
  }

  setValue(e: React.MouseEvent<HTMLLIElement>, index: number) {
    e.stopPropagation();

    // if (index !== this.props.disabledType) {
    if (
      !this.props.disabledTypes.find((disabledType) => disabledType === index)
    ) {
      const archeTypeIndex: keyof IFormValues = `${this.props.field.name}Index` as keyof IFormValues;
      const archeTypeWhy: keyof IFormValues = `${this.props.field.name}Why` as keyof IFormValues;
      const favorite: keyof IFormValues = `${this.props.field.name}Favorite` as keyof IFormValues;
      const favoriteIndex: keyof IFormValues = `${this.props.field.name}FavoriteIndex` as keyof IFormValues;
      const favoriteWhy: keyof IFormValues = `${this.props.field.name}FavoriteWhy` as keyof IFormValues;

      this.props.form.setFieldValue(
        `${this.props.field.name}`,
        this.props.archetypes[index].title
      );
      this.props.form.setFieldValue(archeTypeIndex, index);
      this.props.form.setFieldValue(archeTypeWhy, "");
      this.props.form.setFieldValue(favorite, "", false);
      this.props.form.setFieldValue(favoriteIndex, "", false);
      this.props.form.setFieldValue(favoriteWhy, "", false);
    }
  }

  handleOnClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    this.props.scrollTo(e, this.props.steps[1], 2000);
  }

  isDisabled(index: number) {
    const hm = this.props.disabledTypes.find(
      (disabledType) => disabledType === index
    );
    return hm === undefined ? false : true;
  }

  render() {
    if (!this.props.unlocked) {
      return <QuestionPlaceHolder />;
    }

    const fieldValue: keyof IFormValues = this.props.form.values[
      this.props.field.name as keyof IFormValues
    ] as keyof IFormValues;

    return (
      <Question
        id={this.props.id}
        active
        onClick={(e: React.MouseEvent<HTMLElement>) =>
          this.props.scrollTo(e, this.props.steps[0], 2000)
        }
      >
        <QuestionOffsetBackground />
        <h2>{this.props.question}</h2>

        <ArcheTypesList>
          {this.props.archetypes.map((archetype, index) => (
            <ArcheTypesListItem
              key={archetype.title}
              active={index === this.props.activeType}
              disabled={this.isDisabled(index)}
              onClick={(e: React.MouseEvent<HTMLLIElement>) =>
                this.setValue(e, index)
              }
            >
              <h3>{archetype.title}</h3>
              <p>{archetype.description}</p>
            </ArcheTypesListItem>
          ))}
        </ArcheTypesList>

        <Button
          type="button"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            this.handleOnClick(e)
          }
          disabled={fieldValue.length < 1}
        >
          Next
        </Button>
      </Question>
    );
  }
}

export default ArchetypeSelector;
