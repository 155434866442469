import * as React from "react";
import { GroupActionsContainer } from "./styled";
import { Edit3, Trash2, CornerUpLeft, X } from "react-feather";

interface IGroupActionProps {
  deletePressed: () => void;
  recoverPressed: () => void;
  editPressed: () => void;
  closePressed: () => void;
}

class GroupActions extends React.Component<IGroupActionProps, {}> {
  render() {
    return (
      <GroupActionsContainer>
        <div className="edit-icon" onClick={this.props.editPressed}>
          <Edit3 />
          <label>Edit Topic</label>
        </div>
        <div className="delete-icon" onClick={this.props.deletePressed}>
          <Trash2 />
          <label>Delete</label>
        </div>
        <div className="delete-icon" onClick={this.props.recoverPressed}>
          <CornerUpLeft />
          <label>Recover</label>
        </div>

        <X className="close" onClick={this.props.closePressed} />
      </GroupActionsContainer>
    );
  }
}

export default GroupActions;
