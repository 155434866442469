import styled from "styled-components";

export const Container = styled.div<{ isOpen: boolean }>`
  background-color: white;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 200px;
  padding: 20px;

  transition: all 250ms;
  transition-timing-function: ease;
  transform: ${(props) =>
    props.isOpen ? "translatex(0)" : "translateY(250px)"};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-left: 20px;

  h3 {
    margin: 0;
    margin-left: 10px;
  }
`;

export const Title = styled.div`
  display: flex;
`;

export const ImagesList = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  margin-bottom: 20px;
  margin-left: 20px;
  height: 97px;

  li {
    list-style-type: none;
    height: 100%;
    margin-right: 20px;
    position: relative;

    .delete-image {
      position: absolute;
      top: -15px;
      right: -15px;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }
    }

    img {
      max-height: 100%;
    }
  }
`;
