import React from "react";
import styled from "styled-components";
import {
  useResponses,
  useGrid,
  useBuckets,
  useGridResponses,
  useOptions
} from "hooks";
import useApp from "context/AppContext";
import { Stack } from "components/Facelift/shared";
import GraphBlock from "./GraphBlock";
import { ErrorBoundary } from "react-error-boundary";
import SpinnerIcon from "react-spinkit";
import * as sizes from "utils/facelift/gridMeasurements";
import {
  getGraphData,
  capitalizeFirstLetter,
  getSegmentedBarColor
} from "utils/facelift";
import { Bucket, Response } from "types/facelift";
import FallbackGrid from "./FallbackGrid";
import { GridWrapper } from "./styled";

function Graph() {
  const gridResponses = useResponses((s) => s.gridResponses);
  const resetResponses = useResponses((s) => s.resetResponses);
  const selectedBucket = useBuckets((s) => s.selectedBucket);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);
  const updateSearching = useGrid((s) => s.updateSearching);
  const isStudyLoaded = useGrid((s) => s.isStudyLoaded);
  const { appCtx } = useApp();
  const allResponses = useGridResponses();
  const { options } = useOptions();

  const bucketResponses = gridResponses;

  function handleReset() {
    updateSearching(true);
    resetResponses();
    updateSelectedBucket({} as Bucket);
  }

  const data = getGraphData(allResponses, options);

  return (
    <ErrorBoundary FallbackComponent={FallbackGrid} onReset={handleReset}>
      <GridWrapper style={{ width: sizes.GRID_WIDTH, padding: "1rem" }}>
        {isStudyLoaded === false || appCtx.loading === true ? (
          <Loading />
        ) : (
          <Stack
            type="column"
            gap={2}
            style={{ gridTemplateColumns: "minmax(0, 1fr)" }}
          >
            {Object.entries(data).map(([questionNumber, questionDetails]) => {
              return (
                <GraphBlock
                  key={questionNumber}
                  graphData={questionDetails}
                  questionNumber={questionNumber}
                />
              );
            })}
          </Stack>
        )}
      </GridWrapper>
    </ErrorBoundary>
  );
}

export default Graph;

function Loading() {
  return (
    <LoadingWrapper>
      <SpinnerIcon name="ball-spin-fade-loader" color="#635ec0" fadeIn="none" />
    </LoadingWrapper>
  );
}

const LoadingWrapper = styled.div`
  margin-top: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
