import * as React from "react";
import styled from "styled-components";
import { FieldProps } from "formik";

import { archetype, STEPS } from "./types";
import { IFormValues } from "./index";
import { Question, Button, QuestionOffsetBackground } from "./styled";
import QuestionPlaceHolder from "./QuestionPlaceHolder";

const Paragraph = styled.p`
  margin: 40px 8%;
  font-size: 20px;
  line-height: 1.5;
`;

interface SpanProps {
  active: boolean;
}

const Span = styled.span<SpanProps>`
  cursor: pointer;
  padding: 3px;
  background-color: ${(props) =>
    props.active ? "rgba(250, 166, 26, .2)" : ""};

  &:hover {
    background-color: rgba(250, 166, 26, 0.2);
  }
`;

interface ISentenceSelectorProps extends FieldProps<IFormValues> {
  id: string;
  unlocked: boolean;
  steps: STEPS[];
  regexType: string;
  question: string;
  archetype: archetype;
  // selectSentence: (activeSentenceIndex: number, sentence: string, field: string) => void;
  // selectedSentence: number | null;
  scrollTo: (
    event: React.MouseEvent<HTMLElement>,
    toStep: STEPS,
    speed: number
  ) => void;
}

interface ISentenceSelectorState {
  activeSentenceIndex: number | null;
}

class SentenceSelector extends React.Component<
  ISentenceSelectorProps,
  ISentenceSelectorState
> {
  constructor(props: ISentenceSelectorProps) {
    super(props);

    this.state = {
      activeSentenceIndex: null
    };

    this.selectSentence = this.selectSentence.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.setInnerHTML = this.setInnerHTML.bind(this);
  }

  selectSentence(
    e: React.MouseEvent<HTMLSpanElement>,
    activeSentenceIndex: number,
    sentence: string
  ) {
    e.stopPropagation();

    // const field = this.props.field.name;

    const favorite: keyof IFormValues = this.props.field
      .name as keyof IFormValues;
    const favoriteIndex: keyof IFormValues = `${this.props.field.name}Index` as keyof IFormValues;
    const favoriteWhy: keyof IFormValues = `${this.props.field.name}Why` as keyof IFormValues;

    this.props.form.setFieldValue(favorite, sentence, true);
    this.props.form.setFieldValue(favoriteIndex, activeSentenceIndex, true);
    this.props.form.setFieldValue(favoriteWhy, "", true);
  }

  handleOnClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    this.props.scrollTo(e, this.props.steps[1], 2000);
  }

  setInnerHTML() {
    const { archetype, field } = this.props;
    const favoriteIndex: keyof IFormValues = `${field.name}Index` as keyof IFormValues;
    let sentences: string[] = [];

    if (archetype) {
      // eslint-disable-next-line
      const regexExpression = this.props.regexType === "period" ? /\./g : /\,/g;
      sentences = archetype.description.split(regexExpression);
    }

    return sentences.map((sentence, index) => {
      return sentence.length ? (
        <Span
          key={index}
          active={index === this.props.form.values[favoriteIndex]}
          onClick={(e: React.MouseEvent<HTMLSpanElement>) =>
            this.selectSentence(e, index, sentence)
          }
        >
          {index !== sentences.length - 1
            ? sentence + (this.props.regexType === "period" ? "." : ",")
            : sentence + (this.props.regexType === "period" ? "." : "")}
        </Span>
      ) : null;
    });
  }

  render() {
    if (!this.props.unlocked) {
      return <QuestionPlaceHolder />;
    }

    const fieldValue: keyof IFormValues = this.props.form.values[
      this.props.field.name as keyof IFormValues
    ] as keyof IFormValues;

    return (
      <Question
        id={this.props.id}
        active
        onClick={(e: React.MouseEvent<HTMLElement>) =>
          this.props.scrollTo(e, this.props.steps[0], 2000)
        }
      >
        <QuestionOffsetBackground />

        <h2>{this.props.question}</h2>
        <Paragraph>{this.setInnerHTML()}</Paragraph>

        <Button
          type="button"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
            this.handleOnClick(e)
          }
          disabled={fieldValue.length < 1}
        >
          Next
        </Button>
      </Question>
    );
  }
}

export default SentenceSelector;
