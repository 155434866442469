import * as React from "react";
import styled from "styled-components";
import { FieldProps, FormikTouched } from "formik";

const FormGroup = styled.div<{
  hasError: boolean | undefined | string | FormikTouched<any>;
}>`
  margin-bottom: 20px;

  input {
    width: 100%;
    padding: 16px;
    color: #555;
    font-size: 1.2rem;
    line-height: 1.2rem;
    border: ${(props) =>
      props.hasError
        ? `1px solid ${props.theme.mm.textError}`
        : "1px solid #DBDBDB"};
    border-radius: 10px;

    &::placeholder {
      color: lightgray;
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }

  .input-feedback {
    margin-top: 5px;
    margin-left: 0px;
    text-align: left;
    color: ${(props) => props.theme.mm.textError};
  }
`;

const FormInput: React.StatelessComponent<FieldProps<any>> = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  return (
    <FormGroup hasError={errors[field.name] && touched[field.name]}>
      <input type="text" {...field} {...props} />
      {errors[field.name] && touched[field.name] && (
        <div className="input-feedback">{errors[field.name]}</div>
      )}
    </FormGroup>
  );
};

export default FormInput;
