import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Stack, sentimentIcons } from "components/Facelift/shared";
import { useGridResponses, useOptions } from "hooks";
import useApp from "context/AppContext";
import CountUp from "react-countup";
import tinycolor from "tinycolor2";
import MasonryListCard from "components/Facelift/MasonryListCard";
import Tooltip from "components/Facelift/Tooltip";
import {
  capitalizeFirstLetter,
  getPercentage,
  getPrimaryFormatFromResponses,
  getSegmentedBarColor,
  getEmotionalAverage,
  getDominantSentiment,
  getEmotionalValue,
  getSentimentColorByValue,
  getEmotionalPillValue
} from "utils/facelift";
import {
  Response,
  GraphDataType,
  EmotionsType,
  Sentiment
} from "types/facelift";
import { ExtractFieldsOfType } from "types/magicMirror";

interface GraphBlockProps {
  questionNumber: any;
  graphData: GraphDataType;
}

function GraphBlock({ questionNumber, graphData }: GraphBlockProps) {
  const { text, breakdown, totalCount } = graphData;
  const [selectedFilters, setSelectedFilters] = useState<{
    [key: string]: Array<string>;
  }>({});
  const [selectedEmotion, setSelectedEmotion] = useState<EmotionsType>("joy");

  const responses = useGridResponses();

  function updateSelectedFilters(
    item: { displayName: string },
    selected: string
  ) {
    setSelectedFilters((currentFilters) => {
      const existingFilters = currentFilters?.[item.displayName] || [];

      if (existingFilters.includes(selected)) {
        return {
          ...currentFilters,
          [item.displayName]: existingFilters.filter(
            (filter) => filter !== selected
          )
        };
      } else {
        return {
          ...currentFilters,
          [item.displayName]: [...existingFilters, selected]
        };
      }
    });
  }

  const { options } = useOptions();

  const entries = Object.entries(selectedFilters)
    .filter(([_, values]) => values.length > 0)
    .map(
      ([key, values]) =>
        [
          options.find((option) => option.displayName === key)?.columnName,
          values
        ] as [string, Array<string>]
    );

  const optionsToCheck = Object.fromEntries(entries);

  function passesFilter(response: Response) {
    for (const option in optionsToCheck) {
      const responseOption =
        response[option as ExtractFieldsOfType<Response, string>];
      const stateOption = optionsToCheck[option];

      if (stateOption.includes(responseOption) === false) {
        return false;
      }
    }

    return true;
  }

  const questionResponses = responses.filter(
    (response) => response.question_no === questionNumber
  );

  const questionResponsesFiltered = questionResponses.filter(passesFilter);

  const primaryFormat = getPrimaryFormatFromResponses(
    questionResponsesFiltered
  );

  return (
    <Wrapper type="column" gap={0}>
      {/* Header */}
      <GraphBlockHeader type="row" gap={1}>
        <QuestionNumber>{questionNumber}</QuestionNumber>
        <QuestionText>{text}</QuestionText>
        <Stack
          type="column"
          gap={0}
          style={{
            color: "white"
          }}
        >
          <EmotionText>Responses</EmotionText>
          <CountUp
            preserveValue
            end={questionResponsesFiltered.length}
            delay={0}
            duration={1}
          >
            {({ countUpRef }) => <AverageText ref={countUpRef} />}
          </CountUp>
        </Stack>
        <Stack
          type="column"
          gap={0}
          style={{
            color: "white"
          }}
        >
          <EmotionText>Format</EmotionText>
          <AverageText>{capitalizeFirstLetter(primaryFormat)}</AverageText>
        </Stack>
      </GraphBlockHeader>

      <DataBlock type="column" gap={3}>
        {/* Overall Sentiment */}
        <OverallSentiment
          questionResponsesFiltered={questionResponsesFiltered}
        />

        {/* Response Breakdown */}
        <Stack
          type="column"
          gap={0.75}
          style={{ gridTemplateColumns: "minmax(0, 1fr)" }}
        >
          <Stack type="row" gap={1} style={{ justifyContent: "space-between" }}>
            <Stack type="column" gap={0}>
              <GroupHeader>Response Breakdown</GroupHeader>
              <GroupSubheader>Click on filters to update graphs</GroupSubheader>
            </Stack>

            {/* <span>{questionResponsesFiltered.length} responses</span> */}
          </Stack>
          <Stack
            type="column"
            gap={1}
            style={{
              gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)"
            }}
          >
            {Object.entries(breakdown)
              .filter(([_, option]) => Object.values(option).length > 1)
              .map(([optionName, option]) => {
                const opt = Object.values(option).sort((a, b) => {
                  if (b.percentage > a.percentage) {
                    return 1;
                  } else {
                    return -1;
                  }
                });

                return (
                  <Stack
                    type="column"
                    gap={0.25}
                    key={optionName}
                    style={{
                      gridTemplateColumns: "minmax(0, 1fr)"
                    }}
                  >
                    <QuestionText
                      style={{
                        fontSize: "12.5px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        letterSpacing: "1px"
                      }}
                    >
                      {capitalizeFirstLetter(optionName)}
                    </QuestionText>
                    <SegmentedBarsContainer>
                      {opt.map((result, index) => {
                        const { percentage, count, name } = result;
                        const percentageString = `${percentage}%`;

                        const filters = selectedFilters?.[optionName] || [];

                        const isSelected = filters.includes(name);
                        const areFiltersApplied = Object.values(
                          selectedFilters
                        ).some((filter) => filter.length > 0);

                        const background = getSegmentedBarColor(index);
                        const color = tinycolor(background)
                          .lighten(60)
                          .toString();

                        return (
                          <Tooltip message={`${count} responses`} key={name}>
                            <InteractiveSegmentedBar
                              isSelected={isSelected}
                              style={{
                                flexBasis: percentageString,
                                background,
                                color
                              }}
                              animate={{
                                opacity: areFiltersApplied
                                  ? isSelected
                                    ? 1
                                    : 0.5
                                  : 1
                              }}
                              onClick={() => {
                                updateSelectedFilters(
                                  { displayName: optionName },
                                  name
                                );
                              }}
                            >
                              {percentage > 10 && (
                                <QuestionText
                                  style={{
                                    textAlign: "center",
                                    fontSize: "12.5px",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {percentageString}
                                </QuestionText>
                              )}
                            </InteractiveSegmentedBar>
                          </Tooltip>
                        );
                      })}
                    </SegmentedBarsContainer>
                    <FlexStack>
                      {opt.map((result, index) => {
                        const { name } = result;

                        return (
                          <Stack type="row" gap={0.25} key={name}>
                            <SegmentedColorGuide
                              style={{
                                background: getSegmentedBarColor(index)
                              }}
                            />
                            <QuestionText style={{ fontSize: "12.5px" }}>
                              {capitalizeFirstLetter(name as string)}
                            </QuestionText>
                          </Stack>
                        );
                      })}
                    </FlexStack>
                  </Stack>
                );
              })}
          </Stack>
        </Stack>

        {/* Emotional Analysis */}
        <EmotionalAnalysis
          questionResponsesFiltered={questionResponsesFiltered}
          selectedEmotion={selectedEmotion}
          setSelectedEmotion={setSelectedEmotion}
        />

        <SelectedResponses
          questionResponsesFiltered={questionResponsesFiltered}
          selectedEmotion={selectedEmotion}
        />
      </DataBlock>
    </Wrapper>
  );
}

interface OverallSentimentProps {
  questionResponsesFiltered: Array<Response>;
}

function OverallSentiment({
  questionResponsesFiltered
}: OverallSentimentProps) {
  const { sentimentFeatureEnabled } = useApp();

  if (sentimentFeatureEnabled === false) return null;

  const negativePercentage = getPercentage(questionResponsesFiltered, "neg");
  const neutralPercentage = getPercentage(questionResponsesFiltered, "neu");
  const positivePercentage = getPercentage(questionResponsesFiltered, "pos");

  const sentiments: Array<Sentiment> = [
    { percentage: negativePercentage, name: "negative" },
    { percentage: neutralPercentage, name: "neutral" },
    { percentage: positivePercentage, name: "positive" }
  ];

  const dominantSentiment = getDominantSentiment(sentiments);

  return (
    <Stack
      type="column"
      gap={0.75}
      style={{ gridTemplateColumns: "minmax(0, 1fr)" }}
    >
      <Stack type="row" gap={1} style={{ justifyContent: "space-between" }}>
        <Stack type="column" gap={0}>
          <GroupHeader>Overall Sentiment</GroupHeader>
          <GroupSubheader>Click on filters to update graphs</GroupSubheader>
        </Stack>
        <Stack type="row" gap={0.5}>
          <QuestionText
            style={{
              fontSize: "12.5px",
              fontWeight: "bold",
              textTransform: "uppercase",
              letterSpacing: "1px",
              color: getSentimentColorByValue(dominantSentiment)
            }}
          >
            {capitalizeFirstLetter(dominantSentiment)}
          </QuestionText>

          <IconContainer
            style={{
              fill: getSentimentColorByValue(dominantSentiment),
              color: getSentimentColorByValue(dominantSentiment)
            }}
          >
            {sentimentIcons[dominantSentiment]}
          </IconContainer>
        </Stack>
      </Stack>
      <Stack
        type="column"
        gap={1}
        style={{
          gridTemplateColumns: "minmax(0, 1fr)"
        }}
      >
        <Stack
          type="column"
          gap={0.25}
          style={{
            gridTemplateColumns: "minmax(0, 1fr)"
          }}
        >
          <SegmentedBarsContainer>
            {sentiments.map((result) => {
              const { percentage, name } = result;
              const percentageString = `${percentage}%`;

              return (
                <SegmentedBar
                  key={name}
                  style={{
                    flex: `1 1 ${percentageString}`,
                    background: getSentimentColorByValue(name)
                  }}
                >
                  {percentage > 5 && (
                    <SegmentedBarText layout>
                      {percentageString}
                    </SegmentedBarText>
                  )}
                </SegmentedBar>
              );
            })}
          </SegmentedBarsContainer>
          <Stack type="row" gap={1}>
            {sentiments.map((result) => {
              const { name } = result;

              return (
                <Stack type="row" gap={0.25} key={name}>
                  <SegmentedColorGuide
                    style={{
                      background: getSentimentColorByValue(name)
                    }}
                  />
                  <QuestionText style={{ fontSize: "12.5px" }}>
                    {capitalizeFirstLetter(name as string)}
                  </QuestionText>
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

interface EmotionalAnalysisProps {
  questionResponsesFiltered: Array<Response>;
  selectedEmotion: EmotionsType;
  setSelectedEmotion: React.Dispatch<React.SetStateAction<EmotionsType>>;
}

function EmotionalAnalysis({
  questionResponsesFiltered,
  selectedEmotion,
  setSelectedEmotion
}: EmotionalAnalysisProps) {
  const { emotionFeatureEnabled, emotions } = useApp();

  if (emotionFeatureEnabled === false) return null;

  const emotionalAverages = emotions.reduce(
    (emotionalAverages, currentEmotion) => {
      emotionalAverages[currentEmotion] = getEmotionalAverage(
        questionResponsesFiltered,
        currentEmotion
      );
      return emotionalAverages;
    },
    {} as { [key in EmotionsType]: number }
  );

  return (
    <Stack
      type="column"
      gap={0.75}
      style={{ gridTemplateColumns: "minmax(0, 1fr)" }}
    >
      <Stack type="row" gap={1} style={{ justifyContent: "space-between" }}>
        <Stack type="column" gap={0}>
          <GroupHeader>Emotional Scores</GroupHeader>
          <GroupSubheader>Click on filters to update graphs</GroupSubheader>
        </Stack>
      </Stack>
      <Stack
        type="row"
        gap={1}
        style={{ gridTemplateColumns: "repeat(6, minmax(0, 1fr))" }}
      >
        {Object.entries(emotionalAverages).map(([emotion, average]) => {
          const background = getEmotionalPillValue(emotion);
          const color = tinycolor(background)
            .darken(70)
            .toString();

          return (
            <EmotionScoreContainer
              key={emotion}
              type="row"
              onClick={() => setSelectedEmotion(emotion as EmotionsType)}
              isSelected={selectedEmotion === emotion}
              gap={0}
              style={{
                background,
                color
              }}
            >
              <Stack type="column" gap={0}>
                <EmotionText>{emotion}</EmotionText>
                <CountUp
                  preserveValue
                  decimals={1}
                  end={average}
                  delay={0}
                  suffix={"%"}
                  duration={1}
                >
                  {({ countUpRef }) => <AverageText ref={countUpRef} />}
                </CountUp>
              </Stack>
              <Stack type="column" gap={0} style={{ alignSelf: "flex-start" }}>
                {selectedEmotion === emotion && (
                  <IconContainer
                    style={{
                      fill: "#46D7AB",
                      background: "white",
                      borderRadius: "9999px"
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm-1.999 14.413l-3.713-3.705L7.7 11.292l2.299 2.295 5.294-5.294 1.414 1.414-6.706 6.706z"></path>
                    </svg>
                  </IconContainer>
                )}
              </Stack>
            </EmotionScoreContainer>
          );
        })}
      </Stack>
    </Stack>
  );
}

interface SelectedResponsesProps {
  questionResponsesFiltered: Array<Response>;
  selectedEmotion: EmotionsType;
}

function SelectedResponses({
  questionResponsesFiltered,
  selectedEmotion
}: SelectedResponsesProps) {
  const { emotionFeatureEnabled } = useApp();

  if (emotionFeatureEnabled === false) return null;

  const selectedResponses = [...questionResponsesFiltered]
    .filter((response) => {
      return getEmotionalValue(response[selectedEmotion]) > 0.01;
    })
    .filter((response) => {
      return response.format === "text";
    })
    .sort((a, b) => {
      if (b[selectedEmotion] > a[selectedEmotion]) {
        return 1;
      } else {
        return -1;
      }
    })
    .slice(0, 3);

  return (
    <Stack
      type="column"
      gap={0.75}
      style={{ gridTemplateColumns: "minmax(0, 1fr)" }}
    >
      <Stack type="row" gap={1} style={{ justifyContent: "space-between" }}>
        <Stack type="column" gap={0}>
          <GroupHeader>Selected Responses</GroupHeader>
          <GroupSubheader>Click on filters to update responses</GroupSubheader>
        </Stack>

        {/* <span>{questionResponsesFiltered.length} responses</span> */}
      </Stack>
      <Stack
        type="column"
        gap={1}
        style={{ gridTemplateColumns: "minmax(0, 1fr)" }}
      >
        {selectedResponses.length === 0 && (
          <NoResponsesCard>No responses found for this emotion</NoResponsesCard>
        )}

        {selectedResponses.map((response) => (
          <MasonryListCard key={response.id} data={response} cardWidth={100} />
        ))}
      </Stack>
    </Stack>
  );
}

export default GraphBlock;

const Wrapper = styled(Stack)`
  --shadow-color: hsl(220deg 60% 50%);
  grid-template-columns: minmax(0, 1fr);
  filter: drop-shadow(1px 2px 8px hsl(0deg 0% 50% / 0.3));
`;

const DataBlock = styled(Stack)`
  background: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 2rem;
  grid-template-columns: minmax(0, 1fr);
`;

const SegmentedBarsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0.15rem;
`;

const SegmentedBar = styled(motion.div)`
  color: white;
  background: #635ec0;
  text-align: center;
  padding: 0.25rem 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
`;

const InteractiveSegmentedBar = styled(SegmentedBar)<{ isSelected: boolean }>`
  cursor: pointer;
  box-shadow: ${(p) => (p.isSelected ? "0 0 1px 2px #635ec0" : "none")};
  flex-grow: 1;
  flex-shrink: 1;
  transition: box-shadow 300ms ease-in-out, flex-basis 300ms ease-in-out;

  :hover {
    box-shadow: 0 0 1px 2px #635ec0;
  }
`;

const SegmentedColorGuide = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 9999px;
`;

const QuestionNumber = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-family: "Century Gothic", "Open Sans", sans-serif;
  z-index: 2;
  border-radius: 50%;
  position: relative;
  background: white;
  color: #635ec0;
  font-weight: 600;
  align-self: flex-start;
`;

const QuestionTextContainer = styled.div`
  width: 100%;
`;

const QuestionText = styled.div`
  width: 100%;
  font-size: 16.5px;
  letter-spacing: 0.55px;
  text-align: left;
`;

const EmotionSelect = styled.span`
  color: white;
  font-size: 12px;
  letter-spacing: 0.2px;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 0.1rem 1rem;
  border: 1px solid #635ec0;
  cursor: pointer;
`;

const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: inherit;
  color: inherit;

  svg {
    width: 100%;
    height: 100%;
    display: block;
    fill: inherit;
    color: inherit;
    stroke-width: 4px;
  }
`;

const SegmentedBarText = styled(motion.span)`
  font-size: 12.5px;
  letter-spacing: 0.55px;
  text-align: center;
  font-weight: bold;
`;

const GroupHeader = styled.span`
  font-weight: bold;
  font-size: 20.5px;
  letter-spacing: 0.5px;
`;

const GroupSubheader = styled.span`
  font-size: 14.5px;
  letter-spacing: 0.3px;
`;

const ViewResponsesButton = styled.button``;

const GraphBlockHeader = styled(Stack)`
  background: #8987cd;
  color: white;
  padding: 1.5rem 2rem;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  grid-template-columns: auto 1fr auto;
`;

const EmotionText = styled(QuestionText)`
  font-size: 12.5px;
  /* font-weight: bold; */
  text-transform: uppercase;
  /* letter-spacing: 1px; */
`;

const AverageText = styled.span`
  /* font-weight: bold; */
  font-family: "Inter", sans-serif;
  font-size: 28px;
  letter-spacing: 1px;
  line-height: 1;
`;

const FlexStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
`;

const EmotionScoreContainer = styled(Stack)<{ isSelected: boolean }>`
  border-radius: 4px;
  padding: 0.75rem;
  grid-template-columns: 1fr auto;
  cursor: pointer;
  box-shadow: ${(p) => (p.isSelected ? "0 0 1px 3px #635ec0" : "none")};
  transition: box-shadow 300ms ease-in-out;

  ${(p) =>
    p.isSelected === false &&
    `
     :hover {
       box-shadow: 0 0 1px 3px #635ec0;
     }
  `}
`;

const NoResponsesCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  font-size: 18.5px;
  letter-spacing: 0.1px;
  color: #132142;
`;
