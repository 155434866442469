import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  padding: 15px 25px;
  width: 100%;
`;

export const PageTitle = styled.h4`
  color: ${(props) => props.theme.pallet.white};
  text-align: center;
  line-height: 50px;
  margin: 0px;
`;
