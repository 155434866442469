import * as React from "react";

import { Question } from "./styled";

const QuestionPlaceHolder: React.StatelessComponent<{}> = () => (
  <Question active={false}>
    <h3>This question is not available yet.</h3>
  </Question>
);

export default QuestionPlaceHolder;
