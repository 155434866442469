import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useOrg from "context/OrganizationContext";

export const LogoImage = styled.img`
  height: 50px;
  width: 175px;
  display: block;
  cursor: pointer;
`;

export const Logo: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const org = useOrg();
  const setting = org?.settings?.length ? org.settings[0] : undefined;

  return (
    <Link to={setting?.default_route ?? "/"} onClick={onClick}>
      <LogoImage
        src={org?.icon}
        alt="Logo"
        style={{
          width: "auto"
        }}
      />
    </Link>
  );
};

export default Logo;
