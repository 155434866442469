import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import Highlighter from "react-highlight-words";
import { useDrag } from "react-dnd";
import hexToRgba from "hex-to-rgba";
import tinycolor from "tinycolor2";
import {
  Stack,
  DominantSentimentIcon,
  EmotionalPills
} from "components/Facelift/shared";
import CardControls from "components/Facelift/MasonryListCard/CardControls";
import {
  useGrid,
  useResponses,
  useOverlays,
  useBuckets,
  useOptions
} from "hooks";
import { useBucketsQuery } from "hooks/useRQResponses";
import { QuestionOverlay, QuestionText } from "./styled";
import {
  getMediaType,
  getCardAnimation,
  handleImageError,
  getBucketsForResponse,
  stringToRegex
} from "utils/facelift";
import * as sizes from "utils/facelift/gridMeasurements";
import { Response } from "types/facelift";
import ReactPlayer from "react-player";
import axios from 'axios';
import Modal, { Styles } from "react-modal";
import { X } from "react-feather";
import { CloseButton } from "./styled";


const REVEAL_LENGTH = 110;
const ease = [0.6, 0.05, -0.01, 0.9];

// /(^((?!sauce).)*$)/
// /^((?!sauce).)*$/

interface CardProps {
  data: Response;
  index: number;
  width: number;
}

Modal.setAppElement("#root");

const modalStyles: Styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, .8)",
    zIndex: 11,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  content: {
    backgroundColor: "transparent",
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    top: "initial",
    right: "initial",
    bottom: "initial",
    left: "initial",
    border: "none",
    overflow: "initial",

    height: "711.11px",
    width: "400px",
    maxHeight: "calc(100% - 50px)" // leave room for the offset close button
  }
};

function Card({ data: response, index, width }: CardProps) {
  const searchTerm = useGrid((s) => s.searchTerm);
  const updateDraggingCard = useGrid((s) => s.updateDraggingCard);
  const expandedIndex = useGrid((s) => s.expandedIndex);
  const updateExpandedIndex = useGrid((s) => s.updateExpandedIndex);
  const selectedResponses = useResponses((s) => s.selectedResponses);
  const addSelectedResponse = useResponses((s) => s.addSelectedResponse);
  const removeSelectedResponse = useResponses((s) => s.removeSelectedResponse);
  const showBucketContainer = useOverlays((s) => s.showBucketContainer);
  const updateSidebarContent = useOverlays((s) => s.updateSidebarContent);

  const { displayField } = useOptions();

  const [showQuestionOverlay, setShowQuestionOverlay] = useState(false);
  const [isHovering, setHovering] = useState(false);
  const [hasDeleted, setHasDeleted] = useState(false);

  const { data } = useBucketsQuery();
  const buckets = data ?? [];

  const selectedBucketId = useBuckets((s) => s.selectedBucket.id);
  const selectedBucket = buckets.find(
    (bucket) => bucket.id === selectedBucketId
  );
  const userBuckets = getBucketsForResponse(response.id, buckets);

  const vidRef = useRef<HTMLVideoElement>(null);
  const [youtubePlaying, setYoutubePlaying] = useState(false);
  const [tikTokEmbed, setTikTokEmbed] = useState('');
  const [tikTokEmbedLink, setTokTokEmbedLink] = useState<string>();
  const [modalOpen, setModalOpen] = useState(false);

  const [{ isDragging }, dragRef, preview] = useDrag({
    item: {
      type: "card",
      id: response.id,
      response: response,
      responses: [response, ...selectedResponses]
    },
    begin: (monitor) => {
      updateDraggingCard(true);
      updateSidebarContent("buckets");
      // setTimeout(() => {
      showBucketContainer();
      // }, 10);
    },
    end: (monitor) => {
      // hideBucketContainer();
      updateDraggingCard(false);
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (event.shiftKey) {
      const isSelected = selectedResponses
        .map((resp: Response) => resp.id)
        .includes(response.id);

      if (isSelected) {
        removeSelectedResponse(response);
      } else {
        addSelectedResponse(response);
      }
    } else {
      if (expandedIndex === index) {
        updateExpandedIndex(null);
      } else {
        updateExpandedIndex(index);
      }
    }
  }

  function handleMouseEnter(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    setHovering(true);

    if (event.altKey) {
      setShowQuestionOverlay(true);
    } else {
      setShowQuestionOverlay(false);
    }
  }

  function handleMouseLeave() {
    setHovering(false);
    setShowQuestionOverlay(false);
  }

  const hasText = response.text.length > 0;
  const {
    hasMedia,
    hasImage,
    hasVideo,
    hasAudio,
    hasYouTubeUrl,
    hasTikTokUrl,
    hasInstagramUrl,
  } = getMediaType(response.media_url);

  const height = getCardAnimation({ cardIndex: index, expandedIndex });

  function handleAnimationComplete() {
    if (isExpanded) {
      if (hasVideo && vidRef && vidRef.current) {
        vidRef.current.play();
      } else if (hasYouTubeUrl) {
        setYoutubePlaying(true);
      }
    }

    if (!isExpanded) {
      if (hasVideo && vidRef && vidRef.current) {
        vidRef.current.pause();
      } else if (hasYouTubeUrl) {
        setYoutubePlaying(false);
      }
    }
  }

  const rightColumn = index % sizes.COLUMN_COUNT === sizes.COLUMN_COUNT - 1;
  const isExpanded = expandedIndex === index;

  const selected = selectedResponses
    .map((resp: Response) => resp.id)
    .includes(response.id);

  const opacity = isDragging || selected ? 0.25 : 1;

  const shouldShow =
    hasMedia === false || (hasMedia && isHovering) || hasAudio === true;

  const isBucketView = selectedBucket?.id !== undefined;
  const hasBucket = userBuckets.length > 0;
  const currentBucketColor = selectedBucket?.color ?? "";
  const responseBucketColor = userBuckets[0]?.color;
  const cardBackgroundColor = isBucketView
    ? hexToRgba(currentBucketColor, "0.7")
    : hasBucket
      ? hexToRgba(responseBucketColor, "0.7")
      : "#FFFFFF";

  const displayColumn = displayField === "" ? "subtopic" : displayField;

  const searchWords = searchTerm.startsWith("/")
    ? response.text.match(stringToRegex(searchTerm))
    : [searchTerm];

  // console.log(`youtube playing: `, youtubePlaying);

  const getTikTokEmbed = async (url: string) => {
    try {
      const request = axios.create()
      const response = await request.get(`https://www.tiktok.com/oembed?url=${url}`)
      if (response) {
        return response.data.html + '<style>html{overflow: hidden; background-color: white;}</style>'
      } else {
        return 'Sorry, something went wrong while trying to fetch the tiktok video'
      }
    } catch (error) {
      console.error('Error fetching tikTok embed', error)
      return `Sorry, something went wrong while trying to fetch the tiktok video ${error}`
    }
  }


  useEffect(() => {
    if (hasTikTokUrl) {
      getTikTokEmbed(response.media_url).then(res => {
        return setTikTokEmbed(res as string);
      })
    }
  }, [response])

  const injectTikTokUrl = () => {
    async function getTikTokHtml() {
      const url = `https://www.tiktok.com/embed/${response.media_url.substring(response.media_url.lastIndexOf('/') + 1)}`
      const request = axios.create()
      try {
        const response = await request.get(url); 
        if (response) {
          console.log('tik tok response', response.data)
          setTokTokEmbedLink(response.data)
        }
      } catch (error) {
        console.log('tik tok error', error)
      }
    }
    getTikTokHtml()
  }


  return (
    <Wrapper
      style={{
        zIndex: expandedIndex === index ? 4 : 2,
        maxWidth: sizes.CARD_WIDTH,
        alignItems: rightColumn ? "flex-end" : "flex-start"
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <Spacer animate={{ height }} transition={{ ease }} />
      <Component
        key={response.id}
        ref={dragRef}
        initial={false}
        animate={{
          opacity,
          width: isExpanded ? sizes.EXPANDED_CARD_WIDTH : sizes.CARD_WIDTH,
          height: isExpanded ? sizes.EXPANDED_CARD_HEIGHT : sizes.CARD_WIDTH
        }}
        transition={{ ease }}
        style={{
          color: hasMedia || hasBucket ? "white" : "black",
          background: cardBackgroundColor
        }}
        onAnimationComplete={handleAnimationComplete}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {/* <SquircleWrapper>
          <svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M 0, 75 C 0, 18.75 18.75, 0 75, 0 S 150, 18.75 150, 75 131.25, 150 75, 150 0, 131.25 0, 75"
              fill={cardBackgroundColor}
            ></path>
          </svg>
        </SquircleWrapper> */}

        {hasMedia && (
          <ImageContainer>
            {hasInstagramUrl ? (
              <>
                <Modal
                  isOpen={modalOpen}
                  style={modalStyles}
                  contentLabel="Favroite Media"
                >
                  <CloseButton onClick={() => setModalOpen(false)}>
                    <X />
                  </CloseButton>
                  <iframe id="frame" style={{height: '100%', width: '100%', overflow: 'hidden'}} src={`https://www.instagram.com/p/${response.media_url.substring(response.media_url.lastIndexOf('/') + 1)}/embed`}></iframe>
                </Modal>
                <iframe id="frame" style={{height: '100%', width: '100%', overflow: 'hidden'}} src={`https://www.instagram.com/p/${response.media_url.substring(response.media_url.lastIndexOf('/') + 1)}/embed`}></iframe>
              </>
            )
              :
              (
                <>
                  {tikTokEmbed ? (
                    // <div dangerouslySetInnerHTML={{__html: tikTokEmbed}} />
                    // <iframe style={{height: '100%', width: '100%'}} srcDoc={tikTokEmbed}/>
                    <>
                      <Modal
                        isOpen={modalOpen}
                        style={modalStyles}
                        contentLabel="Favroite Media"
                      >
                        <CloseButton onClick={() => setModalOpen(false)}>
                          <X />
                        </CloseButton>
                        {/* <iframe style={{ height: '100%', width: '100%' }} src={`https://www.tiktok.com/static/profile-video?id=${response.media_url.substring(response.media_url.lastIndexOf('/') + 1)}&hide_author=1&utm_campaign=tt4d_open_api`} /> */}
                        {/* <iframe style={{ height: '100%', width: '100%', overflow: 'hidden' }} src={`https://www.tiktok.com/embed/${response.media_url.substring(response.media_url.lastIndexOf('/') + 1)}`} /> */}
                        <iframe style={{height: '100%', width: '100%'}} srcDoc={tikTokEmbed}/>
                      </Modal>
                      <iframe style={{ height: '100%', width: '100%', overflow: 'hidden' }} src={`https://www.tiktok.com/static/profile-video?id=${response.media_url.substring(response.media_url.lastIndexOf('/') + 1)}&hide_author=1&utm_campaign=tt4d_open_api&utm_source=awbx37vxswqcvsf6`} />
                    </>
                  ) : (
                    <>
                      {hasYouTubeUrl ? (
                        <ReactPlayer playing={youtubePlaying} url={response.media_url} />
                      ) : (
                        <>
                          {hasVideo ? (
                            <Video ref={vidRef}>
                              <source src={response.media_url} type="video/mp4" />
                            </Video>
                          ) : (
                            <Image src={response.media_url} alt="" />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
          </ImageContainer>
        )}

        <ImageOverlay
          key={response.id}
          type="column"
          className="overlay"
          gap={1}
          style={{
            opacity: shouldShow ? 1 : 0,
            background: hasMedia ? "rgba(55 55 55 / 0.75)" : "transparent"
          }}
        >
          <UserDetailContainer type="row" gap={0.5}>
            <SmallAvatarContainer
              src={response.social_profile_picture}
              onError={(e) => handleImageError(e, hasImage)}
            />
            <Stack type="column" gap={-1}>
              <SmallUsername>{response.username.split(" ")[0]}</SmallUsername>
              <SmallDescription>
                {response[displayColumn as keyof Response]}
              </SmallDescription>
            </Stack>

            {isExpanded && (
              <CardControls
                hasText={hasText}
                hasMedia={hasMedia}
                response={response}
                hasDeleted={hasDeleted}
                setHasDeleted={setHasDeleted}
              />
            )}
          </UserDetailContainer>

          <TextContainer>
            {hasAudio && (
              <Audio
                controls
                controlsList="nodownload nofullscreen noremoteplayback"
              >
                <source src={response.media_url} type="audio/ogg" />
              </Audio>
            )}

            {isExpanded ? (
              <Text
                searchWords={searchWords}
                textToHighlight={response.text}
                highlightStyle={{
                  background: "gold"
                }}
              />
            ) : (
              <TextClamped
                searchWords={searchWords}
                textToHighlight={response.text}
                highlightStyle={{
                  background: "gold"
                }}
              />
            )}
          </TextContainer>

          {hasTikTokUrl || hasInstagramUrl ?
            <button
              style={{
                background: "rgba(255,101,177,1)",
                borderRadius: 12.5,
                width: "100%",
                color: "white",
                height: 25,
                borderWidth: 0,
                borderColor: 'transparent'
              }}
              onClick={() => { injectTikTokUrl(); setModalOpen(true) }}
            >
              Watch Video
            </button>
            :
            null
          }

          {hasMedia === false && (
            <Stack
              type="row"
              gap={0}
              style={{ justifyContent: "space-between", width: "100%" }}
            >
              <EmotionalPills response={response} />
              <DominantSentimentIcon response={response} />
            </Stack>
          )}
        </ImageOverlay>

        <AnimatePresence>
          {showQuestionOverlay && (
            <QuestionOverlay
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <QuestionText>{response.question}</QuestionText>
            </QuestionOverlay>
          )}
        </AnimatePresence>
      </Component>
      {/* <TextureComponent /> */}
    </Wrapper>
  );
}

export default Card;

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  /* pointer-events: none; */
  width: 100%;
  isolation: isolate;

  .overlay {
    border: 3px solid transparent;
  }

  :hover {
    .overlay {
      border: 3px solid #635ec0;
    }
  }
`;

const Spacer = styled(motion.div)`
  width: ${sizes.CARD_WIDTH}px;
  height: 0;
  background: none;
  pointer-events: none;
`;

const Component = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: ${sizes.CARD_WIDTH}px;
  height: ${sizes.CARD_WIDTH}px;
  border-radius: ${sizes.CARD_RADIUS}px;
  z-index: 4;
  background: white;
  box-shadow: var(--shadow);
  /* pointer-events: auto; */
`;

const TextureComponent = styled.div`
  position: absolute;
  top: 8px;
  left: 0;
  width: ${sizes.CARD_WIDTH}px;
  height: ${sizes.CARD_WIDTH}px;
  /* transform: scale(0.95); */
  background: white;
  box-shadow: var(--shadow);
  z-index: 1;
  border-radius: ${sizes.CARD_RADIUS}px;
`;

const TextContainer = styled(motion.div)`
  background: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: auto;
  position: relative;
  z-index: 4;
  width: 100%;
  flex: 1;
  color: inherit;
`;

const Text = styled(Highlighter)`
  font-size: 13.5px;
  letter-spacing: 0.5px;
  text-align: left;
  width: 100%;
  color: inherit;
`;

const TextClamped = styled(Highlighter)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-align: left;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.35;
  letter-spacing: 0.2px;
  width: 100%;
  color: inherit;
`;

const ImageContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${sizes.CARD_RADIUS}px;
  z-index: 0;
  cursor: pointer;
  overflow: hidden;
`;

const Image = styled(motion.img)`
  width: 100%;
  height: 100%;
  border-radius: ${sizes.CARD_RADIUS}px;
  display: block;
  object-fit: contain;
`;

const Video = styled(motion.video)`
  width: 100%;
  height: 100%;
  border-radius: ${sizes.CARD_RADIUS}px;
  display: block;
  object-fit: contain;
`;

const Audio = styled(motion.audio)`
  width: 100%;
  height: 32px;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
`;

const ImageOverlay = styled(Stack)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${sizes.CARD_RADIUS}px;
  z-index: 3;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12%;
`;

const SquircleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;

  svg {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
  }
`;

const UserDetailContainer = styled(Stack)`
  grid-template-columns: auto 1fr auto auto;
  width: 100%;
  /* cursor: pointer;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  } */
`;

const SmallAvatarContainer = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;

const SmallUsername = styled.div`
  user-select: text;
  font-size: 14.5px;
  letter-spacing: 0.25px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  color: #1a1d1e;
`;

const SmallDescription = styled.div`
  user-select: text;
  font-size: 12.5px;
  letter-spacing: 0.25px;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left;
  /* white-space: nowrap; */
  overflow: hidden;
  color: #3a3f42;
`;
