import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  useBuckets,
  useQuestions,
  useGrid,
  useResponses,
  useOverlays,
  useOptions
} from "hooks";
import { useBucketsQuery } from "hooks/useRQResponses";
import hexToRgba from "hex-to-rgba";
import { useDrag } from "react-dnd";
import { Stack } from "components/Facelift/shared";
import {
  capitalizeFirstLetter,
  inWords,
  getBucketsForResponse
} from "utils/facelift";
import { Bucket, Response } from "types/facelift";
import { DragHandle } from "@styled-icons/material/DragHandle";
import { MessageSquareDetail } from "@styled-icons/boxicons-regular/MessageSquareDetail";
import { User } from "@styled-icons/fa-solid/User";
import DefaultProfilePic from "assets/images/default-profile-black.png";

function handleImageError(
  event: React.SyntheticEvent<HTMLImageElement, Event>
) {
  (event.target as HTMLImageElement).src = DefaultProfilePic;
}

function UserInfo({ response }: { response: Response }) {
  const selectedBucketId = useBuckets((s) => s.selectedBucket.id);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);
  const currentQuestion = useQuestions((s) => s.currentQuestion);
  const updateCurrentQuestion = useQuestions((s) => s.updateCurrentQuestion);
  const updateSearching = useGrid((s) => s.updateSearching);
  const updateExpandedIndex = useGrid((s) => s.updateExpandedIndex);
  const resetResponses = useResponses((s) => s.resetResponses);
  const updateSelectedUser = useGrid((s) => s.updateSelectedUser);
  const updateDraggingCard = useGrid((s) => s.updateDraggingCard);
  const updateSidebarContent = useOverlays((s) => s.updateSidebarContent);
  const showBucketContainer = useOverlays((s) => s.showBucketContainer);
  const hideBucketContainer = useOverlays((s) => s.hideBucketContainer);
  const showingSidebarContent = useOverlays((s) => s.showingSidebarContent);
  const sidebarContentType = useOverlays((s) => s.sidebarContentType);
  const selectedResponses = useResponses((s) => s.selectedResponses);
  const updateHoveredQuestion = useGrid((s) => s.updateHoveredQuestion);

  const { displayField } = useOptions();

  const { data } = useBucketsQuery();
  const buckets = data ?? [];

  const selectedBucket = buckets.find(
    (bucket) => bucket.id === selectedBucketId
  );

  const responseBuckets = getBucketsForResponse(response.id, data);

  const firstBucketColor = responseBuckets[0]?.color;

  const bucketColor =
    selectedBucket !== undefined
      ? selectedBucket.color
      : firstBucketColor ?? "#635ec0";

  const basicColor = hexToRgba("#635ec0", "0.65");
  const rgbaColor = hexToRgba(bucketColor, "0.65");

  const areNoBuckets = buckets.length === 0;
  const bucketPanelIsOpen =
    showingSidebarContent == true && sidebarContentType === "buckets";
  const shouldShowPulse = areNoBuckets && bucketPanelIsOpen;

  const [{ isDragging }, dragRef, preview] = useDrag({
    item: {
      type: "list-item",
      id: response.id,
      response: response,
      responses: [response, ...selectedResponses]
    },
    previewOptions: {
      captureDraggingState: true
    },
    begin: (monitor) => {
      updateDraggingCard(true);
      updateSidebarContent("buckets");
      setTimeout(() => {
        showBucketContainer();
      }, 10);
    },
    end: (monitor) => {
      updateDraggingCard(false);
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  });

  function handleQuestionChange() {
    if (selectedBucket !== undefined) {
      updateSearching(true);
      updateCurrentQuestion(Number(response.question_no), true);
      updateSelectedBucket({} as Bucket);
      updateExpandedIndex(null);
      resetResponses();
      return;
    }

    if (Number(response.question_no) !== currentQuestion) {
      updateSearching(true);
      updateCurrentQuestion(Number(response.question_no), true);
      updateExpandedIndex(null);
      updateSelectedUser(null);
    }
  }

  return (
    <InfoContainer>
      <InfoContainerTopLayer>
        <DragHandleContainer ref={dragRef} className="hidden">
          <DragHandleIcon />
          <DragHandleIconContainer />
        </DragHandleContainer>

        {currentQuestion === null && (
          <QuestionNumberContainer
            onClick={() => {
              updateHoveredQuestion(null);
              handleQuestionChange();
            }}
          >
            {response.question_no}

            <QuestionBoxContentContainer className="popup">
              <QuestionBoxContent
                question={response.question}
                number={response.question_no}
              />
            </QuestionBoxContentContainer>
          </QuestionNumberContainer>
        )}

        {shouldShowPulse && <Pulse className="hidden" />}
      </InfoContainerTopLayer>

      <InfoContainerBottomLayer>
        <AvatarContainer
          ref={preview}
          data-name={response.username.split(" ")[0]}
        >
          <Avatar
            src={response.social_profile_picture}
            onError={handleImageError}
          />
        </AvatarContainer>

        <Stack
          type="row"
          gap={0.5}
          style={{
            padding: "0.5rem",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            gridTemplateColumns: "auto 1fr"
          }}
        >
          {/* <UserInfoContainer className="hidden">
            <UserInfoIcon />
          </UserInfoContainer> */}

          <DisplayFieldText
            className={displayField === "" ? "hidden" : ""}
            key={displayField}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            {capitalizeFirstLetter(
              (response?.[displayField as keyof Response] as string) ?? ""
            )}
          </DisplayFieldText>
        </Stack>

        <ProfileImageGradient
          className="background-animate"
          style={{
            backgroundImage: `linear-gradient(to bottom right, ${basicColor}, ${rgbaColor} 35%), url(${response.social_profile_picture})`
          }}
        />
      </InfoContainerBottomLayer>
    </InfoContainer>
  );
}

function QuestionBoxContent({
  question,
  number
}: {
  question: string;
  number: string;
}) {
  const currentQuestion = useQuestions((s) => s.currentQuestion);

  return (
    <QuestionBoxContentWrapper>
      <Stack type="row" gap={1}>
        {/* <Division /> */}
        <Stack type="column" gap={0.5}>
          <Stack type="column" gap={0.5}>
            <Stack type="row" gap={0.5}>
              <QuestionCount>Question {inWords(Number(number))}</QuestionCount>
              {currentQuestion === null && (
                <>
                  <PinkDivision />
                  <QuestionBoxInfo>
                    Click to view responses to this question
                  </QuestionBoxInfo>
                </>
              )}
            </Stack>

            <QuestionBoxText>{question}</QuestionBoxText>
          </Stack>

          {/* <Stack type="row" gap={1}>
            <TopTextExtra style={{ background: "hotpink" }}>
              <span style={{ fontWeight: "bold", fontSize: "13.5px" }}>14</span>
              <span style={{ marginLeft: "1ch", fontSize: "11px" }}>
                Responses
              </span>
            </TopTextExtra>
          </Stack> */}
        </Stack>
      </Stack>
    </QuestionBoxContentWrapper>
  );
}

export default UserInfo;

const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-top-left-radius: var(--br);
  border-bottom-left-radius: var(--br);
  position: relative;
`;

const InfoContainerTopLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* pointer-events: auto; */
  z-index: 2;
`;

const InfoContainerBottomLayer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-top-left-radius: var(--br);
  border-bottom-left-radius: var(--br);
  position: relative;
  overflow: hidden;
  z-index: 1;
`;

const ProfileImageGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: none;
  animation-direction: alternate;
  animation-delay: 2s;
  background-size: cover;
  filter: blur(2px);
  transform: scale(1.2);
  z-index: 0;
`;

const QuestionNumberContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: white;
  font-family: "Century Gothic", sans-serif;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: var(--shadow);
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  z-index: 2;
  cursor: pointer;
  transition: color 250ms ease-in-out, background 250ms ease-in-out;

  :hover {
    color: #635ec0;
    background: rgba(255, 255, 255, 1);
  }

  .popup {
    display: none;
  }

  :hover {
    .popup {
      display: grid;
    }
  }
`;

const DragHandleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;

  :active {
    cursor: grabbing;
  }
`;

const DragHandleIconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 32px;
  height: 32px;
`;

const DragHandleIcon = styled(DragHandle)`
  margin-top: 3px;
  margin-left: 3px;
  position: relative;
  z-index: 4;
  width: 20px;
  height: 20px;
  color: white;
  transform: rotate(-45deg);
  display: block;
`;

const AdditionalResponsesContainer = styled.div`
  color: white;
  font-family: "Century Gothic", sans-serif;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: var(--shadow);
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 5px;
  z-index: 2;
  cursor: pointer;
`;

const AdditionalResponsesIcon = styled(MessageSquareDetail)`
  width: 100%;
  height: 100%;
  color: white;
`;

const UserInfoContainer = styled.div`
  color: white;
  font-family: "Century Gothic", sans-serif;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: var(--shadow);
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 6px;
  z-index: 2;
  cursor: pointer;
`;

const UserInfoIcon = styled(User)`
  width: 100%;
  height: 100%;
  color: white;
`;

const AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 105px;
  /* border: 1px solid white; */
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  box-shadow: var(--shadow);
  z-index: 2;

  :after {
    position: absolute;
    content: attr(data-name);
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 115%);
    color: white;
    font-size: 0.9rem;
    letter-spacing: 0.25px;
  }
`;

const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

const QuestionBoxContentContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(calc(100% + 0.5rem), 0);
`;
const DisplayFieldText = styled(motion.div)`
  position: relative;
  z-index: 2;
  width: 100%;
  text-align: center;
  color: hsl(0 0% 96%);
  letter-spacing: 0.25px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: "Inter", sans-serif;
  /* background: rgba(255, 255, 255, 0.25);
  border-radius: 5px;
  padding: 2px 4px; */
`;

const QuestionBoxContentWrapper = styled(motion.div)`
  width: max-content;
  max-width: 455px;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  border-radius: 5px;
  padding: 1.5rem;
  background: #635ec0;
  box-shadow: 0 0 3px 1px hsl(0 0% 90%);
`;

const QuestionBoxText = styled(motion.div)`
  color: white;
  font-size: 14px;
  letter-spacing: 0.5px;
`;

const QuestionBoxInfo = styled(motion.div)`
  white-space: nowrap;
  color: lavender;
  font-size: 12.5px;
  letter-spacing: 0.5px;
`;

const QuestionCount = styled.div`
  font-size: 12px;
  letter-spacing: 1px;
  color: #e7e7f1;
  text-transform: uppercase;
  white-space: nowrap;
`;

const PinkDivision = styled.div`
  width: 2px;
  height: 100%;
  background: hotpink;
  border-radius: 999px;
`;

const Pulse = styled.div`
  border: 2px solid #635ec0;
  position: absolute;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  top: 0.1rem;
  left: 0.1rem;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: pulse 1.5s ease-in-out infinite;
  z-index: 0;
  @keyframes pulse {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.95, 1.95);
      opacity: 0;
    }
  }
`;

/*
  <Tooltip
        id={`${response.question_no}-${response.uid}-question`}
        backgroundColor="#635ec0"
        arrowColor="#635ec0"
        place="right"
        afterShow={() => updateHoveredQuestion(response.question_no)}
        afterHide={() => updateHoveredQuestion(null)}
      >
        <QuestionBoxContent
          question={response.question}
          number={response.question_no}
        />
      </Tooltip>
      <Tooltip
        id={`${response.question_no}-${response.uid}-profile`}
        backgroundColor="transparent"
        arrowColor="transparent"
        effect="solid"
        delayShow={500}
        delayHide={500}
        delayUpdate={500}
      >
        <ProfileInfo response={response} color={iconColor} />
      </Tooltip>
      <Tooltip
        id={`${response.question_no}-${response.uid}-additional`}
        backgroundColor="transparent"
        arrowColor="transparent"
        effect="solid"
        delayShow={500}
        delayHide={500}
        delayUpdate={500}
      >
        <AdditionalResponses
          response={response}
          currentQuestion={response.question_no}
        />
      </Tooltip>
*/

const SentimentBadge = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  color: white;
  font-size: 12px;
  letter-spacing: 0.2px;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 0.1rem 1rem;
`;
