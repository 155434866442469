import * as React from "react";
import styled from "styled-components";

const Circle = styled.div<{ bgColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : props.theme.mm.primary};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: ${(props) => props.theme.animations.default};
  &:hover {
    transform: scale(1.1);
  }
`;

interface IFavoriteButtonProps {
  toggle: () => void;
  Icon: React.ComponentType<any>;
  fill: string;
  color: string;
  className?: string;
  bgColor?: string;
}

export default ({
  toggle,
  Icon,
  color,
  fill,
  className,
  bgColor
}: IFavoriteButtonProps) => (
  <Circle onClick={toggle} className={className} bgColor={bgColor}>
    <Icon color={color} fill={fill} />
  </Circle>
);
