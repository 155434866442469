import * as React from "react";
import styled from "styled-components";

const ErrorText = styled.p`
  color: ${(props) => props.theme.mm.textError};
  margin-top: 0;
`;

interface IErrorTextProps {
  text?: string;
  className?: string;
}

export default ({ text, className }: IErrorTextProps) => (
  <ErrorText className={className}>{text}</ErrorText>
);
