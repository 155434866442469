import styled from "styled-components";

export const QuickLookWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-rows: 140px 1fr;
  grid-template-columns: initial;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`;

export const HeaderSection = styled.div`
  background-color: ${(props) => props.theme.pallet.white};
  display: flex;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

export const MainSection = styled.div`
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  max-width: 1200px;
  padding: 20px;
`;
