import React from "react";
import styled from "styled-components";
import { Stack } from "components/Facelift/shared";

export const AlertStack = styled(Stack)`
  background: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  grid-template-columns: minmax(0, 1fr);
  padding: 1.75rem;
  border-radius: 8px;
  width: min(90%, 520px);
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
`;

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  backdrop-filter: blur(4px);
`;

interface FormCSSProperties extends React.CSSProperties {
  ["--form-input"]: string;
}

export const Grouping = ({
  hasErrors = false,
  children
}: {
  hasErrors?: boolean;
  children: React.ReactNode;
}) => (
  <Stack
    type="column"
    gap={0.25}
    style={
      {
        gridTemplateColumns: "minmax(0, 1fr)",
        ["--form-input"]: hasErrors ? "crimson" : "#635ec0"
      } as FormCSSProperties
    }
  >
    {children}
  </Stack>
);

export const Title = styled.h2`
  all: unset;
  box-sizing: border-box;
  letter-spacing: 0.2px;
  font-size: 1.1rem;
`;

export const Description = styled.p`
  all: unset;
  box-sizing: border-box;
  letter-spacing: 0.1px;
  font-size: 0.9rem;
  color: gray;
`;

export const ModalButton = styled.button`
  all: unset;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1.2rem;
  font-size: 0.9rem;
  transition: background 300ms ease;

  :focus {
    outline: 2px solid cornflowerblue;
    outline-offset: 4px;
  }
`;

export const AlertCancel = styled(ModalButton)`
  background: #ffffff;
  color: #68707a;
  border: 1px solid #cacfd5;

  :hover {
    background: #dad8dd;
  }
`;

export const AlertAction = styled(ModalButton)`
  background: #ffe5e6;
  color: #cb2e36;

  :hover {
    background: #ffbec0;
  }
`;

export const SaveAction = styled(ModalButton)`
  background: #d5f1e1;
  color: #00724c;

  :hover {
    background: #b8e7cc;
  }
`;

export const Label = styled.label`
  all: unset;
  box-sizing: border-box;
  color: #635ec0;
`;

export const Input = styled.input`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  display: inline-flex;
  border-radius: 4px;
  padding: 10px;
  color: #292929;
  box-shadow: 0 0 0 1px var(--form-input);
  transition: box-shadow 200ms ease;

  :focus {
    box-shadow: 0 0 0 2px var(--form-input);
  }
`;

export const Textarea = styled.textarea`
  all: unset;
  box-sizing: border-box;
  display: inline-flex;
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  color: #292929;
  height: 5.4rem;
  box-shadow: 0 0 0 1px var(--form-input);
  transition: box-shadow 200ms ease;

  :focus {
    box-shadow: 0 0 0 2px var(--form-input);
  }
`;

export const CloseIconContainer = styled.button`
  all: unset;
  border-radius: 100%;
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 3%;
  right: 3%;
  fill: #635ec0;
  background: white;
  transition: background 300ms ease;

  svg: {
    width: 100%;
    height: 100%;
    display: block;
  }

  :focus,
  :hover {
    background: #dbdaee;
  }
`;

export const ColorButton = styled.button`
  width: 28px;
  height: 28px;
  border: 3px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  :focus {
    outline: 2px solid #635ec0;
    outline-offset: 1px;
  }
`;

export const FormErrorText = styled.span`
  font-size: 0.7rem;
  color: crimson;
`;
