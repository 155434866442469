import React from "react";
import { useBuckets } from "hooks";
import { useBucketsQuery } from "hooks/useRQResponses";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Stack } from "components/Facelift/shared";
import GridLayoutControls from "components/Facelift/GridLayoutControls";
import BackControls from "./BackControls";
import hexToRgba from "hex-to-rgba";

interface BucketGridHeaderProps {
  handleBack: () => void;
}

function BucketGridHeader({ handleBack }: BucketGridHeaderProps) {
  const { data } = useBucketsQuery();
  const selectedBucketId = useBuckets((s) => s.selectedBucket.id);

  const buckets = data ?? [];
  const selectedBucket = buckets.find(
    (bucket) => bucket.id === selectedBucketId
  )!;

  return (
    <TextBlock
      style={{
        background: hexToRgba(selectedBucket?.color ?? "#635ec0", "0.75")
      }}
    >
      <Stack
        type="column"
        gap={3}
        style={{
          width: "100%",
          justifyContent: "stretch"
        }}
      >
        <Stack
          type="row"
          gap={1}
          style={{
            justifyContent: "space-between"
          }}
        >
          <BackControls handleBack={handleBack} />

          <GridLayoutControls />
        </Stack>
      </Stack>

      <Stack type="column" gap={-0.5}>
        <BucketName>{selectedBucket.name}</BucketName>
        <Description isPlaceholder={selectedBucket.description.length === 0}>
          {selectedBucket.description.length === 0
            ? "No description"
            : selectedBucket.description}
        </Description>
        {/* <ResponsesText>
          <ResponsesCount>{selectedBucket.posts.length}</ResponsesCount>{" "}
          response{selectedBucket.posts.length === 1 ? "" : "s"}
        </ResponsesText> */}
      </Stack>

      {/* <Stack
        type="row"
        gap={0}
        style={{
          justifyContent: "flex-end"
        }}
      >
        <BucketDeleteModal
          bucket={selectedBucket}
          isDeleteDialogOpen={isDeleteDialogOpen}
          setDeleteDialogOpen={setDeleteDialogOpen}
        >
          <IconContainer>
            <DeleteIcon />
          </IconContainer>
        </BucketDeleteModal>
      </Stack> */}
    </TextBlock>
  );
}

export default BucketGridHeader;

const TextBlock = styled(motion.div)`
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  padding: 2rem;
  border-radius: 15px;
  background: #635ec0;
  color: white;
  box-shadow: var(--shadow);
`;

const BucketName = styled.h2`
  all: unset;
  box-sizing: border-box;
  font-size: 2.3rem;
  letter-spacing: 0.5px;
  color: white;
`;

const Description = styled.span<{ isPlaceholder: boolean }>`
  ${(p) =>
    p.isPlaceholder &&
    `
    font-style: italic;
  `}
  overflow-wrap: anywhere;
`;
