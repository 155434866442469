import * as React from "react";

// eslint-disable-next-line
import calendar from "../../assets/images/Calendar.svg";
// eslint-disable-next-line
import datePickerArrow from "../../assets/images/DatePickerArrow.svg";
// eslint-disable-next-line
import download from "../../assets/images/download.svg";
import { Container, PageTitle } from "./styled";

interface IToolbarProps {
  title: string;
}

const Toolbar: React.SFC<IToolbarProps> = ({ title }) => (
  <Container>
    <div className="col-xs-3 col-sm-4 col-md-5">
      <PageTitle>{title}</PageTitle>
    </div>
    <div className="col-xs-9 col-sm-7 col-md-5 datepicker-container">
      {/* <div className="datepicker">
        <img className="calendar" src={calendar} />
        <input className="datepicker-input" type="text" name="daterange" />
        <img className="down-arrow" src={datePickerArrow} />
      </div>

      <div className="btn-group">
        <button type="button" className="button image-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img id="site-download-btn" src={download} />
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <button className="dropdown-item" id="downloadAnalytics" type="button">Download Analytics</button>
        </div>
      </div> */}
    </div>
  </Container>
);

export default Toolbar;
