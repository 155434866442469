import styled from "styled-components";
import { motion } from "framer-motion";

export const Wrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const GridWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow: auto;

  > div {
    outline: none;

    -webkit-tap-highlight-color: transparent;
  }
`;
