import { useEffect } from "react";

function useCloseOnEsc(fn: Function) {
  useEffect(() => {
    function handleEsc(event: KeyboardEvent) {
      if (event.key === "Escape") {
        fn();
      }
    }

    window.addEventListener("keydown", handleEsc);

    return () => window.addEventListener("keydown", handleEsc);
  }, [fn]);
}

export default useCloseOnEsc;
