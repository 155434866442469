import React from "react";
import styled from "styled-components";
import { useApp } from "context";
import { useBucketsQuery } from "hooks/useRQResponses";
import BucketItem from "components/Facelift/Bucket";
import BucketDropzone from "components/Facelift/BucketDropzone";
import { Bucket as BucketIconSVG } from "@styled-icons/entypo/Bucket";
import { Bucket } from "types/facelift";

function SidebarContentBuckets() {
  const { data } = useBucketsQuery();
  const buckets = data ?? [];

  const bucketsToRender = buckets.sort((a, b) => {
    if (new Date(a.created_at).getTime() < new Date(b.created_at).getTime()) {
      return -1;
    } else {
      return 1;
    }
  });

  return (
    <>
      <Buckets>
        {buckets.length > 0 ? (
          bucketsToRender.sort((a, b) => {
            let aVal = a.order;
            let bVal = b.order;
            if (aVal !== null && aVal!== undefined && bVal !== null && bVal !== undefined) {
              if (aVal > bVal){
                return 1;
              } else if (aVal < bVal){
                return -1;
              } else if (aVal === bVal){
                return 0;
              } else {
                return -1
              }
            } else {
              return -1;
            }
          }).map((bucket, index) => (
            <BucketItem key={index + 1} bucket={bucket} />
          ))
        ) : (
          <EmptyBuckets />
        )}
      </Buckets>

      <BucketDropzone />
    </>
  );
}

export default SidebarContentBuckets;

function EmptyBuckets() {
  return (
    <EmptyBucketsWrapper>
      <BackgroundContainer>
        <BackgroundBucketIcon />
      </BackgroundContainer>
      <BoldText>Looks like you haven't created any buckets yet.</BoldText>
      <RegularText>Drag cards to get started.</RegularText>
    </EmptyBucketsWrapper>
  );
}

const EmptyBucketsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  position: relative;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.05;
`;

const BackgroundBucketIcon = styled(BucketIconSVG)`
  width: 100%;
  height: 100%;
  display: block;
  color: var(--gray-300);
`;

const BoldText = styled.div`
  font-size: 1.15rem;
  letter-spacing: 0.75px;
  color: #333;
  font-weight: 600;
  position: relative;
  text-align: center;
  z-index: 1;
`;

const RegularText = styled.div`
  margin-top: 0.5rem;
  font-size: 0.85rem;
  letter-spacing: 0.5px;
  color: #666;
  font-weight: 400;
  position: relative;
  text-align: center;
  z-index: 1;
`;

const Buckets = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  overflow-x: visible;
  overflow-y: auto;
  background: white;
  border-radius: 5px;
`;
