import * as React from "react";
import styled from "styled-components";
import { Loader } from "react-feather";
import SearchImage from "../../../assets/images/search-one.svg";

const Container = styled.div`
  padding: 20px 40px;
  align-self: center;
  text-align: center;
  border-radius: 5px;

  .search-image {
    max-width: 20%;
  }

  p {
    font-size: 1.5rem;
    color: ${(props) => props.theme.pallet.grey};
  }

  .loading {
    animation: App-logo-spin infinite 2s linear;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

interface INoDataProps {
  dataLoading: boolean;
}

export default ({ dataLoading }: INoDataProps) => (
  <Container>
    {dataLoading ? (
      <Loader className="loading" />
    ) : (
      <React.Fragment>
        <img src={SearchImage} alt="Search" className="search-image" />
        <p>To begin search for a #, location or person above.</p>
      </React.Fragment>
    )}
  </Container>
);
