import React from "react";
import styled from "styled-components";
import { useDrop, DropTargetMonitor } from "react-dnd";
import toast from "react-hot-toast";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import {
  useBuckets,
  useGrid,
  useResponses,
  useOptions,
  useQuestions
} from "hooks";
import { useApp } from "context";
import { motion } from "framer-motion";
import { useBucketsMutation } from "hooks/useRQResponses";
import { Stack } from "components/Facelift/shared";
import Tooltip from "components/Facelift/Tooltip";
import BucketMenu from "./BucketMenu";
import hexToRgba from "hex-to-rgba";
import {
  addAdditionalFields,
  sliceItems,
  handleImageError
} from "utils/facelift";
import { Bucket, BucketPost, Response, DragObjectWithId } from "types/facelift";

interface BucketProps {
  bucket: Bucket;
}

interface BucketCSSProperties extends React.CSSProperties {
  ["--color"]: string;
  ["--color-100"]: string;
  ["--color-300"]: string;
  ["--color-500"]: string;
}

function BucketItem({ bucket }: BucketProps) {
  const selectedBucket = useBuckets((s) => s.selectedBucket);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);
  const notificationBucketIds = useBuckets((s) => s.notificationBucketIds);
  const removeNotificationBucketId = useBuckets(
    (s) => s.removeNotiticationBucketId
  );
  const updateSearchTerm = useGrid((s) => s.updateSearchTerm);
  const updateSearching = useGrid((s) => s.updateSearching);
  const updateExpandedIndex = useGrid((s) => s.updateExpandedIndex);
  const displayStyle = useGrid((s) => s.displayStyle);
  const updateDisplayStyle = useGrid((s) => s.updateDisplayStyle);
  const updateGridResponses = useResponses((s) => s.updateGridResponses);
  const selectedResponses = useResponses((s) => s.selectedResponses);
  const { displayField, reset } = useOptions();
  const updateSelectedUser = useGrid((s) => s.updateSelectedUser);
  const updateCurrentQuestion = useQuestions((s) => s.updateCurrentQuestion);
  const { appCtx } = useApp();

  const { addResponseToBucket } = useBucketsMutation();

  const isLoading = bucket?.isLoading ?? false;
  const isDeleting = bucket?.isDeleting ?? false;
  const isRemoving = bucket?.isRemoving ?? false;
  const isWaiting = isLoading || isDeleting || isRemoving;

  async function handleClick() {
    if (isLoading) {
      toast.error("Unable to view bucket until it has finished building.");
      return;
    }

    if (isDeleting || isRemoving) {
      toast.error("The bucket is being deleted/removed.");
      return;
    }

    if (bucket.id !== selectedBucket.id) {
      updateSearching(true);
      updateSelectedBucket(bucket, true);
      updateSearchTerm("");
      updateSelectedUser(null);
      updateCurrentQuestion(null);
      updateExpandedIndex(null);
      removeNotificationBucketId(bucket.id);
      if (displayStyle === "graph") updateDisplayStyle("compact");
      reset();

      const gridResponses = bucket.posts
        .map((bucketPost: BucketPost) => bucketPost.post)
        .filter((p) => p != null);

      updateGridResponses(addAdditionalFields(gridResponses));
    }
  }

  function handleDrop(item: DragObjectWithId) {
    if (isLoading) {
      toast.error("Please wait until bucket has been created.");
      return;
    }

    if (isDeleting) {
      toast.error("Cannot add while bucket is deleting.");
      return;
    }

    if (isRemoving) {
      toast.error("Cannot add while bucket is removing.");
      return;
    }

    if (appCtx?.user?.id !== bucket.userId) {
      toast.error(
        "Cannot add to buckets owned by another user. Create a copy of the bucket to add responses."
      );
      return;
    }

    const selectedIds = selectedResponses.map((resp: Response) => resp.id);
    const bucketResponseIds = bucket.posts.map(
      (bucketPost: BucketPost) => bucketPost.postId
    );

    // Adding / Removing one response at a time
    const bucketContainsResponse = bucketResponseIds.includes(item.id);
    if (bucketContainsResponse) {
      // toast.error(`${bucket.name} already contains this response.`);
    } else {
      addResponseToBucket.mutate({
        bucketId: bucket.id,
        response: item.response
      });
    }
  }

  function handleCollect(dropTargetMonitor: DropTargetMonitor) {
    return {
      isOver: !!dropTargetMonitor.isOver()
    };
  }

  const [{ isOver }, dropRef] = useDrop({
    accept: ["card", "list-item"],
    drop: handleDrop,
    collect: handleCollect
  });

  const selected = bucket.id === selectedBucket.id;

  const allPosts = bucket.posts.filter((p) => p?.post != null);
  const bucketResponses = allPosts.slice(0, 4).map((p) => p.post);
  const remainingPosts = allPosts.length - 4;

  const isBucketOwner = bucket.userId === appCtx.user?.id;
  const isSharingBucket = bucket.shares.length > 0;
  const isSharedBucket = bucket.userId !== appCtx.user?.id;

  return (
    <BucketWrapper
      onClick={handleClick}
      ref={dropRef}
      selected={selected}
      isOver={isOver}
      style={
        {
          ["--color"]: bucket.color,
          ["--color-100"]: hexToRgba(bucket.color, "0.1"),
          ["--color-300"]: hexToRgba(bucket.color, "0.3"),
          ["--color-500"]: hexToRgba(bucket.color, "0.5"),
          filter: isWaiting ? "grayscale(0.9)" : "none",
          cursor: isWaiting ? "progress" : "pointer"
        } as BucketCSSProperties
      }
    >
      <BucketInformation>
        <Stack
          type="row"
          gap={0.5}
          style={{
            gridTemplateColumns: "minmax(0, 1fr)",
            gridAutoColumns: "auto"
          }}
        >
          <ResponseBucketWrapper
            style={{ background: bucket?.color || "#635ec0" }}
          >
            <ResponseBucketText>{bucket.name}</ResponseBucketText>
          </ResponseBucketWrapper>

          <BucketMenu bucket={bucket} isSharedBucket={isSharedBucket} />
        </Stack>

        <Stack
          type="row"
          gap={0.5}
          style={{
            gridTemplateColumns: "minmax(0, 1fr)",
            gridAutoColumns: "auto"
          }}
        >
          <BucketCards
            style={{
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            {bucketResponses.map((response, i) => (
              <>
                {response && (
                  <MiniCardWrapper key={response.id}>
                    <MiniCard>
                      <MiniAvatar
                        src={response?.social_profile_picture}
                        onError={handleImageError}
                      />
                    </MiniCard>
                  </MiniCardWrapper>
                )}
              </>
            ))}
            {remainingPosts > 0 && (
              <Tooltip message={`${bucket.posts.length} total`}>
                <MiniCard>+{remainingPosts}</MiniCard>
              </Tooltip>
            )}
          </BucketCards>

          {notificationBucketIds.includes(bucket.id) && <Badge>New</Badge>}

          {isSharedBucket && isWaiting === false && (
            <Tooltip
              message={`Shared by ${bucket.shares.map((share) => {
                return share.user.name;
              })}`}
              offset={8}
            >
              <AvatarRoot>
                <AvatarFallback style={{ background: "white" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 24">
                    <path
                      fill="var(--color)"
                      d="M16.413.879c-2.842 0-5.146 2.69-5.146 6.008 0 2.3 1.109 4.3 2.736 5.308l-1.887.875-5.022 2.329c-.485.243-.727.654-.727 1.236v5.53c.04.692.455 1.336 1.128 1.347h17.864c.768-.067 1.156-.685 1.164-1.347v-5.53c0-.582-.242-.994-.727-1.236l-4.84-2.329-2.011-.953c1.56-1.032 2.614-2.987 2.614-5.23 0-3.318-2.304-6.008-5.146-6.008zm-8.3 2.113c-1.223.046-2.192.575-2.929 1.418a5.499 5.499 0 00-1.219 3.42c.05 1.773.844 3.452 2.256 4.33L.582 14.78C.194 14.926 0 15.266 0 15.8v4.438c.03.588.336 1.083.909 1.091h3.748v-4.694c.062-1.255.651-2.27 1.71-2.765l3.747-1.782c.291-.17.57-.4.837-.692a8.43 8.43 0 01-.764-7.75c-.647-.396-1.375-.65-2.074-.654zm16.736 0c-.8.016-1.539.31-2.146.727.969 2.534.701 5.39-.728 7.604.315.364.643.643.983.837l3.602 1.71c1.097.602 1.663 1.626 1.674 2.765v4.694h3.856c.634-.054.904-.56.91-1.091v-4.439c0-.485-.194-.824-.582-1.019l-5.567-2.657c1.444-1.064 2.169-2.66 2.183-4.293-.039-1.293-.433-2.493-1.22-3.42-.82-.89-1.84-1.409-2.965-1.418z"
                    ></path>
                  </svg>
                </AvatarFallback>
              </AvatarRoot>
            </Tooltip>
          )}

          {isBucketOwner && isSharingBucket && isWaiting === false && (
            <Tooltip
              message={
                <UserTooltipList>
                  {bucket.shares.map((share) => {
                    return (
                      <UserTooltipListItem key={share.id}>
                        Shared by {share.user.name}
                      </UserTooltipListItem>
                    );
                  })}
                </UserTooltipList>
              }
            >
              <AvatarRoot>
                <AvatarFallback>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 24">
                    <path d="M16.413.879c-2.842 0-5.146 2.69-5.146 6.008 0 2.3 1.109 4.3 2.736 5.308l-1.887.875-5.022 2.329c-.485.243-.727.654-.727 1.236v5.53c.04.692.455 1.336 1.128 1.347h17.864c.768-.067 1.156-.685 1.164-1.347v-5.53c0-.582-.242-.994-.727-1.236l-4.84-2.329-2.011-.953c1.56-1.032 2.614-2.987 2.614-5.23 0-3.318-2.304-6.008-5.146-6.008zm-8.3 2.113c-1.223.046-2.192.575-2.929 1.418a5.499 5.499 0 00-1.219 3.42c.05 1.773.844 3.452 2.256 4.33L.582 14.78C.194 14.926 0 15.266 0 15.8v4.438c.03.588.336 1.083.909 1.091h3.748v-4.694c.062-1.255.651-2.27 1.71-2.765l3.747-1.782c.291-.17.57-.4.837-.692a8.43 8.43 0 01-.764-7.75c-.647-.396-1.375-.65-2.074-.654zm16.736 0c-.8.016-1.539.31-2.146.727.969 2.534.701 5.39-.728 7.604.315.364.643.643.983.837l3.602 1.71c1.097.602 1.663 1.626 1.674 2.765v4.694h3.856c.634-.054.904-.56.91-1.091v-4.439c0-.485-.194-.824-.582-1.019l-5.567-2.657c1.444-1.064 2.169-2.66 2.183-4.293-.039-1.293-.433-2.493-1.22-3.42-.82-.89-1.84-1.409-2.965-1.418z"></path>
                  </svg>
                </AvatarFallback>
              </AvatarRoot>
            </Tooltip>
          )}

          {isLoading && (
            <Stack type="row" gap={0.5}>
              <span style={{ fontSize: "0.8rem" }}>Creating...</span>
              <IconContainer
                animate={{
                  rotate: 360,
                  transition: { duration: 2, repeat: Infinity, ease: "linear" }
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M18.364 5.636L16.95 7.05A7 7 0 1019 12h2a9 9 0 11-2.636-6.364z"></path>
                </svg>
              </IconContainer>
            </Stack>
          )}

          {isDeleting && (
            <Stack type="row" gap={0.5}>
              <span style={{ fontSize: "0.8rem" }}>Deleting...</span>
              <IconContainer
                animate={{
                  rotate: 360,
                  transition: { duration: 2, repeat: Infinity, ease: "linear" }
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M18.364 5.636L16.95 7.05A7 7 0 1019 12h2a9 9 0 11-2.636-6.364z"></path>
                </svg>
              </IconContainer>
            </Stack>
          )}

          {isRemoving && (
            <Stack type="row" gap={0.5}>
              <span style={{ fontSize: "0.8rem" }}>Removing...</span>
              <IconContainer
                animate={{
                  rotate: 360,
                  transition: { duration: 2, repeat: Infinity, ease: "linear" }
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M18.364 5.636L16.95 7.05A7 7 0 1019 12h2a9 9 0 11-2.636-6.364z"></path>
                </svg>
              </IconContainer>
            </Stack>
          )}
        </Stack>
      </BucketInformation>

      {/* <AvatarContainer></AvatarContainer> */}
    </BucketWrapper>
  );
}

export default BucketItem;

const BucketWrapper = styled.div<{ selected: boolean; isOver: boolean }>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  gap: 0.75rem;
  padding: 1rem;
  cursor: pointer;
  background: none;
  /* transition: background 50ms ease-in-out; */

  &:hover {
    background: var(--color-100);
  }

  ${(p) =>
    p.selected &&
    `
    background: var(--color-500);

    &:hover {
      background: var(--color-500);
    }
  `}

  ${(p) =>
    p.isOver &&
    `
    background: var(--color-100);
  `}
`;

const BucketInformation = styled.div`
  width: 100%;
  display: flex;
  gap: 0.25rem;
  flex-direction: column;
`;

const BucketCards = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  user-select: none;
`;

const MiniCardWrapper = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const MiniCard = styled.div`
  background: white;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: 0px 20px 40px rgba(30, 14, 57, 0.06);
  box-shadow: var(--shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-size: 0.65rem;
`;

const MiniAvatar = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const MiniCardText = styled(motion.div)`
  color: black;
  font-size: 14.5px;
  letter-spacing: 0.5px;
  margin: 0 1rem;
`;

const ResponseBucketWrapper = styled.div`
  width: min-content;
  border-radius: 9999px;
  padding: 0.25rem 1rem;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResponseBucketText = styled.span`
  color: white;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.75px;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
`;

const IconContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 2px;
  fill: var(--color);

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const AvatarContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
`;

const AvatarRoot = styled(AvatarPrimitive.Root)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  overflow: hidden;
  /* user-select: none; */
  width: 26px;
  height: 26px;
  border-radius: 100%;
  box-shadow: var(--shadow);
`;

const AvatarFallback = styled(AvatarPrimitive.Fallback)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color);
  color: white;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 1px;
  font-weight: bold;
  padding: 5px;

  svg {
    width: 100%;
    height: auto;
    display: block;
    fill: white;
  }
`;

const UserTooltipList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
`;

const UserTooltipListItem = styled.li`
  list-style: none;
`;

const Badge = styled.span`
  background: #635ec0;
  padding: 0.25rem 1rem;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  color: white;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.75px;
`;
