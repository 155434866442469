import * as React from "react";
import { Helmet } from "react-helmet";

import {
  AbstractImage,
  QuestionsContainer,
  Question,
  QuestionOffsetBackground
} from "./styled";
import abstractImageSource from "../../assets/images/abstract.png";

interface IErrorBox {
  errorMessage: string;
}

const ErrorBox: React.StatelessComponent<IErrorBox> = ({ errorMessage }) => (
  <QuestionsContainer>
    <Helmet>
      <title>Survey</title>
    </Helmet>
    <AbstractImage src={abstractImageSource} />

    <Question active style={{ textAlign: "center" }}>
      <QuestionOffsetBackground />
      <h3>Whoops!</h3>
      <p>{errorMessage}</p>
    </Question>
  </QuestionsContainer>
);

export default ErrorBox;
