import React, { useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import {
  useOverlays,
  useOnClickOutside,
  useBuckets,
  useOptions,
  useToasts,
  useGrid
} from "hooks";
import { useBucketsQuery } from "hooks/useRQResponses";
import axios from "axios";
import useApp from "context/AppContext";
import { v4 as uuid } from "uuid";
import { SidebarWrapper } from "./styled";
import { Stack } from "components/Facelift/shared";
import Tooltip from "components/Facelift/Tooltip";
import Logo from "components/Facelift/Logo";
import { Bucket as BucketIconSVG } from "@styled-icons/entypo/Bucket";
import { Slider as FilterIconSVG } from "@styled-icons/boxicons-regular/Slider";
import { Settings as SettingsIconSVG } from "@styled-icons/material/Settings";
import { Data } from "@styled-icons/boxicons-regular/Data";
import { BarChart } from "react-feather";
import { SwapHorizontalCircle as SwapSVG } from "@styled-icons/material";
import AuthStorage from "../../../utils/AuthStorage";
import { IOrganization } from "../../../types/organization";

function Sidebar() {
  const { appCtx, sentimentFeatureEnabled, emotionFeatureEnabled } = useApp();
  const showBucketContainer = useOverlays((s) => s.showBucketContainer);
  const hideBucketContainer = useOverlays((s) => s.hideBucketContainer);
  const updateSidebarContent = useOverlays((s) => s.updateSidebarContent);
  const showingSidebarContent = useOverlays((s) => s.showingSidebarContent);
  const sidebarContentType = useOverlays((s) => s.sidebarContentType);
  const addToast = useToasts((s) => s.addToast);
  const displayStyle = useGrid((s) => s.displayStyle);
  const updateDisplayStyle = useGrid((s) => s.updateDisplayStyle);
  const notificationBucketIds = useBuckets((s) => s.notificationBucketIds);
  const { numberOfFiltersApplied } = useOptions();

  const [showingSettings, setShowingSettings] = useState(false);
  const [showingOrgSwitcher, setShowingOrgSwitcher] = useState(false);
  const settingsRef = useRef(null);
  const orgSwitcherRef = useRef(null);
  const [inputText, setInputText] = useState("");

  // const localStorageData = localStorage.getItem(AUTH.SYMPLER);
  // const { token, orgId } = JSON.parse(localStorageData ?? "");
  const { token, orgId } = AuthStorage.getToken();

  const closeSettings = useCallback(() => {
    setShowingSettings(false);
  }, []);

  const closeOrgSwitcher = useCallback(() => {
    setShowingOrgSwitcher(false);
  }, []);

  const { data } = useBucketsQuery();
  const buckets = data ?? [];
  const bucketAmount = buckets.length;

  useOnClickOutside(settingsRef, closeSettings);
  useOnClickOutside(orgSwitcherRef, closeOrgSwitcher);

  async function handleSubmit(event?: React.FormEvent<HTMLFormElement>) {
    if (event) event.preventDefault();
    const invitedUsers = inputText.replace(/\s/g, '').split(',')
    for (const user of invitedUsers) {
      const data = {
        email: user
      };
  
      try {
        let request = await axios.post("invite", data);
        console.log('request config headers', request.config.headers)
        addToast({
          id: uuid(),
          type: "add",
          message: <ToastText>Successfully invited {user}.</ToastText>
        });
        setInputText("");
        setShowingSettings(false);
      } catch (err) {
        addToast({
          id: uuid(),
          type: "remove",
          message: (
            <ToastText>
              Unable to send invitation. Please try again later.
            </ToastText>
          )
        });
        setInputText("");
        setShowingSettings(false);
      }
    }
  }

  function handleClick(event: React.MouseEvent<HTMLDivElement>) {
    handleSubmit();
    setShowingSettings(false);
  }

  async function clickedOrg(org: IOrganization) {
    const orgLink = `https://${org.subdomain}.sympler.co`
    window.location.href = orgLink;
    // await localStorage.setItem(
    //   AUTH_LOCAL_STORAGE.SYMPLER,
    //   JSON.stringify({ token, orgId: org.id })
    // );
    // appCtx.refreshApp();
  }

  const showingGraphSection =
    sentimentFeatureEnabled === true || emotionFeatureEnabled === true;

  return (
    <SidebarWrapper>
      <Tooltip message={"Home"} side="right">
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Tooltip>

      <IconsContainer>
        <Tooltip
          message={
            `Buckets menu` +
            `${notificationBucketIds.length > 0 ? notificationBucketIds.length + " new shared" : ""}`
          }
          side="right"
        >
          <IconContainer
            selected={
              showingSidebarContent === true && sidebarContentType === "buckets"
            }
            onClick={() => {
              updateSidebarContent("buckets");

              if (showingSidebarContent === false) {
                showBucketContainer();
              }

              if (
                showingSidebarContent === true &&
                sidebarContentType === "buckets"
              ) {
                hideBucketContainer();
              }
            }}
          >
            <BucketIcon />
            <BucketAmountNote>{bucketAmount}</BucketAmountNote>
            {notificationBucketIds.length > 0 && <Pulse />}
          </IconContainer>
        </Tooltip>

        <Tooltip message={"Filters menu"} side="right">
          <IconContainer
            selected={
              showingSidebarContent === true && sidebarContentType === "filters"
            }
            onClick={() => {
              updateSidebarContent("filters");

              if (showingSidebarContent === false) {
                showBucketContainer();
              }

              if (
                showingSidebarContent === true &&
                sidebarContentType === "filters"
              ) {
                hideBucketContainer();
              }
            }}
          >
            <FilterIcon />
            {numberOfFiltersApplied > 0 && (
              <BucketAmountNote>{numberOfFiltersApplied}</BucketAmountNote>
            )}
          </IconContainer>
        </Tooltip>

        {showingGraphSection && (
          <Tooltip message={"Data view"} side="right">
            <IconContainer
              selected={displayStyle === "graph"}
              style={{ marginBottom: "1rem" }}
              onClick={() => {
                if (displayStyle === "graph") {
                  updateDisplayStyle("list");
                } else {
                  updateDisplayStyle("graph");
                }
              }}
            >
              <GraphIcon />
            </IconContainer>
          </Tooltip>
        )}
      </IconsContainer>

      <ExtraContainer>
        {appCtx.user?.organizations && appCtx.user?.organizations.length > 1 && (
          <IconContainer
            selected={showingOrgSwitcher === true}
            onClick={() => setShowingOrgSwitcher((c) => !c)}
            ref={orgSwitcherRef}
          >
            <Tooltip message={"Switch organization"} side="right">
              <OrgSwapIcon />
            </Tooltip>
            <AnimatePresence>
              {showingOrgSwitcher && (
                <SettingsContainer
                  key="settings"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Stack
                    type="column"
                    gap={1}
                    style={{
                      maxHeight: "80vh",
                      overflow: "auto"
                    }}
                  >
                    <Stack type="row" gap={0}>
                      <Header>Organizations</Header>
                    </Stack>
                    {(appCtx.user?.organizations ?? []).filter(f => f.approved === 1).sort((a,b) => a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1).map((org, i) => (
                      <Stack
                        type="row"
                        key={org.id}
                        gap={0}
                        style={{
                          justifyContent: "space-between",
                          padding: "0rem 0.5rem",
                          backgroundColor:
                            org.id == Number(orgId) ? "#635ec0" : "transparent",
                          borderRadius: 4,
                          cursor: "pointer"
                        }}
                        onClick={() => clickedOrg(org)}
                      >
                        <OrgLineItem
                          style={{
                            color: org.id == Number(orgId) ? "white" : "black",
                            paddingTop: org.id == Number(orgId) ? 5 : 0,
                            paddingBottom: org.id == Number(orgId) ? 5 : 0
                          }}
                        >
                          <Stack
                            type="row"
                            gap={0}
                            style={{
                              justifyContent: "space-between",
                              padding: "0rem 0.5rem"
                            }}
                          >
                            {org.icon && org.icon.length > 0 ? (
                              <OrgLogo src={org.icon} />
                            ) : (
                              <OrgDot color={org.color ?? "#635ec0"} />
                            )}
                            <p>{org.name}</p>
                          </Stack>
                        </OrgLineItem>
                      </Stack>
                    ))}
                  </Stack>
                </SettingsContainer>
              )}
            </AnimatePresence>
          </IconContainer>
        )}

        <IconContainer
          selected={showingSettings === true}
          onClick={() => setShowingSettings((c) => !c)}
          ref={settingsRef}
        >
          <Tooltip message={"Settings"} side="right">
            <SettingsIcon />
          </Tooltip>
          <AnimatePresence>
            {showingSettings && (
              <SettingsContainer
                key="settings"
                onClick={(e) => e.stopPropagation()}
              >
                <Stack type="column" gap={1}>
                  <Stack
                    type="row"
                    gap={0}
                    style={{
                      justifyContent: "space-between",
                      padding: "0rem 0.5rem"
                    }}
                  >
                    <Header>Options</Header>
                    <LogoutButton onClick={appCtx.logout}>
                      <LogoutText>Logout</LogoutText>
                    </LogoutButton>
                  </Stack>

                  {/* <Division /> */}

                  <Setting>
                    <Stack type="column" gap={0.25}>
                      <InviteLabel htmlFor="email">Invite users</InviteLabel>
                      <InviteText>Comma separated for multiple addresses</InviteText>
                      <InviteForm onSubmit={handleSubmit}>
                        <InviteInput
                          type="email"
                          id="email"
                          value={inputText}
                          placeholder="email@example.com"
                          required
                          size={30}
                          onChange={(e) => setInputText(e.target.value)}
                        />

                        <SendButton onClick={handleClick}><SendButtonText>Send</SendButtonText></SendButton>
                      </InviteForm>
                    </Stack>
                  </Setting>
                </Stack>
              </SettingsContainer>
            )}
          </AnimatePresence>
        </IconContainer>
      </ExtraContainer>
      {/* <Tips /> */}
    </SidebarWrapper>
  );
}

export default Sidebar;

const defaultTransition = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.3 }
};

const OrgLogo = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-bottom: 2px;
  border-radius: 10px;
`;

const OrgDot = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-right: 15px;
  border-radius: 5px;
  background-color: ${(p) => p.color};
  display: inline-block;
`;

const LogoContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OrgLineItem = styled.div`
  width: 100%;
`;

const IconsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
`;

const IconContainer = styled(motion.div)<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  color: ${(p) => (p.selected ? "white" : "#6a7489")};
  background: ${(p) => (p.selected ? "#635ec0" : "none")};
  box-shadow: none;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
  appearance: none !important;
`;

const BucketAmountNote = styled(motion.div)`
  width: 20px;
  height: 20px;
  position: absolute;
  font-size: 13.5px;
  font-weight: bold;
  bottom: -2.5px;
  right: -2.5px;
  border-radius: 50%;
  background: #ff69b4;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const IconBanner = styled(motion.div).attrs({
  ...defaultTransition
})`
  top: 50%;
  right: 0%;
  position: absolute;
  transform: translate(calc(100% + 1rem), -50%);
  width: auto;
  color: white;
  padding: 0.5rem 1rem;
  background: #635ec0;
  white-space: nowrap;
  border-radius: 5px;
  letter-spacing: 1px;
  font-size: 0.7rem;
  display: flex;
  align-items: center;

  ::before {
    position: absolute;
    content: "";
    width: 16px;
    height: 16px;
    background: inherit;
    top: 50%;
    left: -4px;
    transform: translateY(-50%) rotate(-45deg);
  }

  span:nth-of-type(1) {
    margin-left: 1.25rem;
  }
`;

const KeypressButton = styled(motion.span)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  letter-spacing: 0.5px;
  border-radius: 2.5px;
  background: hotpink;
  color: white;
  padding: 2px 4px;
  margin: 0 6px;
`;

const BucketIcon = styled(BucketIconSVG)`
  width: 100%;
  height: 100%;
  display: block;
  color: inherit;
  position: relative;
  z-index: 1;
`;

const FilterIcon = styled(FilterIconSVG)`
  width: 100%;
  height: 100%;
  display: block;
  color: inherit;
`;

const GraphIcon = styled(BarChart)`
  width: 100%;
  height: 100%;
  display: block;
  color: inherit;
`;

const SettingsIcon = styled(SettingsIconSVG)`
  width: 100%;
  height: 100%;
  display: block;
  color: inherit;
`;

const OrgSwapIcon = styled(SwapSVG)`
  width: 100%;
  height: 100%;
  display: block;
  color: inherit;
`;

const SettingsContainer = styled(motion.div).attrs({
  ...defaultTransition
})`
  right: 0%;
  bottom: -0.5rem;
  position: absolute;
  transform: translateX(calc(100% + 2rem));
  width: auto;
  padding: 1.5rem 1rem;
  background: white;
  border-radius: 5px;
  cursor: auto;
  box-shadow: var(--shadow);
`;

const Setting = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0;
  color: inherit;
  transition: color 200ms ease-in-out;
`;

const ExtraContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const BucketText = styled(motion.div)`
  font-size: 14px;
  letter-spacing: 0.5px;
  color: cornflowerblue;
  margin-left: 1rem;
`;

export const BucketAmount = styled(motion.div)`
  width: 20px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  padding: 0.25rem;
  border-radius: 50%;
  color: white;
  background: cornflowerblue;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.div`
  font-size: 0.6rem;
  letter-spacing: 0.5px;
  color: #6a7489;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 0.5rem;
`;

const InviteForm = styled.form`
  position: relative;
  display: inline-flex;
`;

const InviteInput = styled.input`
  background: white;
  font-size: 15.6px;
  letter-spacing: 0.25px;
  color: hsl(0 0% 5%);
  border: 1px solid #cdddf9;
  padding: 0.75rem 1.5rem 0.75rem 0.5rem;
  border-radius: 5px;
  appearance: none;
  width: 375px;
  position: relative;

  :focus {
    border: 1px solid cornflowerblue;
  }
`;

const InviteLabel = styled.label`
  padding-left: 0.75rem;
  margin: 0;
  font-size: 12px;
  letter-spacing: 1px;
  color: hsl(0 0% 55%);
  text-transform: uppercase;
`;

const InviteText = styled.p`
  padding-left: 0.75rem;
  margin: 0;
  font-size: 10px;
  letter-spacing: 1px;
  color: hsl(0 0% 55%);
`;

  // position: absolute;
  // top: 50%;
  // right: 2.5%;
  // transform: translateY(-50%);
const SendButton = styled.div`
  color: white;
  margin-left: 15px;
  font-size: 14px;
  letter-spacing: 0.5px;
  background: #635ec0;
  border-radius: 5px;
  padding: 0.25rem 1rem;
  cursor: pointer;
`;

const SendButtonText = styled.div`
  transform: translateY(25%);`

const LogoutButton = styled.div`
  display: flex;
  align-items: center;
  background: #635ec0;
  border-radius: 5px;
  padding: 0.25rem 0.75rem;
`;

const LogoutText = styled.p`
  color: white;
  font-size: 14px;
  letter-spacing: 0.5px;
`;

const Header = styled.div`
  padding: 0 0.5rem;
  color: #635ec0;
  font-size: 19.5px;
  letter-spacing: 0.5px;
`;

const ToastText = styled.div`
  font-size: 14px;
  color: white;
  letter-spacing: 0.75px;
`;

const Pulse = styled.div`
  border: 2px solid #635ec0;
  position: absolute;
  border-radius: 100%;
  height: 24px;
  width: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: pulse 1.5s ease-in-out infinite;
  z-index: 0;

  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(0.5, 0.5);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translate(-50%, -50%) scale(2.5, 2.5);
      opacity: 0;
    }
  }
`;
