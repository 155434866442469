import * as React from "react";
import styled from "styled-components";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Helmet } from "react-helmet";

import ToolBar from "../../components/Toolbar";
import SnapchatLoginForm from "../../components/SnapchatLoginForm";
import InstagramLoginForm from "../../components/InstagramLoginForm";

import "react-tabs/style/react-tabs.css";
import "../../assets/styles/settings.scss";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SettingsContainer = styled.div`
  background-color: ${(props) => props.theme.pallet.white};
  padding: 60px 40px;
  margin: 0 40px;
`;

const Settings: React.SFC<{}> = () => (
  <Container>
    <Helmet>
      <title>Settings</title>
    </Helmet>
    <ToolBar title="Settings" />

    <SettingsContainer>
      <Tabs>
        <TabList>
          <Tab>Instagram</Tab>
          <Tab>Snapchat</Tab>
        </TabList>

        <TabPanel>
          <InstagramLoginForm />
        </TabPanel>
        <TabPanel>
          <SnapchatLoginForm />
        </TabPanel>
      </Tabs>
    </SettingsContainer>
  </Container>
);

export default Settings;
