import React from "react";
import styled from "styled-components";
import {
  useResponses,
  useGrid,
  useBuckets,
  useQuestions,
  useOptions
} from "hooks";
import { Bucket, Response, BucketPost } from "types/facelift";
import { addAdditionalFields } from "utils/facelift";

function ResponseBucket({
  bucket,
  response
}: {
  bucket: Bucket;
  response: Response;
}) {
  const removeResponseFromBucket = useBuckets(
    (s) => s.removeResponseFromBucket
  );
  const updateSearchTerm = useGrid((s) => s.updateSearchTerm);
  const updateSearching = useGrid((s) => s.updateSearching);
  const updateSelectedUser = useGrid((s) => s.updateSelectedUser);
  const updateCurrentQuestion = useQuestions((s) => s.updateCurrentQuestion);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);
  const updateExpandedIndex = useGrid((s) => s.updateExpandedIndex);
  const updateGridResponses = useResponses((s) => s.updateGridResponses);
  const { reset } = useOptions();

  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (bucket !== undefined) {
      if (event.shiftKey === true) {
        event.stopPropagation();
        removeResponseFromBucket(response.id, bucket.id);
        return;
      }

      updateSearching(true);
      updateSelectedBucket(bucket, true);
      updateSearchTerm("");
      updateSelectedUser(null);
      updateCurrentQuestion(null);
      updateExpandedIndex(null);
      reset();

      const gridResponses = bucket.posts.map(
        (bucketPost: BucketPost) => bucketPost.post
      );

      updateGridResponses(addAdditionalFields(gridResponses));
    }
  }

  return (
    <ResponseBucketWrapper
      style={{ background: bucket?.color || "red" }}
      onClick={handleClick}
    >
      <ResponseBucketText>{bucket!.name ?? "Unknown"}</ResponseBucketText>
    </ResponseBucketWrapper>
  );
}

export default ResponseBucket;

const ResponseBucketWrapper = styled.div`
  width: auto;
  flex-shrink: 0;
  border-radius: 9999px;
  padding: 0.25rem 1rem;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResponseBucketText = styled.div`
  color: white;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.75px;
  /* font-weight: 300; */
  font-family: "Inter", sans-serif;
  text-overflow: ellipsis;
  max-width: 200px;
  overflow: hidden;
`;
