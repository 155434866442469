import create from "zustand";

interface FiltersProps {
  onScreen: string;
  updateOnScreen: (displayName: string) => void;
}

const [useFilters, useFiltersAPI] = create<FiltersProps>((set) => ({
  onScreen: "",
  updateOnScreen: (displayName: string) => set({ onScreen: displayName })
}));

export default useFilters;

export { useFiltersAPI };
