import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Stack } from "components/Facelift/shared";
import Tooltip from "components/Facelift/Tooltip";
import { useGrid } from "hooks";
import { LayoutMasonry as GridSVG } from "@styled-icons/remix-fill/LayoutMasonry";
import { ViewDay } from "@styled-icons/material-rounded/ViewDay";

function GridLayoutControls() {
  const displayStyle = useGrid((s) => s.displayStyle);
  const updateDisplayStyle = useGrid((s) => s.updateDisplayStyle);
  const updateSearching = useGrid((s) => s.updateSearching);

  function handleGridClick(displayStyle: "compact" | "list") {
    updateSearching(true);

    updateDisplayStyle(displayStyle);

    setTimeout(() => {
      updateSearching(false);
    }, 150);
  }
  return (
    <Stack type="row" gap={0.5}>
      <Tooltip message="List View">
        <SettingIconContainer
          onClick={() => {
            if (displayStyle === "list") return;

            handleGridClick("list");
          }}
          selected={displayStyle === "list"}
        >
          <ListIcon />
        </SettingIconContainer>
      </Tooltip>
      <Tooltip message="Grid View">
        <SettingIconContainer
          onClick={() => {
            if (displayStyle === "compact") return;

            handleGridClick("compact");
          }}
          selected={displayStyle === "compact"}
        >
          <GridIcon />
        </SettingIconContainer>
      </Tooltip>
    </Stack>
  );
}

export default GridLayoutControls;

const SettingIconContainer = styled(motion.div)<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 6px;
  color: white;
  position: relative;

  ${(p) =>
    p.selected &&
    `
    color: white;
    ::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background: white;
      bottom: 0;
      left: 0;
    }
  `}
`;

const GridIcon = styled(GridSVG)`
  width: 100%;
  height: 100%;
  display: block;
  color: inherit;
`;

const ListIcon = styled(ViewDay)`
  width: 100%;
  height: 100%;
  display: block;
  color: inherit;
`;
