import * as React from "react";
import qs from "query-string";
import axios from "axios";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import Loading from "../../components/QuickLook/Loading";
import LoadingError from "../../components/QuickLook/LoadingError";
import AutoComplete from "../../components/QuickLook/AutoComplete";
import Posts from "../../components/QuickLook/Posts";
import NoData from "../../components/QuickLook/NoData";
import SlideoutToggles from "../../components/QuickLook/SlideoutToggles";
import HashtagsSlideout from "../../components/QuickLook/HashtagsSlideout";
import SavedImagesSlideout from "../../components/QuickLook/SavedImagesSlideout";
import { IPostSearch } from "../../types/quickLook";
import fakeLogo from "../../assets/images/hexasharp.svg";
import CompanyInfo from "../../components/QuickLook/CompanyInfo";
import {
  QuickLookWrapper,
  HeaderSection,
  Header,
  Container,
  MainSection
} from "./styled";

interface IQuickLoookState {
  pageLoading: boolean;
  pageLoadingError: string;
  dataLoading: boolean;
  dataType: string;
  data: any;
  hashTagsSlideoutOpen: boolean;
  savedImageSlideoutOpen: boolean;
}

class QuickLook extends React.Component<{}, IQuickLoookState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      pageLoading: true,
      pageLoadingError: "",
      dataLoading: false,
      dataType: "",
      // data: ['nufdsll'],
      data: null,
      hashTagsSlideoutOpen: false,
      savedImageSlideoutOpen: false
    };

    this.toggleHashtagsSlideout = this.toggleHashtagsSlideout.bind(this);
    this.toggleSavedImagesSlideout = this.toggleSavedImagesSlideout.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    // Get the "code" query string param from the URL
    // and parse it to get what we need.
    const parsed = qs.parse(window.location.search);

    if (Object.keys(parsed).length === 0) {
      // No query string found?
      return this.setState({
        pageLoading: false,
        pageLoadingError: "It looks like the page you requested doesn't exist."
      });
    } else if (!parsed.code) {
      // Does the "code" param exist?
      return this.setState({
        pageLoading: false,
        pageLoadingError: "It looks like the page you requested doesn't exist."
      });
    }

    // It's possible for the param to be a string or array.
    // Take the param and ensure it's in string form.
    // eslint-disable-next-line
    const code: string = Array.isArray(parsed.code)
      ? parsed!.code[0]
      : parsed!.code;

    // Mimic api call to verify code for now.
    setTimeout(() => {
      this.setState({
        pageLoading: false,
        pageLoadingError: ""
      });
    }, 1);
  }

  toggleHashtagsSlideout() {
    this.setState({
      hashTagsSlideoutOpen: !this.state.hashTagsSlideoutOpen
    });
  }

  toggleSavedImagesSlideout() {
    this.setState({
      savedImageSlideoutOpen: !this.state.savedImageSlideoutOpen
    });
  }

  async fetchData({ url, queryParam, queryParamValue }: IPostSearch) {
    this.setState({
      dataLoading: true,
      data: []
    });
    const igAccount = `ig_account=3`;
    const query = `${queryParam}=${queryParamValue}`;

    try {
      const response = await axios.get(`ig/${url}?${igAccount}&${query}`);
      console.log("fetchData result: ", response);
      this.setState({
        dataLoading: false,
        data: response,
        dataType: url
      });
    } catch (error) {
      toast.error((error as any).errorMessage);
    }
  }

  render() {
    const {
      pageLoading,
      pageLoadingError,
      dataLoading,
      dataType,
      data,
      hashTagsSlideoutOpen,
      savedImageSlideoutOpen
    } = this.state;

    if (pageLoading) {
      return <Loading text="Loading QuickLook data" />;
    }

    if (pageLoadingError.length > 0) {
      return <LoadingError text={pageLoadingError} />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>QuickLook</title>
        </Helmet>
        <QuickLookWrapper>
          <HeaderSection>
            <Container>
              <Header>
                <CompanyInfo
                  logo={fakeLogo}
                  name="Company Name"
                  brief="Brief Name"
                />
                <AutoComplete setPostSearch={this.fetchData} />
              </Header>
            </Container>
          </HeaderSection>
          <MainSection>
            {data ? (
              <Posts data={data} dataType={dataType} />
            ) : (
              <NoData dataLoading={dataLoading} />
            )}
          </MainSection>

          <SlideoutToggles
            toggleHastagsSlideout={this.toggleHashtagsSlideout}
            toggleSavedImagesSlideout={this.toggleSavedImagesSlideout}
          />
          <HashtagsSlideout
            toggleHashtagsSlideout={this.toggleHashtagsSlideout}
            isOpen={hashTagsSlideoutOpen}
            hashtags={[]}
          />
          <SavedImagesSlideout
            toggle={this.toggleSavedImagesSlideout}
            isOpen={savedImageSlideoutOpen}
          />
        </QuickLookWrapper>
      </React.Fragment>
    );
  }
}

export default QuickLook;
