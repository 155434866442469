import "./wdyr";

import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App";

// import 'bootstrap/dist/css/bootstrap.css';
// import './assets/styles/app.scss';

ReactDOM.render(<App />, document.getElementById("root"));
