import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import * as Dialog from "@radix-ui/react-dialog";
import { useUsers, useBucketsMutation } from "hooks/useRQResponses";
import ShareCheckbox from "./ShareCheckbox";
import { Stack } from "components/Facelift/shared";
import Tooltip from "components/Facelift/Tooltip";
import {
  AlertStack,
  Overlay,
  Grouping,
  Title,
  Description,
  SaveAction,
  CloseIconContainer
} from "./styled";
import { Bucket } from "types/facelift";
import { User } from "types/user";
import { IInvitation } from "types/invitation";
import axios, { AxiosResponse } from "axios";

interface BucketEditModalProps {
  bucket: Bucket;
  isShareDialogOpen: boolean;
  setShareDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  invitedUsers?: IInvitation[]
}

function BucketShareModal({
  bucket,
  isShareDialogOpen,
  setShareDialogOpen,
  invitedUsers
}: BucketEditModalProps) {
  const { data: users } = useUsers();
  
  const { shareBucket } = useBucketsMutation();

  const [selectedIds, setSelectedIds] = useState<Array<number>>(
    bucket.shares.map((share) => share.userId)
  );
  const [searchInput, setSearchInput] = useState("");
  const [inviteUsersString, setInviteUsersString] = useState("");
  // const [invitedUsers, setInvitedUsers] = useState<User[]>()
  
  function passesFilter(user: User) {
    const term = searchInput.toLowerCase();
    
    if (user.name !== 'Invited User') {
      let check = false;
      for (let i=0;i<user.organizations.length;i++) {
        if (user.organizations[i].name == user.organization) {
          check = true
        }
      }
      if (!check) {
        return false;
      }
    }


    if (term.length === 0) return true;
    if (user.name.toLowerCase().includes(term)) return true;
    if (user.email.toLowerCase().includes(term)) return true;
    if (user.organization.toLowerCase().includes(term)) return true;

    return false;
  }

  function invitePassesFilter(user: IInvitation) {
    const term = searchInput.toLowerCase();
    
    // if (user.name !== 'Invited User') {
    //   let check = false;
    //   for (let i=0;i<user.organizations.length;i++) {
    //     if (user.organizations[i].name == user.organization.name) {
    //       check = true
    //     }
    //   }
    //   if (!check) {
    //     return false;
    //   }
    // }


    if (term.length === 0) return true;
    if (user.name !== undefined && user.name.toLowerCase().includes(term)) return true;
    if (user.email.toLowerCase().includes(term)) return true;
    if (user.organizations !== undefined && user?.organizations[0].name.toLowerCase().includes(term)) return true;

    return false;
  }

  const filteredUsers = (users ?? []).filter(passesFilter);
  // const filteredInvites = (invitedUsers ?? []).filter(i => !(users ?? []).map(u => u.email).includes(i.email)).filter(invitePassesFilter)
  const filteredInvites = (invitedUsers ?? []).filter(i => !(users ?? []).map(u => u.email).includes(i.email)).filter(invitePassesFilter)
  const [selectedInviteUsers, setSelectedInviteUsers] = useState<Array<string>>([])
  // .filter(f => (f && f.bucket_shares) ? f.bucket_shares.includes(bucket.id.toString()) : [])
  // useEffect(() => {
  //   if (invitedUsers && selectedInviteUsers.length === 0) {
  //     setSelectedInviteUsers(invitedUsers.filter(
  //       f => (f && f.bucket_shares) ? f.bucket_shares.includes(bucket.id.toString()) : [])
  //       .map(u => u.email))
  //   }
  // }, [invitedUsers])

    console.log('filteredInvites', filteredInvites)
  console.log('selecedInviteUsers', selectedInviteUsers)

  function handleAll() {
    if (users === undefined && invitedUsers === undefined) return;
    if (users !== undefined) {
      if ((selectedIds.length === filteredUsers.length && selectedInviteUsers.length === filteredInvites.length) || (selectedIds.length === filteredUsers.length && filteredInvites === undefined)) {
        setSelectedIds([]);
      } else {
        setSelectedIds(filteredUsers.map((user: User) => user.id));
      }
    }
    if (filteredInvites !== undefined) {
      if ((selectedInviteUsers.length === filteredInvites.length && selectedIds.length === filteredUsers.length) || ( selectedInviteUsers.length === filteredInvites.length && users === undefined)) {
        setSelectedInviteUsers([]);
      } else {
        setSelectedInviteUsers(filteredInvites.map((user: IInvitation) => user.email));
      }
      // let selectedEmails = ""
      // for (const user of filteredInvites) {
      //     if (!inviteUsersString.includes(user.email)) {
      //       const iu = inviteUsersString === "" ? user.email : inviteUsersString + ', ' + user.email
      //       selectedEmails += selectedEmails === "" ? iu : ', ' + user.email
      //     }
      // }
      // let selectedCount = 0
      // for (const user of filteredInvites) {
      //   if (inviteUsersString.includes(user.email)) {
      //     selectedCount++
      //   }
      // }
      // if (selectedCount > 0) {
      //   let i = inviteUsersString
      //   for (const user of filteredInvites) {
      //     let commaFront = i.replace(', ' + user.email, '')
      //     let commaBack = commaFront.replace(user.email + ', ', '')
      //     let email = commaBack.replace(user.email, '')
      //     i = email
      //   }
      //   selectedEmails = i
      //   setInviteUsersString(selectedEmails)
      // } else {
      //   setInviteUsersString(selectedEmails)
      // }
      
    }
  }

  function handleClick(userId: number) {
    setSelectedIds((currentIds) => {
      if (currentIds.includes(userId)) {
        return currentIds.filter((id) => id !== userId);
      } else {
        return [...currentIds, userId];
      }
    });
  }

  function handleInviteClick(userEmail: string) {
    setSelectedInviteUsers((currentUsers) => {
      if (currentUsers.includes(userEmail)) {
        return currentUsers.filter((email) => email !== userEmail);
      } else {
        return [...currentUsers, userEmail];
      }
    });
  }

  function handleOpenChange(open: boolean) {
    setShareDialogOpen(open);
  }

  function handleShare() {
    let iUsers = inviteUsersString
    if (iUsers !== "" && iUsers !== undefined){
      for (const email of selectedInviteUsers) {
          iUsers += (', ' + email)
      }
    } else {
      for (const email of selectedInviteUsers) {
        if (selectedInviteUsers.indexOf(email) === 0) {
          iUsers += email
        } else {
          iUsers += (', ' + email)
        }
      }
    }
    shareBucket.mutate({
      bucket_id: bucket.id,
      user_ids: selectedIds,
      releaseOwnerLocked: false,
      invite_users: iUsers,
    });
  }



  // useEffect(() => {
  //   async function invites() {
  //     const response: AxiosResponse<any> = await axios.get<any>(
  //       `invitations`
  //     );
  //     let userData =[];
  //     for (const user of response.data.response) {
  //       console.log('invite orgId', user.organization_id)
  //       let data: User = {
  //         id: user.id,
  //         email: user.email,
  //         name: "Invited User",
  //         organization: user.organization_id.toString(),
  //         organizations: [],
  //         bucket_shares: user.bucket_shares
  //       }

  //       userData.push(data)
  //     }
  //     setInvitedUsers(userData)
  //   }
  //   if (invitedUsers === undefined || invitedUsers.length <= 0) {
  //     invites()
  //   }
  // },[invitedUsers])

  return (
    <DialogRoot open={isShareDialogOpen} onOpenChange={handleOpenChange}>
      <DialogOverlay asChild>
        <Overlay />
      </DialogOverlay>
      <DialogContent asChild>
        <AlertStack type="column" gap={1.5}>
          <Grouping>
            <DialogTitle asChild>
              <Title>Share Bucket</Title>
            </DialogTitle>
            <DialogDescription asChild>
              <Description>
                Select users from your organization whom you would like to share
                this bucket with.
              </Description>
            </DialogDescription>
          </Grouping>

          {users !== undefined && (
            <SearchInputContainer>
              <SearchInputIconContainer>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 17A8 8 0 109 1a8 8 0 000 16zM19 19l-4.35-4.35"
                  ></path>
                </svg>
              </SearchInputIconContainer>
              <SearchInput
                type="text"
                value={searchInput}
                placeholder="Search names, emails, organizations..."
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </SearchInputContainer>
          )}

          {users === undefined ? (
            <ShareListContainer type="column" gap={0}>
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            </ShareListContainer>
          ) : filteredUsers.length === 0 ? (
            <ShareListContainer type="column" gap={0}>
              <SpinnerContainer>
                <span>No users found.</span>
              </SpinnerContainer>
            </ShareListContainer>
          ) : (
            <ShareListContainer type="column" gap={0}>
              <ShareListHeader type="row" gap={1} onClick={handleAll}>
                <ShareCheckbox
                  checked={
                    users === undefined
                      ? false
                      : (selectedIds.length === filteredUsers.length && (filteredInvites.length > 0 ? selectedInviteUsers.length === filteredInvites.length : true))
                      ? true
                      : (selectedIds.length > 0 || selectedInviteUsers.length > 0)
                      ? "indeterminate"
                      : false
                  }
                />
                <HeaderText>User</HeaderText>
                <HeaderText>Organization</HeaderText>
              </ShareListHeader>
              {filteredUsers.map((user) => {
                return (
                  <ShareListItem
                    type="row"
                    gap={1}
                    key={user.id}
                    onClick={() => {
                      handleClick(user.id)
                    }}
                  >
                    <ShareCheckbox checked={selectedIds.includes(user.id)} />
                    <Stack type="column" gap={0}>
                      <SharePrimary>{user.name}</SharePrimary>
                      <ShareSecondary>{user.email}</ShareSecondary>
                    </Stack>
                    <ShareSecondary>{user.organizations[0]?.name}</ShareSecondary>
                  </ShareListItem>
                );
              })}
              {filteredInvites && filteredInvites.map((user) => {
                return (
                  <ShareListItem
                    type="row"
                    gap={1}
                    key={user.id}
                    onClick={() => {
                      handleInviteClick(user.email)
                    }}
                    // onClick={() => {
                    //     if (!inviteUsersString.includes(user.email)) {
                    //       setInviteUsersString(inviteUsersString.length > 0 ? (inviteUsersString + ', ' + user.email) : user.email )
                    //     } else {
                    //       let commaFront = inviteUsersString.replace(', ' + user.email, '')
                    //       let commaBack = commaFront.replace(user.email + ', ', '')
                    //       let email = commaBack.replace(user.email, '')
                    //       setInviteUsersString(email)
                    //     }
                    //   }
                    // }
                  >
                    <ShareCheckbox checked={selectedInviteUsers.includes(user.email)} />
                    <Stack type="column" gap={0}>
                      <SharePrimary>{user.name}</SharePrimary>
                      <ShareSecondary>{user.email}</ShareSecondary>
                    </Stack>
                    <ShareSecondary>{user.organization ?  user.organization.name : ''}</ShareSecondary>
                  </ShareListItem>
                );
              })}
            </ShareListContainer>
          )}

          {(
            <SearchInputContainer>
              <SearchInput
                type="text"
                value={inviteUsersString}
                placeholder="Add users (email), comma separated"
                onChange={(e) => setInviteUsersString(e.target.value)}
              />
            </SearchInputContainer>
          )}

          {(users !== undefined || inviteUsersString !== undefined) && (
            <Stack
              type="row"
              gap={1}
              style={{ justifyContent: "space-between" }}
            >
              <Stack type="row" gap={0.5}>
                {/* {console.log('selectedIds', selectedIds)}
                {console.log('inviteUsersString', inviteUsersString)}
                {console.log('selectedInviteUsers', selectedInviteUsers)} */}
                <Description>
                  Sharing with {selectedIds.length + inviteUsersString.replaceAll(' ', '').split(',').filter(e => e.match(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/)).length + selectedInviteUsers.length} user
                  {(selectedIds.length === 1 || inviteUsersString.replaceAll(' ', '').split(',').filter(e => e.match(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/)).length === 1 || selectedInviteUsers.length === 1)
                  ? "" : "s"}
                </Description>
                <Tooltip
                  message={
                    <UserTooltipList>
                      {(users as unknown as User[] ?? [])
                          .filter((user) => selectedIds.includes(user.id))
                          .map((user) => {
                            return (
                              <UserTooltipListItem key={user.id}>
                                {user.name}
                              </UserTooltipListItem>
                            );
                      })}
                      {inviteUsersString.replaceAll(' ', '').split(',').filter(e => e.match(/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/)).map((email, key) => {
                           return (
                            <UserTooltipListItem key={key}>
                              {email}
                            </UserTooltipListItem>
                          );
                      })}
                      {(filteredInvites as unknown as User[] ?? [])
                          .filter((user) => selectedInviteUsers.includes(user.email))
                          .map((user) => {
                            return (
                              <UserTooltipListItem key={user.id}>
                                {user.email}
                              </UserTooltipListItem>
                            );
                      })}
                      {/* {(invitedUsers as unknown as User[] ?? [])
                          .filter((user) => inviteUsersString.includes(user.email))
                          .map((user) => {
                            return (
                              <UserTooltipListItem key={user.id}>
                                {user.email}
                              </UserTooltipListItem>
                            );
                      })} */}
                    </UserTooltipList>
                  }
                >
                  <InfoIconContainer>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0zm1 15H9V9h2v6zm0-8H9V5h2v2z"></path>
                    </svg>
                  </InfoIconContainer>
                </Tooltip>
              </Stack>
              <DialogClose asChild>
                <SaveAction type="button" onClick={handleShare}>
                  Share
                </SaveAction>
              </DialogClose>
            </Stack>
          )}

          <DialogClose asChild>
            <CloseIconContainer type="button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15">
                <path
                  fillRule="evenodd"
                  d="M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </CloseIconContainer>
          </DialogClose>
        </AlertStack>
      </DialogContent>
    </DialogRoot>
  );
}

export default BucketShareModal;

const DialogRoot = styled(Dialog.Root)``;

const DialogTrigger = styled(Dialog.Trigger)``;

const DialogOverlay = styled(Dialog.Overlay)``;

const DialogContent = styled(Dialog.Content)``;

const DialogTitle = styled(Dialog.Title)``;

const DialogDescription = styled(Dialog.Description)``;

const DialogClose = styled(Dialog.Close)``;

const ShareListContainer = styled(Stack)`
  border-radius: 12px;
  border: 2px solid #efe9f3;
  grid-template-columns: minmax(0, 1fr);
  grid-auto-rows: min-content;
  max-height: 300px;
  overflow-y: auto;
  position: relative;
  align-items: flex-start;
`;

const ShareListHeader = styled(Stack)`
  background: #efe9f3;
  padding: 0.75rem 0.75rem;
  grid-template-columns: auto 1.5fr 1fr;
`;

const HeaderText = styled.span`
  /* color: #635ec0; */
  font-size: 0.85rem;
`;

const ShareListItem = styled(Stack)`
  padding: 0.5rem 0.75rem;
  grid-template-columns: auto 1.5fr 1fr;
  cursor: pointer;

  :hover {
    background: #efe9f3;
  }
`;

const SharePrimary = styled.span`
  color: #000000;
  font-size: 0.85rem;
  letter-spacing: 0.2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ShareSecondary = styled.span`
  color: #979797;
  font-size: 0.8rem;
  letter-spacing: 0.2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const InfoIconContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  fill: #635ec0;

  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const UserTooltipList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
`;

const UserTooltipListItem = styled.li`
  list-style: none;
`;

const spin = keyframes`
  	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
`;

const Spinner = styled.div`
  display: inline-block;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: #635ec0;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spin} 1.2s linear infinite;
`;

const SearchInputContainer = styled.div`
  position: relative;
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: -0.5rem;
  width: 100%;
  padding: 5px 10px;
  border: 2px solid #efe9f3;
  border-radius: 4px;
  background: white;
`;

const SearchInput = styled.input`
  all: unset;
  flex: 1;
  color: #635ec0;
  font-size: 14.5px;
  min-width: 20ch;
`;

const SearchInputIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;

  svg {
    width: 100%;
    height: 100%;
    display: block;
    stroke: #635ec0;
    fill: white;
  }
`;
