import create from "zustand";
import { useGridAPI } from "./useGrid";
import { useSnapshotHistoryAPI } from "./useSnapshotHistory";
import { range } from "utils/facelift";

interface QuestionList {
  [key: number]: string;
}

interface QuestionsProps {
  currentQuestion: number | null;
  updateCurrentQuestion: (
    questionNo: number | null,
    shouldCapture?: boolean
  ) => void;
  questionList: QuestionList;
  updateQuestionList: (newQuestionList: QuestionList) => void;
}

const [useQuestions, useQuestionsAPI] = create<QuestionsProps>((set) => ({
  currentQuestion: null,
  updateCurrentQuestion: (
    newQuestionNo: number | null,
    shouldCapture: boolean = false
  ) => {
    if (shouldCapture) useSnapshotHistoryAPI.getState().captureSnapshot();

    set({ currentQuestion: newQuestionNo });

    setTimeout(() => {
      useGridAPI.setState({ isSearching: false, scrollTop: 0 });
    }, range(1, 50));
  },
  questionList: {} as QuestionList,
  updateQuestionList: (newQuestionList: QuestionList) =>
    set({
      questionList: newQuestionList
    })
}));

export { useQuestionsAPI };

export default useQuestions;
