import * as React from "react";
import Draggable, { DraggableData } from "react-draggable";
import { XCircle, Edit } from "react-feather";

import { POST_OBJECT_TYPES } from "../../../containers/PostBuilder";
import {
  DraggableTextContainer,
  DeleteButton,
  EditButton,
  Resizable,
  TextObject
} from "./styled";

interface IPostObjectProps {
  className?: string;
  index: number;
  type: POST_OBJECT_TYPES;
  imgs: any[];
  width: number;
  height: number;
  x: number;
  y: number;
  setPostObjectPosition: (
    type: any,
    index: number,
    x: number,
    y: number
  ) => void;
  setPostObjectDimensions: (
    type: any,
    index: number,
    width: number,
    height: number
  ) => void;
  editObject: (index: number) => void;
  deleteNode: (index: number) => void;
}

class PostObject extends React.Component<IPostObjectProps, {}> {
  onStopDrag(e: any, data: DraggableData) {
    this.props.setPostObjectPosition(
      this.props.type,
      this.props.index,
      data.lastX,
      data.lastY
    );
  }

  onResizeStop(e: any, data: any) {
    this.props.setPostObjectDimensions(
      this.props.type,
      this.props.index,
      data.size.width,
      data.size.height
    );
  }

  render() {
    const { index, imgs, width, height, editObject, deleteNode } = this.props;

    return (
      <Draggable
        defaultPosition={{ x: this.props.x, y: this.props.y }}
        bounds="parent"
        handle=".draggable-handle"
        onStop={(e: any, data: DraggableData) => this.onStopDrag(e, data)}
      >
        <DraggableTextContainer width={this.props.width}>
          <div className="top-bottom" />
          <div className="left-right" />
          <div className="draggable-handle" data-html2canvas-ignore />
          <DeleteButton
            className="delete-button"
            onClick={() => deleteNode(index)}
            data-html2canvas-ignore
          >
            <XCircle />
          </DeleteButton>
          <EditButton
            className="edit-button"
            onClick={() => editObject(index)}
            data-html2canvas-ignore
          >
            <Edit />
          </EditButton>
          <Resizable
            width={width}
            height={height}
            maxConstraints={[356, 635]}
            onResizeStop={(e: any, data: any) => this.onResizeStop(e, data)}
          >
            <TextObject>{imgs.map((img) => img)}</TextObject>
          </Resizable>
        </DraggableTextContainer>
      </Draggable>
    );
  }
}

export default PostObject;
