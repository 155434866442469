import { useResponsesAPI } from "hooks/useResponses";
import { useBucketsAPI } from "hooks/useBuckets";
import axios from "axios";
import {
  RawResponse,
  TypeFormattedResponse,
  Response,
  Bucket,
  BucketPost,
  FormatCountsType,
  FormatType,
  SearchCount,
  FilterOptionType,
  BreakdownType,
  GraphDataType,
  GraphDataCollectionType,
  Sentiment,
  SentimentType,
  EmotionsType
} from "types/facelift";
import { IUser, User } from "types/user";
import { FilterConfigWithResults } from "utils/Site";
import * as sizes from "utils/facelift/gridMeasurements";
import defaultFilterCategories from "utils/defaultFilterCategories";
import { ExtractFieldsOfType } from "types/magicMirror";
import DefaultProfilePicBlack from "assets/images/default-profile-black.png";
import DefaultProfilePicWhite from "assets/images/default-profile-white.png";

export function handleImageError(
  event: React.SyntheticEvent<HTMLImageElement, Event>,
  hasImage: boolean = false
) {
  if (hasImage) {
    (event.target as HTMLImageElement).src = DefaultProfilePicWhite;
  } else {
    (event.target as HTMLImageElement).src = DefaultProfilePicBlack;
  }
}

export const emojiEmotions: { [key in EmotionsType]: string } = {
  joy: `😀`,
  sadness: `😥`,
  anger: `😠`,
  disgust: `🤮`,
  surprise: `😲`,
  fear: `😱`
};

export function getOptionsFromCategory(
  column: ExtractFieldsOfType<Response, string>,
  responses: Response[]
) {
  const data = responses.reduce(
    (
      acc: {
        [key: string]: {
          name: string;
          color: string;
          amounts: { [key: string]: number };
        };
      },
      curr: Response
    ) => {
      const columnOption = curr[column];
      acc[columnOption] = acc[columnOption] || {
        name: columnOption,
        amounts: {}
      };
      acc[columnOption].amounts[curr.question_no] =
        acc[columnOption].amounts[curr.question_no] || 0;
      acc[columnOption].amounts[curr.question_no] += 1;

      return acc;
    },
    {}
  );

  return Object.values(data).map((item, position) => {
    return {
      ...item,
      color: getSegmentedBarColor(position)
    };
  });
}

export function getGraphData(
  responses: Response[],
  options: Array<FilterConfigWithResults>
) {
  return responses.reduce((graphData, curr) => {
    const currentQuestionNumber = curr.question_no;

    if (graphData[currentQuestionNumber] === undefined) {
      const breakdown = {} as BreakdownType;

      for (const option of options) {
        breakdown[option.displayName] = {};
        const name =
          curr[option.columnName as ExtractFieldsOfType<Response, string>];

        breakdown[option.displayName][name] = {
          name,
          count: 1,
          percentage: 100
        };
      }

      graphData[currentQuestionNumber] = {
        text: curr.question,
        totalCount: 1,
        breakdown
      };
    } else {
      const breakdown = graphData[currentQuestionNumber].breakdown;

      for (const option of options) {
        const optionName = breakdown[option.displayName];
        const name =
          curr[option.columnName as ExtractFieldsOfType<Response, string>];
        const defaultGroup = {
          name: name,
          count: 0,
          percentage: 100
        };

        optionName[name] = optionName[name] || defaultGroup;
      }

      graphData[currentQuestionNumber].totalCount += 1;

      const totalCount = responses.filter(
        (response) => response.question_no === currentQuestionNumber
      ).length;

      for (const option of options) {
        const optionName = breakdown[option.displayName];
        const name =
          curr[option.columnName as ExtractFieldsOfType<Response, string>];
        const newPercentage = Math.round(
          (optionName[name].count / totalCount) * 100
        );

        optionName[name].count += 1;
        optionName[name].percentage = Math.round(
          (breakdown[option.displayName][
            curr[option.columnName as ExtractFieldsOfType<Response, string>]
          ].count /
            totalCount) *
            100
        );
      }
    }

    return graphData;
  }, {} as GraphDataCollectionType);
}

export function getPercentage(
  responses: Response[],
  value: "pos" | "neg" | "neu"
) {
  if (responses.length === 0) return 33;

  return Math.round(
    (responses
      .map((response) => Number(response[value]))
      .reduce((a, c) => a + c, 0) /
      responses.length) *
      100
  );
}

export function getEmotionalPercentage(
  responses: Response[],
  emotion: EmotionsType,
  section: "low" | "medium" | "high"
) {
  if (responses.length === 0) return 33;

  return Math.round(
    (responses
      .map((response) => Number(response[emotion]) * 100)
      .filter((emotionalValue) => {
        if (section === "low") {
          return emotionalValue <= 0.33;
        } else if (section === "medium") {
          return 0.33 < emotionalValue && emotionalValue <= 0.66;
        } else if (section === "high") {
          return 0.66 < emotionalValue;
        }
      }).length /
      responses.length) *
      100
  );
}

export function capitalizeFirstLetter(text?: string) {
  if (text && text.length > 0) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return "";
}

export function getResponsesFromBucket(bucket: Bucket) {
  return bucket.posts
    .filter((bucket) => bucket.post != null)
    .map((bucket: BucketPost) => bucket.post);
}

export function addResponseFormat(
  responses: RawResponse[]
): TypeFormattedResponse[] {
  return responses.map((response) => {
    const format = getFormatFromResponse(response);

    return {
      ...response,
      format
    };
  });
}

export function addAdditionalFields(responses: RawResponse[]): Response[] {
  return responses
    .map((response) => {
      const format = getFormatFromResponse(response);

      return {
        ...response,
        media_url: response.media_url?.includes("text-cards")
          ? ""
          : response.media_url,
        format
      };
    })
    .sort((a, z) => (a.text.length > z.text.length ? -1 : 1));
}

export function getBucketsForResponse(id: number, buckets: Bucket[] = []) {
  return buckets.filter((bucket) => {
    const responses = getResponsesFromBucket(bucket);

    return responses.map((response) => response.id).includes(id);
  });
}

function getFormatFromResponse(response: RawResponse): FormatType {
  let format = "text";

  const containsMedia =
    response.media_url?.length > 0 &&
    response.media_url?.includes("text-cards") === false;

  if (containsMedia) {
    const fileType = response.media_url!.split(".").pop() as string;

    if (fileType === "mp4") {
      format = "video";
    } else if (fileType === "gif") {
      format = "gif";
    } else if (fileType === "mp3" || fileType === "m4a") {
      format = "audio";
    } else if (fileType === "png" || fileType === "jpg") {
      format = "image";
    }
  }

  return format as FormatType;
}

export function removeUnwantedResponses(responses: RawResponse[]) {
  return responses.filter((response) => {
    if (response.username.length === 0) {
      return false;
    }

    if (response.uid.length === 0) {
      return false;
    }

    if (response.text.includes("https")) {
      return false;
    }

    if (response.subtopic.length === 0) {
      return false;
    }

    if (isNaN(Number(response.question_no))) {
      return false;
    }

    // if (response.text.length === 0) {
    //   return false;
    // }

    if (response.deleted === "1") {
      return false;
    }

    return true;
  });
}

export function truncateText(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }

  return str.slice(0, num) + "...";
}

export function buildSearchCount(value: string) {
  const responses = useResponsesAPI.getState().responses;

  return Object.entries(
    responses.reduce((searchCount: SearchCount, curr: Response) => {
      const words = [
        ...new Set(
          curr.text.split(" ").map((word) =>
            word
              .toLowerCase()
              // eslint-disable-next-line
              .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
              .replace(/\s{2,}/g, " ")
          )
        )
      ];
      const lowercasedValue = value.toLowerCase();

      for (let word of words) {
        if (word.startsWith(lowercasedValue)) {
          searchCount[word] = searchCount[word] || 0;
          searchCount[word]++;
        }
      }

      return searchCount;
    }, {})
  )
    .sort((a, b) => (a[1] > b[1] ? -1 : 1))
    .slice(0, 10);
}

export function range(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export async function copyTextToClipboard(text: string, callback: () => void) {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
    callback();
  } else {
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.setAttribute("value", text);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    callback();
  }
}

export async function toDataURL(url: string) {
  console.log({ url });
  return axios
    .get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
      responseType: "blob"
    })
    .then((blob) => {
      console.log({ blob });
      return URL.createObjectURL(blob.data);
    })
    .catch((err) => {
      console.log({ err });
      return "";
    });
}

export function mergeRefs(...refs: any) {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
}

export function removeDups<T extends { id: number }>(items: T[]): T[] {
  return items.reduce((acc: T[], current: T) => {
    const x = acc.find((item) => item.id === current.id);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
}

export function getTopRightElement(
  elements: { top: number; left: number; index: number }[]
) {
  const farthestRight = Math.max(...elements.map((element) => element.left));
  const farthestRightElements = elements.filter(
    (element) => element.left === farthestRight
  );
  const topMost = Math.min(
    ...farthestRightElements.map((element) => element.top)
  );
  const topRightElement = farthestRightElements.find(
    (element) => element.top === topMost
  );

  return topRightElement?.index ?? null;
}

export function getMediaType(mediaUrl: string) {
  const url = mediaUrl.toLocaleLowerCase();
  const isJpeg = url.endsWith(".jpg") || url.endsWith('.webp');
  const isPng = url.endsWith(".png") && !url.includes("text-cards");
  const isGif = url.endsWith(".gif");
  const isVideo = url.endsWith(".mp4");
  const isAudio = url.endsWith(".mp3") || url.endsWith(".m4a") || url.endsWith('.aac');

  const hasMedia =
    (url.length > 0 &&
    url.includes("text-cards") === false &&
    url.endsWith(".mp3") === false &&
    url.endsWith(".m4a") === false) ||
    (isJpeg || isPng || isGif || isVideo || isAudio);

  const hasImage =
    url.length > 0 &&
    url.endsWith(".mp4") === false &&
    url.includes("text-cards") === false;

  const hasVideo =
    url.length > 0 &&
    (url.endsWith(".mp4") === true || url.endsWith(".mov") === true);

  const hasAudio =
    url.length > 0 &&
    (url.endsWith(".mp3") === true || url.endsWith(".m4a") === true || url.endsWith(".aac") === true);

  const hasYouTubeUrl =
    url.length > 0 && (url.includes("youtube.com") || url.includes("youtu.be"));
  
  const hasTikTokUrl =
    url.length > 0 && url.includes("tiktok.com");
  
  const hasInstagramUrl =
    url.length > 0 && url.includes("instagram.com");

  return {
    isAudio,
    isGif,
    isJpeg,
    isPng,
    isVideo,
    hasMedia,
    hasImage,
    hasVideo,
    hasAudio,
    hasYouTubeUrl,
    hasTikTokUrl,
    hasInstagramUrl
  };
}

interface CardAnimationProps {
  cardIndex: number;
  expandedIndex: number | null;
}

export function getCardAnimation({
  cardIndex,
  expandedIndex
}: CardAnimationProps) {
  if (expandedIndex === null) return 0;

  let height = 0;

  const isBelow =
    expandedIndex !== null &&
    cardIndex > expandedIndex &&
    (cardIndex - expandedIndex) % sizes.COLUMN_COUNT === 0;

  const isRight =
    expandedIndex !== null &&
    cardIndex > expandedIndex &&
    (cardIndex - expandedIndex) % sizes.COLUMN_COUNT === 1;

  const isLeft =
    expandedIndex !== null &&
    (expandedIndex - 1 === cardIndex ||
      (cardIndex > expandedIndex &&
        (cardIndex - expandedIndex) % sizes.COLUMN_COUNT ===
          sizes.COLUMN_COUNT - 1));

  const aCardIsExpanded = expandedIndex !== null;

  const expandedCardIsFarRightColumn =
    expandedIndex !== null &&
    expandedIndex % sizes.COLUMN_COUNT === sizes.COLUMN_COUNT - 1;

  if (aCardIsExpanded) {
    if (expandedCardIsFarRightColumn) {
      if (isLeft) {
        height = sizes.TWO_CARDS_DOWN;
      } else if (isBelow) {
        height = sizes.ONE_CARD_DOWN;
      }
    } else {
      if (isRight) {
        height = sizes.TWO_CARDS_DOWN;
      } else if (isBelow) {
        height = sizes.ONE_CARD_DOWN;
      }
    }
  }

  return height;
}

export function inWords(number: number) {
  const numbers: { [key: number]: string } = {
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
    10: "ten",
    11: "eleven",
    12: "twelve",
    13: "thirteen",
    14: "fourteen",
    15: "fifthteen",
    16: "sixteen",
    17: "seventeen",
    18: "eighteen",
    19: "nineteen",
    20: "twenty",
    21: "twenty one",
    22: "twenty two",
    23: "twenty three",
    24: "twenty four",
    25: "twenty five",
    26: "twenty six",
    27: "twenty seven",
    28: "twenty eight",
    29: "twenty nine",
    30: "thirty"
  };

  return numbers[number] ?? "";
}

export function subtractHoursFromDate(date: Date, hours: number): Date {
  return new Date(new Date(date).setHours(date.getHours() - hours));
}

export function getMediaInfo(url: string) {
  const isJpeg = url.endsWith(".jpg") || url.endsWith('.webp');
  const isPng = url.endsWith(".png") && !url.includes("text-cards");
  const isGif = url.endsWith(".gif");
  const isVideo = url.endsWith(".mp4");
  const isAudio = url.endsWith(".mp3") || url.endsWith(".m4a") || url.endsWith(".aac");
  const hasMedia = isJpeg || isPng || isGif || isVideo || isAudio;

  return { isJpeg, isPng, isGif, isVideo, isAudio, hasMedia };
}

export function hexToLuma(color: string) {
  const hex = color.replace(/#/, "");
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  return [0.299 * r, 0.587 * g, 0.114 * b].reduce((a, b) => a + b) / 255;
}

// export const bucketColors = ["#6495ed", "#f9813a", "#CD4374", "#587850"];
export const bucketColors = [
  "#EA5F47",
  "#EE5289",
  "#BC59C4",
  "#7C6AD9",
  "#5576E4",
  "#1CB1CB"
];

export function getBucketColor() {
  return bucketColors[Math.floor(Math.random() * bucketColors.length)];
}

const segmentedBarColors = [
  "#9FBEFD",
  "#FD7B96",
  "#FBC17D",
  "#F391D6",
  "#FDFF86"
];

export function getSegmentedBarColor(position: number) {
  return segmentedBarColors[position];
}

export function getPrimaryFormatFromResponses(responses: Array<Response>) {
  const formatCountsObject = responses.reduce(
    (formatCountsObject, curr: Response) => {
      formatCountsObject[curr.format] = formatCountsObject[curr.format] ?? 0;
      formatCountsObject[curr.format]++;
      return formatCountsObject;
    },
    {} as FormatCountsType
  );

  const primaryFormatGroup = Object.entries(formatCountsObject).reduce(
    (primaryFormatGroup, currentFormatGroup) => {
      const [currentFormat, currentFormatCount] = currentFormatGroup as [
        FormatType,
        number
      ];
      const [primaryFormat, primaryFormatCount] = primaryFormatGroup as [
        FormatType,
        number
      ];

      if (currentFormatCount > primaryFormatCount) {
        return currentFormatGroup;
      }

      return primaryFormatGroup;
    },
    ["", 0]
  );

  const [primaryFormat] = primaryFormatGroup as [FormatType, number];

  return primaryFormat;
}

export function getEmotionalPillValue(value: string) {
  switch (value) {
    case "anger":
      return "#F3B2C5";
    // return "#CD4374";
    case "fear":
      return "#C29FFA";
    // return "#AD6DE1";
    case "disgust":
      return "#D1FDE3";
    // return "#A6E872";
    case "joy":
      return "#FCFC9D";
    // return "#F8E438";
    case "sadness":
      return "#9DDDFD";
    // return "#40A2C4";
    case "surprise":
      return "#FED09A";
    // return "#EB916B";
    default:
      return "#635ec0";
  }
}

export function getSentimentColorByValue(value: string) {
  switch (value) {
    case "negative":
      return "#CD4374";
    case "neutral":
      return "#dcdcdb";
    case "positive":
      return "#46D7AB";
    default:
      return "#635ec0";
  }
}

export function getEmotionalValue(value: string) {
  return Number(value);
}

export function getDominantSentiment(sentiments: Array<Sentiment>) {
  let dominant = "";
  let max = Number.NEGATIVE_INFINITY;

  for (const sentiment of sentiments) {
    const { percentage, name } = sentiment;

    if (percentage > max) {
      dominant = name;
      max = percentage;
    }
  }

  return dominant as SentimentType;
}

export function getEmotionalAverage(
  responses: Array<Response>,
  emotion: EmotionsType
) {
  const totalEmotionalValue = responses.reduce(
    (totalEmotionalValue, response) =>
      totalEmotionalValue + Number(response[emotion]),
    0
  );
  const totalResponses = responses.length;

  const average = (totalEmotionalValue / totalResponses) * 100;

  return average;
}

export function getEmotionsFromResponse(
  response: Response,
  emotions: Array<EmotionsType>
) {
  const userEmotions: Array<EmotionsType> = [];

  emotions.forEach((emotion) => {
    const emotionValue = getEmotionalValue(response[emotion]);
    if (emotionValue > 0.01) {
      userEmotions.push(emotion);
    }
  });

  return userEmotions;
}

export function getQuestionsFromResponses(responses: Array<Response>) {
  return responses.reduce<Record<string, string>>((questions, response) => {
    if (questions.hasOwnProperty(response.question_no) === false) {
      questions[response.question_no] = response.question;
    }

    return questions;
  }, {});
}

export function sliceItems<T>(items: Array<T>, limit: number) {
  if (items.length <= limit + 1) {
    return { visible: items, hidden: [] };
  }
  return {
    visible: items.slice(0, limit),
    hidden: items.slice(limit)
  };
}

export function createTemporaryBucket(
  data: {
    ids: Array<number>;
    responses: Array<Response>;
    name: string;
    description: string;
    color: string;
  },
  userId: number
) {
  return {
    id: Math.random(),
    name: data.name,
    description: data.description,
    color: data.color,
    isLoading: true,
    shares: [],
    ownerLock: 0,
    ownerLockExpiration: null,
    study_id: 1,
    userId,
    created_at: new Date()
      .toISOString()
      .replace("T", " ")
      .replace("Z", ""),
    updated_at: new Date()
      .toISOString()
      .replace("T", " ")
      .replace("Z", ""),
    posts: (data.responses.map((response) => ({
      id: Math.random(),
      bucketId: Math.random(),
      post: response
    })) as unknown) as Array<BucketPost>
  } as Bucket;
}

// https://stackoverflow.com/a/55258958
export function stringToRegex(string: string) {
  // Main regex
  const main = string.match(/\/(.+)\/.*/)![1];

  // Regex options
  const options = string.match(/\/.+\/(.*)/)![1];

  // Compiled regex
  return new RegExp(main, options);
}

export function formatUsers(
  users: Array<IUser>,
  currentOrganization: string
): Array<User> {
  return users
    .map((user) => {
      return {
        id: user.id,
        name: user.name,
        email: user.email,
        organization: currentOrganization,
        organizations: user.organizations
      };
    })
    .sort((a, b) => {
      if (a.organization.toLowerCase() === b.organization.toLowerCase()) {
        if (a.name < b.name) {
          return -1;
        } else {
          return 1;
        }
      } else if (a.organization < b.organization) {
        return -1;
      } else {
        return 1;
      }
    });
}

export const getOrganizationFromURI = () => {
  const subdomain = window.location.host.includes("localhost")
    ? "localhost"
    : window.location.host.split(".")[1]
    ? window.location.host.split(".")[0]
    : "";
  return subdomain;
};
