import styled from "styled-components";

export const StyledColumn = styled.div`
  position: absolute;
  font-weight: bold;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const TableWrapper = styled.div`
  .ReactVirtualized__Table {
  }

  .ReactVirtualized__Table__row {
    display: flex;
    border-bottom: 1px solid rgb(220, 220, 220);
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;

    &.deleted {
      background: rgba(231, 76, 60, 0.15);
    }

    textarea {
      width: 100%;
      height: 100%;
    }

    .edit-buttons {
      margin-top: 25px;
      padding-top: 15px;
      padding-bottom: 15px;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 6px;
    }

    .edit-icon {
      cursor: pointer;

      &:hover {
        color: #a3057f;
      }
    }

    .undo-icon {
      cursor: pointer;
      margin-top: 10px;

      &:hover {
        color: #a3057f;
      }
    }

    .delete-icon {
      cursor: pointer;
      margin-top: 10px;

      &:hover {
        color: #e74c3c;
      }
    }
  }

  .ReactVirtualized__Table__headerRow {
    display: flex;
    background: rgb(239, 239, 239);
    border-bottom: 1px solid rgb(220, 220, 220);
    cursor: pointer;
    position: relative;
    z-index: 10;
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    background: whitesmoke;
    height: 100%;
    overflow-y: scroll;
  }

  .ReactVirtualized__Table__headerColumn {
    display: inline-block;
    position: relative;
  }

  .headerSortDown:after,
  .headerSortUp:after {
    content: " ";
    position: absolute;
    right: -17px;
    border: 7px solid transparent;
  }
  .headerSortDown:after {
    top: 7px;
    border-top-color: silver;
  }
  .headerSortUp:after {
    bottom: 7px;
    border-bottom-color: silver;
  }

  .cell {
    cursor: default !important;
    text-align: center;
    padding: 5px;
  }

  .media-column {
    position: relative;
    width: 100%;
    height: 100%;

    img,
    video {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      object-fit: contain;
      padding: 10px;
    }
  }

  .profile-picture {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      object-fit: contain;
      padding: 10px;
    }

    .default-profile-picture {
      object-fit: contain;
    }
  }
`;

export const GroupActionsContainer = styled.div`
  width: 100%;
  height: 80px;
  background: white;
  position: fixed;
  bottom: -80px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  animation-name: slideup;
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @-webkit-keyframes slideup {
    0% {
      bottom: -80px;
    }
    100% {
      bottom: 0px;
    }
  }

  @keyframes slideup {
    0% {
      bottom: -80px;
    }
    100% {
      bottom: 0px;
    }
  }

  div {
    display: inline-block;
    text-align: center;
    width: 90px;
  }

  svg {
    display: block;
    margin: 0 auto;
  }

  label {
    display: block;
    margin-top: 10px;
  }

  .close {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
      color: #a3057f;
    }
  }

  .edit-icon {
    cursor: pointer;

    &:hover {
      color: #a3057f;
    }
  }

  .delete-icon {
    cursor: pointer;

    &:hover {
      color: #e74c3c;
    }
  }
`;

export const TopicInputContainer = styled.div`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 20px;
  width: 100%;

  label,
  input {
    width: 100%;
  }

  input {
    border-radius: 4px;
    border: 1px solid lightgray;
    margin-top: 10px;
  }
`;

export const SaveButton = styled.button`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 5px 10px;
  background: #a3057f;
  color: white;
  border: none;
  border-radius: 4px;
`;

export const CheckBox = styled.input`
  width: 30px;
  height: 30px;
  margin-top: 55px;
  margin-left: 7px;
`;

export const TooltipWrapper = styled.div`
  .tooltip-text {
    max-width: 320px;
  }
`;
