import React from "react";
import styled from "styled-components";
import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { Stack } from "components/Facelift/shared";
import { useBucketsMutation } from "hooks/useRQResponses";
import {
  AlertStack,
  Overlay,
  Title,
  Description,
  AlertCancel,
  SaveAction
} from "./styled";
import { Bucket } from "types/facelift";
import { getResponsesFromBucket } from "utils/facelift";

interface BucketCopyModalProps {
  bucket: Bucket;
  isCopyDialogOpen: boolean;
  setCopyDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
}

type ButtonEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

function BucketCopyModal({
  bucket,
  isCopyDialogOpen,
  setCopyDialogOpen,
  children
}: BucketCopyModalProps) {
  const { createBucket } = useBucketsMutation();

  function handleCopy(event: ButtonEvent) {
    event.stopPropagation();

    createBucket.mutate({
      ids: getResponsesFromBucket(bucket).map((response) => response.id),
      responses: getResponsesFromBucket(bucket),
      name: `${bucket.name} (Copy)`,
      description: bucket.description,
      color: bucket.color
    });
  }

  function handleCancel(event: ButtonEvent) {
    event.stopPropagation();
  }

  return (
    <AlertRoot
      open={isCopyDialogOpen}
      onOpenChange={(open) => setCopyDialogOpen(open)}
    >
      <AlertTrigger asChild>{children}</AlertTrigger>

      <AlertOverlay asChild>
        <Overlay />
      </AlertOverlay>

      <AlertContent asChild>
        <AlertStack type="column" gap={1}>
          <Stack
            type="column"
            gap={0.25}
            style={{ gridTemplateColumns: "minmax(0, 1fr)" }}
          >
            <AlertTitle asChild>
              <Title>Copy Bucket</Title>
            </AlertTitle>
            <AlertDescription asChild>
              <Description>
                Create a copy of this bucket for your own use.
              </Description>
            </AlertDescription>
          </Stack>
          <Stack type="row" gap={1} style={{ justifyContent: "flex-end" }}>
            <AlertDialog.Cancel asChild>
              <AlertCancel type="button" onClick={handleCancel}>
                Cancel
              </AlertCancel>
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <SaveAction type="button" onClick={handleCopy}>
                Create Copy
              </SaveAction>
            </AlertDialog.Action>
          </Stack>
        </AlertStack>
      </AlertContent>
    </AlertRoot>
  );
}

export default BucketCopyModal;

const AlertRoot = styled(AlertDialog.Root)``;

const AlertTrigger = styled(AlertDialog.Trigger)``;

const AlertOverlay = styled(AlertDialog.Overlay)``;

const AlertTitle = styled(AlertDialog.Title)``;

const AlertDescription = styled(AlertDialog.Description)``;

const AlertContent = styled(AlertDialog.Content)``;
