import React from "react";
import styled from "styled-components";

const StyledInputContainer = styled.div`
  input {
    width: 100%;
    padding: 12px;
    color: #555;
    font-size: 1.2rem;
    line-height: 1.2rem;
    border: 1px solid #dbdbdb;
    border-radius: 10px;

    &::placeholder {
      color: lightgray;
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }
`;

export const TextInput: React.FC<React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>> = (props) => {
  return (
    <StyledInputContainer>
      <input type="text" {...props} />
    </StyledInputContainer>
  );
};

export default TextInput;
