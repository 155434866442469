import React from "react";
import styled from "styled-components";
import { useQuestions, useGridResponses } from "hooks";
import { Stack } from "components/Facelift/shared";

function CurrentQuestionInformation() {
  const currentQuestion = useQuestions((s) => s.currentQuestion);
  const responses = useGridResponses();

  const questions = responses.reduce((acc, response) => {
    if (acc.hasOwnProperty(response.question_no) === false) {
      acc[response.question_no] = response.question;
    }

    return acc;
  }, {} as { [key: string]: string });

  if (currentQuestion === null) {
    return <p>Loading...</p>;
  }

  return (
    <Stack
      type="column"
      gap={2}
      style={{
        width: "100%",
        justifyContent: "stretch",
        padding: "1.5rem 0.5rem"
      }}
    >
      <Stack type="column" gap={3} style={{ justifyContent: "normal" }}>
        <QuestionTextContainer>
          <Stack type="row" gap={1}>
            <QuestionNumber>{currentQuestion}</QuestionNumber>
            <QuestionText>
              {currentQuestion !== null && `${questions[currentQuestion]}`}
            </QuestionText>
          </Stack>
        </QuestionTextContainer>
      </Stack>

      {/* <AdditionalGridInformation /> */}
    </Stack>
  );
}

export default CurrentQuestionInformation;

const QuestionNumber = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-family: "Century Gothic", "Open Sans", sans-serif;
  z-index: 2;
  border-radius: 50%;
  position: relative;
  background: white;
  color: #635ec0;
  font-weight: 600;
  align-self: flex-start;
`;

const QuestionTextContainer = styled.div`
  width: 100%;
`;

const QuestionText = styled.div`
  width: 100%;
  font-size: 16.5px;
  letter-spacing: 0.55px;
  text-align: left;
`;

const RespondentOverviewText = styled.div`
  width: 100%;
  font-size: 22px;
  letter-spacing: 0.15px;
  text-align: center;

  span {
    font-weight: bold;
    font-style: italic;
  }
`;

const ExpandIconContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(25%, -25%);
  width: 1.4rem;
  height: 1.4rem;
  background: #8989cb;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  cursor: pointer;

  svg: {
    fill: white;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const SentimentControl = styled.div`
  background: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  padding: 0.1rem 0.5rem;
  text-align: center;
  cursor: pointer;
`;

const OptionResults = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 0.15rem;
`;

const OptionResult = styled.div`
  color: white;
  background: #635ec0;
  text-align: center;
  padding: 0.25rem 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 2px;
`;

const SegmentedColorGuide = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 9999px;
`;

/*
const data: MyDatum[] = useMemo(() => {
    const positiveResponses = responses.filter(
      (response) => response.sentiments[gridSentiment].sentiment === "High"
    );
    const neutralResponses = responses.filter(
      (response) => response.sentiments[gridSentiment].sentiment === "Neutral"
    );
    const negativeResponses = responses.filter(
      (response) => response.sentiments[gridSentiment].sentiment === "Low"
    );

    return [
      {
        name: "Low",
        value: negativeResponses.length
      },
      {
        name: "Neutral",
        value: neutralResponses.length
      },
      {
        name: "High",
        value: positiveResponses.length
      }
    ];
  }, [currentQuestion, responses, gridSentiment, gridSentimentGroup]);

  const colors: { [key: string]: string } = {
    ["High"]: "#98C2FC",
    Neutral: "#FB7C63",
    ["Low"]: "#EB95D9"
  };

  function getColor({ data }: any) {
    return colors[data.name as string];
  }

  function getOverviewText() {
    const mostCommonSentiment = data.reduce(
      (acc: any, current: any) => {
        if (current.value > acc.value) {
          return current;
        }
        return acc;
      },
      { value: 0, name: "" }
    );

    if (mostCommonSentiment.name === "Low Trust")
      return "Respondents reacted distrustful to this prompt";
    if (mostCommonSentiment.name === "Neutral")
      return "Respondents reacted neutral to this prompt";
    if (mostCommonSentiment.name === "High Trust")
      return "Respondents reacted truthfully to this prompt";
    else return "Respondents displayed a high level of trust to this prompt";
  }

  function handleGridClick(
    data: ComputedDatum<MyDatum> & {
      color: string;
    }
  ) {
    const newGridSentimentGroup = data.data.name as SentimentGroupType;

    updateSearching(true);

    if (gridSentimentGroup === newGridSentimentGroup) {
      updateGridSentimentGroup(null);
    } else {
      updateGridSentimentGroup(newGridSentimentGroup);
    }
    
  const segmented = (
    <Stack
      type="column"
      gap={1}
      style={{
        gridTemplateColumns: "minmax(0, 1fr)"
      }}
    >
      {options.map((option) => {
        return (
          <Stack
            type="column"
            gap={0}
            style={{
              gridTemplateColumns: "minmax(0, 1fr)"
            }}
          >
            <QuestionText style={{ fontSize: "15px" }}>
              {capitalizeFirstLetter(option.displayName)}
            </QuestionText>
            <OptionResults>
              {option.results
                .filter((result) => result.name.length > 0)
                .filter((result) => currentQuestion in result.amounts)
                .map((result, index) => {
                  const percentage = Math.round(
                    (result.amounts[currentQuestion] / responses.length) * 100
                  );
                  const percentageString = `${percentage}%`;

                  return (
                    <OptionResult
                      style={{
                        flex: `1 1 ${percentageString}`,
                        background: getSegmentedBarColor(index)
                      }}
                    >
                      {percentage > 5 && (
                        <QuestionText style={{ textAlign: "center" }}>
                          {percentageString}
                        </QuestionText>
                      )}
                    </OptionResult>
                  );
                })}
            </OptionResults>
            <Stack type="row" gap={1}>
              {option.results
                .filter((result) => result.name.length > 0)
                .filter((result) => currentQuestion in result.amounts)
                .map((result, index) => {
                  return (
                    <Stack type="row" gap={0.25}>
                      <SegmentedColorGuide
                        style={{ background: getSegmentedBarColor(index) }}
                      />
                      <QuestionText style={{ fontSize: "14px" }}>
                        {capitalizeFirstLetter(result.name)}
                      </QuestionText>
                    </Stack>
                  );
                })}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );

  const sentiment = (
    <Stack
      type="column"
      gap={1}
      style={{ gridTemplateColumns: "minmax(0, 1fr)" }}
    >
      <Stack type="row" gap={1} style={{ justifyContent: "stretch" }}>
        <SentimentControl
          style={{
            color: gridSentiment === "disgust" ? "#635ec0" : "white",
            background:
              gridSentiment === "disgust" ? "white" : "rgba(255, 255, 255, 0.3)"
          }}
          onClick={() => updateGridSentiment("disgust")}
        >
          Trust
        </SentimentControl>
        <SentimentControl
          style={{
            color: gridSentiment === "joy" ? "#635ec0" : "white",
            background:
              gridSentiment === "joy" ? "white" : "rgba(255, 255, 255, 0.3)"
          }}
          onClick={() => updateGridSentiment("joy")}
        >
          Happiness
        </SentimentControl>
        <SentimentControl
          style={{
            color: gridSentiment === "anger" ? "#635ec0" : "white",
            background:
              gridSentiment === "anger" ? "white" : "rgba(255, 255, 255, 0.3)"
          }}
          onClick={() => updateGridSentiment("anger")}
        >
          Anger
        </SentimentControl>
      </Stack>

      <Stack
        type="row"
        gap={0}
        style={{
          gridTemplateColumns: "minmax(0, 1fr)",
          position: "relative",
          width: "100%",
          height: "100%",
          minHeight: "175px",
          background: "rgba(255, 255, 255, 0.3)",
          borderRadius: "8px",
          padding: "8px"
        }}
      >
        <ResponsiveBar
          data={data}
          theme={{ tooltip: { basic: { color: "#132142" } } }}
          margin={{ top: 10, right: 20, bottom: 50, left: 50 }}
          colors={getColor}
          label={(d) => `${d.data.value}`}
          labelTextColor={"#FFFFFF"}
          borderRadius={4}
          indexBy="name"
          padding={0.4}
          enableGridY={false}
          enableGridX={true}
          labelSkipWidth={50}
          labelSkipHeight={12}
          layout="horizontal"
          onClick={handleGridClick}
          // barComponent={Bar}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legend: "# of responses",
            legendPosition: "middle",
            legendOffset: 35
          }}
          animate={false}
          tooltip={({ id, value, color }) => <div></div>}
        />
        <ExpandIconContainer onClick={showModalGraph}>
          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20 5a1 1 0 00-1-1h-5a1 1 0 000 2h2.57l-3.28 3.29a1 1 0 000 1.42 1 1 0 001.42 0L18 7.42V10a1 1 0 001 1 1 1 0 001-1zM10.71 13.29a1 1 0 00-1.42 0L6 16.57V14a1 1 0 00-1-1 1 1 0 00-1 1v5a1 1 0 001 1h5a1 1 0 000-2H7.42l3.29-3.29a1 1 0 000-1.42z"
              fill="white"
            ></path>
          </svg>
        </ExpandIconContainer>
      </Stack>
    </Stack>
  );
*/
