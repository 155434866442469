import * as React from "react";
import { Formik, FormikActions, Field } from "formik";
import * as Yup from "yup";

import FormInput from "../ui/FormInput";

interface IFormValues {
  username: string;
  password: string;
}

class SnapchatLoginForm extends React.Component {
  initialFormValues = {
    username: "",
    password: ""
  };

  formValidation = Yup.object().shape({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required")
  });

  handleSubmit(values: IFormValues, actions: FormikActions<IFormValues>) {
    console.log("Form submitted, values here: ", values);
    actions.setSubmitting(false);
  }

  render() {
    return (
      <div className="form-input-group">
        <h2>Snapchat Login</h2>
        <Formik
          initialValues={this.initialFormValues}
          onSubmit={(values, actions) => this.handleSubmit(values, actions)}
          validationSchema={this.formValidation}
        >
          {(props) => {
            const { isSubmitting, handleSubmit } = props;

            return (
              <form onSubmit={handleSubmit}>
                <Field
                  name="username"
                  placeholder="Username"
                  component={FormInput}
                />

                <Field
                  name="password"
                  type="password"
                  placeholder="Password"
                  component={FormInput}
                />

                <button
                  type="submit"
                  className="theme-button"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

export default SnapchatLoginForm;
