import React, { useRef } from "react";
import { usePositioner, useMasonry } from "masonic";
import {
  useResponses,
  useGrid,
  useBuckets,
  useSize,
  useScroller,
  useGridResponses
} from "hooks";
import { ErrorBoundary } from "react-error-boundary";
import SkeletonCard from "components/Facelift/MasonryCard/Skeleton";
import GridHeader from "components/Facelift/GridHeader";
import * as sizes from "utils/facelift/gridMeasurements";
import { Bucket } from "types/facelift";

import FallbackGrid from "./FallbackGrid";
import { GridWrapper } from "./styled";

function clamp(items: number) {
  if (items >= 12) return 12;
  return items;
}

function SkeletonGrid() {
  const resetResponses = useResponses((s) => s.resetResponses);
  const isSearching = useGrid((s) => s.isSearching);
  const updateSearching = useGrid((s) => s.updateSearching);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);

  const containerRef = useRef(null);
  const { width, height } = useSize(containerRef);
  const { scrollTop, isScrolling } = useScroller(containerRef);
  const positioner = usePositioner(
    {
      width,
      columnWidth: sizes.COLUMN_WIDTH,
      columnCount: sizes.COLUMN_COUNT,
      columnGutter: sizes.GUTTER
    },
    [isSearching]
  );

  const items = useGridResponses();

  const skeletonResponses = Array.from({ length: clamp(items.length) }).map(
    (_, index) => ({
      id: index
    })
  );

  function handleReset() {
    updateSearching(true);
    resetResponses();
    updateSelectedBucket({} as Bucket);
  }

  return (
    <ErrorBoundary FallbackComponent={FallbackGrid} onReset={handleReset}>
      <GridWrapper style={{ width: sizes.GRID_WIDTH }} ref={containerRef}>
        <GridHeader />
        {useMasonry({
          positioner,
          items: skeletonResponses,
          render: SkeletonCard,
          height,
          scrollTop,
          isScrolling
        })}
      </GridWrapper>
    </ErrorBoundary>
  );
}

export default SkeletonGrid;
