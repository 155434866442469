import styled from "styled-components";

export const ScreenWrapper = styled.div`
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;

  .close {
    font-size: 1rem;
    cursor: pointer;
    align-self: flex-end;
    margin: 10px;

    &:hover {
      color: ${(props) => props.theme.pallet.primary};
    }
  }
`;

export const QuestionWrapper = styled.div`
  /* background-color: blue; */
  width: 80%;
  align-self: center;
  margin-top: 30%;
  padding-top: 25px;
`;

export const Container = styled.div`
  width: 100%;
  align-self: center;
  /* margin-top: 30%; */
  background-color: ${(props) => props.theme.pallet.white};
  padding: 20px;
  padding-top: 40px;
  border-radius: 10px;
  position: relative;
`;

export const Avatar = styled.div`
  border: 1px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -20px;
  left: calc(50% - 20px);

  img {
    width: 100%;
  }
`;

export const TextArea = styled.textarea`
  resize: none;
  overflow: hidden;
  height: 30px;
  width: 100%;
  background-color: transparent;
  font-weight: 600;
  color: ${(props) => props.theme.pallet.black};
  border: none;
  text-align: center;
  font-size: 20px;

  &:focus {
    outline: none;
  }
`;

export const AnswersHere = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.pallet.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-radius: 8px;
  margin-top: 10px;

  p {
    color: ${(props) => props.theme.pallet.grey};
    font-size: 14px;
  }
`;
