import * as React from "react";
import { Formik, FormikActions, Field } from "formik";
import * as Yup from "yup";
import axios, { AxiosResponse, AxiosError } from "axios";
import { toast } from "react-toastify";

import AppContext, { IAppContext, IIGAccounts } from "../../utils/AppContext";
import FormInput from "../ui/FormInput";
import { LoggedInAsContainer } from "./styled";

interface IFormValues {
  username: string;
  password: string;
}

interface IInstagramLoginFormState {
  submitErrors: string;
}

class InstagramLoginForm extends React.Component<{}, IInstagramLoginFormState> {
  initialFormValues = {
    username: "maurer_test_dude",
    password: "SomeP@ssw0rd1234!"
  };

  formValidation = Yup.object().shape({
    username: Yup.string().required("Required"),
    password: Yup.string().required("Required")
  });

  constructor(props: {}) {
    super(props);

    this.state = {
      submitErrors: ""
    };
  }

  async handleSubmit(
    values: IFormValues,
    actions: FormikActions<IFormValues>,
    addIGAccounts: (iGAccounts: IIGAccounts[]) => void
  ) {
    const data = {
      username: values.username,
      password: values.password
    };

    try {
      const response: AxiosResponse<any> = await axios.post<any>(
        `user/addigaccount`,
        data
      );

      addIGAccounts([response.data.data]);
      actions.setSubmitting(false);
      toast(`User ${values.username} added!`);
    } catch (error) {
      this.setState({ submitErrors: (error as any).errorMessage });
      actions.setSubmitting(false);
    }
  }

  render() {
    return (
      <AppContext.Consumer>
        {(context: IAppContext) => (
          <div className="form-input-group">
            <h2>Instagram Login</h2>

            <LoggedInAsContainer>
              <p>Logged in as:</p>
              <select
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  context.setIGAccount(
                    (e.currentTarget.value as unknown) as number
                  )
                }
                value={context.currentIGAccount}
              >
                <option value="-1">No Account Selected</option>
                {context.iGAccounts.map((iGAccount, index) => {
                  return (
                    <option key={index} value={index}>
                      {iGAccount.username}
                    </option>
                  );
                })}
              </select>
            </LoggedInAsContainer>

            <Formik
              initialValues={this.initialFormValues}
              onSubmit={(values, actions) =>
                this.handleSubmit(values, actions, context.addIGAccounts)
              }
              validationSchema={this.formValidation}
            >
              {(props) => {
                const { isSubmitting, handleSubmit } = props;

                return (
                  <form onSubmit={handleSubmit}>
                    {this.state.submitErrors.length > 0 && (
                      <p>{this.state.submitErrors}</p>
                    )}

                    <Field
                      name="username"
                      placeholder="Username"
                      component={FormInput}
                    />

                    <Field
                      name="password"
                      type="password"
                      placeholder="Password"
                      component={FormInput}
                    />

                    <button
                      type="submit"
                      className="theme-button"
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </form>
                );
              }}
            </Formik>
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

export default InstagramLoginForm;
