import styled, { createGlobalStyle } from "styled-components";
import { motion } from "framer-motion";
import image from "assets/images/blurry.png";

export const GlobalStyles = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  p {
    margin: 0;
  }

  :root {
    --gray-100: #f8f9fa;
    --gray-200: #e9ecef;
    --gray-300: #dee2e6;
    --gray-400: #ced4da;
    --gray-500: #eaeaea;
    --gray-600: #6c757d;
    --gray-700: #454343;
    --gray-800: #343a40;
    --gray-900: #212529;
    --dark-gray: rgba(46 53 57 / 1);
    --dark-gray-gradient: rgba(46 53 57 / 0.95);
    --dark-gray-hover: rgba(119, 136, 153, 50%);
    --shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    --sympler-blue: linear-gradient(90deg, #7ecefd 0%, #9ebdff 100%);
    --sympler-purple: linear-gradient(238.62deg, #D1A5F6 15.06%, #FC89CB 90.81%);
    --sympler-orange: linear-gradient(156.67deg, #FC7D49 14.16%, #FF788D 91.79%);
    --sympler-pink: linear-gradient(180.31deg, #F78DD3 4.18%, #FF7DA9 79.3%);
  }

  body {
    background: var(--gray-500);
    min-height: 100vh;
    max-width: 100vw;
    height: 100%;
    width: 100%;
    overscroll-behavior-x: none;
    overscroll-behavior-y: none;
  }

 
  .select-box {
    border: 1px dotted lightgray;
    background: #6495ed;
    opacity: 0.3;
    overflow: auto;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  position: relative;
`;

export const Content = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr;
  background-image: url(${image});
  object-fit: cover;
  overflow-y: hidden;
`;
