import * as React from "react";
import html2canvas from "html2canvas";

import {
  IPollObject,
  POST_OBJECT_TYPES
} from "../../../containers/PostBuilder";
import { Container, PollContainer, TextArea, YesNoContainer } from "./styled";

interface IPollBuilderProps {
  content: any;
  close: (action: any, poll?: IPollObject) => void;
}

interface IPolllBuilderState {
  textAreaValue: string;
  answerOne: string;
  answerTwo: string;
}

class PollBuilder extends React.Component<
  IPollBuilderProps,
  IPolllBuilderState
> {
  private textArea: React.RefObject<HTMLTextAreaElement>;
  private answerOneArea: React.RefObject<HTMLTextAreaElement>;
  private answerTwoArea: React.RefObject<HTMLTextAreaElement>;

  constructor(props: IPollBuilderProps) {
    super(props);

    console.log("pollBuilder props: ", props);

    if (props.content === null) {
      this.state = {
        textAreaValue: "",
        answerOne: "Yes",
        answerTwo: "No"
      };
    } else {
      this.state = {
        textAreaValue: props.content.question,
        answerOne: props.content.answerOne,
        answerTwo: props.content.answerTwo
      };
    }

    this.textArea = React.createRef();
    this.answerOneArea = React.createRef();
    this.answerTwoArea = React.createRef();

    this.exportAndExit = this.exportAndExit.bind(this);
  }

  componentDidMount() {
    if (this.props.content !== null) {
      this.updateTextAreaHeight(this.props.content.question);
      this.updateAnswerOneHeight(this.props.content.answerOne);
      this.updateAnswerTwoHeight(this.props.content.answerTwo);
    }

    this.textArea.current!.focus();
  }

  updateTextAreaHeight(textAreaValue: string) {
    this.setState({ textAreaValue }, () => {
      this.textArea.current!.style.height = 30 + "px";
      this.textArea.current!.style.height =
        this.textArea.current!.scrollHeight + "px";
    });
  }

  updateAnswerOneHeight(answerOneValue: string) {
    if (answerOneValue.length <= 20) {
      this.setState({ answerOne: answerOneValue }, () => {
        this.answerOneArea.current!.style.height = 27 + "px";
        this.answerOneArea.current!.style.height =
          this.answerOneArea.current!.scrollHeight + "px";
      });
    }
  }

  updateAnswerTwoHeight(answerTwoValue: string) {
    if (answerTwoValue.length <= 20) {
      this.setState({ answerTwo: answerTwoValue }, () => {
        this.answerTwoArea.current!.style.height = 27 + "px";
        this.answerTwoArea.current!.style.height =
          this.answerTwoArea.current!.scrollHeight + "px";
      });
    }
  }

  exportAndExit() {
    const questionElem: HTMLElement = document.querySelector(
      ".question-container"
    ) as HTMLElement;
    const yesNoElem: HTMLElement = document.querySelector(
      ".yes-no-container"
    ) as HTMLElement;

    html2canvas(questionElem!, { backgroundColor: null }).then(
      (questionCanvas) => {
        questionCanvas.toBlob((questionBlob) => {
          html2canvas(yesNoElem!, { backgroundColor: null }).then(
            (yesNoCanvas) => {
              yesNoCanvas.toBlob((yesNoBlob) => {
                if (!questionBlob || this.state.textAreaValue === "") {
                  this.props.close({ pollBuilderOpen: false });
                } else {
                  const questionUrl = URL.createObjectURL(questionBlob);
                  const yesNoUrl = URL.createObjectURL(yesNoBlob);

                  const poll = {
                    type: POST_OBJECT_TYPES.POLL,
                    questionURL: questionUrl,
                    yesNoURL: yesNoUrl,
                    width: this.props.content
                      ? this.props.content.width
                      : yesNoCanvas.width / 2,
                    height: this.props.content
                      ? this.props.content.height
                      : (yesNoCanvas.height + questionCanvas.height) / 2,
                    x: this.props.content
                      ? this.props.content.x
                      : 356 / 2 - yesNoCanvas.width / 2 / 2,
                    y: this.props.content ? this.props.content.y : 0,
                    question: this.state.textAreaValue,
                    answerOne: this.state.answerOne,
                    answerTwo: this.state.answerTwo
                  };

                  this.props.close({ pollBuilderOpen: false }, poll);
                }
              });
            }
          );
        });
      }
    );
  }

  render() {
    return (
      <Container>
        <p className="close" onClick={this.exportAndExit}>
          Done
        </p>
        <PollContainer>
          <div className="question-container">
            <TextArea
              ref={this.textArea}
              onChange={(e: React.FormEvent<HTMLTextAreaElement>) =>
                this.updateTextAreaHeight(e.currentTarget.value)
              }
              value={this.state.textAreaValue}
            />
          </div>
          <YesNoContainer className="yes-no-container">
            <div>
              <textarea
                ref={this.answerOneArea}
                value={this.state.answerOne}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  this.updateAnswerOneHeight(e.currentTarget.value)
                }
              />
            </div>
            <div>
              <textarea
                ref={this.answerTwoArea}
                value={this.state.answerTwo}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  this.updateAnswerTwoHeight(e.currentTarget.value)
                }
              />
            </div>
          </YesNoContainer>
        </PollContainer>
      </Container>
    );
  }
}

export default PollBuilder;
