import { createGlobalStyle } from "styled-components";
import styledNormalize from "styled-normalize";
import abstract from "../images/abstract.png";
import theme from "./theme";

export default createGlobalStyle`
  ${styledNormalize}

  html {
    height: 100%;
    font-family: ${theme.fonts.$fontFamily};
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    height: 100vh;
    background-color: ${theme.mm.white};
    /* background-color: #211549; */
    /* background-image: url(${abstract}); */
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: right -80px top 0px;
    overflow-x: hidden;

    @media screen and (min-width: ${theme.breakPoints.medium}) {
      background-size: 70%;
    }

    @media screen and (min-width: ${theme.breakPoints.large}) {
      background-size: 50%;
    }


    /* The following styles have to be applied at the body-scoped level 
        because when react-sortable-hoc creates a new element to be dragged
        in csv-editor, it affixes that element to the body and the ReactVirtualized
        __Table__Row styles are not applied, and thus must be applied to the body as
        well.
    */
    .sortableHelper {
      display: flex;
      border-bottom: 1px solid rgb(220, 220, 220);
      padding-top: 10px;
      padding-bottom: 10px;
      background: white;

      &.deleted {
        background: rgba(231, 76, 60, 0.15);
      }

      textarea {
        width: 100%;
        height: 100%;
      }

      .edit-buttons {
        margin-top: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 6px;
      }

      .edit-icon {
        cursor: pointer;

        &:hover {
          color: #a3057f;
        }
      }

      .undo-icon {
        cursor: pointer;
        margin-top: 10px;

        &:hover {
          color: #a3057f;
        }
      }

      .delete-icon {
        cursor: pointer;
        margin-top: 10px;

        &:hover {
          color: #e74c3c;
        }
      }
    }

    .cell {
      text-align: center;
      padding: 5px;
    }

    .media-column {
      position: relative;
      width: 100%;
      height: 100%;

      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        object-fit: contain;
        padding: 10px;
      }
    }

    .profile-picture {
      position: relative;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        object-fit: contain;
        padding: 10px;
      }

      .default-profile-picture {
        object-fit: contain;
      }
    }

    .ReactModal__Overlay {
      opacity: 0;
      transition: opacity 400ms ease-in-out
    }

    .ReactModal__Overlay--after-open{
      opacity: 1;
    }

    .ReactModal__Overlay--before-close{
      opacity: 0;
    }
  }

  #root {
    height: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h1 {
    font-weight: ${theme.fonts.$fontWeightExtraBold};
    font-size: 20px;

    @media screen and (min-width: ${theme.breakPoints.small}) {
      font-size: 30px;
    }

    @media screen and (min-width: ${theme.breakPoints.medium}) {
      font-size: 40px;
    }

    @media screen and (min-width: ${theme.breakPoints.large}) {
      font-size: 50px;
    }
  }

  h2, h3  {
    font-weight: ${theme.fonts.$fontWeightBold};
  }

  h4, h5, h6 {
    font-weight: ${theme.fonts.$fontWeightMedium};
  }

  h4 {
    font-size: 24px;
  }

  p {
    font-weight: ${theme.fonts.$fontWeightRegular};
    font-size: 12px;

    @media screen and (min-width: ${theme.breakPoints.small}) {
      font-size: 14px;
    }

    @media screen and (min-width: ${theme.breakPoints.medium}) {
      font-size: 16px;
    }
  }

  button:focus {
    outline: none;
  }
`;
