import styled from "styled-components";
import { ResizableBox } from "react-resizable";

export const DraggableTextContainer = styled.div<{ width: number }>`
  position: absolute;
  top: 22%;
  /* left: calc(50% - (${(props) => props.width}px)  / 2); */

  /* https://codepen.io/Elyx0/pen/bLldB */
  .top-bottom,
  .left-right {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .left-right:after {
    content:'';
    position:absolute;
    bottom:0;
    left:0;
    top:0;
    right:2px;
    height:100%;
    width:2px;
    background-image: linear-gradient(to top, #000 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 2px 11px;
    background-repeat: repeat-y;
  }

  .left-right:before {
    content:'';
    position:absolute;
    bottom:0;
    top:0;
    right:0px;
    height:100%;
    width:2px;
    background-image: linear-gradient(to top, #000 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 2px 11px;
    background-repeat: repeat-y;
  }
  
  .top-bottom:before {
    content:'';
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    height:2px;
    width:100%;
    background-image: linear-gradient(to right, #000 71%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 11px 2px;
    background-repeat: repeat-x;
  }

  .top-bottom:after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    right:0;
    height:2px;
    width:100%;
    background-image: linear-gradient(to right, #000 71%, rgba(255, 255, 255, 0) 0%);
    background-position: top;
    background-size: 11px 2px;
    background-repeat: repeat-x;
  }


  /* This show the resizable handle, it's not in 
     Resizable component because that actually
     doesn't get rendered.
  */
  &:hover {
    .top-bottom,
    .left-right {
      display: block;
    }

    .delete-button,
    .edit-button,
    .react-resizable-handle {
      display: block;
    }  
  }

  .draggable-handle {
    position: absolute;
    cursor: move;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* background-color: blue; */
  }
`;

export const DeleteButton = styled.div`
  position: absolute;
  color: ${(props) => props.theme.mm.red};
  left: 3px;
  top: 3px;
  /* top: -27px; */
  cursor: pointer;
  padding: 4px 4px 2px 4px;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
`;

export const EditButton = styled.div`
  position: absolute;
  color: white;
  left: 35px;
  top: 3px;
  /* left: 35px;
  top: -27px; */
  cursor: pointer;
  padding: 4px 4px 2px 4px;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
`;

export const Resizable = styled(ResizableBox)`
  .react-resizable-handle {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: 0;
    right: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgY2xhc3M9ImZlYXRoZXIgZmVhdGhlci1hcnJvdy1kb3duLXJpZ2h0Ij48bGluZSB4MT0iNyIgeTE9IjciIHgyPSIxNyIgeTI9IjE3Ij48L2xpbmU+PHBvbHlsaW5lIHBvaW50cz0iMTcgNyAxNyAxNyA3IDE3Ij48L3BvbHlsaW5lPjwvc3ZnPg==");
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
    display: none;
  }
`;

export const TextObject = styled.div`
  width: 100%;

  img {
    width: 100%;
  }
`;
