import create from "zustand";

interface SpinnerProps {
  showingSpinner: boolean | number;
  updateSpinner: (newValue: boolean | number) => void;
}

const [useSpinner] = create<SpinnerProps>((set) => ({
  showingSpinner: false,
  updateSpinner: (newValue: boolean | number) =>
    set({ showingSpinner: newValue })
}));

export default useSpinner;
