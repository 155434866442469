import * as React from "react";
import Modal, { Styles } from "react-modal";
import { X, Star } from "react-feather";

import { Aside, CloseContainer, ImageContainer, Content } from "./styled";
import BigTestImage from "../../../assets/images/big-test-image.png";

const modalStyles: Styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, .8)",
    zIndex: 2
  },
  content: {
    backgroundColor: "transparent",
    padding: 0,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    border: "none"
  }
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

interface IViewMoreModalProps {
  isOpen: boolean;
  toggleModal: (value: boolean) => void;
}

class ViewMoreModal extends React.Component<IViewMoreModalProps, {}> {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={() => this.props.toggleModal(false)}
        contentLabel="View More Modal"
        style={modalStyles}
      >
        <CloseContainer onClick={() => this.props.toggleModal(false)}>
          <X color="white" />
        </CloseContainer>

        <Aside>
          <h2>PGATour</h2>
          <p>New York, NY</p>
          <ul>
            <li>
              <button>#PGATour</button>
            </li>
            <li>
              <button>#pga</button>
            </li>
            <li>
              <button>#PGATour</button>
            </li>
            <li>
              <button>#pagegolfmemes</button>
            </li>
            <li>
              <button>#taylormadegolf</button>
            </li>
            <li>
              <button>#callawaygolf</button>
            </li>
            <li>
              <button>#dustinjustin</button>
            </li>
            <li>
              <button>#tigerwoods</button>
            </li>
            <li>
              <button>#rory</button>
            </li>
          </ul>
          <div className="star-container">
            <Star color="white" fill="white" />
          </div>
        </Aside>

        <Content>
          <ImageContainer>
            <img src={BigTestImage} alt="big test" />
          </ImageContainer>
        </Content>
      </Modal>
    );
  }
}

export default ViewMoreModal;
