import styled from "styled-components";

export const Wrapper = styled.div<{ hideSidebar: boolean }>`
  display: grid;
  /* grid-template-rows: 70px 1fr; */
  grid-template-rows: ${(props) => (props.hideSidebar ? "1fr" : "70px 1fr")};
  height: 100%;

  @media screen and (min-width: ${(props) => props.theme.breakPoints.medium}) {
    /* grid-template-columns: 80px 1fr; */
    grid-template-columns: ${(props) =>
      props.hideSidebar ? "1fr" : "80px 1fr"};
    grid-template-rows: initial;
  }
`;
