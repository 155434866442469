import { IOrganization } from "./organization";
import { IRoute } from "./routes";
import { ISocialMediaAccountsContext } from "./socialMediaAccounts";

enum Auth {
  SYMPLER = "SYMPLER",
  LOCALSTORAGE = "LOCALSTORAGE"
  // PERMISSIONS = "PERMISSIONS"
}

export enum AUTH_LOCAL_STORAGE {
  SYMPLER = "SYMPLER"
}

export interface ISetAuthCreds {
  token: string;
  orgId: number;
}

export type ISetAuth = (
  creds: ISetAuthCreds,
  routes: IRoute[],
  permissions: {}[],
  organizations: IOrganization[],
  socialMediaAccounts: ISocialMediaAccountsContext
) => void;

export type IRemoveAuth = () => void;

export interface IAuthContext {
  token?: string;
  orgId?: number;
  user?: {
    created_at: string;
    email: string;
    id: number;
    invitations?: string[];
    name: string;
    perimissions?: string[];
    updated_at: string;
  };
  setAuth: ISetAuth;
  removeAuth: IRemoveAuth;
}

export default Auth;
