import create from "zustand";
import { useSnapshotHistoryAPI } from "./useSnapshotHistory";
import { range } from "utils/facelift";
import { Response } from "types/facelift";

export interface GridProps {
  searchTerm: string;
  hoveredQuestion: string | null;
  updateHoveredQuestion: (val: string | null) => void;
  updateSearchTerm: (newSearchTerm: string, shouldCapture?: boolean) => void;
  isSearching: boolean;
  updateSearching: (newValue: boolean) => void;
  isDraggingCard: boolean;
  updateDraggingCard: (newValue: boolean) => void;
  modalCardIndex: number | null;
  updateModalCardIndex: (index: number | null) => void;
  displayStyle: "compact" | "list" | "graph";
  updateDisplayStyle: (newStyle: GridProps["displayStyle"]) => void;
  expandedIndex: number | null;
  updateExpandedIndex: (index: number | null) => void;
  isTopOfGrid: boolean;
  updateTopOfGrid: (newValue: boolean) => void;
  selectedUser: Response | null;
  updateSelectedUser: (
    newUser: Response | null,
    shouldCapture?: boolean
  ) => void;
  isStudyLoaded: boolean;
  updateStudyLoaded: (newValue: boolean) => void;
  scrollTop: number;
  setScrollTop: (newValue: number) => void;
  gridRef: HTMLDivElement | null;
  updateGridRef: (newValue: HTMLDivElement) => void;
}

const [useGrid, useGridAPI] = create<GridProps>((set) => ({
  searchTerm: "",
  hoveredQuestion: null,
  updateHoveredQuestion: (val: string | null) => set({ hoveredQuestion: val }),
  updateSearchTerm: (newSearchTerm: string, shouldCapture: boolean = false) => {
    if (shouldCapture) useSnapshotHistoryAPI.getState().captureSnapshot();

    set({ searchTerm: newSearchTerm });

    setTimeout(() => {
      set({ isSearching: false });
    }, range(1, 50));
  },
  isSearching: true,
  updateSearching: async (newValue: boolean) => set({ isSearching: newValue }),
  isDraggingCard: false,
  updateDraggingCard: (newValue: boolean) => set({ isDraggingCard: newValue }),
  modalCardIndex: null,
  updateModalCardIndex: (index: number | null) =>
    set({ modalCardIndex: index }),
  displayStyle: "compact",
  updateDisplayStyle: (newStyle: GridProps["displayStyle"]) =>
    set({ displayStyle: newStyle }),
  expandedIndex: null,
  updateExpandedIndex: (index: number | null) => {
    set({ expandedIndex: index });
  },
  isTopOfGrid: true,
  updateTopOfGrid: (newValue: boolean) => set({ isTopOfGrid: newValue }),
  selectedUser: null,
  updateSelectedUser: (
    newUser: Response | null,
    shouldCapture: boolean = false
  ) => {
    if (shouldCapture) useSnapshotHistoryAPI.getState().captureSnapshot();

    set({ selectedUser: newUser });

    setTimeout(() => {
      set({ isSearching: false });
    }, range(1, 50));
  },
  isStudyLoaded: false,
  updateStudyLoaded: (newValue: boolean) => set({ isStudyLoaded: newValue }),
  scrollTop: 0,
  setScrollTop: (newValue: number) => set({ scrollTop: newValue }),
  gridRef: null,
  updateGridRef: (newRef: HTMLDivElement) => set({ gridRef: newRef })
}));

export { useGridAPI };

export default useGrid;
