import create from "zustand";
import { useQuestionsAPI } from "hooks/useQuestions";
import { useGridAPI } from "hooks/useGrid";
import { useBucketsAPI } from "hooks/useBuckets";
import { useFilterOptionsAPI } from "hooks/useOptions";
import { range } from "utils/facelift";
import { Response, Bucket } from "types/facelift";
import { FilterState } from "utils/Site";

interface Snapshot {
  currentQuestion: number | null;
  selectedUser: Response | null;
  selectedBucket: Bucket;
  scrollTop: number;
  searchTerm: string;
  displayStyle: "list" | "compact" | "graph";
  selectedFilters: FilterState;
}

interface SnapshotHistoryProps {
  snapshots: Array<Snapshot>;
  captureSnapshot: () => void;
  previousSnapshot: () => void;
  resetSnapshots: () => void;
}

const [useSnapshotHistory, useSnapshotHistoryAPI] = create<
  SnapshotHistoryProps
>((set) => ({
  snapshots: [],
  captureSnapshot: () => {
    const { currentQuestion } = useQuestionsAPI.getState();
    const {
      selectedUser,
      scrollTop,
      searchTerm,
      displayStyle
    } = useGridAPI.getState();
    const { selectedBucket } = useBucketsAPI.getState();
    const { selectedFilters } = useFilterOptionsAPI.getState();

    const snapshot = {
      currentQuestion,
      selectedUser,
      selectedBucket,
      scrollTop,
      searchTerm,
      displayStyle,
      selectedFilters
    };

    set((state) => ({ snapshots: [...state.snapshots, snapshot] }));
  },
  previousSnapshot: () => {
    set((state) => {
      const snapshotCopies = [...state.snapshots];
      const previousState = snapshotCopies.pop();

      if (previousState !== undefined) {
        useQuestionsAPI.setState({
          currentQuestion: previousState.currentQuestion
        });
        useGridAPI.setState({
          selectedUser: previousState.selectedUser,
          searchTerm: previousState.searchTerm,
          displayStyle: previousState.displayStyle
        });
        useBucketsAPI.setState({
          selectedBucket: previousState.selectedBucket
        });
        useFilterOptionsAPI.setState({
          selectedFilters: previousState.selectedFilters
        });
      }

      setTimeout(() => {
        useGridAPI.setState({ isSearching: false });
        if (previousState !== undefined) {
          const { gridRef } = useGridAPI.getState();

          gridRef?.scroll({
            top: previousState.scrollTop,
            behavior: "smooth"
          });
        }
      }, range(1, 50));

      return { snapshots: snapshotCopies };
    });
  },
  resetSnapshots: () => set({ snapshots: [] })
}));

export { useSnapshotHistoryAPI };

export default useSnapshotHistory;
