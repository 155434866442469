import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Formik, Field, FormikActions } from "formik";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import qs from "query-string";
import { Helmet } from "react-helmet";
import { FullStoryAPI } from "react-fullstory";
import FormInput from "../../components/ui/FormInput";
import FormErrorText from "../../components/ui/FormErrorText";
import Logo from "../../assets/images/sympler.png";
import RegisterShadowSrc from "../../assets/images/register-shadow.png";
import MagicMirrorSrc from "../../assets/images/mm-mirror.png";
import {
  AuthContainer,
  SymplerLogo,
  Title,
  Form,
  SubmitButton,
  AuthContainerShadow,
  MagicMirrorGuy
} from "../../assets/styles/authStyles";
import { ReactComponent as BrandLogo } from "assets/images/circle-logo-with-text.svg";

interface IRegisterProps extends RouteComponentProps {}
interface IFormValues {
  name: string;
  password: string;
  password_confirmation: string;
}

const formValidation = Yup.object().shape({
  name: Yup.string().required("Please enter your full name"),
  password: Yup.string().required("A password is required"),
  password_confirmation: Yup.string()
    .required("Please confirm your password")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
});

const initialFormValues = {
  name: "",
  password: "",
  password_confirmation: ""
};

export const Register: React.FC<IRegisterProps> = (props) => {
  const history = useHistory();
  const [code, setCode] = useState("");
  const [submitError, setSubmitError] = useState("");

  useEffect(() => {
    const parsed = qs.parse(window.location.search);

    if (Object.keys(parsed).length === 0) {
      // No query string found?
      console.log("no query strings found");
      // return this.setState({
      //   loading: false,
      //   error: 'It looks like the page you requested doesn\'t exist.'
      // });
    } else if (!parsed.code) {
      // Does the code exist?
      console.log("no code found");
      // return this.setState({
      //   loading: false,
      //   error: 'It looks like the page you requested doesn\'t exist.'
      // });
    }

    console.log(parsed);

    // It's possible for the param to be a string or array.
    // Take the param and ensure it's in string form.
    const code: string = Array.isArray(parsed.code)
      ? parsed!.code[0]!
      : parsed!.code!;
    setCode(code);
    console.log("code: ", code);
  }, []);

  const handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    const data = {
      name: values.name,
      password: values.password,
      password_confirmation: values.password_confirmation
    };

    try {
      const response: AxiosResponse<any> = await axios.post<any>(
        `accept/${code}`,
        data
      );
      console.log("registerResponse: ", response);
      const user = response.data.response.user;
      console.log("user: ", user);

      const fullStoryIdInstantiated = await FullStoryAPI(
        "identifyAccount",
        code
      );
      console.log(
        "fullStoryIdInstantiated register: ",
        fullStoryIdInstantiated
      );

      actions.setSubmitting(false);
      toast.success("Succesfully registered. Now please login...");

      history.push("/login");
    } catch (error) {
      setSubmitError((error as any).errorMessage);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Magic Mirror - Register</title>
      </Helmet>
      <AuthContainer>
        {/* <SymplerLogo src={Logo} alt="Sympler Logo" /> */}
        <BrandLogo style={{ margin: "3rem 0", height: "60px" }} />
        <Formik
          initialValues={initialFormValues}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          validationSchema={formValidation}
        >
          {(props) => {
            const { handleSubmit } = props;

            return (
              <Form onSubmit={handleSubmit}>
                <Title>Register</Title>

                {submitError && <FormErrorText text={submitError} />}

                <Field
                  name="name"
                  placeholder="Full Name"
                  component={FormInput}
                />

                <Field
                  name="password"
                  type="password"
                  placeholder="Password"
                  component={FormInput}
                />

                <Field
                  name="password_confirmation"
                  type="password"
                  placeholder="Confirm Password"
                  component={FormInput}
                />

                <SubmitButton
                  type="submit"
                  text="Register"
                  // disabled={isSubmitting}
                />
              </Form>
            );
          }}
        </Formik>
        <AuthContainerShadow src={RegisterShadowSrc} alt="Drop shadow" />
      </AuthContainer>
      <MagicMirrorGuy src={MagicMirrorSrc} alt="Magic Mirror" />
    </React.Fragment>
  );
};

export default Register;
