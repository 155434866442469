import styled from "styled-components";

export const SectionGroup = styled.div`
  padding: 20px;
  background: rgba(64, 64, 64, 0.05);
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const TextFieldContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;

  .demo-wrapper {
    margin-bottom: 30px;
  }

  .rdw-editor-toolbar {
    margin-bottom: 0px;
  }

  .editor {
    background: white;
    margin-left: 1px;
    margin-right: 1px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const PageContainer = styled.div`
  padding: 50px;

  table {
    background: white;
    padding: 10px;
    width: 100%;
    overflow: hidden;
    border-radius: 10px;

    tr {
      border-bottom: 1px solid lightgray;

      th {
        background: rgba(45, 45, 45, 0.1);
      }

      td,
      th {
        padding: 15px;
      }

      td:first-child {
        width: 30px;
      }
    }
  }

  textarea {
    width: 100%;
    border: none;
    border-radius: 8px;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
  }

  hr {
    width: 100%;
    height: 1px;
    margin: 0px;
    margin-bottom: 10px;
    padding: 0px;
  }
`;

export const TableContainer = styled.div`
  background: rgba(230, 230, 230, 1);
  border-radius: 10px;
  border: 1px solid rgba(200, 200, 200, 0.5);
  overflow: hidden;

  thead {
    th:last-child {
      text-align: right;
      cursor: pointer;
    }
  }

  tbody {
    td {
      cursor: pointer;
    }
  }
`;

export const ModalContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
`;

export const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ModalInnerContainer = styled.div`
  position: fixed;
  width: 90%;
  height: 90%;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: scroll;

  input[type="text"] {
    width: 100%;
    padding: 16px;
    color: #555;
    font-size: 1.2rem;
    line-height: 1.2rem;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
  }

  input::placeholder {
    color: rgba(64, 64, 64, 0.3);
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`;
