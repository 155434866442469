import create from "zustand";
import { Toast } from "types/facelift";

interface ToastProps {
  toasts: Toast[];
  addToast: (toast: Toast) => void;
  removeToast: (toastId: string) => void;
}

const [useToasts] = create<ToastProps>((set, get) => ({
  toasts: [],
  addToast: (toast: Toast) => {
    set((state) => ({
      toasts: [...state.toasts, toast]
    }));
  },
  removeToast: (toastId: string) => {
    set((state) => ({
      toasts: state.toasts.filter((toast) => toast.id !== toastId)
    }));
  }
}));

export default useToasts;
