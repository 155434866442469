import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useResponses, useBuckets } from "hooks";
import { useBucketsQuery } from "hooks/useRQResponses";
import useApp from "context/AppContext";
import {
  getMediaInfo,
  getEmotionsFromResponse,
  getBucketsForResponse
} from "utils/facelift";
import tinycolor from "tinycolor2";
import hexToRgba from "hex-to-rgba";
import CardControls from "./CardControls";
import UserInfo from "./UserInfo";
import {
  Stack,
  DominantSentimentIcon,
  EmotionalPills
} from "components/Facelift/shared";
import UserResponse from "./UserResponse";
import { Response } from "types/facelift";
interface ListItemProps {
  data: Response;
  index?: number;
  width?: number;
  cardWidth?: number;
}

function ListItem({ data: response, cardWidth = 95 }: ListItemProps) {
  const selectedResponses = useResponses((s) => s.selectedResponses);
  const addSelectedResponse = useResponses((s) => s.addSelectedResponse);
  const removeSelectedResponse = useResponses((s) => s.removeSelectedResponse);
  const { emotions } = useApp();

  const { data } = useBucketsQuery();
  const buckets = data ?? [];

  const selectedBucketId = useBuckets((s) => s.selectedBucket.id);
  const selectedBucket = buckets.find(
    (bucket) => bucket.id === selectedBucketId
  );

  const responseBuckets = getBucketsForResponse(response.id, data);

  const [hasDeleted, setHasDeleted] = useState(false);

  const { isJpeg, isPng, isGif, isVideo, isAudio } = getMediaInfo(
    response.media_url
  );

  const isSelected = selectedResponses
    .map((resp: Response) => resp.id)
    .includes(response.id);

  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (event.shiftKey) {
      if (isSelected) {
        removeSelectedResponse(response);
      } else {
        addSelectedResponse(response);
      }
    }
  }

  const opacity = isSelected ? 0.25 : 1;

  const hasText = response.text.length > 0;
  const hasMedia = isJpeg || isPng || isGif || isVideo || isAudio;

  const firstBucketColor = responseBuckets[0]?.color;

  const bucketColor =
    selectedBucket !== undefined
      ? selectedBucket.color
      : firstBucketColor
      ? firstBucketColor
      : "#635ec0";

  const rgbaColor = hexToRgba(bucketColor, "0.65");
  const iconColor = hexToRgba(bucketColor, "0.075");

  const responseEmotions = getEmotionsFromResponse(response, emotions);

  return (
    <ListItemWrapper
      onClick={handleClick}
      animate={{ opacity }}
      borderColor={rgbaColor}
      style={{
        width: `${cardWidth}%`
      }}
    >
      <UserInfo response={response} />

      <UserResponse response={response} />

      <CardControlsContainer type="row" gap={1}>
        {hasMedia === false && <EmotionalPills response={response} />}

        <DominantSentimentIcon response={response} />

        <CardControls
          hasText={hasText}
          hasMedia={hasMedia}
          response={response}
          hasDeleted={hasDeleted}
          setHasDeleted={setHasDeleted}
        />
      </CardControlsContainer>

      <AnimatePresence exitBeforeEnter>
        {hasDeleted && (
          <RemovalOverlay
            key="removal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <RemovalOverlayText>Removed</RemovalOverlayText>
          </RemovalOverlay>
        )}
      </AnimatePresence>
    </ListItemWrapper>
  );
}

export default ListItem;

const ListItemWrapper = styled(motion.div)<{ borderColor: string }>`
  --br: 10px;

  width: 95%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 2fr 7fr;
  align-items: center;
  border-radius: var(--br);
  background: white;
  color: black;
  box-shadow: 0 0 10px 1px hsl(0 0% 80%);
  margin: 0 auto;
  position: relative;

  .hidden {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    box-shadow: 0 0 20px 2px ${(p) => p.borderColor};

    .hidden {
      opacity: 1;
    }
  }
`;

const RemovalOverlay = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
  width: 100%;
  height: 100%;
  background: rgba(99, 94, 192, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--br);
  backdrop-filter: blur(12px);
`;

const RemovalOverlayText = styled.div`
  /* background: rgba(255, 255, 255, 0.25); */
  border-radius: 9999px;
  padding: 1rem;
  color: hsl(0 0% 98%);
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 0.85px;
`;

const CardControlsContainer = styled(Stack)`
  position: absolute;
  top: 8%;
  right: 5%;
`;

const IconContainer = styled.div`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: inherit;
  color: inherit;

  svg {
    width: 100%;
    height: 100%;
    display: block;
    fill: inherit;
    color: inherit;
    stroke-width: 4px;
  }
`;
