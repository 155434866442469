import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useBucketsQuery, useBucketsMutation } from "hooks/useRQResponses";
import { useResponses } from "hooks";
import { useDrop, DropTargetMonitor } from "react-dnd";
import BucketCreateModal from "components/Facelift/Modals/BucketCreateModal";
import { Plus } from "@styled-icons/heroicons-solid/Plus";
import { getBucketColor, removeDups } from "utils/facelift";
import { DragObjectWithId, Response } from "types/facelift";

function BucketDropzone() {
  const clearSelectedResponses = useResponses((s) => s.clearSelectedResponses);
  const { data } = useBucketsQuery();
  const buckets = data ?? [];

  const { createBucket } = useBucketsMutation();

  const [isProfileDialogOpen, setProfileDialogOpen] = useState(false);

  function handleDrop(item: DragObjectWithId) {
    if (item?.responses === undefined) return;

    const responses = removeDups(item.responses);

    handleBucket(responses);
  }

  function handleCollect(dropTargetMonitor: DropTargetMonitor) {
    return {
      isOver: !!dropTargetMonitor.isOver()
    };
  }

  async function handleBucket(responses: Array<Response> = []) {
    // Must specify a bucket name or API will error
    const bucketName = `Bucket ${buckets.length + 1}`;
    const color = getBucketColor();
    const ids = responses.map((response) => (response.response_id !== undefined) ? response.response_id : response.id);

    createBucket.mutate({
      ids,
      responses,
      name: bucketName,
      description: "",
      color
    });

    clearSelectedResponses();
  }

  const [{ isOver }, dropRef] = useDrop({
    accept: ["card", "list-item"],
    drop: handleDrop,
    collect: handleCollect
  });

  return (
    <DropContainer>
      <BucketCreateModal
        isProfileDialogOpen={isProfileDialogOpen}
        setProfileDialogOpen={setProfileDialogOpen}
      >
        <BucketDropDarea ref={dropRef} isOver={isOver}>
          <CreateBucketText>
            <span>Click here</span> or <span>drag cards</span> <br /> to create
            buckets.
          </CreateBucketText>
        </BucketDropDarea>
      </BucketCreateModal>
    </DropContainer>
  );
}

export default BucketDropzone;

const DropContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 85%;
`;

const BucketDropDarea = styled.div<{ isOver: boolean }>`
  appearance: none;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  padding: 2rem 1rem;
  /* background: linear-gradient(
    180deg,
    #ffffff 58.51%,
    rgba(255, 255, 255, 0.0001) 149.18%
  ); */
  box-shadow: 0px 10px 40px rgba(19, 33, 66, 0.04);
  border: 1px dotted lightgray;
  transition: background 200ms ease-in-out;

  ${(props) =>
    props.isOver &&
    `
      background: rgba(119, 136, 153, 50%);
  `}
`;

const CreateBucketText = styled(motion.div)`
  text-align: center;
  color: black;
  letter-spacing: 0.5px;
  font-size: 0.8rem;

  span {
    font-weight: bold;
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  padding: 6px;
  background: #635ec0;
  border-radius: 100%;
  cursor: pointer;
  appearance: none;
`;

const PlusIcon = styled(Plus)`
  width: 100%;
  height: 100%;
  display: block;
  color: white;
`;
