import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useOptions, useGrid, useResponses, useQuestions } from "hooks";
import { Stack } from "components/Facelift/shared";
import { capitalizeFirstLetter } from "utils/facelift";
import { Response } from "types/facelift";
import { CheckCircle } from "@styled-icons/boxicons-regular/CheckCircle";

function SidebarContentsFilter() {
  const updateSearching = useGrid((s) => s.updateSearching);
  const selectedUser = useGrid((s) => s.selectedUser);
  const searchTerm = useGrid((s) => s.searchTerm);
  const gridResponses = useResponses((s) => s.gridResponses);
  const currentQuestion = useQuestions((s) => s.currentQuestion);
  const { options, selectedFilters, updateSelectedFilter } = useOptions();

  console.log(`options: `, options);

  return (
    <Wrapper>
      <OptionsWrapper>
        {options.map((option) => {
          return (
            <OptionWrapper key={option.displayName}>
              <OptionsContainer type="column" gap={0}>
                <OptionTitle>{option.displayName}</OptionTitle>
                {option.results.map((result, index) => {
                  const amount = gridResponses
                    .filter((resp: Response) => {
                      if (currentQuestion === null) return true;

                      return Number(resp.question_no) === currentQuestion;
                    })
                    .filter((resp: Response) => {
                      return (
                        resp[option.columnName as keyof Response] ===
                        result.name
                      );
                    })
                    .filter((resp: Response) => {
                      if (searchTerm.length === 0) return true;

                      return resp.text
                        .toLowerCase()
                        .includes(searchTerm?.toLowerCase());
                    })
                    .filter((resp: Response) => {
                      if (selectedUser === null) return true;

                      return resp.uid === selectedUser.uid;
                    });

                  const selected = selectedFilters[option.displayName]?.includes(
                    result.name
                  );

                  return (
                    <Option
                      key={index}
                      onClick={() => {
                        updateSearching(true);
                        updateSelectedFilter(option, result.name, true);
                      }}
                    >
                      <OptionAmount>{amount.length}</OptionAmount>
                      <OptionName>
                        {capitalizeFirstLetter(result.name)}
                      </OptionName>
                      <OptionSelectedContainer>
                        {selected && <SelectedIcon />}
                      </OptionSelectedContainer>
                    </Option>
                  );
                })}
              </OptionsContainer>
            </OptionWrapper>
          );
        })}
      </OptionsWrapper>
    </Wrapper>
  );
}

export default SidebarContentsFilter;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1rem;
`;

const OptionWrapper = styled.div`
  width: 100%;
  padding: 0.25rem 0;
`;

const OptionTitle = styled.div`
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Century Gothic", sans-serif;
  background: rgba(99, 94, 192, 0.75);
  padding: 0.5rem 1rem;
  color: hsl(0 0% 98%);
  text-align: left;
`;

// const OptionsContainer = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   align-items: flex-start;
//   padding: 0.25rem;
//   padding-left: 0.5rem;

//   div {
//     cursor: pointer;
//   }
// `;

// const Option = styled(motion.div)<{ selected: boolean }>`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin: 2px 0;
//   padding: 0 1rem;

//   ${(p) =>
//     p.selected &&
//     `
//     background: #635ec0;
//     color: white;
//     border-radius: 10px;
//   `}
// `;

// const OptionName = styled(motion.span)`
//   letter-spacing: 0.25px;
//   font-size: 14.5px;
// `;

// const OptionAmount = styled(motion.span)`
//   font-size: 14px;
// `;

const OptionsContainer = styled(Stack)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 5px;
  background: white;
  box-shadow: var(--shadow);
  overflow: hidden;
`;

const Option = styled(motion.div)`
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  background: inherit;
  transition: background 0.1s ease-in-out;
  display: grid;
  grid-gap: 1rem;
  align-items: center;
  grid-template-columns: auto 1fr auto;

  :hover {
    background: rgba(99, 94, 192, 0.2);
  }
`;

const OptionName = styled.div`
  color: hsl(0 0% 15%);
  letter-spacing: 0.5px;
  font-size: 13.5px;
  text-align: left;
  width: 100%;
`;

const OptionAmount = styled.div`
  background: hotpink;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  min-width: 7ch;
  color: hsl(0 0% 99%);
  letter-spacing: 0.5px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Century Gothic", sans-serif;
  white-space: nowrap;
  text-align: center;
`;

const OptionSelectedContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SelectedIcon = styled(CheckCircle)`
  width: 100%;
  height: 100%;
  display: block;
  color: rgba(99, 94, 192, 0.75);
`;
