import * as React from "react";
import {
  INotificationGroup,
  INotificationGroupUser
} from "../../types/magicMirror";
import styled from "styled-components";

interface NotificationGroupTrayProps {
  group: INotificationGroup;
  deselectGroup: () => void;
}

interface NotificationGroupTrayState {}

const NotificationTrayContainer = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

const NotificationTrayInnerContainer = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 40vw;
  background: rgba(245, 245, 245);
  border-left: 1px solid light-gray;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 20px;
  overflow: scroll;
`;

export default class NotificationGroupTray extends React.Component<
  NotificationGroupTrayProps,
  NotificationGroupTrayState
> {
  renderUserRow(user: INotificationGroupUser) {
    let name = "";
    let type = "";

    if (user.organization) {
      name = user.organization.name;
      type = "Organization";
    } else if (user.user) {
      name = user.user.name + " - " + user.user.email;
      type = "User";
    } else if (user.invitation) {
      name = user.invitation.email;
      type = "Invitation";
    } else if (user.email) {
      name = user.email;
      type = "Email";
    }

    return (
      <tr>
        <td>
          <strong>{type}</strong>
        </td>
        <td>{name}</td>
      </tr>
    );
  }

  render() {
    return (
      <NotificationTrayContainer onClick={this.props.deselectGroup}>
        <NotificationTrayInnerContainer>
          <h2>{this.props.group.name}</h2>
          <table>
            <thead>
              <tr>
                <th>
                  <strong>Type</strong>
                </th>
                <th>User</th>
              </tr>
            </thead>
            <tbody>
              {this.props.group.users.map((user) => {
                return this.renderUserRow(user);
              })}
            </tbody>
          </table>
        </NotificationTrayInnerContainer>
      </NotificationTrayContainer>
    );
  }
}
