import React from "react";
import styled, { keyframes } from "styled-components";
import { motion } from "framer-motion";
import { range } from "utils/facelift";

function SkeletonListItem() {
  return <Wrapper></Wrapper>;
}

export default SkeletonListItem;

const shine = keyframes`
  0% { 
    transform:translateX(-100%)
  },
	100% {
    transform:translateX(100%)
  }
`;

const Wrapper = styled(motion.div)`
  --bars-height: 28px;

  width: 95%;
  height: 215px;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  opacity: 0.1;
  position: relative;
  overflow: hidden;
  background: #7a7a7a;
  z-index: 0;

  ::after {
    animation: ${shine} 2s ease-in-out infinite;

    position: absolute;
    content: "";
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    z-index: 1;

    background: linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    );
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(0%, rgba(255, 255, 255, 0)),
      color-stop(50%, rgba(255, 255, 255, 0.8)),
      color-stop(99%, rgba(128, 186, 232, 0)),
      color-stop(100%, rgba(125, 185, 232, 0))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(
      left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* IE10+ */
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(128, 186, 232, 0) 99%,
      rgba(125, 185, 232, 0) 100%
    ); /* W3C */
  }
`;

const Header = styled(motion.div)`
  width: 25%;
  height: calc(var(--bars-height) + 12px);
  background: #333;
  border-radius: 5px;
`;

const Bars = styled.div`
  padding: 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
`;

const LongBar = styled(motion.div)`
  width: 100%;
  height: var(--bars-height);
  display: flex;
  background: #7a7a7a;
  border-radius: 5px;
`;

const SmallBar = styled(motion.div)`
  width: 25%;
  height: var(--bars-height);
  display: flex;
  background: #7a7a7a;
  border-radius: 5px;
`;
