import styled from "styled-components";

export const Aside = styled.div`
  background-color: white;
  width: 350px;
  height: 100%;
  color: black;
  padding: 20px;

  ul {
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    li {
      list-style-type: none;
      margin-bottom: 10px;
      margin-right: 10px;

      a {
        color: ${(props) => props.theme.pallet.black};
      }
    }
  }

  .star-container {
    background-color: ${(props) => props.theme.pallet.lightGrey};
    padding: 15px;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    align-self: center;
    justify-self: flex-end;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseContainer = styled.div`
  background-color: ${(props) => props.theme.pallet.funGreen};
  padding: 5px;
  position: absolute;
  width: 34px;
  height: 34px;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  transition: ${(props) => props.theme.animations.default};

  &:hover {
    transform: scale(1.2);
  }

  svg {
    cursor: pointer;
  }
`;

export const ImageContainer = styled.div`
  background: linear-gradient(135deg, #4b5acb 0%, #c134a2 50%, #fcbe45 100%);
  padding: 4px;
  max-width: 90%;
  max-height: 90% & > img {
    display: block;
    max-width: 100%;
  }
`;
