import * as React from "react";
import styled from "styled-components";

const Container = styled.section`
  background-color: ${(props) => props.theme.pallet.white};
  display: flex;
  justify-content: center;
  height: 180px;
`;

const StatContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  text-align: center;

  p {
    margin-bottom: 5px;
  }

  h1 {
    color: ${(props) => props.theme.pallet.sidebarBackground};
  }
`;

const TopLevelStats: React.SFC<{}> = () => (
  <Container>
    <StatContainer>
      <p>Story Views</p>
      <h1>0</h1>
    </StatContainer>
    <StatContainer>
      <p>Total Sent</p>
      <h1>0</h1>
    </StatContainer>
    <StatContainer>
      <p>Total Received</p>
      <h1>0</h1>
    </StatContainer>
    <StatContainer>
      <p>Page Visits</p>
      <h1>0</h1>
    </StatContainer>
  </Container>
);

export default TopLevelStats;
