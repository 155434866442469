import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Formik, Field, FormikActions } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import FormInput from "../../components/ui/FormInput";
import FormErrorText from "../../components/ui/FormErrorText";
import Checkbox from "../../components/ui/Checkbox";
import { ReactComponent as BrandLogo } from "assets/images/circle-logo-with-text.svg";
import RegisterShadowSrc from "../../assets/images/register-shadow.png";
import {
  AuthContainer,
  Title,
  Form,
  SubmitButton,
  AuthContainerShadow
} from "../../assets/styles/authStyles";
import { ForgotPassword } from "./styled";
import useApp from "context/AppContext";

interface ILoginProps extends RouteComponentProps {}

interface IFormValues {
  email: string;
  password: string;
  remember: boolean;
}

const formValidation = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Please enter your email"),
  password: Yup.string().required("Please enter you password"),
  remember: Yup.boolean()
});

const initialFormValues = {
  email: "",
  password: "",
  remember: false
};

export const Login: React.FC<ILoginProps> = () => {
  const { appCtx } = useApp();

  const handleSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    actions.setSubmitting(true);

    const errorMessage = await appCtx.login(values.email, values.password);
    if (errorMessage) {
      actions.setStatus(errorMessage);
    }

    actions.setSubmitting(false);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Sympler Login</title>
      </Helmet>
      <AuthContainer>
        {/* <SymplerLogo src={Logo} alt="Sympler Logo" /> */}
        <BrandLogo style={{ margin: "3rem 0", height: "60px" }} />

        <Formik
          initialValues={initialFormValues}
          onSubmit={(
            values: IFormValues,
            actions: FormikActions<IFormValues>
          ) => handleSubmit(values, actions)}
          validationSchema={formValidation}
        >
          {(props) => {
            const { handleSubmit, status } = props;

            return (
              <Form onSubmit={handleSubmit}>
                <Title>Login</Title>

                {status && <FormErrorText text={status} />}

                <Field name="email" placeholder="Email" component={FormInput} />

                <Field
                  name="password"
                  type="password"
                  placeholder="Password"
                  component={FormInput}
                />

                <SubmitButton
                  type="submit"
                  text="Login"
                  disabled={props.isSubmitting}
                />

                <Field name="checkbox" component={Checkbox} />

                <ForgotPassword to="forgot-password">
                  Forgot Your Password?
                </ForgotPassword>
              </Form>
            );
          }}
        </Formik>
        <AuthContainerShadow src={RegisterShadowSrc} alt="Drop shadow" />
      </AuthContainer>
      {/* <MagicMirrorGuy src={MagicMirrorSrc} alt="Magic Mirror" /> */}
    </React.Fragment>
  );
};

export default Login;
