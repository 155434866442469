import * as React from "react";
import styled from "styled-components";

import { LoadingContainer } from "../../../assets/styles/animations";

const PageWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 200px;
  border-radius: 10px;

  p {
    margin-top: 20px;
    margin-bottom: 0;
  }
`;

interface ILoadingProps {
  text: string;
}

const Loading: React.SFC<ILoadingProps> = ({ text }) => (
  <PageWrap>
    <Container>
      <LoadingContainer>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </LoadingContainer>
      <p>{text}</p>
    </Container>
  </PageWrap>
);

export default Loading;
