import styled from "styled-components";
import searchIcon from "../../../assets/images/search.svg";

export const InputContainer = styled.div`
  display: flex;
  border: 2px solid ${(props) => props.theme.pallet.black};
  border-radius: ${(props) => props.theme.utils.borderRadius};
  align-items: center;
  padding-right: 20px;

  .loading {
    animation: App-logo-spin infinite 2s linear;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export const Input = styled.input`
  padding: 20px;
  padding-left: 55px;
  width: 100%;
  background: url(${searchIcon}) no-repeat scroll 20px 17px;
  border: none;

  &:focus {
    outline: none;
  }
`;

export const AutoSuggestContainer = styled.div`
  position: relative;
  width: 50%;
`;

export const SuggestionsContainer = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 1;

  ul {
    margin: 0;
    border-left: 1px solid ${(props) => props.theme.pallet.black};
    border-right: 1px solid ${(props) => props.theme.pallet.black};
    border-bottom: 1px solid ${(props) => props.theme.pallet.black};
    max-height: 400px;
    overflow-y: scroll;
  }
`;

export const Suggestion = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 20px;
  cursor: pointer;

  &:hover {
    background-color: #00db93;
    color: white;
  }

  > svg {
    margin-right: 10px;
  }
`;

export const NoSuggestionsContainer = styled.div`
  background-color: white;
  position: absolute;
  left: 0;
  right: 0;
  padding-left: 20px;
`;
