import * as React from "react";
import { Formik, Field, FormikActions } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Helmet } from "react-helmet";

import FormInput from "../../components/ui/FormInput";
import FormErrorText from "../../components/ui/FormErrorText";
import Logo from "../../assets/images/sympler.png";
import RegisterShadowSrc from "../../assets/images/register-shadow.png";
import MagicMirrorSrc from "../../assets/images/mm-mirror.png";
import {
  AuthContainer,
  SymplerLogo,
  Title,
  Form,
  SubmitButton,
  AuthContainerShadow,
  MagicMirrorGuy
} from "../../assets/styles/authStyles";
import { SuccessMessage } from "./styled";

interface IForgotPasswordState {
  submitSuccess: boolean;
}

interface IFormValues {
  email: string;
}

class ForgotPassword extends React.Component<{}, IForgotPasswordState> {
  constructor(props: IForgotPasswordState) {
    super(props);

    this.state = {
      submitSuccess: false
    };
  }

  formValidation = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required("Please enter your email")
  });

  initialFormValues = { email: "" };

  async handleSubmit(values: IFormValues, actions: FormikActions<IFormValues>) {
    const data = { email: values.email };

    try {
      await axios.post<any>(`auth/forgotpassword`, data);

      actions.setStatus("");
      this.setState({ submitSuccess: true });
    } catch (error) {
      actions.setStatus((error as any).errorMessage);
      actions.setSubmitting(false);
      this.setState({ submitSuccess: false });
    }
  }

  renderForm() {
    return (
      <React.Fragment>
        <AuthContainer>
          <SymplerLogo src={Logo} alt="Sympler Logo" />
          <Formik
            initialValues={this.initialFormValues}
            onSubmit={(values, actions) => this.handleSubmit(values, actions)}
            validationSchema={this.formValidation}
          >
            {(props) => {
              const { handleSubmit, isSubmitting, status } = props;

              return (
                <Form onSubmit={handleSubmit}>
                  <Title>Forgot Password</Title>

                  {status && <FormErrorText text={status} />}

                  <Field
                    name="email"
                    placeholder="Email"
                    component={FormInput}
                  />

                  <SubmitButton
                    type="submit"
                    text="Reset"
                    loadingText="loading"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  />
                </Form>
              );
            }}
          </Formik>
          <AuthContainerShadow src={RegisterShadowSrc} alt="Drop shadow" />
        </AuthContainer>
        <MagicMirrorGuy src={MagicMirrorSrc} alt="Magic Mirror" />
      </React.Fragment>
    );
  }

  renderSuccess() {
    return (
      <React.Fragment>
        <AuthContainer>
          {/* <MarsWrigleyLogo src={MarsWriglySrc} alt="Mars Wrigley Logo" /> */}
          <Form>
            <SuccessMessage>An email has been sent.</SuccessMessage>
          </Form>
          <AuthContainerShadow src={RegisterShadowSrc} alt="Drop shadow" />
        </AuthContainer>
        <MagicMirrorGuy src={MagicMirrorSrc} alt="Magic Mirror" />
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Magic Mirror - Forgot Password</title>
        </Helmet>
        {this.state.submitSuccess ? this.renderSuccess() : this.renderForm()}
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
