import create from "zustand";

interface OverlaysProps {
  showingCreateBucketOverlay: boolean;
  showCreateBucketOverlay: () => void;
  hideCreateBucketOverlay: () => void;
  showingBucketsOverlay: boolean;
  showBucketsOverlay: () => void;
  hideBucketsOverlay: () => void;
  toggleBucketsOverlay: () => void;
  showingCombineBucketOverlay: boolean;
  showCombineBucketOverlay: () => void;
  hideCombineBucketOverlay: () => void;
  toggleCombineBucketOverlay: () => void;
  showingSidebarContent: boolean;
  showBucketContainer: () => void;
  hideBucketContainer: () => void;
  toggleBucketContainer: () => void;
  showingContextMenu: boolean;
  showContextMenu: () => void;
  hideContextMenu: () => void;
  toggleContextMenu: () => void;
  showingInsightsGraph: boolean;
  showInsightsGraph: () => void;
  hideInsightsGraph: () => void;
  toggleInsightsGraph: () => void;
  sidebarContentType: SidebarContentType;
  updateSidebarContent: (newType: SidebarContentType) => void;
  showingModalGraph: boolean;
  showModalGraph: () => void;
  hideModalGraph: () => void;
  toggleModalGraph: () => void;
}

type SidebarContentType = "buckets" | "filters";

const [useOverlays] = create<OverlaysProps>((set) => ({
  showingCreateBucketOverlay: false,
  showCreateBucketOverlay: () =>
    set(() => ({ showingCreateBucketOverlay: true })),
  hideCreateBucketOverlay: () =>
    set(() => ({ showingCreateBucketOverlay: false })),
  showingBucketsOverlay: false,
  showBucketsOverlay: () => set(() => ({ showingBucketsOverlay: true })),
  hideBucketsOverlay: () => set(() => ({ showingBucketsOverlay: false })),
  toggleBucketsOverlay: () =>
    set((state) => ({
      showingSidebarContent: !state.showingSidebarContent,
      showingSearchOverlay: false,
      showingFiltersOverlay: false
    })),
  showingCombineBucketOverlay: false,
  showCombineBucketOverlay: () =>
    set(() => ({ showingCombineBucketOverlay: true })),
  hideCombineBucketOverlay: () =>
    set(() => ({ showingCombineBucketOverlay: false })),
  toggleCombineBucketOverlay: () =>
    set((state) => ({
      showingCombineBucketOverlay: !state.showingCombineBucketOverlay
    })),
  showingSidebarContent: false,
  showBucketContainer: () => set({ showingSidebarContent: true }),
  hideBucketContainer: () => set({ showingSidebarContent: false }),
  toggleBucketContainer: () =>
    set((state) => ({
      showingSidebarContent: !state.showingSidebarContent,
      showingFiltersOverlay: false,
      showingSearchOverlay: false
    })),
  showingContextMenu: false,
  showContextMenu: () => set({ showingContextMenu: true }),
  hideContextMenu: () => set({ showingContextMenu: false }),
  toggleContextMenu: () =>
    set((state) => ({
      showingContextMenu: !state.showingContextMenu
    })),
  showingInsightsGraph: false,
  showInsightsGraph: () => set({ showingInsightsGraph: true }),
  hideInsightsGraph: () => set({ showingInsightsGraph: false }),
  toggleInsightsGraph: () =>
    set((state) => ({
      showingInsightsGraph: !state.showingInsightsGraph
    })),
  sidebarContentType: "buckets",
  updateSidebarContent: (newType: SidebarContentType) =>
    set({ sidebarContentType: newType }),
  showingModalGraph: false,
  showModalGraph: () => set({ showingModalGraph: true }),
  hideModalGraph: () => set({ showingModalGraph: false }),
  toggleModalGraph: () =>
    set((state) => ({
      showingModalGraph: !state.showingModalGraph
    }))
}));

export default useOverlays;
