import * as React from "react";
import { IUserPermission } from "../types/user";
import { IOrganization } from "../types/organization";

export interface IIGAccounts {
  id: string;
  username: string;
  password: string;
  authToken: string;
  userId: string;
  apiInstanceId: string;
}

export interface IAppContext {
  token?: string;
  iGAccounts: IIGAccounts[];
  currentIGAccount: number;
  menu: any;
  permissions: IUserPermission[];
  selectedOrganizationId?: number;
  organizations: IOrganization[];
  setAuthentication: (
    token: string,
    orgId: number,
    iGAccounts: IIGAccounts[],
    currentIGAccount: number,
    menu: any
  ) => void;
  addIGAccounts: (iGAccounts: IIGAccounts[]) => void;
  setIGAccount: (index: number) => void;
  refreshApp: () => void;
}

const AppContext = React.createContext<IAppContext>({
  token: "",
  iGAccounts: [],
  currentIGAccount: -1,
  menu: "",
  permissions: [],
  organizations: [],
  setAuthentication: (
    token: string,
    orgId: number,
    iGAccounts: IIGAccounts[],
    currentIGAccount: number,
    menu: any
  ) => {},
  addIGAccounts: (iGAccounts: IIGAccounts[]) => {},
  setIGAccount: (index: number) => {},
  refreshApp: () => {}
});

export default AppContext;
