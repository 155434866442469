import * as React from "react";
import Autosuggest from "react-autosuggest";
import axios from "axios";
import { Loader, Hash, MapPin, User } from "react-feather";
import { toast } from "react-toastify";

import {
  InputContainer,
  Input,
  AutoSuggestContainer,
  SuggestionsContainer,
  Suggestion,
  NoSuggestionsContainer
} from "./styled";
import { IPostSearch } from "../../../types/quickLook";

const theme = {
  container: {
    position: "relative"
  } as React.CSSProperties,
  suggestionsList: {
    listStyleType: "none",
    padding: 0
  }
};

// Imagine you have a list of languages that you'd like to autosuggest.
// eslint-disable-next-line
const languages = [
  {
    name: "C",
    year: 1972
  },
  {
    name: "C#",
    year: 1972
  },
  {
    name: "Elm",
    year: 2012
  },
  {
    name: "ElmOne",
    year: 2012
  },
  {
    name: "ElmTwo",
    year: 2012
  },
  {
    name: "ElmThree",
    year: 2012
  },
  {
    name: "ElmFour",
    year: 2012
  },
  {
    name: "ElmFive",
    year: 2012
  },
  {
    name: "ElmSix",
    year: 2012
  },
  {
    name: "ElmSeven",
    year: 2012
  }
];

interface IAutoCompleteProps {
  setPostSearch: (postSearch: IPostSearch) => void;
}

interface IAutoCompleteState {
  value: string;
  loadingSuggestions: boolean;
  suggestions: any;
  noSuggestions: boolean;
}

interface IRenderSuggestionsContainerProps {
  containerProps: any;
  children: any;
  query: any;
}

class AutoComplete extends React.Component<
  IAutoCompleteProps,
  IAutoCompleteState
> {
  constructor(props: IAutoCompleteProps) {
    super(props);

    this.state = {
      value: "",
      loadingSuggestions: false,
      suggestions: [],
      noSuggestions: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(
      this
    );
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(
      this
    );
    this.renderInputComponent = this.renderInputComponent.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
  }

  onChange(
    event: React.SyntheticEvent<HTMLInputElement>,
    { newValue, method }: { newValue: string; method: string }
  ) {
    console.log("newValue: ", newValue);
    console.log("method: ", method);
    this.setState({
      value: newValue
    });
  }

  shouldRenderSuggestions(value: string) {
    return value.trim().length > 2;
  }

  async onSuggestionsFetchRequested({ value }: { value: string }) {
    this.setState({ loadingSuggestions: true });

    const igAccount = `ig_account=3`;
    const query = "query=golf";

    try {
      const response = await axios.get(`ig/search?${igAccount}&${query}`);
      console.log("response: ", response);

      let suggestions = [];
      if (response && response.data && response.data.response) {
        suggestions = response.data.response.list;
      }

      // const suggestions = inputLength === 0 ? [] : languages.filter(lang =>
      //   lang.name.toLowerCase().slice(0, inputLength) === inputValue
      // );

      // console.log('suggestions: ', suggestions);
      const isInputBlank = value.trim() === "";

      this.setState({
        suggestions,
        noSuggestions: !isInputBlank && suggestions.length === 0,
        loadingSuggestions: false
      });
    } catch (error) {
      toast.error((error as any).errorMessage);
    }
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
      noSuggestions: false
    });
  }

  renderInputComponent(inputProps: any) {
    return (
      <InputContainer>
        <Input {...inputProps} />
        {this.state.loadingSuggestions && <Loader className="loading" />}
      </InputContainer>
    );
  }

  renderSuggestionsContainer({
    containerProps,
    children,
    query
  }: IRenderSuggestionsContainerProps) {
    return (
      <SuggestionsContainer {...containerProps}>
        {children}
      </SuggestionsContainer>
    );
  }

  renderSuggestion(suggestion: any) {
    // console.log('renderSuggestion: ', suggestion);

    if (suggestion.hashtag !== undefined) {
      return (
        <Suggestion>
          <Hash /> {suggestion.hashtag.name}
        </Suggestion>
      );
    }

    if (suggestion.place !== undefined) {
      return (
        <Suggestion>
          <MapPin /> {suggestion.place.title}
        </Suggestion>
      );
    }

    if (suggestion.user !== undefined) {
      return (
        <Suggestion>
          <User /> {suggestion.user.username}
        </Suggestion>
      );
    }

    return <Suggestion>Error</Suggestion>;
  }

  getSuggestionValue(suggestion: any) {
    console.log("suggestion: ", suggestion);

    if (suggestion.hashtag !== undefined) {
      const postSearch = {
        url: "hashtagsearch",
        queryParam: "hashtag",
        queryParamValue: suggestion.hashtag.name
      };
      this.props.setPostSearch(postSearch);

      return suggestion.hashtag.name;
    }

    if (suggestion.place !== undefined) {
      const postSearch = {
        url: "locationsearch",
        queryParam: "location_id",
        queryParamValue: suggestion.place.title
      };
      this.props.setPostSearch(postSearch);

      return suggestion.place.title;
    }

    if (suggestion.user !== undefined) {
      const postSearch = {
        url: "peoplesearch",
        queryParam: "query",
        queryParamValue: suggestion.user.username
      };
      this.props.setPostSearch(postSearch);

      return suggestion.user.username;
    }
  }

  render() {
    const {
      value,
      suggestions
    }: { value: string; suggestions: any } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: "by #, story, location and people",
      value,
      onChange: this.onChange
    };

    // Finally, render it!
    return (
      <AutoSuggestContainer>
        <Autosuggest
          suggestions={suggestions}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderInputComponent={this.renderInputComponent}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          theme={theme}
        />
        {this.state.noSuggestions && (
          <NoSuggestionsContainer>
            <p>No results found.</p>
          </NoSuggestionsContainer>
        )}
      </AutoSuggestContainer>
    );
  }
}

export default AutoComplete;
