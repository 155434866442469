import * as React from "react";
import styled from "styled-components";
import { FieldProps } from "formik";

const Label = styled.label`
  color: ${(props) => props.theme.mm.white};
`;

const Input = styled.input`
  margin-right: 10px;
  margin-bottom: 20px;
`;

const CheckBox: React.StatelessComponent<FieldProps<any>> = ({
  field,
  form: { touched, errors },
  ...props
}) => {
  return (
    <Label>
      <Input type="checkbox" {...field} {...props} />
      Remember Me
    </Label>
  );
};

export default CheckBox;
