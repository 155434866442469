import React, { useRef } from "react";
import { usePositioner, useResizeObserver, useMasonry } from "masonic";
import {
  useResponses,
  useGrid,
  useBuckets,
  useSize,
  useScroller,
  useGridResponses
} from "hooks";
import { ErrorBoundary } from "react-error-boundary";
import SkeletonListItem from "components/Facelift/SkeletonListItem";
import GridHeader from "components/Facelift/GridHeader";
import * as sizes from "utils/facelift/gridMeasurements";
import { Bucket } from "types/facelift";

import FallbackGrid from "./FallbackGrid";
import { GridWrapper } from "./styled";

function clamp(items: number) {
  if (items >= 8) return 8;
  return items;
}

function SkeletonGrid() {
  const resetResponses = useResponses((s) => s.resetResponses);
  const gridResponses = useResponses((s) => s.gridResponses);
  const isSearching = useGrid((s) => s.isSearching);
  const updateSearching = useGrid((s) => s.updateSearching);
  const updateSelectedBucket = useBuckets((s) => s.updateSelectedBucket);

  const containerRef = useRef(null);
  const { width, height } = useSize(containerRef);
  const { scrollTop, isScrolling } = useScroller(containerRef);
  const positioner = usePositioner(
    { width, columnCount: 1, columnGutter: 16 },
    [isSearching]
  );
  const resizeObserver = useResizeObserver(positioner);

  function handleReset() {
    updateSearching(true);
    resetResponses();
    updateSelectedBucket({} as Bucket);
  }

  const items = useGridResponses();

  const skeletonResponses = Array.from({
    length: clamp(items.length)
  }).map((_, index) => ({
    id: index
  }));

  return (
    <ErrorBoundary FallbackComponent={FallbackGrid} onReset={handleReset}>
      <GridWrapper style={{ width: sizes.GRID_WIDTH }} ref={containerRef}>
        <GridHeader />
        {useMasonry({
          positioner,
          resizeObserver,
          items: skeletonResponses,
          render: SkeletonListItem,
          height,
          scrollTop,
          isScrolling
        })}
      </GridWrapper>
    </ErrorBoundary>
  );
}

export default SkeletonGrid;
