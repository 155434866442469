import React, { useEffect } from "react";
import { Wrapper } from "./styled";
import { useResponses, useGrid, useOverlays } from "hooks";
import useApp from "context/AppContext";
import Grid from "./Grid";
import List from "./List";
import Graph from "./Graph";
import SkeletonGrid from "./SkeletonGrid";
import SkeletonList from "./SkeletonList";

function MasonryGrid() {
  const isSearching = useGrid((s) => s.isSearching);
  const displayStyle = useGrid((s) => s.displayStyle);

  return (
    <Wrapper>
      {displayStyle === "compact" && (
        <>{isSearching === false ? <Grid /> : <SkeletonGrid />}</>
      )}

      {displayStyle === "list" && (
        <>{isSearching === false ? <List /> : <SkeletonList />}</>
      )}

      {displayStyle === "graph" && (
        <>{isSearching === false ? <Graph /> : <div>Loading!</div>}</>
      )}
    </Wrapper>
  );
}

export default MasonryGrid;
